define("travis/serializers/preference", ["exports", "travis/serializers/v3"], function (_exports, _v) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _v.default.extend({
    primaryKey: 'name',
    serialize: function serialize() {
      var _this$_super = this._super.apply(this, arguments),
          value = _this$_super.value;

      return {
        'preference.value': value
      };
    }
  });

  _exports.default = _default;
});