define("travis/templates/components/remove-log-popup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Bu2zWrSF",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h3\",true],[10,\"class\",\"h3--red\"],[8],[0,\"\\n  This action will remove the log permanently!\\n\"],[9],[0,\"\\n\"],[7,\"p\",true],[10,\"class\",\"text-big\"],[8],[0,\"\\n  Do you want to continue?\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"buttons\"],[8],[0,\"\\n  \"],[7,\"button\",true],[10,\"class\",\"button-delete plans-button--red\"],[10,\"title\",\"Permanently remove this log\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"removeLog\"],null]],[10,\"type\",\"button\"],[8],[0,\"\\n    Yes, remove log\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"button\",true],[10,\"trype\",\"button\"],[10,\"title\",\"Close modal\"],[10,\"class\",\"modal-close pointer\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"toggleRemoveLogModal\"],null]],[8],[0,\"\\n  \"],[5,\"svg-image\",[],[[\"@name\",\"@class\"],[\"icon-failed\",\"icon\"]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/remove-log-popup.hbs"
    }
  });

  _exports.default = _default;
});