define("travis/templates/build", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "U/svKcX0",
    "block": "{\"symbols\":[],\"statements\":[[5,\"build-layout\",[],[[\"@build\",\"@repo\"],[[23,0,[\"build\"]],[23,0,[\"repo\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/build.hbs"
    }
  });

  _exports.default = _default;
});