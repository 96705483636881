define("travis/adapters/branch", ["exports", "travis/adapters/v3"], function (_exports, _v) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _v.default.extend({
    query: function query(store, type, _query) {
      var repoId = _query.repository_id;
      delete _query.repository_id;
      var url = "".concat(this.urlPrefix(), "/repo/").concat(repoId, "/branches");
      return this.ajax(url, 'GET', _query);
    },
    findRecord: function findRecord(store, type, id) {
      return this.ajax(this.urlPrefix() + id, 'GET');
    }
  });

  _exports.default = _default;
});