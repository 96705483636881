define("travis/templates/components/sync-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TsWvb5KJ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"user\",\"isSyncing\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"button is-syncing\"],[8],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"loading-indicator--white label-align\"],[8],[0,\"\\n      \"],[7,\"i\",true],[8],[9],[0,\"\\n      \"],[7,\"i\",true],[8],[9],[0,\"\\n      \"],[7,\"i\",true],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"label-align\"],[8],[0,\"\\n      Syncing from \"],[1,[28,\"vcs-name\",[[23,0,[\"user\",\"vcsType\"]]],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"button\",true],[10,\"class\",\"button\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"sync\"],null]],[10,\"type\",\"button\"],[8],[0,\"\\n    \"],[5,\"svg-image\",[],[[\"@name\",\"@class\"],[\"icon-restart\",\"icon white\"]]],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"label-align\"],[8],[0,\"\\n      Sync account\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"sync-last-container\"],[8],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"sync-last\"],[8],[0,\"\\n      Last synced\\n      \"],[1,[28,\"format-time\",[[23,0,[\"user\",\"syncedAt\"]]],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/sync-button.hbs"
    }
  });

  _exports.default = _default;
});