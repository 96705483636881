define("travis/components/status-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    classNames: ['status-icon', 'icon'],
    classNameBindings: ['status'],
    attributeBindings: ['label:aria-label', 'label:title'],
    label: Ember.computed('status', function () {
      return "Job ".concat(this.status);
    }),
    hasPassed: Ember.computed('status', function () {
      return ['passed', 'approved'].includes(this.status);
    }),
    hasFailed: Ember.computed('status', function () {
      return ['failed', 'rejected'].includes(this.status);
    }),
    hasErrored: Ember.computed.equal('status', 'errored'),
    wasCanceled: Ember.computed.equal('status', 'canceled'),
    isRunning: Ember.computed('status', function () {
      var runningStates = ['started', 'queued', 'booting', 'received', 'created', 'pending'];
      return runningStates.includes(this.status);
    }),
    isEmpty: Ember.computed.empty('status')
  });

  _exports.default = _default;
});