define("travis/templates/components/paper-block", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+mOHC2BJ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,0,[\"title\"]]],null,{\"statements\":[[0,\"  \"],[7,\"h3\",true],[10,\"class\",\"title\"],[8],[0,\"\\n    \"],[1,[23,0,[\"title\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/paper-block.hbs"
    }
  });

  _exports.default = _default;
});