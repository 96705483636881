define("travis/components/org-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    tagName: 'li',
    classNames: ['media', 'account'],
    classNameBindings: ['type', 'selected'],
    account: null,
    selected: Ember.computed.reads('account.selected'),
    name: Ember.computed.or('account.name', 'account.login'),
    routeName: Ember.computed('account.isOrganization', function () {
      var isOrganization = this.get('account.isOrganization');
      return isOrganization ? 'organization' : 'account';
    }),
    routeModel: Ember.computed('account.isOrganization', function () {
      var isOrganization = this.get('account.isOrganization');
      if (isOrganization) return this.account.login;
    })
  });

  _exports.default = _default;
});