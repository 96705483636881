define("travis/utils/expandable-record-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.ArrayProxy.extend({
    isLoaded: false,
    isLoading: false,
    promise: Ember.computed(function () {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve) {
        var observer = function observer() {
          if (_this.isLoaded) {
            resolve(_this);

            _this.removeObserver('isLoaded', observer);

            return true;
          }
        };

        if (!observer()) {
          return _this.addObserver('isLoaded', observer);
        }
      });
    }),
    load: function load(array) {
      var _this2 = this;

      this.set('isLoading', true);
      return array.then(function () {
        array.forEach(function (record) {
          if (!_this2.includes(record)) {
            return _this2.pushObject(record);
          }
        });

        _this2.set('isLoading', false);

        return _this2.set('isLoaded', true);
      });
    },
    observe: function observe(collection) {
      return collection.addArrayObserver(this, {
        willChange: 'observedArrayWillChange',
        didChange: 'observedArraydidChange'
      });
    },
    observedArrayWillChange: function observedArrayWillChange(array, index, removedCount) {
      var i, len, object, removedObjects, results;
      removedObjects = array.slice(index, index + removedCount);
      results = [];

      for (i = 0, len = removedObjects.length; i < len; i++) {
        object = removedObjects[i];
        results.push(this.removeObject(object));
      }

      return results;
    },
    observedArraydidChange: function observedArraydidChange(array, index, removedCount, addedCount) {
      var addedObjects, i, len, object, results;
      addedObjects = array.slice(index, index + addedCount);
      results = [];

      for (i = 0, len = addedObjects.length; i < len; i++) {
        object = addedObjects[i]; // TODO: I'm not sure why deleted objects get here, but I'll just filter them
        // for now

        if (!object.get('isDeleted')) {
          if (!this.includes(object)) {
            results.push(this.pushObject(object));
          } else {
            results.push(void 0);
          }
        } else {
          results.push(void 0);
        }
      }

      return results;
    },
    pushObject: function pushObject(record) {
      var content = this.content;

      if (content) {
        if (!content.includes(record)) {
          return content.pushObject(record);
        }
      }
    }
  });

  _exports.default = _default;
});