define("travis/templates/components/svg-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CYfLJmcw",
    "block": "{\"symbols\":[\"@height\",\"@width\",\"@alt\",\"@class\",\"@name\"],\"statements\":[[1,[28,\"svg-jar\",[[23,5,[]]],[[\"class\",\"alt\",\"width\",\"height\"],[[23,4,[]],[23,3,[]],[23,2,[]],[23,1,[]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/svg-image.hbs"
    }
  });

  _exports.default = _default;
});