define("travis/utils/paginated-collection-promise", ["exports", "travis/utils/paginated-collection"], function (_exports, _paginatedCollection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _paginatedCollection.default.extend(Ember.PromiseProxyMixin, {
    promise: Ember.computed('content', function () {
      var content = this.content;
      var promise = new Ember.RSVP.Promise(function (resolve, reject) {
        content.then(function (value) {
          resolve(_paginatedCollection.default.create({
            content: value
          }));
        }, function (error) {
          reject(error);
        });
      });
      return promise;
    })
  });

  _exports.default = _default;
});