define("travis/routes/integration/bitbucket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    auth: Ember.inject.service(),
    router: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (this.auth.isSignedInWith('bitbucket')) {
        this.router.transitionTo('account.repositories');
      }
    }
  });

  _exports.default = _default;
});