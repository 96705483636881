define("travis/templates/builds", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RviFq6IO",
    "block": "{\"symbols\":[\"build\"],\"statements\":[[4,\"if\",[[23,0,[\"model\",\"isLoaded\"]]],null,{\"statements\":[[0,\"  \"],[7,\"ul\",true],[10,\"class\",\"build-list\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"builds\"]]],null,{\"statements\":[[0,\"      \"],[5,\"builds-item\",[],[[\"@build\",\"@vcsType\"],[[23,1,[]],[23,0,[\"repo\",\"vcsType\"]]]]],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"      \"],[5,\"no-builds\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"flex flex--center flex--space-between flex--v-center\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"showBuildHistoryLink\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[8],[0,\"\\n        Previous build runs are available at\\n        \"],[5,\"external-link-to\",[[12,\"class\",\"link link--underline link--teal\"]],[[\"@href\"],[[23,0,[\"buildHistoryLink\"]]]],{\"statements\":[[0,\"\\n          travis-ci.org\\n        \"]],\"parameters\":[]}],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,0,[\"displayShowMoreButton\"]]],null,{\"statements\":[[0,\"      \"],[7,\"p\",true],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"loadMoreBuilds\",\"isRunning\"]]],null,{\"statements\":[[0,\"          \"],[5,\"loading-indicator\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[5,\"show-more-button\",[],[[\"@click\"],[[28,\"perform\",[[23,0,[\"loadMoreBuilds\"]]],null]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[5,\"loading-indicator\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/builds.hbs"
    }
  });

  _exports.default = _default;
});