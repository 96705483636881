define("travis/components/forms/form-slider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['travis-form__field--slider'],
    max: '',
    min: '',
    value: '',
    step: 1,
    list: '',
    onInit: function onInit() {},
    onChange: function onChange() {},
    input: function input(_ref) {
      var target = _ref.target;
      this.set('value', target.value);
      this.onChange(this.value);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.onInit(this.elementId);
    }
  });

  _exports.default = _default;
});