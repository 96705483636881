define("travis/controllers/search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    auth: Ember.inject.service(),
    features: Ember.inject.service(),
    tabStates: Ember.inject.service(),
    statusImages: Ember.inject.service(),
    repositories: Ember.inject.service(),
    repo: Ember.computed.alias('repositories.searchResults.firstObject'),
    tab: Ember.computed.alias('tabStates.mainTab'),
    build: Ember.computed.alias('repo.currentBuild'),
    job: Ember.computed.alias('repo.currentBuild.jobs.firstObject')
  });

  _exports.default = _default;
});