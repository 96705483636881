define("travis/utils/json-parser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = parseWithDefault;

  function parseWithDefault(json, defaultValue) {
    try {
      return JSON.parse(json) || defaultValue;
    } catch (e) {
      return defaultValue;
    }
  }
});