define("travis/templates/components/resubscribe-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dyJidtXe",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"account\",\"hasSubscriptionPermissions\"]]],null,{\"statements\":[[0,\"  \"],[7,\"a\",true],[11,\"href\",[23,0,[\"subscription\",\"billingUrl\"]]],[10,\"class\",\"button--green manage-subscription resubscribe\"],[8],[0,\"\\n    Resubscribe\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"button--green manage-subscription disabled resubscribe\"],[8],[0,\"\\n    Resubscribe\\n    \"],[5,\"ember-tooltip\",[],[[\"@text\"],[\"You do not have permission to resubscribe\"]]],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/resubscribe-button.hbs"
    }
  });

  _exports.default = _default;
});