define("travis/services/flashes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var messageTypeToIcon = {
    notice: 'icon-flag',
    success: 'flash-success',
    error: 'flash-error'
  };
  var messageTypeToPreamble = {
    notice: 'Heads up!',
    success: 'Hooray!',
    error: 'Oh no!'
  };
  var messageTypeToCloseButton = {
    notice: true,
    success: false,
    error: true
  };

  var _default = Ember.Service.extend({
    auth: Ember.inject.service(),
    store: Ember.inject.service(),
    currentUser: Ember.computed.alias('auth.currentUser'),
    // This changes when scrolling to adjust flash messages to fixed
    topBarVisible: true,
    init: function init() {
      this._super.apply(this, arguments);

      this.setup();
    },
    setup: function setup() {
      this.set('flashes', []);
    },
    messages: Ember.computed('flashes.[]', function () {
      var flashes = this.flashes;
      var model = [];

      if (flashes.length) {
        model.pushObjects(flashes.toArray());
      }

      return model.uniq();
    }),
    // TODO: when we rewrite all of the place where we use `loadFlashes` we could
    // rewrite this class and make the implementation better, because right now
    // it's really complicated for just displaying a flash message (especially
    // that we show only one at the moment anyway). We still get some error
    // messages from API responses in V2 that way, so I think that cleaning this
    // up once we're using V3 would be a good point.
    loadFlashes: function loadFlashes() {
      var _this = this;

      var flashes = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      flashes.forEach(function (flash) {
        var type = Object.keys(flash)[0];
        var _flash$type = flash[type],
            message = _flash$type.message,
            _flash$type$preamble = _flash$type.preamble,
            preamble = _flash$type$preamble === void 0 ? messageTypeToPreamble[type] : _flash$type$preamble,
            aboveOverlay = _flash$type.aboveOverlay;
        var icon = messageTypeToIcon[type];
        var closeButton = messageTypeToCloseButton[type];
        var item = {
          type: type,
          message: message,
          icon: icon,
          preamble: preamble,
          closeButton: closeButton,
          aboveOverlay: aboveOverlay
        };

        _this.flashes.unshiftObject(item);

        if (!closeButton) _this.removeFlash(item);
      });
    },
    removeFlash: function removeFlash(msg) {
      var _this2 = this;

      setTimeout(function () {
        Ember.run(_this2, function () {
          if (_this2.flashes.length > 0) {
            return _this2.flashes.removeObject(msg);
          }
        }); // Fadeout is currently done separatly with css, and completes at 7s. Keeping the message around longer than that can result in weird situations
        // where reloading a page can result in a message showing again that you thought was gone.
      }, 7000);
    },
    close: function close(msg) {
      return this.flashes.removeObject(msg);
    },
    clear: function clear() {
      this.setup();
    },
    display: function display(type, message, preamble) {
      var aboveOverlay = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

      if (!['error', 'notice', 'success'].includes(type)) {
        // eslint-disable-next-line
        console.warn("WARNING: <service:flashes> display(type, message) function can only handle 'error', 'notice' and 'success' types");
      }

      this.loadFlashes([_defineProperty({}, type, {
        message: message,
        preamble: preamble,
        aboveOverlay: aboveOverlay
      })]);
    },
    success: function success(message) {
      var preamble = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : messageTypeToPreamble['success'];
      var aboveOverlay = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      this.display('success', message, preamble, aboveOverlay);
    },
    error: function error(message) {
      var preamble = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : messageTypeToPreamble['error'];
      var aboveOverlay = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      this.display('error', message, preamble, aboveOverlay);
    },
    notice: function notice(message) {
      var preamble = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : messageTypeToPreamble['notice'];
      var aboveOverlay = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      this.display('notice', message, preamble, aboveOverlay);
    }
  });

  _exports.default = _default;
});