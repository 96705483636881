define("travis/routes/repo", ["exports", "travis/routes/basic", "travis/models/repo", "travis/mixins/scroll-reset"], function (_exports, _basic, _repo, _scrollReset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = _basic.default.extend(_scrollReset.default, {
    store: Ember.inject.service(),
    tabStates: Ember.inject.service(),
    auth: Ember.inject.service(),
    features: Ember.inject.service(),
    slug: null,
    onRunningTab: Ember.computed('features.showRunningJobsInSidebar', 'tabStates.sidebarTab', function () {
      var showRunningJobsInSidebar = this.get('features.showRunningJobsInSidebar');
      var sidebarTab = this.get('tabStates.sidebarTab');
      return showRunningJobsInSidebar && sidebarTab === 'running';
    }),
    activate: function activate() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      this._super(args);

      if (this.get('auth.signedIn')) {
        if (this.onRunningTab) {
          return;
        }

        if (!this.get('tabStates.sidebarTab', 'search')) {
          this.tabStates.set('sidebarTab', 'owned');
        }

        this.set('tabStates.mainTab', null);
      }
    },
    titleToken: function titleToken(model) {
      return model.get('slug');
    },
    setupController: function setupController(controller, model) {
      if (model && !model.get) {
        model = this.store.find('repo', model.id);
      }

      return controller.set('repo', model);
    },
    serialize: function serialize(repo) {
      // slugs are sometimes unknown ???
      var slug = Ember.getWithDefault(repo, 'slug', 'unknown/unknown');

      var _slug$split = slug.split('/'),
          _slug$split2 = _slicedToArray(_slug$split, 2),
          owner = _slug$split2[0],
          name = _slug$split2[1];

      var provider = repo.get('vcsProvider.urlPrefix');
      return {
        provider: provider,
        owner: owner,
        name: name
      };
    },
    model: function model(_ref) {
      var provider = _ref.provider,
          owner = _ref.owner,
          name = _ref.name;
      var slug = "".concat(owner, "/").concat(name);
      this.set('slug', slug);
      return _repo.default.fetchBySlug(this.store, slug, provider);
    },
    actions: {
      error: function error(_error) {
        _error.slug = this.slug;
        return true;
      }
    }
  });

  _exports.default = _default;
});