define("travis/routes/plans/index", ["exports", "travis/routes/basic"], function (_exports, _basic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basic.default.extend({
    auth: Ember.inject.service(),
    router: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (this.auth.signedIn) {
        this.router.transitionTo('account.billing');
      }
    }
  });

  _exports.default = _default;
});