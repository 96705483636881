define("travis/templates/components/limit-concurrent-builds", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zVJKfdnU",
    "block": "{\"symbols\":[],\"statements\":[[5,\"travis-switch\",[],[[\"@active\",\"@description\",\"@onToggle\"],[[23,0,[\"enabled\"]],[23,0,[\"description\"]],[28,\"perform\",[[23,0,[\"toggle\"]]],null]]]],[0,\"\\n\"],[5,\"external-link-to\",[[12,\"class\",\"settings-tooltip\"]],[[\"@href\",\"@title\"],[\"https://docs.travis-ci.com/user/customizing-the-build/#limiting-concurrent-jobs\",\"about the concurrency setting\"]],{\"statements\":[[0,\"\\n  \"],[5,\"ember-tooltip\",[],[[\"@text\"],[\"Read more about concurrent jobs\"]]],[0,\"\\n  \"],[5,\"svg-image\",[],[[\"@name\",\"@class\"],[\"icon-help\",\"icon-help\"]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[23,0,[\"enabled\"]]],null,{\"statements\":[[0,\"  \"],[7,\"input\",true],[11,\"value\",[23,0,[\"value\"]]],[11,\"onchange\",[28,\"action\",[[23,0,[]],[23,0,[\"limitChanged\"]]],[[\"value\"],[\"target.value\"]]]],[10,\"pattern\",\"/^[0-9]+$/\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,0,[\"toggle\",\"isRunning\"]]],null,{\"statements\":[[0,\"  \"],[5,\"loading-indicator\",[],[[\"@inline\"],[true]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/limit-concurrent-builds.hbs"
    }
  });

  _exports.default = _default;
});