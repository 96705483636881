define("travis/templates/components/job-infrastructure-notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VeIn2wRK",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[23,0,[\"features\",\"enterpriseVersion\"]]],null,{\"statements\":[[4,\"if\",[[23,0,[\"auth\",\"signedIn\"]]],null,{\"statements\":[[4,\"if\",[[23,0,[\"isDeprecatedOrRetiredMacImage\"]]],null,{\"statements\":[[0,\"      \"],[5,\"notice-banner\",[],[[],[]],{\"statements\":[[0,\"\\n        \"],[1,[23,0,[\"deprecatedOrRetiredMacImageMessage\"]],true],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,0,[\"isPreciseEOL\"]]],null,{\"statements\":[[0,\"      \"],[5,\"notice-banner\",[],[[],[]],{\"statements\":[[0,\"\\n        This job \"],[1,[23,0,[\"conjugatedRun\"]],false],[0,\" on our \"],[7,\"b\",true],[8],[0,\"Precise\"],[9],[0,\" environment,\\n        which is in the process of being decommissioned.\\n        Please update to a newer Ubuntu version by specifying\\n        \"],[7,\"code\",true],[8],[0,\"dist: xenial\"],[9],[0,\" in your \"],[7,\"em\",true],[8],[0,\".travis.yml\"],[9],[0,\".\\n      \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null],[4,\"if\",[[23,0,[\"isWindows\"]]],null,{\"statements\":[[0,\"  \"],[5,\"notice-banner\",[],[[],[]],{\"statements\":[[0,\"\\n    Windows builds are in early access stage. Please head to the\\n    \"],[5,\"external-link-to\",[],[[\"@content\",\"@href\"],[\"Travis CI Community\",\"https://travis-ci.community/c/windows\"]]],[0,\"\\n    forum to get help or post ideas.\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/job-infrastructure-notification.hbs"
    }
  });

  _exports.default = _default;
});