define("travis/components/button-activate-all-github", ["exports", "travis/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _config$githubApps$ap = _environment.default.githubApps.appName,
      appName = _config$githubApps$ap === void 0 ? 'travis-ci' : _config$githubApps$ap;

  var _default = Ember.Component.extend({
    tagName: '',
    accounts: Ember.inject.service(),
    owner: Ember.computed.reads('accounts.user'),
    githubId: Ember.computed.reads('owner.githubId'),
    isVisible: Ember.computed.bool('githubId'),
    activateAllUrl: Ember.computed('githubId', function () {
      var githubId = this.githubId;
      return "".concat(_environment.default.githubAppsEndpoint, "/").concat(appName, "/installations/new/permissions?suggested_target_id=").concat(githubId);
    }),
    actions: {
      activateAll: function activateAll() {
        window.location.href = this.activateAllUrl;
      }
    }
  });

  _exports.default = _default;
});