define("travis/templates/components/forms/form-switch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ckm9pcI8",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"switch-inner\"],[8],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"on\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"onText\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\",true],[8],[1,[23,0,[\"onText\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[5,\"svg-image\",[],[[\"@name\",\"@class\",\"@alt\"],[\"icon-passed\",\"icon-on\",\"on\"]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"off\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"offText\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\",true],[8],[1,[22,\"offText\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[5,\"svg-image\",[],[[\"@name\",\"@class\",\"@alt\"],[\"icon-failed\",\"icon-off\",\"off\"]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/forms/form-switch.hbs"
    }
  });

  _exports.default = _default;
});