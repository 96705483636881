define("travis/templates/components/add-ssh-key", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pnK4jKz7",
    "block": "{\"symbols\":[],\"statements\":[[7,\"form\",false],[3,\"action\",[[23,0,[]],[28,\"perform\",[[23,0,[\"save\"]]],null]],[[\"on\"],[\"submit\"]]],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"form-elem\"],[8],[0,\"\\n    \"],[5,\"input\",[[12,\"class\",\"ssh-description\"],[12,\"placeholder\",\"Description\"]],[[\"@value\"],[[23,0,[\"description\"]]]]],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"form-elem\"],[8],[0,\"\\n    \"],[5,\"textarea\",[[12,\"class\",\"ssh-value\"],[12,\"placeholder\",\"SSH Key\"]],[[\"@value\",\"@rows\"],[[23,0,[\"value\"]],\"10\"]]],[0,\"\"],[4,\"if\",[[23,0,[\"valueError\"]]],null,{\"statements\":[[0,\"      \"],[7,\"p\",true],[10,\"class\",\"form-error-message\"],[8],[0,\"\\n        \"],[1,[23,0,[\"valueError\"]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"form-elem form-footer\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"save\",\"isRunning\"]]],null,{\"statements\":[[0,\"      \"],[5,\"loading-indicator\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"form-actions\"],[8],[0,\"\\n        \"],[7,\"input\",true],[10,\"value\",\"Add\"],[10,\"class\",\"form-submit\"],[10,\"type\",\"submit\"],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"p\",true],[10,\"class\",\"form-hint\"],[8],[0,\"Private key in PEM format only.\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/add-ssh-key.hbs"
    }
  });

  _exports.default = _default;
});