define("travis/templates/components/loading-indicator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7yKCkT9a",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\",true],[10,\"class\",\"loading-indicator\"],[8],[0,\"\\n  \"],[7,\"i\",true],[8],[9],[0,\"\\n\"],[4,\"unless\",[[23,0,[\"single\"]]],null,{\"statements\":[[0,\"    \"],[7,\"i\",true],[8],[9],[7,\"i\",true],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/loading-indicator.hbs"
    }
  });

  _exports.default = _default;
});