define("travis/mixins/components/form-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var OPTIONS_FOR_SEARCH = 5;
  var CSS_CLASSES = {
    DISABLED: 'travis-form__field-component--disabled',
    FIELD_COMPONENT: 'travis-form__field-component',
    FIELD_SELECT: 'travis-form__field-select'
  };

  var _default = Ember.Mixin.create({
    disabled: false,
    placeholder: '',
    searchEnabled: Ember.computed('options.length', 'oninput', function () {
      return this.options.length >= OPTIONS_FOR_SEARCH || !!this.search;
    }),
    searchPlaceholder: 'Type to filter options...',
    allowClear: false,
    horizontalPosition: 'auto',
    verticalPosition: 'below',
    triggerClass: Ember.computed('disabled', function () {
      var classes = [CSS_CLASSES.FIELD_COMPONENT, CSS_CLASSES.FIELD_SELECT];

      if (this.disabled) {
        classes.push(CSS_CLASSES.DISABLED);
      }

      return classes.join(' ');
    })
  });

  _exports.default = _default;
});