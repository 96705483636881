define("travis/controllers/first-sync", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    auth: Ember.inject.service(),
    user: Ember.computed.alias('auth.currentUser'),
    isSyncing: Ember.computed.alias('user.isSyncing')
  });

  _exports.default = _default;
});