define("travis/helpers/travis-mb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (size) {
    if (size) {
      return (size / 1024 / 1024).toFixed(2);
    }
  });

  _exports.default = _default;
});