define("travis/templates/components/link-to-account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wOGsUIww",
    "block": "{\"symbols\":[\"@class\",\"&default\"],\"statements\":[[4,\"if\",[[23,0,[\"routeModel\"]]],null,{\"statements\":[[0,\"  \"],[5,\"link-to\",[[12,\"class\",[23,1,[]]]],[[\"@route\",\"@model\"],[[23,0,[\"routeName\"]],[23,0,[\"routeModel\"]]]],{\"statements\":[[0,\"\\n    \"],[14,2],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[5,\"link-to\",[[12,\"class\",[23,1,[]]]],[[\"@route\"],[[23,0,[\"routeName\"]]]],{\"statements\":[[0,\"\\n    \"],[14,2],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/link-to-account.hbs"
    }
  });

  _exports.default = _default;
});