define("travis/templates/components/billing-resubscribe-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MgItXkAN",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"account\",\"hasSubscriptionPermissions\"]]],null,{\"statements\":[[4,\"if\",[[23,0,[\"isActiveGithubSubscription\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"notice-banner--white flex flex--v-center\"],[8],[0,\"\\n      \"],[7,\"div\",true],[8],[0,\"\\n        \"],[5,\"svg-image\",[],[[\"@name\",\"@class\"],[\"icon-help\",\"icon-help\"]]],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"pl-3\"],[8],[0,\"\\n        You can have only one subscription at a time. Please cancel your current GitHub marketplace subscription and resubscribe.\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"button disabled\"],[8],[0,\"\\n      Resubscribe to plan\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"button disabled\"],[8],[0,\"\\n      Subscribe to different plan\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,0,[\"subscription\",\"isResubscribable\"]]],null,{\"statements\":[[0,\"    \"],[7,\"button\",false],[12,\"class\",\"button--green mr-1\"],[3,\"on\",[\"click\",[28,\"action\",[[23,0,[]],[28,\"perform\",[[23,0,[\"resubscribe\"]]],null]],null]]],[8],[0,\"\\n      Resubscribe to plan\\n    \"],[9],[0,\"\\n    \"],[7,\"button\",false],[12,\"class\",\"button--white button--hover\"],[3,\"on\",[\"click\",[23,0,[\"openPlansSelector\"]]]],[8],[0,\"\\n      Subscribe to different plan\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"button\",false],[12,\"class\",\"button--blue\"],[3,\"on\",[\"click\",[23,0,[\"openPlansSelector\"]]]],[8],[0,\"\\n      Change plan\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/billing-resubscribe-button.hbs"
    }
  });

  _exports.default = _default;
});