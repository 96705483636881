define("travis/components/repository-migration-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['repository-migration-modal'],
    actions: {
      startMigration: function startMigration() {
        this.repositories.forEach(function (repo) {
          return repo.startMigration();
        });
        return this.onClose();
      }
    }
  });

  _exports.default = _default;
});