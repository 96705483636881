define("travis/templates/dashboard/repositories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VDFGJP4F",
    "block": "{\"symbols\":[\"repo\"],\"statements\":[[7,\"section\",true],[10,\"class\",\"dashboard-section dashboard-active\"],[8],[0,\"\\n  \"],[7,\"ul\",true],[10,\"class\",\"repo-list\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"filteredRepos\"]]],null,{\"statements\":[[0,\"      \"],[5,\"dashboard-row\",[],[[\"@repo\",\"@star\",\"@unstar\"],[[23,1,[]],[23,0,[\"dashboardController\",\"star\"]],[23,0,[\"dashboardController\",\"unstar\"]]]]],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"      \"],[5,\"no-repos\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n  \"],[5,\"pagination-navigation\",[],[[\"@collection\",\"@route\"],[[23,0,[\"model\",\"repos\"]],\"dashboard.repositories\"]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/dashboard/repositories.hbs"
    }
  });

  _exports.default = _default;
});