define("travis/components/billing/account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    accounts: Ember.inject.service(),
    account: null,
    subscription: Ember.computed.reads('account.subscription'),
    isSubscriptionEmpty: Ember.computed.empty('subscription'),
    trial: Ember.computed.reads('account.trial'),
    isEducationalAccount: Ember.computed.bool('account.education'),
    isNotEducationalAccount: Ember.computed.not('isEducationalAccount'),
    isTrial: Ember.computed.and('isSubscriptionEmpty', 'isNotEducationalAccount'),
    isManual: Ember.computed.bool('subscription.isManual'),
    isManaged: Ember.computed.bool('subscription.managedSubscription'),
    isEducation: Ember.computed.and('isSubscriptionEmpty', 'isEducationalAccount'),
    invoices: Ember.computed('subscription.id', function () {
      var subscriptionId = this.get('subscription.id');

      if (subscriptionId) {
        return this.store.query('invoice', {
          subscription_id: subscriptionId
        });
      } else {
        return [];
      }
    })
  });

  _exports.default = _default;
});