define("travis/components/getting-started-steps-generic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    // Lifecycle
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      (false && !(this.provider) && Ember.assert('@provider is required for GettingStartedStepsGeneric component', this.provider));
    }
  });

  _exports.default = _default;
});