define("travis/components/billing/contact-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    info: null,
    billingEmail: Ember.computed.reads('info.billingEmail'),
    billingEmails: Ember.computed('billingEmail', function () {
      return (this.billingEmail || '').split(',');
    }),
    actions: {
      updateEmails: function updateEmails(values) {
        this.info.set('billingEmail', values.join(','));
      }
    }
  });

  _exports.default = _default;
});