define("travis/routes/insufficient-oauth-permissions", ["exports", "travis/routes/simple-layout"], function (_exports, _simpleLayout) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _simpleLayout.default.extend({
    setupController: function setupController(controller) {
      var existingUser;

      this._super.apply(this, arguments);

      existingUser = document.location.hash.match(/#existing[_-]user/);
      return controller.set('existingUser', existingUser);
    }
  });

  _exports.default = _default;
});