define("travis/routes/build/config", ["exports", "travis/routes/basic"], function (_exports, _basic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basic.default.extend({
    titleToken: 'Config',
    model: function model() {
      return this.modelFor('build').get('request');
    },
    afterModel: function afterModel(request) {
      return request.fetchMessages.perform();
    }
  });

  _exports.default = _default;
});