define("travis/services/session-storage", ["exports", "travis/services/storage"], function (_exports, _storage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _storage.default.extend({
    get storage() {
      return window.sessionStorage;
    }

  });

  _exports.default = _default;
});