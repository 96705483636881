define("travis/templates/job/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xFKIQVqm",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"model\",\"build\",\"isMatrix\"]]],null,{\"statements\":[[0,\"  \"],[5,\"paper-block\",[],[[\"@elevation\"],[\"2\"]],{\"statements\":[[0,\"\\n    \"],[5,\"svg-image\",[],[[\"@name\",\"@class\"],[\"icon-megaphone\",\"icon icon-megaphone\"]]],[0,\"\\n    This is the configuration for all of build #\"],[1,[23,0,[\"model\",\"build\",\"number\"]],false],[0,\", including this job\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[5,\"annotated-yaml\",[],[[\"@request\"],[[23,0,[\"model\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/job/config.hbs"
    }
  });

  _exports.default = _default;
});