define("travis/components/flash-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'li',
    classNameBindings: ['type', 'isFixed:fixed:below-top-bar'],
    flashes: Ember.inject.service(),
    type: Ember.computed('flash.type', function () {
      var type = this.get('flash.type');
      return type || 'broadcast';
    }),
    topBarNotVisible: Ember.computed.not('flashes.topBarVisible'),
    isFixed: Ember.computed.or('topBarNotVisible', 'flash.aboveOverlay'),
    actions: {
      close: function close() {
        return this.attrs.close(this.flash);
      }
    }
  });

  _exports.default = _default;
});