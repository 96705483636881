define("travis/services/raven", ["exports", "ember-cli-sentry/services/raven", "travis/config/environment"], function (_exports, _raven, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _raven.default.extend({
    features: Ember.inject.service(),
    benignErrors: ['TransitionAborted', 'TaskInstance', 'UnrecognizedURLError', 'not found', 'returned a 403', 'returned a 404', 'operation failed', 'operation was aborted', 'needs-auth'],
    unhandledPromiseErrorMessage: '',
    captureException: function captureException()
    /* error */
    {
      this._super.apply(this, arguments);
    },
    logException: function logException(e) {
      var forceSampling = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      // eslint-disable-next-line
      console.log('Caught an exception:', e);

      if (!this.ignoreError(e, forceSampling)) {
        this.captureException(e);
      }
    },
    captureMessage: function captureMessage()
    /* message */
    {
      return this._super.apply(this, arguments);
    },
    enableGlobalErrorCatching: function enableGlobalErrorCatching() {
      return this._super.apply(this, arguments);
    },
    ignoreError: function ignoreError(error) {
      var forceSampling = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      if (!this.shouldReportError(forceSampling)) {
        return true;
      } else {
        var message = error.message;

        if (message) {
          return this.benignErrors.any(function (error) {
            return message.includes(error);
          });
        } else {
          return false;
        }
      }
    },
    callRaven: function callRaven()
    /* methodName, ...optional */
    {
      return this._super.apply(this, arguments);
    },
    shouldReportError: function shouldReportError(forceSampling) {
      // Sentry recommends only reporting a small subset of the actual
      // frontend errors. This can get *very* noisy otherwise.
      if (this.get('features.enterpriseVersion') || _environment.default.sentry.development) {
        return false;
      } else if (forceSampling) {
        return true;
      } else {
        return this.sampleError();
      }
    },
    sampleError: function sampleError() {
      var sampleRate = 10;
      return Math.random() * 100 <= sampleRate;
    }
  });

  _exports.default = _default;
});