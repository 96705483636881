define("travis/mixins/route/account/billing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    stripe: Ember.inject.service(),
    store: Ember.inject.service(),
    storage: Ember.inject.service(),
    beforeModel: function beforeModel() {
      return this.stripe.load();
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      this.checkBillingStep();
      controller.set('newSubscription', this.newSubscription());
    },
    deactivate: function deactivate() {
      this._super.apply(this, arguments);

      this.controller.set('billingStep', 1);
    },
    newSubscription: function newSubscription() {
      var savedPlan = this.storage.billingPlan;
      var selectedPlan = savedPlan && savedPlan.id && this.store.peekRecord('plan', savedPlan.id);
      var plan = selectedPlan || this.store.createRecord('plan', this.storage.billingPlan);
      var billingInfo = this.store.createRecord('billing-info', this.storage.billingInfo);
      var creditCardInfo = this.store.createRecord('credit-card-info');
      return this.store.createRecord('subscription', {
        billingInfo: billingInfo,
        plan: plan,
        creditCardInfo: creditCardInfo
      });
    },
    checkBillingStep: function checkBillingStep() {
      var billingStepQueryParams = this.controller.get('billingStep');

      if (billingStepQueryParams !== this.storage.billingStep) {
        this.storage.clearBillingData();
      }
    }
  });

  _exports.default = _default;
});