define("travis/templates/github_apps_installation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eTaum41C",
    "block": "{\"symbols\":[],\"statements\":[[5,\"travis-layout\",[],[[\"@layoutName\"],[\"layouts/center\"]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"content-page centered\"],[8],[0,\"\\n    \"],[7,\"img\",true],[10,\"src\",\"/images/getting-started/truck.gif\"],[10,\"alt\",\"truck loading animation\"],[8],[9],[0,\"\\n    \"],[7,\"h1\",true],[10,\"class\",\"content-title\"],[8],[0,\"\\n      Keep your hard hat on!\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"content-text\"],[8],[0,\"\\n      \"],[7,\"p\",true],[8],[0,\"\\n        We’re waiting to hear back from GitHub.\"],[7,\"br\",true],[8],[9],[0,\"\\n        Your repositories will be set up and ready shortly.\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/github_apps_installation.hbs"
    }
  });

  _exports.default = _default;
});