define("travis/components/external-link-to", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'a',
    attributeBindings: ['href', 'target', 'rel', 'title'],
    rel: 'noopener noreferrer',
    target: '_blank'
  });

  _exports.default = _default;
});