define("travis/models/cron", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    branch: (0, _model.belongsTo)('branch'),
    interval: (0, _model.attr)('string'),
    dont_run_if_recent_build_exists: (0, _model.attr)('boolean'),
    created_at: (0, _model.attr)('string'),
    last_run: (0, _model.attr)('string'),
    next_run: (0, _model.attr)('string'),
    repo: (0, _model.belongsTo)('repo')
  });

  _exports.default = _default;
});