define("travis/adapters/beta-feature", ["exports", "travis/adapters/v3"], function (_exports, _v) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _v.default.extend({
    auth: Ember.inject.service(),
    buildURL: function buildURL(modelName, id, snapshot, requestType) {
      var url = this._super.apply(this, arguments);

      if (requestType === 'GET') {
        var replacement = "user/".concat(this.get('auth.currentUser.id'), "/beta_features");
        url = url.replace(/beta_features/, replacement);
      } else {
        if (requestType === 'PUT') {
          requestType = 'PATCH';
        }

        var _replacement = "user/".concat(this.get('auth.currentUser.id'), "/beta_feature");

        url = url.replace(/beta_feature/, _replacement);
      }

      return url;
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, snapshot);
      var _data = data,
          enabled = _data.enabled;
      data = {
        'beta_feature.enabled': enabled
      };
      var url = this.buildURL(type.modelName, snapshot.id, snapshot, 'updateRecord');
      return this.ajax(url, 'PATCH', {
        data: data
      });
    }
  });

  _exports.default = _default;
});