define("travis/templates/components/layouts/striped", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eIDQLXjL",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"wrapper layout-striped \",[28,\"if\",[[23,0,[\"useTailwind\"]],\"tailwind-overrides\"],null]]]],[8],[0,\"\\n  \"],[5,\"top-bar\",[],[[\"@isWhite\"],[[23,0,[\"isTopBarWhite\"]]]]],[0,\"\\n  \"],[5,\"flash-display\",[],[[],[]]],[0,\"\\n  \"],[7,\"div\",true],[10,\"id\",\"main\"],[11,\"class\",[29,[\"main \",[28,\"if\",[[23,0,[\"isFlush\"]],\"main--flush\"],null]]]],[10,\"role\",\"main\"],[8],[0,\"\\n    \"],[14,1,[[28,\"hash\",null,[[\"section\"],[[28,\"component\",[\"layouts/striped-section\"],null]]]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/layouts/striped.hbs"
    }
  });

  _exports.default = _default;
});