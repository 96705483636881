define("travis/components/ui-kit/note", ["exports", "travis/utils/ui-kit/variant", "travis/components/ui-kit/box", "travis/components/ui-kit/text"], function (_exports, _variant, _box, _text) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  // Variants
  var VARIANTS = {
    WARN: 'warn'
  };

  var VARIANT_PROPS = _defineProperty({}, VARIANTS.WARN, {
    bgColor: _box.COLORS.YELLOW_LIGHTER,
    textColor: _text.COLORS.YELLOW_DARK
  });

  var _default = Ember.Component.extend({
    tagName: '',
    // Public interface
    tag: 'div',
    bgColor: (0, _variant.variantProp)(VARIANT_PROPS, null),
    textColor: (0, _variant.variantProp)(VARIANT_PROPS, null),
    margin: Ember.computed(function () {
      return {
        bottom: 4
      };
    }),
    padding: Ember.computed(function () {
      return {
        x: 4,
        y: 2
      };
    }),
    variant: null
  });

  _exports.default = _default;
});