define("travis/components/request-config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    copied: false,
    isExpanded: true,
    toggleStatusClass: Ember.computed('isExpanded', function () {
      return this.isExpanded ? 'expanded' : 'collapsed';
    }),
    buttonLabel: Ember.computed('copied', function () {
      return this.copied ? 'Copied!' : 'Copy build config';
    }),
    formattedConfig: Ember.computed('config', 'slug', function () {
      var config = this.get('config');

      try {
        return JSON.stringify(config, null, 2);
      } catch (e) {
        return config;
      }
    }),
    actions: {
      copied: function copied() {
        var _this = this;

        this.set('copied', true);
        Ember.run.later(function () {
          return _this.set('copied', false);
        }, 3000);
      },
      toggle: function toggle() {
        this.toggleProperty('isExpanded');
      }
    }
  });

  _exports.default = _default;
});