define("travis/components/raw-config", ["exports", "travis/utils/format-config"], function (_exports, _formatConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Component.extend({
    externalLinks: Ember.inject.service(),
    copied: false,
    baseYmlName: '.travis.yml',
    isExpanded: Ember.computed('rawConfig.config', function () {
      return this.get('rawConfig.config') !== '{}';
    }),
    toggleStatusClass: Ember.computed('isExpanded', function () {
      return this.isExpanded ? 'expanded' : 'collapsed';
    }),
    buttonLabel: Ember.computed('copied', 'rawConfig.source', function () {
      var source = this.get('rawConfig.source');
      return this.copied ? 'Copied!' : "Copy ".concat((0, _formatConfig.fileNameWithoutSha)(source));
    }),
    formattedConfig: Ember.computed('rawConfig.config', 'slug', function () {
      var config = this.get('rawConfig.config');

      try {
        return JSON.stringify(JSON.parse(config), null, 2);
      } catch (e) {
        return config;
      }
    }),
    filePath: Ember.computed('rawConfig.source', 'slug', function () {
      var source = this.get('rawConfig.source');
      var name = (0, _formatConfig.fileNameWithoutSha)(source);

      if (name === this.baseYmlName) {
        return name;
      }

      return (0, _formatConfig.presentedPath)(source, this.slug);
    }),
    fileUrl: Ember.computed('rawConfig.source', 'build.branchName', 'build.repo.{slug,vcsType}', function () {
      var slug = this.get('build.repo.slug');
      var vcsType = this.get('build.repo.vcsType');
      var source = this.get('rawConfig.source');

      if ((0, _formatConfig.isInternal)(source, slug)) {
        return;
      }

      var _slug$split = slug.split('/'),
          _slug$split2 = _slicedToArray(_slug$split, 2),
          owner = _slug$split2[0],
          repo = _slug$split2[1];

      var branch = this.get('build.branchName');
      var file = (0, _formatConfig.fileNameWithoutSha)(source);
      return this.externalLinks.fileUrl(vcsType, {
        owner: owner,
        repo: repo,
        branch: branch,
        file: file
      });
    }),
    codeblockId: Ember.computed('rawConfig.source', function () {
      return (0, _formatConfig.codeblockName)(this.rawConfig.source);
    }),
    actions: {
      copied: function copied() {
        var _this = this;

        this.set('copied', true);
        Ember.run.later(function () {
          return _this.set('copied', false);
        }, 3000);
      },
      toggle: function toggle() {
        this.toggleProperty('isExpanded');
      }
    }
  });

  _exports.default = _default;
});