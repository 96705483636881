define("travis/models/beta-feature", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    enabled: (0, _model.attr)('boolean'),
    feedbackUrl: (0, _model.attr)('string'),
    dasherizedName: Ember.computed('name', function () {
      return Ember.String.dasherize(this.name);
    }),
    displayName: Ember.computed('dasherizedName', function () {
      return this.dasherizedName.split('-').map(function (x) {
        return x.capitalize();
      }).join(' ');
    })
  });

  _exports.default = _default;
});