define("travis/components/status-image-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.TextArea.extend({
    click: function click() {
      this.element.select();
    }
  });

  _exports.default = _default;
});