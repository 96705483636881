define("travis/components/build-layout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    job: Ember.computed.alias('build.jobs.firstObject'),
    noJobsError: Ember.computed('build.jobs.[]', function () {
      var jobs = this.get('build.jobs');
      return jobs.get('length') === 0;
    }),
    loading: Ember.computed.alias('build.isLoading'),
    jobsLoaded: Ember.computed('build.jobs.@each.{isConfigLoaded,isLoaded}', 'build.stagesAreLoaded', function () {
      var jobs = this.get('build.jobs');
      var stagesAreLoaded = this.get('build.stagesAreLoaded');
      jobs.forEach(function (j) {
        return j.get('config');
      });
      return jobs.isEvery('isLoaded') && jobs.isEvery('isConfigLoaded') && stagesAreLoaded;
    })
  });

  _exports.default = _default;
});