define("travis/templates/head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c+roZLdX",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"model\",\"useTailwindBase\"]]],null,{\"statements\":[[0,\"  \"],[7,\"link\",true],[10,\"rel\",\"stylesheet\"],[10,\"href\",\"/assets/tailwind-base.css\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/head.hbs"
    }
  });

  _exports.default = _default;
});