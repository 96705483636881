define("travis/serializers/cron", ["exports", "travis/serializers/v3"], function (_exports, _v) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Serializer = _v.default.extend({});

  var _default = Serializer;
  _exports.default = _default;
});