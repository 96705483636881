define("travis/controllers/account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    auth: Ember.inject.service(),
    externalLinks: Ember.inject.service(),
    features: Ember.inject.service(),
    user: Ember.computed.reads('auth.currentUser'),
    account: Ember.computed.reads('model'),
    subscription: Ember.computed.reads('account.subscription'),
    isSubscribed: Ember.computed.or('subscription.isSubscribed', 'account.education'),
    isNotSubscribed: Ember.computed.not('isSubscribed'),
    actions: {
      sync: function sync() {
        return this.user.sync();
      },
      toggle: function toggle(hook) {
        return hook.toggle();
      }
    }
  });

  _exports.default = _default;
});