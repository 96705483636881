define("travis/services/ajax", ["exports", "ember-fetch/utils/serialize-query-params", "fetch"], function (_exports, _serializeQueryParams, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DEFAULT_ACCEPT = 'application/json; version=2';

  var _default = Ember.Service.extend({
    features: Ember.inject.service(),
    getDefaultOptions: function getDefaultOptions() {
      return {
        accept: DEFAULT_ACCEPT
      };
    },
    isRetrieve: function isRetrieve(method) {
      return method === 'GET' || method === 'HEAD';
    },
    setupHeaders: function setupHeaders(method) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var _options$headers = options.headers,
          headers = _options$headers === void 0 ? {} : _options$headers; // Content-Type

      if (!this.isRetrieve(method)) {
        headers['Content-Type'] = options.contentType || 'application/json; charset=utf-8';
      } // Accept


      headers['Accept'] = options.accept || DEFAULT_ACCEPT;
      return headers;
    },
    setupBody: function setupBody(method, options) {
      if (this.isRetrieve(method)) {
        return null;
      }

      var data = options.data,
          stringifyData = options.stringifyData;

      if (data && stringifyData !== false && typeof data !== 'string') {
        return JSON.stringify(data);
      }

      return data;
    },
    setupUrl: function setupUrl(requestUrl, method, options) {
      var _options$host = options.host,
          host = _options$host === void 0 ? '' : _options$host,
          data = options.data;
      var baseUrl = "".concat(host).concat(requestUrl);

      if (data && this.isRetrieve(method)) {
        var params = (0, _serializeQueryParams.default)(data);
        var delimeter = baseUrl.indexOf('?') === -1 ? '?' : '&';
        return "".concat(baseUrl).concat(delimeter).concat(params);
      }

      return baseUrl;
    },
    request: function request(requestUrl) {
      var mthd = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'GET';
      var opts = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var defaultOpts = this.getDefaultOptions();
      var options = Object.assign({}, defaultOpts, opts);
      var method = mthd.toUpperCase();
      var url = this.setupUrl(requestUrl, method, options);
      options.body = this.setupBody(method, options);
      options.headers = this.setupHeaders(method, options);
      return this.fetchRequest(url, method, options);
    },
    fetchRequest: function fetchRequest(url, method, options) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var headers = options.headers,
            body = options.body;
        var fetchOptions = {
          headers: headers,
          method: method
        };

        if (body) {
          fetchOptions['body'] = body;
        }

        (0, _fetch.default)(url, fetchOptions).then(function (response) {
          if (!response.ok) {
            _this.handleFetchError(reject, response);
          } else {
            var _response$headers$map = response.headers.map['content-type'],
                resContentType = _response$headers$map === void 0 ? '' : _response$headers$map;
            var resContent;

            if (resContentType.includes('application/json')) {
              resContent = response.json();
            } else {
              resContent = response.text();
            }

            resContent.then(function (data) {
              return resolve(data);
            }).catch(function (error) {
              return _this.handleFetchError(reject, error);
            });
          }
        }).catch(function (error) {
          _this.handleFetchError(reject, {
            isNetworkError: true,
            details: error
          });
        });
      });
    },
    handleFetchError: function handleFetchError(reject, error) {
      reject(error);
      this.logFetchError(error);
    },
    logFetchError: function logFetchError(response) {
      var _response$status = response.status,
          status = _response$status === void 0 ? 'UNKNOWN' : _response$status;
      var message = "[ERROR] Fetch error (".concat(status, "): ").concat(response);
      (false && Ember.warn(message, {
        id: 'travis.ajax.fetch'
      }));
    }
  });

  _exports.default = _default;
});