define("travis/templates/components/repository-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V57y1h0s",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"isFiltering\",\"isLoading\",\"form\",\"query\",\"results\",\"computeName\"],[[23,0,[\"isFiltering\"]],[23,0,[\"isLoading\"]],[28,\"component\",[\"repository-filter-form\"],[[\"query\",\"onSearch\"],[[23,0,[\"query\"]],[28,\"perform\",[[23,0,[\"search\"]]],null]]]],[23,0,[\"query\"]],[23,0,[\"repositories\"]],[23,0,[\"computeName\"]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/repository-filter.hbs"
    }
  });

  _exports.default = _default;
});