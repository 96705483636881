define("travis/routes/dashboard/repositories", ["exports", "travis/routes/basic", "travis/utils/dashboard-repositories-sort", "travis/config/environment"], function (_exports, _basic, _dashboardRepositoriesSort, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basic.default.extend({
    features: Ember.inject.service(),
    accounts: Ember.inject.service(),
    queryParams: {
      page: {
        refreshModel: true
      }
    },

    get recordsPerPage() {
      return _environment.default.pagination.dashboardReposPerPage;
    },

    model: function model(params) {
      var offset = (params.page - 1) * this.recordsPerPage;
      return Ember.RSVP.hash({
        starredRepos: this.store.filter('repo', {
          active: true,
          sort_by: 'current_build:desc',
          starred: true
        }, function (repo) {
          return repo.get('starred');
        }, ['starred'], true),
        repos: this.store.paginated('repo', {
          active: true,
          sort_by: 'current_build:desc',
          offset: offset,
          limit: this.recordsPerPage
        }, {
          filter: function filter(repo) {
            return repo.get('active') && repo.get('isCurrentUserACollaborator');
          },
          sort: _dashboardRepositoriesSort.default,
          dependencies: ['active', 'isCurrentUserACollaborator'],
          forceReload: true
        }),
        accounts: this.accounts.fetch()
      });
    },
    afterModel: function afterModel(model) {
      var repos = model.repos;
      var currentBuilds = repos.mapBy('currentBuild');
      var defaultBranches = repos.mapBy('defaultBranch'); // This preloads related models to prevent a backtracking rerender error.

      return {
        currentBuilds: currentBuilds,
        defaultBranches: defaultBranches
      };
    }
  });

  _exports.default = _default;
});