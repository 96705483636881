define("travis/templates/components/manual-subscription-help", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jly2p1wm",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\",true],[10,\"class\",\"manual-help\"],[8],[0,\"\\n  If you have any questions or would like to update your plan, please\\n  \"],[5,\"link-to\",[],[[\"@route\",\"@query\"],[\"help\",[28,\"hash\",null,[[\"anchor\"],[\"form\"]]]]],{\"statements\":[[0,\"\\n    contact our support team.\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/manual-subscription-help.hbs"
    }
  });

  _exports.default = _default;
});