define("travis/routes/basic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    auth: Ember.inject.service(),
    featureFlags: Ember.inject.service(),
    activate: function activate() {
      if (this.routeName !== 'error') {
        this.controllerFor('error').set('layoutName', null);
      }

      return this._super.apply(this, arguments);
    },
    beforeModel: function beforeModel(transition) {
      if (!this.auth.signedIn && this.needsAuth) {
        return Ember.RSVP.reject('needs-auth');
      } else if (this.redirectToProfile(transition)) {
        return this.transitionTo('account');
      } else {
        return this._super.apply(this, arguments);
      }
    },
    redirectToProfile: function redirectToProfile(transition) {
      var targetName = transition.targetName;

      var _this$paramsFor = this.paramsFor('owner'),
          owner = _this$paramsFor.owner;

      if (targetName === 'owner.repositories' && owner === 'profile') {
        this.transitionTo('account', {
          queryParams: {
            offset: 0
          }
        });
      }
    }
  });

  _exports.default = _default;
});