define("travis/utils/computed-limit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var limit = function limit(dependentKey, limitKey) {
    return Ember.computed(dependentKey, "".concat(dependentKey, ".[]"), function () {
      var limit = Ember.get(this, limitKey),
          array = this.get(dependentKey);
      return array ? array.toArray().slice(0, limit) : [];
    });
  };

  var _default = limit;
  _exports.default = _default;
});