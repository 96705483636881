define("travis/components/insights-tabs", ["exports", "travis/services/insights"], function (_exports, _insights) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.INSIGHTS_TABS = void 0;
  var INSIGHTS_TABS = Object.values(_insights.INSIGHTS_INTERVALS).map(function (slug) {
    return {
      slug: slug,
      title: slug.capitalize()
    };
  });
  _exports.INSIGHTS_TABS = INSIGHTS_TABS;

  var _default = Ember.Component.extend({
    tagName: 'ul',
    classNames: ['travistab-nav travistab-nav--insights insights-tabs'],
    tabs: INSIGHTS_TABS
  });

  _exports.default = _default;
});