define("travis/services/multi-vcs", ["exports", "travis/utils/vcs"], function (_exports, _vcs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    auth: Ember.inject.service(),
    features: Ember.inject.service(),
    isProVersion: Ember.computed.reads('features.proVersion'),
    enabled: Ember.computed.or('enableAssemblaLogin', 'enableBitbucketLogin', 'enableGitlabLogin'),
    disabled: Ember.computed.not('enabled'),

    get enableGithubLogin() {
      return this.isProviderEnabled('github');
    },

    get enableAssemblaLogin() {
      return this.isProviderEnabled('assembla');
    },

    get enableBitbucketLogin() {
      return this.isProviderEnabled('bitbucket');
    },

    get enableGitlabLogin() {
      return this.isProviderEnabled('gitlab');
    },

    primaryProviderConfig: Ember.computed(function () {
      return _vcs.defaultVcsConfig;
    }),
    primaryProvider: Ember.computed.reads('primaryProviderConfig.urlPrefix'),
    isProviderEnabled: function isProviderEnabled(provider) {
      var isProVersion = this.isProVersion,
          features = this.features;
      var isEnabled = features.isEnabled("enable-".concat(provider, "-login")) || features.isEnabled("".concat(provider, "-login"));
      return this.isProviderPrimary(provider) || isProVersion && isEnabled;
    },
    isProviderPrimary: function isProviderPrimary(provider) {
      return provider === this.primaryProvider;
    },
    isProviderBeta: function isProviderBeta(provider) {
      var config = provider && (0, _vcs.vcsConfigByUrlPrefix)(provider) || _vcs.defaultVcsConfig;

      return config.isBeta;
    },
    currentProviderConfig: Ember.computed('auth.currentUser.vcsType', function () {
      var currentUser = this.auth.currentUser;

      if (currentUser) {
        var vcsType = currentUser.vcsType;
        return (0, _vcs.vcsConfig)(vcsType);
      }
    }),
    currentProvider: Ember.computed.reads('currentProviderConfig.urlPrefix'),
    currentProviderIsBeta: Ember.computed('currentProvider', function () {
      return this.isProviderBeta(this.currentProvider);
    })
  });

  _exports.default = _default;
});