define("travis/utils/api-errors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = hasErrorWithStatus;

  /**
   * Check if the status error occurs in the response errors collection
   *
   */
  function hasErrorWithStatus(errorResponse, status) {
    var _ref = errorResponse || {},
        _ref$errors = _ref.errors,
        errors = _ref$errors === void 0 ? [] : _ref$errors;

    return errors.isAny('status', status);
  }
});