define("travis/templates/components/request-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gQJcv8St",
    "block": "{\"symbols\":[],\"statements\":[[5,\"svg-image\",[],[[\"@name\",\"@class\"],[[23,0,[\"icon\"]],[28,\"concat\",[[23,0,[\"state\"]],\" \",[23,0,[\"event\"]],\" request-icon\"],null]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/request-icon.hbs"
    }
  });

  _exports.default = _default;
});