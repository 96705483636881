define("travis/templates/layouts/landing-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q3i9CSyV",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"id\",\"top\"],[10,\"class\",\"top landing-page\"],[8],[0,\"\\n  \"],[5,\"top-bar\",[],[[\"@landingPage\"],[[23,0,[\"model\",\"landingPage\"]]]]],[0,\"\\n\"],[9],[0,\"\\n\"],[5,\"flash-display\",[],[[],[]]],[0,\"\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/layouts/landing-page.hbs"
    }
  });

  _exports.default = _default;
});