define("travis/routes/organization/repositories", ["exports", "travis/routes/basic", "travis/mixins/route/owner/repositories"], function (_exports, _basic, _repositories) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basic.default.extend(_repositories.default, {
    beforeModel: function beforeModel() {
      this.owner = this.modelFor('organization');
    }
  });

  _exports.default = _default;
});