define("travis/utils/format-config", ["exports", "travis/utils/format-sha"], function (_exports, _formatSha) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.codeblockName = _exports.presentedPath = _exports.isInternal = _exports.fileNameWithoutSha = void 0;

  var slugRe = function slugRe() {
    var slug = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    return new RegExp("^".concat(slug, "/"));
  };

  var fileName = function fileName() {
    var source = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    return (source.match(/([^\/]*)(@\w{16})?$/) || [])[1];
  };

  var fileNameWithoutSha = function fileNameWithoutSha(source) {
    return fileName(source).replace(/(@\w{16})$/, '');
  };

  _exports.fileNameWithoutSha = fileNameWithoutSha;

  var isInternal = function isInternal() {
    var source = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    var slug = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
    return !source.match(slugRe(slug));
  };

  _exports.isInternal = isInternal;

  var presentedPath = function presentedPath() {
    var source = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    var slug = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
    return source.replace(/@\w{16}$/, function (sha) {
      return "@".concat((0, _formatSha.default)(sha.substring(1)));
    }).replace(slugRe(slug), '');
  };

  _exports.presentedPath = presentedPath;

  var codeblockName = function codeblockName() {
    var source = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    return "rccb_".concat(fileName(source));
  };

  _exports.codeblockName = codeblockName;
});