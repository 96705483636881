define("travis/utils/ui-kit/assertions", ["exports", "travis/utils/ui-kit/responsive", "travis/utils/ui-kit/colors"], function (_exports, _responsive, _colors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.checkDictionary = checkDictionary;
  _exports.requireProp = requireProp;
  _exports.getValuesToCheck = getValuesToCheck;
  _exports.checkColor = checkColor;

  function checkDictionary(value, dictionary) {
    var propertyName = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
    var componentName = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';

    // Allow null/undefined values
    if (Ember.isNone(value)) {
      return true;
    }

    var screenVals = (0, _responsive.getResponsiveProp)(value);

    var conflicts = _responsive.screenKeys.map(function (screen) {
      var currentVal = screenVals[screen];

      if (Ember.isNone(currentVal)) {
        return true;
      }

      var found = Object.values(dictionary).includes(currentVal);
      (false && !(found) && Ember.assert("".concat(propertyName, " \"").concat(currentVal, "\" is not allowed on this ").concat(componentName, " component"), found));
      return found;
    }).filter(function (found) {
      return found === false;
    });

    return conflicts.length === 0;
  }

  function requireProp(value) {
    var propertyName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
    var componentName = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
    var screenVals = (0, _responsive.getResponsiveProp)(value);
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = _responsive.screenKeys[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var screen = _step.value;
        var currentVal = screenVals[screen];
        var found = Ember.isPresent(currentVal);

        if (found) {
          return true;
        }
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return != null) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    (false && !(false) && Ember.assert("".concat(propertyName, " property must be present on this ").concat(componentName, " component. Current value: ").concat(value), false));
    return false;
  }

  function getValuesToCheck(inputValue) {
    var responsivePropMap = (0, _responsive.getResponsiveProp)(inputValue);
    return Ember.A(Object.values(responsivePropMap)).compact().without('');
  }

  function checkColor() {
    var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        value = _ref.value,
        dictionary = _ref.dictionary,
        _ref$property = _ref.property,
        property = _ref$property === void 0 ? '@color' : _ref$property,
        _ref$component = _ref.component,
        component = _ref$component === void 0 ? '' : _ref$component;

    // Allow null/undefined values
    if (Ember.isNone(value)) {
      return true;
    }

    var valuesToCheck = getValuesToCheck(value);
    var dictVals = Object.values(dictionary);
    var conflicts = Ember.A();
    var _iteratorNormalCompletion2 = true;
    var _didIteratorError2 = false;
    var _iteratorError2 = undefined;

    try {
      for (var _iterator2 = valuesToCheck[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
        var currentValue = _step2.value;

        if ((0, _colors.colorExists)(currentValue)) {
          continue;
        }

        if (dictVals.includes(currentValue)) {
          continue;
        }

        conflicts.push(currentValue);
      }
    } catch (err) {
      _didIteratorError2 = true;
      _iteratorError2 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
          _iterator2.return();
        }
      } finally {
        if (_didIteratorError2) {
          throw _iteratorError2;
        }
      }
    }

    (false && !(!conflicts.length) && Ember.assert("".concat(property, " \"").concat(conflicts, "\" is not allowed on this ").concat(component, " component"), !conflicts.length));
  }
});