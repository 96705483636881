define("travis/components/landing-pro-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    auth: Ember.inject.service(),
    multiVcs: Ember.inject.service(),
    metrics: Ember.inject.service(),
    actions: {
      signIn: function signIn(provider) {
        this.auth.signInWith(provider);
      },
      signOut: function signOut() {
        return this.signOut();
      }
    }
  });

  _exports.default = _default;
});