define("travis/templates/components/travis-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8iLVhVUH",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"status-circle\"],[8],[0,\"\\n  ●\\n\"],[9],[0,\"\\n\"],[5,\"external-link-to\",[[12,\"class\",\"status-link\"]],[[\"@href\"],[\"http://www.traviscistatus.com/\"]],{\"statements\":[[0,\"\\n  Travis CI Status\\n  \"],[7,\"span\",true],[11,\"class\",[29,[\"status-description \",[28,\"if\",[[23,0,[\"showDescription\"]],\"status-description--visible\"],null]]]],[8],[0,\":\\n    \"],[1,[23,0,[\"description\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[23,0,[\"showTooltip\"]]],null,{\"statements\":[[0,\"  \"],[5,\"ember-tooltip\",[],[[\"@text\",\"@side\"],[[23,0,[\"description\"]],\"left\"]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/travis-status.hbs"
    }
  });

  _exports.default = _default;
});