define("travis/controllers/features-tracing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    tracingEnabled: TravisTracer.isEnabled(),
    actions: {
      toggle: function toggle() {
        if (TravisTracer.isEnabled()) {
          TravisTracer.disable();
        } else {
          TravisTracer.enable();
        }
      }
    }
  });

  _exports.default = _default;
});