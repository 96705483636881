define("travis/models/commit", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    sha: (0, _model.attr)(),
    branch: (0, _model.attr)(),
    message: (0, _model.attr)(),
    compareUrl: (0, _model.attr)(),
    authorName: (0, _model.attr)(),
    authorEmail: (0, _model.attr)(),
    committerName: (0, _model.attr)(),
    committerEmail: (0, _model.attr)(),
    committedAt: (0, _model.attr)(),
    committerAvatarUrl: (0, _model.attr)(),
    authorAvatarUrl: (0, _model.attr)(),
    build: (0, _model.belongsTo)('build'),
    externalLinks: Ember.inject.service(),
    subject: Ember.computed('message', function () {
      var message = this.message;

      if (message) {
        return message.split('\n', 1)[0];
      }
    }),
    body: Ember.computed('message', function () {
      var message = this.message;

      if (message && message.indexOf('\n') > 0) {
        return message.substr(message.indexOf('\n') + 1).trim();
      } else {
        return '';
      }
    }),
    authorIsCommitter: Ember.computed('authorName', 'authorEmail', 'committerName', 'committerEmail', function () {
      var authorName = this.authorName;
      var authorEmail = this.authorEmail;
      var committerName = this.committerName;
      var committerEmail = this.committerEmail;
      return authorName === committerName && authorEmail === committerEmail;
    }),
    url: Ember.computed('build.repo.{ownerName,vcsName,vcsType}', 'sha', function () {
      var owner = this.get('build.repo.ownerName');
      var repo = this.get('build.repo.vcsName');
      var vcsType = this.get('build.repo.vcsType');
      var commit = this.get('sha');
      return this.externalLinks.commitUrl(vcsType, {
        owner: owner,
        repo: repo,
        commit: commit
      });
    })
  });

  _exports.default = _default;
});