define("travis/utils/time-ago-in-words", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = timeAgoInWords;

  _moment.default.updateLocale('en', {
    relativeTime: {
      future: '%s from now',
      past: '%s ago',
      s: 'less than a minute',
      m: 'about a minute',
      mm: '%d minutes',
      h: 'about an hour',
      hh: '%d hours',
      d: 'a day',
      dd: '%d days',
      M: 'about a month',
      MM: '%d months',
      y: 'about a year',
      yy: '%d years'
    }
  });

  _moment.default.relativeTimeThreshold('s', 60);

  _moment.default.relativeTimeThreshold('m', 60);

  _moment.default.relativeTimeThreshold('h', 24);

  _moment.default.relativeTimeThreshold('d', 29);

  function timeAgoInWords(time) {
    if (time) {
      return (0, _moment.default)(time).fromNow();
    }
  }
});