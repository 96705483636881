define("travis/routes/owner", ["exports", "travis/routes/basic"], function (_exports, _basic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basic.default.extend({
    auth: Ember.inject.service(),
    deactivate: function deactivate() {
      return this.controllerFor('loading').set('layoutName', null);
    },
    titleToken: function titleToken(model) {
      var name = model.name || model.login;
      return name;
    },
    model: function model(_ref) {
      var provider = _ref.provider,
          owner = _ref.owner;
      return this.store.queryRecord('owner', {
        provider: provider,
        login: owner
      });
    },
    actions: {
      error: function error(_error)
      /* transition, originRoute*/
      {
        var is404 = _error.status === 404 || _error.errors.firstObject.status === '404';

        if (!is404) {
          var message = 'There was an error while loading data, please try again.';
          this.controllerFor('error').set('layoutName', 'simple');
          this.controllerFor('error').set('message', message);
          return true;
        } else {
          _error.ownerName = this.paramsFor('owner').owner;
          return true;
        }
      }
    }
  });

  _exports.default = _default;
});