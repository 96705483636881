define("travis/serializers/user", ["exports", "travis/serializers/v3"], function (_exports, _v) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _v.default.extend({
    serialize: function serialize(snapshot, options) {
      var _ref = options || {},
          forLocalStorage = _ref.forLocalStorage;

      if (forLocalStorage) {
        return this._super.apply(this, arguments);
      } else {
        return {
          utm_params: snapshot.attr('utmParams') || ''
        };
      }
    }
  });

  _exports.default = _default;
});