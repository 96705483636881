define("travis/templates/dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gsmyPIog",
    "block": "{\"symbols\":[\"repo\"],\"statements\":[[5,\"travis-layout\",[[12,\"class\",\"dashboard\"]],[[\"@layoutName\"],[\"layouts/center\"]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"dashboard-repos\"],[8],[0,\"\\n    \"],[7,\"section\",true],[10,\"class\",\"dashboard-section dashboard-starred\"],[8],[0,\"\\n      \"],[7,\"h2\",true],[10,\"class\",\"small-title\"],[8],[0,\"\\n        Starred repositories\\n      \"],[9],[0,\"\\n      \"],[7,\"ul\",true],[10,\"class\",\"repo-list\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"starredRepos\"]]],null,{\"statements\":[[0,\"          \"],[5,\"dashboard-row\",[],[[\"@repo\",\"@star\",\"@unstar\"],[[23,1,[]],[23,0,[\"star\"]],[23,0,[\"unstar\"]]]]],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"          \"],[7,\"div\",true],[10,\"class\",\"starred-empty\"],[8],[0,\"\\n            \"],[5,\"svg-image\",[],[[\"@name\",\"@class\"],[\"icon-star\",\"icon--m\"]]],[0,\"\\n            You can keep track of your favorite repositories here once you start starring!\\n          \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"header\",true],[10,\"class\",\"dashboard tabs\"],[8],[0,\"\\n    \"],[7,\"h2\",true],[10,\"class\",\"small-title\"],[8],[0,\"\\n      \"],[5,\"link-to\",[],[[\"@route\"],[\"dashboard.repositories\"]],{\"statements\":[[0,\"\\n        Active repositories\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"h2\",true],[10,\"class\",\"small-title\"],[8],[0,\"\\n      \"],[5,\"link-to\",[],[[\"@route\"],[\"dashboard.builds\"]],{\"statements\":[[0,\"\\n        My builds\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/dashboard.hbs"
    }
  });

  _exports.default = _default;
});