define("travis/templates/components/layouts/sidebar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BGfm0h65",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[7,\"div\",false],[12,\"class\",[29,[\"wrapper \",[28,\"if\",[[23,0,[\"hideSidebar\"]],\"centered\"],null]]]],[13,1],[8],[0,\"\\n  \"],[5,\"top-bar\",[],[[],[]]],[0,\"\\n  \"],[5,\"flash-display\",[],[[],[]]],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"wrapper-main\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"id\",\"main\"],[10,\"class\",\"main\"],[10,\"role\",\"main\"],[8],[0,\"\\n      \"],[14,2],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"if\",[[23,0,[\"showSidebar\"]]],null,{\"statements\":[[0,\"    \"],[7,\"aside\",true],[10,\"id\",\"left\"],[8],[0,\"\\n      \"],[5,\"repository-sidebar\",[],[[],[]]],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/layouts/sidebar.hbs"
    }
  });

  _exports.default = _default;
});