define("travis/components/build-messages-list", ["exports", "ember-inflector", "travis/mixins/components/with-config-validation"], function (_exports, _emberInflector, _withConfigValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var MSGS = {
    'alert': 'alert',
    'error': 'error',
    'warn': 'warning',
    'info': 'info'
  };

  var _default = Ember.Component.extend(_withConfigValidation.default, {
    tagName: '',
    isExpanded: false,
    request: null,
    repo: Ember.computed.reads('request.repo'),
    messages: Ember.computed.reads('request.messages'),
    toggleStatusClass: Ember.computed('isExpanded', function () {
      return this.get('isExpanded') ? 'expanded' : 'collapsed';
    }),
    sortedMessages: Ember.computed.sort('request.messages', function (lft, rgt) {
      return sortOrder(lft.level) - sortOrder(rgt.level);
    }),
    maxLevel: Ember.computed('sortedMessages', function () {
      return this.get('sortedMessages.firstObject.level') || 'info';
    }),
    iconClass: Ember.computed('maxLevel', function () {
      return "icon icon-".concat(this.get('maxLevel'));
    }),
    summary: Ember.computed('sortedMessages', function () {
      var counts = countBy(this.get('sortedMessages'), 'level');

      if (Object.entries(counts).length > 0) {
        return Object.entries(counts).map(function (entry) {
          return formatLevel.apply(void 0, _toConsumableArray(entry));
        }).join(', ');
      }
    }),
    actions: {
      toggle: function toggle() {
        this.toggleProperty('isExpanded');
      }
    }
  });

  _exports.default = _default;

  function formatLevel(level, count) {
    return (0, _emberInflector.pluralize)(count, MSGS[level]);
  }

  function sortOrder(level) {
    return Object.keys(MSGS).indexOf(level);
  }

  function countBy(objs, name) {
    return objs.reduce(function (counts, obj) {
      if (!counts[obj[name]]) {
        counts[obj[name]] = 0;
      }

      counts[obj[name]] += 1;
      return counts;
    }, {});
  }
});