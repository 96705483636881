define("travis/templates/components/forms/multiple-inputs-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PuQ3umFp",
    "block": "{\"symbols\":[\"field\",\"index\"],\"statements\":[[4,\"each\",[[23,0,[\"fields\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"mb-2 flex full-width multiple-input\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"travis-form__field full-width\"],[8],[0,\"\\n      \"],[7,\"input\",false],[12,\"class\",\"travis-form__field-input travis-form__field-component\"],[12,\"value\",[23,1,[\"value\"]]],[12,\"autocomplete\",\"off\"],[12,\"type\",\"text\"],[3,\"on\",[\"change\",[28,\"action\",[[23,0,[]],\"handleChange\",[23,2,[]]],null]]],[3,\"on\",[\"blur\",[28,\"action\",[[23,0,[]],\"handleBlur\"],null]]],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,2,[]],[23,0,[\"lastFieldIndex\"]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"button\",false],[12,\"class\",\"multiple-input__add-input-button cursor-pointer\"],[3,\"on\",[\"click\",[28,\"action\",[[23,0,[]],\"addInput\"],null]]],[8],[0,\"\\n        \"],[5,\"svg-image\",[],[[\"@name\",\"@class\"],[\"icon-plus\",\"multiple-input__add-icon\"]]],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"button\",false],[12,\"class\",\"cursor-pointer multiple-input__remove-input-button\"],[3,\"on\",[\"click\",[28,\"action\",[[23,0,[]],\"removeInput\",[23,2,[]]],null]]],[8],[0,\"\\n        \"],[5,\"svg-image\",[],[[\"@name\",\"@class\"],[\"icon-close\",\"multiple-input__remove-icon\"]]],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/forms/multiple-inputs-field.hbs"
    }
  });

  _exports.default = _default;
});