define("travis/adapters/stage", ["exports", "travis/adapters/v3"], function (_exports, _v) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _v.default.extend({
    coalesceFindRequests: true,
    groupRecordsForFindMany: function groupRecordsForFindMany(store, snapshots) {
      var stagesByBuildId = {};
      snapshots.forEach(function (snapshot) {
        var buildId = snapshot.belongsTo('build').id;

        if (!stagesByBuildId[buildId]) {
          stagesByBuildId[buildId] = [];
        }

        stagesByBuildId[buildId].push(snapshot);
      });
      return Object.values(stagesByBuildId);
    },
    findMany: function findMany(store, modelClass, ids, snapshots) {
      var buildId = snapshots[0].belongsTo('build').id;
      return this.ajax("".concat(this.buildURL(), "/build/").concat(buildId, "/stages"));
    },
    findRecord: function findRecord(store, type, id, snapshot) {
      var buildId = snapshot.belongsTo('build').id; // TODO: I'd rather implement /stage/:id endpoint in API, but for now this
      // is a simpler way to fetch a single stage

      return this.ajax("".concat(this.buildURL(), "/build/").concat(buildId, "/stages")).then(function (data) {
        return data.stages.filterBy('id', parseInt(id))[0];
      });
    }
  });

  _exports.default = _default;
});