define("travis/helpers/format-message", ["exports", "emoji-js", "travis/config/environment", "travis/services/external-links"], function (_exports, _emojiJs, _environment, _externalLinks) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var emojiConvertor = new _emojiJs.default();
  emojiConvertor.img_sets.apple.path = "".concat(_environment.default.emojiPrepend, "/images/emoji/");
  emojiConvertor.include_title = true;
  emojiConvertor.allow_native = false;

  function escape(text) {
    return text.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
  }

  function handleMaxLength(message, maxLength) {
    if (maxLength && message.length > maxLength) {
      return "".concat(message.slice(0, maxLength), "\u2026");
    } else {
      return message;
    }
  }

  function handleShort(message, short) {
    return short ? message.split(/\n/)[0] : message;
  }

  function handlePre(message, pre) {
    return pre ? message.replace(/\n/g, '<br/>') : message;
  }

  function handleEventType(message, eventType) {
    if (eventType == 'cron') {
      return "<span class='message-label badge'>cron</span> ".concat(message);
    }

    return message;
  }

  function handleRepo(message, repo) {
    // TODO: Figure out more permanent fix for teal #1885
    if (repo) {
      var owner = Ember.get(repo, 'owner');
      var login = _typeof(owner) === 'object' ? owner.login : owner;
      var repoName = Ember.get(repo, 'name');
      var vcsType = Ember.get(repo, 'vcsType');
      message = includeProfileLink(message, vcsType);
      message = includeIssueLink(message, login, repoName, vcsType);
      message = includeCommitLink(message, login, repoName, vcsType);
      return message;
    }

    return message;
  }

  function formatMessage(message, options) {
    message = escape(message);
    message = handleMaxLength(message, options.maxLength);
    message = handleShort(message, options.short);
    message = handleRepo(message, options.repo);
    message = handlePre(message, options.pre);
    message = handleEventType(message, options.eventType);
    message = emojiConvertor.replace_colons(message);
    return message;
  }

  function includeIssueLink(text, owner, repo, vcsType) {
    var refRegexp = new RegExp('([\\w-]+)?\\/?([\\w-]+)?(?:#|gh-)(\\d+)', 'g');
    return text.replace(refRegexp, function (ref, matchedOwner, matchedRepo, matchedNumber) {
      var current = {
        owner: owner,
        repo: repo
      };
      var matched = {
        owner: matchedOwner,
        repo: matchedRepo,
        number: matchedNumber
      };
      return _issueLink(ref, current, matched, vcsType);
    });
  }

  function includeProfileLink(text, vcsType) {
    var userRegexp = new RegExp('\\B@([\\w-]+)', 'g');
    return text.replace(userRegexp, function (reference, username) {
      var href = _externalLinks.vcsLinks.profileUrl(vcsType, {
        owner: username
      });

      return "<a href=\"".concat(href, "\">").concat(reference, "</a>");
    });
  }

  function includeCommitLink(text, owner, repo, vcsType) {
    var commitRegexp = new RegExp('([\\w-]+)?\\/([\\w-]+)?@([0-9A-Fa-f]+)', 'g');
    return text.replace(commitRegexp, function (reference, matchedOwner, matchedRepo, matchedSHA) {
      var current = {
        owner: owner,
        repo: repo
      };
      var matched = {
        owner: matchedOwner,
        repo: matchedRepo,
        sha: matchedSHA
      };
      return _commitLink(reference, current, matched, vcsType);
    });
  }

  function _issueLink(reference, current, matched, vcsType) {
    var owner = matched.owner || current.owner;
    var repo = matched.repo || current.repo;
    var issue = matched.number;

    var href = _externalLinks.vcsLinks.issueUrl(vcsType, {
      owner: owner,
      repo: repo,
      issue: issue
    });

    return "<a href=\"".concat(href, "\">").concat(reference, "</a>");
  }

  function _commitLink(reference, current, matched, vcsType) {
    var owner = matched.owner || current.owner;
    var repo = matched.repo || current.repo;
    var commit = matched.sha;

    var href = _externalLinks.vcsLinks.commitUrl(vcsType, {
      owner: owner,
      repo: repo,
      commit: commit
    });

    return "<a href=\"".concat(href, "\">").concat(reference, "</a>");
  }

  var _default = Ember.Helper.helper(function (params, options) {
    var message = params[0] || '';
    var formattedMessage = formatMessage(message, options);
    return new Ember.String.htmlSafe(formattedMessage);
  });

  _exports.default = _default;
});