define("travis/templates/components/ui-kit/text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "B/7GmP3r",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[5,\"ui-kit/box\",[[12,\"class\",[23,0,[\"allClasses\"]]],[13,1]],[[\"@tag\",\"@borderColor\",\"@borderWidth\",\"@display\",\"@flex\",\"@margin\",\"@maxWidth\",\"@padding\"],[[23,0,[\"tag\"]],[23,0,[\"borderColor\"]],[23,0,[\"borderWidth\"]],[23,0,[\"display\"]],[23,0,[\"flex\"]],[23,0,[\"margin\"]],[23,0,[\"maxWidth\"]],[23,0,[\"padding\"]]]],{\"statements\":[[14,2]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/ui-kit/text.hbs"
    }
  });

  _exports.default = _default;
});