define("travis/utils/form-validators", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.difference = _exports.presense = _exports.equal = _exports.max = _exports.creditCard = _exports.email = _exports.regexp = void 0;

  function createValidator(validate, errorMessage) {
    return function (value) {
      return validate(value) || errorMessage;
    };
  }

  var regexp = function regexp(rule) {
    var errorMessage = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'This field is invalid';
    var validator = createValidator(function (value) {
      return new RegExp(rule).test(value);
    }, errorMessage);
    validator.kind = regexp;
    return validator;
  };

  _exports.regexp = regexp;

  var email = function email() {
    var errorMessage = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'Email has incorrect format';
    var validator = regexp('\\S+@\\S+\\.\\S+', errorMessage);
    validator.kind = email;
    return validator;
  };

  _exports.email = email;

  var creditCard = function creditCard() {
    var errorMessage = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'Credit card has incorrect format';
    var validator = regexp('^\\d{4}\\s?\\d{4}\\s?\\d{4}\\s?\\d{4}$', errorMessage);
    validator.kind = creditCard;
    return validator;
  };

  _exports.creditCard = creditCard;

  var max = function max(length) {
    var validator = createValidator(function (value) {
      return value.length <= length;
    }, "Must be less than ".concat(length, " characters"));
    validator.kind = length;
    return validator;
  };

  _exports.max = max;

  var equal = function equal(length) {
    var validator = createValidator(function (value) {
      return value.length === length;
    }, "Must be equal to ".concat(length, " characters"));
    validator.kind = length;
    return validator;
  };

  _exports.equal = equal;

  var presense = function presense() {
    var errorMessage = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'This field is required';
    var validator = createValidator(function (value) {
      return Ember.isPresent(value);
    }, errorMessage);
    validator.kind = presense;
    return validator;
  };

  _exports.presense = presense;

  var difference = function difference(sample, errorMessage) {
    var validator = createValidator(function (value) {
      return !Ember.isEqual(value, sample);
    }, errorMessage || "This field should be different from ".concat(sample));
    validator.kind = difference;
    return validator;
  };

  _exports.difference = difference;
});