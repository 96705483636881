define("travis/components/ui-kit/text", ["exports", "travis/utils/ui-kit/assertions", "travis/utils/ui-kit/prefix", "travis/utils/ui-kit/concat", "travis/utils/ui-kit/variant"], function (_exports, _assertions, _prefix, _concat, _variant) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.COLORS = _exports.ALIGNMENTS = void 0;

  var _TEXT_COLORS, _DECORATIONS, _VARIANT_PROPS;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var ALIGNMENTS = {
    LEFT: 'left',
    CENTER: 'center',
    RIGHT: 'right',
    JUSTIFY: 'justify'
  };
  _exports.ALIGNMENTS = ALIGNMENTS;
  var COLORS = {
    BLUE_LIGHT: 'blue-light',
    BLUE: 'blue',
    GREEN_LIGHT: 'green-light',
    GREEN: 'green',
    GREY: 'grey',
    GREY_DARK: 'grey-dark',
    YELLOW_DARK: 'yellow-dark',
    WHITE: 'white'
  };
  _exports.COLORS = COLORS;
  var DEFAULT_TEXT_COLOR = COLORS.GREY_DARK;
  var TEXT_COLORS = (_TEXT_COLORS = {}, _defineProperty(_TEXT_COLORS, COLORS.BLUE_LIGHT, 'blue-300'), _defineProperty(_TEXT_COLORS, COLORS.BLUE, 'blue-400'), _defineProperty(_TEXT_COLORS, COLORS.GREEN_LIGHT, 'green-300'), _defineProperty(_TEXT_COLORS, COLORS.GREEN, 'green-400'), _defineProperty(_TEXT_COLORS, COLORS.GREY, 'grey-400'), _defineProperty(_TEXT_COLORS, COLORS.GREY_DARK, 'grey-800'), _defineProperty(_TEXT_COLORS, COLORS.YELLOW_DARK, 'yellow-600'), _defineProperty(_TEXT_COLORS, COLORS.WHITE, 'white'), _TEXT_COLORS);
  var FAMILIES = {
    SANS: 'sans',
    SERIF: 'serif',
    MONO: 'mono'
  };
  var DEFAULT_FAMILY = FAMILIES.SANS;
  var LEADINGS = {
    NONE: 'none',
    XS2: '2xs',
    XS: 'xs',
    SM: 'sm',
    MD: 'md',
    LG: 'lg',
    XL: 'xl'
  };
  var DEFAULT_LEADING = LEADINGS.MD;
  var LINES = {
    UNDER: 'under',
    THROUGH: 'through',
    NONE: 'none'
  };
  var DEFAULT_LINE = LINES.NONE;
  var DECORATIONS = (_DECORATIONS = {}, _defineProperty(_DECORATIONS, LINES.UNDER, 'underline'), _defineProperty(_DECORATIONS, LINES.THROUGH, 'line-through'), _defineProperty(_DECORATIONS, LINES.NONE, 'no-underline'), _DECORATIONS);
  var SIZES = {
    XS3: '3xs',
    XS2: '2xs',
    XS: 'xs',
    SM: 'sm',
    MD: 'md',
    LG: 'lg',
    XL: 'xl',
    XL2: '2xl',
    XL3: '3xl',
    XL4: '4xl',
    XL5: '5xl',
    XL6: '6xl'
  };
  var DEFAULT_SIZE = SIZES.MD;
  var TRACKINGS = {
    SM: 'sm',
    MD: 'md',
    LG: 'lg'
  };
  var DEFAULT_TRACKING = TRACKINGS.MD;
  var TRANSFORMS = {
    UPPERCASE: 'uppercase',
    LOWERCASE: 'lowercase',
    CAPITALIZE: 'capitalize',
    NORMAL: 'normal-case'
  };
  var DEFAULT_TRANSFORM = TRANSFORMS.NORMAL;
  var WEIGHTS = {
    LIGHT: 'light',
    NORMAL: 'normal',
    SEMIBOLD: 'semibold',
    BOLD: 'bold'
  };
  var DEFAULT_WEIGHT = WEIGHTS.NORMAL; // Variants

  var VARIANTS = {
    SMALLCAPS: 'smallcaps',
    H1: 'h1',
    H2: 'h2',
    P: 'p',
    LINK_BLUE: 'link-blue',
    LINK_UNDER: 'link-underlined'
  };
  var VARIANT_PROPS = (_VARIANT_PROPS = {}, _defineProperty(_VARIANT_PROPS, VARIANTS.SMALLCAPS, {
    size: 'xs',
    tracking: 'lg',
    weight: 'bold',
    transform: 'uppercase'
  }), _defineProperty(_VARIANT_PROPS, VARIANTS.H1, {
    leading: {
      base: 'none',
      md: 'md'
    },
    margin: {
      bottom: 3
    },
    size: '6xl',
    weight: 'bold'
  }), _defineProperty(_VARIANT_PROPS, VARIANTS.H2, {
    leading: 'xs',
    margin: {
      bottom: 3
    },
    size: '5xl',
    weight: 'bold'
  }), _defineProperty(_VARIANT_PROPS, VARIANTS.P, {
    margin: {
      bottom: 4
    }
  }), _defineProperty(_VARIANT_PROPS, VARIANTS.LINK_BLUE, {
    color: 'blue'
  }), _defineProperty(_VARIANT_PROPS, VARIANTS.LINK_UNDER, {
    borderWidth: {
      bottom: 'px'
    }
  }), _VARIANT_PROPS); // Component definition

  var _default = Ember.Component.extend({
    tagName: '',
    // Public interface
    tag: 'p',
    align: (0, _variant.variantProp)(VARIANT_PROPS, null),
    color: (0, _variant.variantProp)(VARIANT_PROPS, DEFAULT_TEXT_COLOR),
    family: (0, _variant.variantProp)(VARIANT_PROPS, DEFAULT_FAMILY),
    leading: (0, _variant.variantProp)(VARIANT_PROPS, DEFAULT_LEADING),
    line: (0, _variant.variantProp)(VARIANT_PROPS, DEFAULT_LINE),
    size: (0, _variant.variantProp)(VARIANT_PROPS, DEFAULT_SIZE),
    tracking: (0, _variant.variantProp)(VARIANT_PROPS, DEFAULT_TRACKING),
    transform: (0, _variant.variantProp)(VARIANT_PROPS, DEFAULT_TRANSFORM),
    weight: (0, _variant.variantProp)(VARIANT_PROPS, DEFAULT_WEIGHT),
    borderColor: (0, _variant.variantProp)(VARIANT_PROPS, null),
    borderWidth: (0, _variant.variantProp)(VARIANT_PROPS, null),
    display: (0, _variant.variantProp)(VARIANT_PROPS, null),
    margin: (0, _variant.variantProp)(VARIANT_PROPS, null),
    maxWidth: (0, _variant.variantProp)(VARIANT_PROPS, null),
    padding: (0, _variant.variantProp)(VARIANT_PROPS, null),
    flex: (0, _variant.variantProp)(VARIANT_PROPS, null),
    variant: null,
    // Private
    alignClass: (0, _prefix.default)('align', 'text'),
    colorClass: (0, _prefix.default)('color', 'text', {
      dictionary: TEXT_COLORS
    }),
    familyClass: (0, _prefix.default)('family', 'font'),
    leadingClass: (0, _prefix.default)('leading', 'leading'),
    lineClass: (0, _prefix.default)('line', '', {
      dictionary: DECORATIONS
    }),
    sizeClass: (0, _prefix.default)('size', 'text'),
    trackingClass: (0, _prefix.default)('tracking', 'tracking'),
    transformClass: (0, _prefix.default)('transform'),
    weightClass: (0, _prefix.default)('weight', 'font'),
    allClasses: (0, _concat.default)('alignClass', 'colorClass', 'familyClass', 'leadingClass', 'lineClass', 'sizeClass', 'trackingClass', 'transformClass', 'weightClass'),
    // Lifecycle
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      (0, _assertions.checkDictionary)(this.align, ALIGNMENTS, '@align', 'Text');
      (0, _assertions.checkColor)({
        value: this.color,
        dictionary: COLORS,
        component: 'Text'
      });
      (0, _assertions.checkDictionary)(this.size, SIZES, '@size', 'Text');
      (0, _assertions.checkDictionary)(this.family, FAMILIES, '@family', 'Text');
      (0, _assertions.checkDictionary)(this.leading, LEADINGS, '@leading', 'Text');
      (0, _assertions.checkDictionary)(this.line, LINES, '@line', 'Text');
      (0, _assertions.checkDictionary)(this.weight, WEIGHTS, '@weight', 'Text');
      (0, _assertions.checkDictionary)(this.tracking, TRACKINGS, '@tracking', 'Text');
      (0, _assertions.checkDictionary)(this.transform, TRANSFORMS, '@transform', 'Text');
      (0, _assertions.checkDictionary)(this.variant, VARIANTS, '@variant', 'Text');
    }
  });

  _exports.default = _default;
});