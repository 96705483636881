define("travis/templates/components/travis-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DXlToQaY",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"form\",true],[11,\"onsubmit\",[28,\"action\",[[23,0,[]],\"submit\"],null]],[10,\"class\",\"travis-form\"],[8],[0,\"\\n  \"],[14,1,[[28,\"hash\",null,[[\"validators\",\"hasErrors\",\"isValid\",\"submit\",\"field\"],[[23,0,[\"validators\"]],[23,0,[\"hasErrors\"]],[23,0,[\"isValid\"]],[28,\"action\",[[23,0,[]],\"submit\"],null],[28,\"component\",[\"forms/form-field\"],[[\"form\"],[[23,0,[]]]]]]]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/travis-form.hbs"
    }
  });

  _exports.default = _default;
});