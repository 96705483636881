define("travis/components/visibility-setting-list", ["exports", "ember-keyboard-shortcuts"], function (_exports, _emberKeyboardShortcuts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['visibility-setting-list'],
    keyboardShortcuts: {
      'esc': 'closeConfirmationModal'
    },
    // An example item that might be in the options array:
    // {
    //   key: 'private',
    //   displayValue: 'you',
    //   description: 'Do not allow everyone to see insights from your private builds',
    //   modalText: 'Do not allow everyone to see my private insights',
    // }
    //
    // `key` is used to match initialKey and selectionKey up with the correct details
    // `displayValue` is used to generate text for the modal
    // `description` is for the label next to the radio button
    // `modalText` can be used to override the generated modal text
    options: Ember.computed(function () {
      return [];
    }),
    isEmpty: Ember.computed.empty('options'),
    isVisible: Ember.computed.not('isEmpty'),
    isShowingConfirmationModal: false,
    isNotShowingConfirmationModal: Ember.computed.not('isShowingConfirmationModal'),
    onConfirm: function onConfirm() {},
    doAutofocus: false,
    focusOnList: Ember.computed.and('doAutofocus', 'isNotShowingConfirmationModal'),
    focusOnModal: Ember.computed.and('doAutofocus', 'isShowingConfirmationModal'),
    initialKey: '',
    initial: Ember.computed('initialKey', 'options.@each.key', function () {
      return this.options.findBy('key', this.initialKey);
    }),
    initialIndex: Ember.computed('initial', 'options.[]', function () {
      return this.options.indexOf(this.initial);
    }),
    selectionKey: Ember.computed.reads('initialKey'),
    selection: Ember.computed('selectionKey', 'options.@each.key', function () {
      return this.options.findBy('key', this.selectionKey);
    }),
    selectionIndex: Ember.computed('selection', 'options.[]', function () {
      return this.options.indexOf(this.selection);
    }),
    selectionTitle: Ember.computed('selection.{displayValue,key}', function () {
      return this.selection.displayValue || this.selection.key;
    }),
    change: Ember.computed('initialIndex', 'selectionIndex', function () {
      return this.selectionIndex - this.initialIndex;
    }),
    isChangeNegative: Ember.computed.lt('change', 0),
    isChangeNeutral: Ember.computed.equal('change', 0),
    isChangePositive: Ember.computed.gt('change', 0),
    didRender: function didRender() {
      this._super.apply(this, arguments);

      var af = this.element.querySelector('[autofocus]');

      if (this.doAutofocus === true && af !== null) {
        af.focus();
        this.set('doAutofocus', false);
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      (0, _emberKeyboardShortcuts.bindKeyboardShortcuts)(this);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      (0, _emberKeyboardShortcuts.unbindKeyboardShortcuts)(this);
    },
    actions: {
      confirm: function confirm() {
        this.set('isShowingConfirmationModal', false);
        this.onConfirm(this.selectionKey);
      },
      toggleConfirmationModal: function toggleConfirmationModal() {
        this.toggleProperty('isShowingConfirmationModal');
        this.set('doAutofocus', true);
      }
    }
  });

  _exports.default = _default;
});