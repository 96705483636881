define("travis/components/link-to-account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LinkToAccountComponent = Ember.Component.extend({
    tagName: '',
    routeName: '',
    routeModel: null
  });
  LinkToAccountComponent.reopenClass({
    positionalParams: ['routeName', 'routeModel']
  });
  var _default = LinkToAccountComponent;
  _exports.default = _default;
});