define("travis/templates/job-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MZWEUhMH",
    "block": "{\"symbols\":[],\"statements\":[[5,\"job-not-found\",[],[[],[]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/job-error.hbs"
    }
  });

  _exports.default = _default;
});