define("travis/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "skp2Sg2H",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"auth\",\"signedIn\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"class\",[29,[\"wrapper \",[28,\"if\",[[23,0,[\"auth\",\"signedOut\"]],\"centered\"],null]]]],[8],[0,\"\\n    \"],[5,\"top-bar\",[],[[],[]]],[0,\"\\n    \"],[5,\"flash-display\",[],[[],[]]],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"wrapper-main\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"id\",\"main\"],[10,\"role\",\"main\"],[10,\"class\",\"main\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"repo\",\"isLoaded\"]]],null,{\"statements\":[[0,\"          \"],[5,\"repository-layout\",[],[[\"@repo\",\"@build\",\"@job\",\"@tab\"],[[23,0,[\"repo\"]],[23,0,[\"build\"]],[23,0,[\"job\"]],[23,0,[\"tab\"]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[5,\"loading-indicator\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[4,\"if\",[[23,0,[\"auth\",\"signedIn\"]]],null,{\"statements\":[[4,\"unless\",[[23,0,[\"features\",\"dashboard\"]]],null,{\"statements\":[[0,\"        \"],[7,\"aside\",true],[10,\"id\",\"left\"],[11,\"class\",[29,[[28,\"unless\",[[23,0,[\"auth\",\"signedIn\"]],\"hidden\"],null]]]],[8],[0,\"\\n          \"],[5,\"repository-sidebar\",[],[[],[]]],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"wrapper centered\"],[8],[0,\"\\n    \"],[1,[28,\"component\",[[23,0,[\"landingPage\"]]],[[\"signIn\",\"signOut\"],[[28,\"action\",[[23,0,[]],\"signIn\"],[[\"target\"],[[23,0,[\"auth\"]]]]],[28,\"action\",[[23,0,[]],\"signOut\"],[[\"target\"],[[23,0,[\"auth\"]]]]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/index.hbs"
    }
  });

  _exports.default = _default;
});