define("travis/controllers/plans/index", ["exports", "travis/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var plans = _environment.default.plans;
  var ANCHOR = {
    ENTERPRISE_SECTION: 'enterprise-section'
  };

  var _default = Ember.Controller.extend({
    queryParams: ['anchor'],
    auth: Ember.inject.service(),
    metrics: Ember.inject.service(),
    config: _environment.default,
    anchor: '',
    scrollToEnterpriseSection: Ember.computed.equal('anchor', ANCHOR.ENTERPRISE_SECTION),
    billingUrl: "".concat(_environment.default.billingEndpoint, "/account/subscription"),
    buildMatrixUrl: _environment.default.urls.buildMatrix,
    enterpriseUrl: _environment.default.urls.enterprise,
    plans: Ember.computed(function () {
      return plans;
    }),
    annualPlans: Ember.computed.filterBy('plans', 'period', 'annual'),
    monthlyPlans: Ember.computed.filterBy('plans', 'period', 'monthly'),
    plansToDisplay: Ember.computed('showAnnual', 'annualPlans', 'monthlyPlans', function () {
      return this.showAnnual ? this.annualPlans : this.monthlyPlans;
    }),
    showAnnual: true,
    scrollToContact: false,
    actions: {
      signIn: function signIn() {
        this.auth.signIn();
      },
      toggleContactScroll: function toggleContactScroll() {
        var _this = this;

        this.set('scrollToContact', true);
        setTimeout(function () {
          return _this.set('scrollToContact', false);
        }, 500);
      },
      contactSuccess: function contactSuccess() {
        this.transitionToRoute('plans.thank-you');
      }
    }
  });

  _exports.default = _default;
});