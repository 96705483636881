define("travis/routes/integration/index", ["exports", "travis/routes/basic"], function (_exports, _basic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basic.default.extend({
    beforeModel: function beforeModel() {
      return this.transitionTo('/');
    }
  });

  _exports.default = _default;
});