define("travis/templates/components/caches-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LYfwUFSZ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row-item row-branch\"],[8],[0,\"\\n  \"],[5,\"request-icon\",[],[[\"@event\"],[[23,0,[\"cache\",\"type\"]]]]],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"label-align\"],[8],[0,\"\\n    \"],[1,[23,0,[\"cache\",\"branch\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"row-item row-calendar\"],[8],[0,\"\\n  \"],[5,\"svg-image\",[],[[\"@name\",\"@class\"],[\"icon-calendar\",\"icon\"]]],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"label-align\"],[8],[0,\"\\n    \"],[1,[28,\"format-time\",[[23,0,[\"cache\",\"last_modified\"]]],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"row-item row-size\"],[8],[0,\"\\n  \"],[1,[28,\"svg-jar\",[\"icon-scales\"],[[\"class\"],[\"icon\"]]],false],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"label-align\"],[8],[0,\"\\n    \"],[1,[28,\"travis-mb\",[[23,0,[\"cache\",\"size\"]]],null],false],[0,\"MB\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"row-item\"],[8],[0,\"\\n  \"],[7,\"a\",true],[11,\"class\",[29,[[28,\"if\",[[23,0,[\"delete\",\"isRunning\"]],\"deleting\"],null],\" pointer delete-cache-icon\"]]],[10,\"title\",\"Delete this cache\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"performDelete\"],null]],[8],[0,\"\\n    \"],[5,\"ember-tooltip\",[],[[\"@text\"],[\"Delete\"]]],[0,\"\\n    \"],[5,\"svg-image\",[],[[\"@name\",\"@class\"],[\"icon-trash\",\"icon\"]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/caches-item.hbs"
    }
  });

  _exports.default = _default;
});