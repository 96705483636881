define("travis/controllers/owner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    externalLinks: Ember.inject.service(),
    isLoading: false,
    profileUrl: Ember.computed('model.{login,vcsType}', function () {
      var owner = this.get('model.login');
      var vcsType = this.get('model.vcsType');
      return this.externalLinks.profileUrl(vcsType, {
        owner: owner
      });
    }),
    owner: Ember.computed.reads('model')
  });

  _exports.default = _default;
});