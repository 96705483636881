define("travis/utils/dynamic-query", ["exports", "ember-concurrency", "travis/utils/bind-generator"], function (_exports, _emberConcurrency, _bindGenerator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = dynamicQuery;
  _exports.EVENTS = void 0;

  /*
   * This utility creates a computed property that can be used on any Ember.Object instance
   * to dynamically handle pagination navigation and filtering on a remote collection.
   * The resulting property is an Ember.Array itself and can be iterated, but has some
   * additional helper properties and methods.
   *
   * Example:
   *
   * const obj = Ember.Object.extend({
   *   resources: dynamicQuery(function* ({ page = 1, filter = '' }) {
   *     yield this.store.query('resource', { page, filter });
   *   }),
   *
   *   activeResources: filterBy('resources', 'active')
   * })
   *
   * obj.resources.forEach(resource => console.log(resource));
   *
   * obj.resources.switchToNextPage();
   * obj.resources.switchToPreviousPage();
   * obj.resources.applyFilter('term');
   *
   * console.log(obj.resources.length) // 25 -> items per page
   * console.log(obj.resources.total) // 68 -> total items
   *
   * {{#unless obj.resources.isLoading }}
   *   {{#each obj.resources as |resource| }}
   *     {{resource.name}}
   *   {{/each}}
   * {{/unless}}
   *
   * {{#if obj.resources.hasNextPage }}
   *   <button onclick={{action obj.resources.switchToNextPage }}>
   *     Go To Next Page
   *   </button>
   * {{/if}}
   */
  function dynamicQuery() {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    if (typeof args.lastObject === 'function') {
      args.push({});
    }

    var initialState = Object.assign({}, args.pop(), {
      content: []
    });
    var taskFn = args.pop();
    (false && !(typeof taskFn === 'function') && Ember.assert('Task must be provided', typeof taskFn === 'function'));
    (false && !(taskFn.constructor.name === 'GeneratorFunction') && Ember.assert('Task must be a GeneratorFunction', taskFn.constructor.name === 'GeneratorFunction'));
    (false && !(!initialState.limitPagination || initialState.limit) && Ember.assert('Limit must be provided if using Limit Pagination', !initialState.limitPagination || initialState.limit));
    args.push(function () {
      var taskFnBound = (0, _bindGenerator.default)(taskFn, this);
      return DynamicQuery.extend({
        task: (0, _emberConcurrency.task)(taskFnBound).keepLatest()
      }).create(initialState);
    });
    return Ember.computed.apply(void 0, args);
  }

  var EVENTS = {
    PAGE_CHANGED: 'page-changed',
    FILTER_CHANGED: 'filter-changed'
  };
  _exports.EVENTS = EVENTS;
  var DynamicQuery = Ember.ArrayProxy.extend(Ember.Evented, {
    task: null,
    promise: null,
    page: 1,
    filterTerm: '',
    appendResults: false,
    limitPagination: false,
    limit: null,
    pagination: null,
    isLoading: Ember.computed.reads('task.isRunning'),
    isNotLoading: Ember.computed.not('isLoading'),
    isFiltering: Ember.computed.notEmpty('filterTerm'),
    isNotFiltering: Ember.computed.not('isFiltering'),
    isEmpty: Ember.computed.equal('total', 0),
    isNotEmpty: Ember.computed.not('isEmpty'),
    hasNextPage: Ember.computed.not('pagination.isLast'),
    hasPreviousPage: Ember.computed.not('pagination.isFirst'),
    total: Ember.computed.reads('pagination.total'),
    totalPages: Ember.computed.reads('pagination.numberOfPages'),
    init: function init() {
      this._super.apply(this, arguments);

      this.setObjects([]);
      this.load();
    },
    switchToNextPage: function switchToNextPage() {
      var hasNextPage = this.hasNextPage,
          page = this.page,
          promise = this.promise;
      return hasNextPage ? this.switchToPage(page + 1) : promise;
    },
    switchToPreviousPage: function switchToPreviousPage() {
      var hasPreviousPage = this.hasPreviousPage,
          page = this.page,
          promise = this.promise;
      return hasPreviousPage ? this.switchToPage(page - 1) : promise;
    },
    switchToPage: function switchToPage() {
      var page = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
      var currentPage = this.page,
          promise = this.promise;
      return page === currentPage ? promise : this.reload({
        page: page
      });
    },
    hasPage: function hasPage() {
      var page = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
      return page <= this.totalPages && page > 0;
    },
    applyFilter: function applyFilter() {
      var filterTerm = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      var page = 1;
      return this.reload({
        filterTerm: filterTerm,
        page: page
      });
    },
    load: function load(options) {
      return this.promise || this.reload(options);
    },
    reload: function reload(options) {
      var _this = this;

      this.applyOptions(options);
      var page = this.page,
          filterTerm = this.filterTerm;
      this.promise = this.task.perform({
        page: page,
        filter: filterTerm
      }).then(function () {
        var result = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

        if (_this.limitPagination) {
          _this.set('pagination', _this.calcLimitPagination(result));
        } else {
          _this.set('pagination', result.pagination);
        }

        var results = result.toArray();

        if (_this.appendResults) {
          _this.addObjects(results);
        } else {
          _this.set('content', results);
        }

        if (!_this.hasPage(page)) {
          Ember.run.next(function () {
            return _this.switchToPage(1);
          });
        }

        return _this;
      });
      return this.promise;
    },
    applyOptions: function applyOptions() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          page = _ref.page,
          filterTerm = _ref.filterTerm;

      if (page !== undefined && page !== this.currentPage) {
        this.set('page', page);
        this.trigger(EVENTS.PAGE_CHANGED, page);
      }

      if (filterTerm !== undefined && filterTerm !== this.filterTerm) {
        this.set('filterTerm', filterTerm);
        this.trigger(EVENTS.FILTER_CHANGED, filterTerm);
      }
    },
    // For use with heuristic pagination, AKA skip_count=true
    // Although now that I think about it, maybe it would be better to update API to do this?
    calcLimitPagination: function calcLimitPagination() {
      var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          length = _ref2.length;

      var limit = this.limit,
          page = this.page,
          _this$total = this.total,
          oldTotal = _this$total === void 0 ? 0 : _this$total;
      var limitDiff = length % limit;
      var hasMore = length && limitDiff === 0;
      var total = hasMore ? oldTotal + length + 1 : oldTotal + length;
      var numberOfPages = hasMore ? page + 1 : page;
      var isLast = page === numberOfPages;
      var isFirst = page === 1;
      return {
        total: total,
        numberOfPages: numberOfPages,
        isLast: isLast,
        isFirst: isFirst
      };
    }
  });
});