define("travis/components/requests-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['request-item'],
    classNameBindings: ['requestClass', 'highlightedClass'],
    tagName: 'li',
    requestClass: Ember.computed.reads('request.result'),
    build: Ember.computed.reads('request.build'),
    type: Ember.computed('request.isPullRequest', function () {
      var isPullRequest = this.get('request.isPullRequest');
      return isPullRequest ? 'pull_request' : 'push';
    }),
    highlightedClass: Ember.computed('highlightedRequestId', 'request.id', function () {
      var paramId = this.highlightedRequestId;
      var currentId = this.get('request.id');
      return paramId === currentId ? 'highlighted' : '';
    }),
    status: Ember.computed('request.result', function () {
      var result = this.get('request.result');
      return result.capitalize();
    }),
    message: Ember.computed('features.proVersion', 'request.message', function () {
      var proVersion = this.get('features.proVersion');
      var message = this.get('request.message');

      if (proVersion && message === 'private repository') {
        return '';
      } else if (!message) {
        return 'Build created successfully ';
      }

      return message;
    })
  });

  _exports.default = _default;
});