define("travis/components/owner/migrate", ["exports", "travis/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _config$githubApps$ap = _environment.default.githubApps.appName,
      appName = _config$githubApps$ap === void 0 ? 'travis-ci' : _config$githubApps$ap;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    // for pagination
    owner: null,
    isShowingRepositoryMigrationModal: false,
    repositories: Ember.computed.reads('owner.githubAppsRepositoriesOnOrg'),
    selectedRepositories: Ember.computed(function () {
      return [];
    }),
    selectableRepositories: Ember.computed.filterBy('repositories', 'isMigratable'),
    hasRepos: Ember.computed.gt('repositories.total', 0),
    isFiltering: Ember.computed.notEmpty('repositories.filterTerm'),
    isAllSelected: Ember.computed('selectedRepositories.[]', 'selectableRepositories.[]', function () {
      var selectedRepositories = this.selectedRepositories,
          selectableRepositories = this.selectableRepositories;
      return selectableRepositories.every(function (repo) {
        return selectedRepositories.includes(repo);
      });
    }),
    showSelectAll: Ember.computed('selectableRepositories.[]', function () {
      return this.selectableRepositories.length > 1;
    }),
    showActivationStep: Ember.computed.and('repositories.isEmpty', 'repositories.isNotFiltering', 'repositories.isNotLoading'),
    showFilter: Ember.computed.or('hasRepos', 'isFiltering', 'repositories.isLoading'),
    activateAllUrl: Ember.computed('owner.githubId', function () {
      var githubId = this.owner.githubId;
      return "".concat(_environment.default.githubAppsEndpoint, "/").concat(appName, "/installations/new/permissions?suggested_target_id=").concat(githubId);
    }),
    init: function init() {
      this._super.apply(this, arguments);

      var selectedRepositories = this.selectedRepositories,
          selectableRepositories = this.selectableRepositories;

      if (selectableRepositories.length === 1) {
        selectedRepositories.addObjects(selectableRepositories);
      }
    },
    actions: {
      toggleRepository: function toggleRepository(repo) {
        var selectedRepositories = this.selectedRepositories;
        var isSelected = selectedRepositories.includes(repo);

        if (isSelected) {
          selectedRepositories.removeObject(repo);
        } else {
          selectedRepositories.addObject(repo);
        }
      },
      toggleAll: function toggleAll() {
        var isAllSelected = this.isAllSelected,
            selectableRepositories = this.selectableRepositories,
            selectedRepositories = this.selectedRepositories;

        if (isAllSelected) {
          selectedRepositories.removeObjects(selectableRepositories.toArray());
        } else {
          selectedRepositories.addObjects(selectableRepositories.toArray());
        }
      },
      closeMigrateModal: function closeMigrateModal() {
        this.set('isShowingRepositoryMigrationModal', false);
        this.selectedRepositories.clear();
        this.repositories.switchToPage(1);
      }
    }
  });

  _exports.default = _default;
});