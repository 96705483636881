define("travis/templates/features-tracing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bnIBZi18",
    "block": "{\"symbols\":[],\"statements\":[[5,\"travis-layout\",[],[[\"@layoutName\"],[\"layouts/center\"]],{\"statements\":[[0,\"\\n  \"],[7,\"header\",true],[10,\"class\",\"features-hero\"],[8],[0,\"\\n    \"],[7,\"h1\",true],[10,\"class\",\"h1--grey\"],[8],[0,\"\\n      Tracing ✨\\n    \"],[9],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"text-big\"],[8],[0,\"\\n      Tracing is a super secret feature for performance debugging.\\n    \"],[9],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"text-big\"],[8],[0,\"\\n      ➡️ \\n      \"],[7,\"a\",true],[10,\"class\",\"pointer\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"toggle\"],[[\"preventDefault\"],[true]]]],[8],[0,\"\\n        \"],[1,[28,\"if\",[[23,0,[\"tracingEnabled\"]],\"Disable\",\"Enable\"],null],false],[0,\" tracing\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"text-big\"],[8],[0,\"\\n      You can also drag this amazing bookmarklet into your bookmarks:\\n    \"],[9],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"text-big\"],[8],[0,\"\\n      ➡️ \\n      \"],[7,\"a\",true],[10,\"href\",\"javascript:window.TravisTracer&&TravisTracer.bookmarklet()\"],[8],[0,\"\\n        Toggle tracing ✨\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"text-big\"],[8],[0,\"\\n      The tracing panel color theme adapted from\\n      \"],[7,\"a\",true],[10,\"href\",\"http://twitter.com/sailorhg\"],[8],[0,\"sailorhg\"],[9],[0,\"'s\\n      \"],[7,\"a\",true],[10,\"href\",\"http://sailorhg.github.io/fairyfloss/\"],[8],[0,\"fairyfloss\"],[9],[0,\" theme.\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/features-tracing.hbs"
    }
  });

  _exports.default = _default;
});