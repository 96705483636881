define("travis/components/job-tabs", ["exports", "travis/mixins/components/with-config-validation"], function (_exports, _withConfigValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_withConfigValidation.default, {
    tagName: 'div',
    classNames: ['travistab'],
    router: Ember.inject.service(),
    isConfig: Ember.computed.match('router.currentRouteName', /config$/),
    isLog: Ember.computed.not('isConfig'),
    messages: Ember.computed.reads('job.build.request.messages')
  });

  _exports.default = _default;
});