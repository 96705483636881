define("travis/serializers/request", ["exports", "travis/serializers/v2_fallback"], function (_exports, _v2_fallback) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Serializer = _v2_fallback.default.extend({
    keyForV2Relationship: function keyForV2Relationship(key
    /* , typeClass, method*/
    ) {
      if (key === 'repo') {
        return 'repository_id';
      } else {
        return this._super.apply(this, arguments);
      }
    },
    normalizeArrayResponse: function normalizeArrayResponse(store, primaryModelClass, payload
    /* , id, requestType*/
    ) {
      if (payload.commits) {
        payload.requests.forEach(function (request) {
          var commit = commit = payload.commits.findBy('id', request.commit_id);

          if (commit) {
            request.commit = commit;
            return delete request.commit_id;
          }
        });
      }

      return this._super.apply(this, arguments);
    },
    normalize: function normalize(modelClass, resourceHash) {
      // This converts this from hasMany to belongsTo
      if (resourceHash.builds) {
        resourceHash.build = resourceHash.builds[0];
      }

      return this._super(modelClass, resourceHash);
    }
  });

  var _default = Serializer;
  _exports.default = _default;
});