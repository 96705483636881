define("travis/templates/components/env-var", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MB13CkTO",
    "block": "{\"symbols\":[],\"statements\":[[5,\"ember-tooltip\",[],[[\"@text\",\"@targetId\",\"@popperContainer\"],[[23,0,[\"envVar\",\"name\"]],[29,[\"env-var-name-\",[24,[\"envVar\",\"id\"]]]],\"body\"]]],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"env-var-name\"],[11,\"id\",[29,[\"env-var-name-\",[24,[\"envVar\",\"id\"]]]]],[8],[0,\"\\n  \"],[1,[23,0,[\"envVar\",\"name\"]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"env-var-value\"],[8],[0,\"\\n\"],[4,\"unless\",[[23,0,[\"envVar\",\"public\"]]],null,{\"statements\":[[0,\"    \"],[5,\"svg-image\",[],[[\"@name\",\"@class\"],[\"icon-private\",\"icon\"]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"input\",true],[11,\"value\",[23,0,[\"value\"]]],[10,\"readonly\",\"readonly\"],[10,\"type\",\"pw\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"env-var-branch\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"envVar\",\"branch\"]]],null,{\"statements\":[[0,\"    Only available to the \"],[7,\"code\",true],[8],[1,[23,0,[\"envVar\",\"branch\"]],false],[9],[0,\" branch\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    Available to all branches\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"env-var-action\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"delete\",\"isRunning\"]]],null,{\"statements\":[[0,\"    \"],[5,\"loading-indicator\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"button\",true],[10,\"class\",\"no-button env-var-delete\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],[28,\"perform\",[[24,[\"delete\"]]],null]],null]],[10,\"type\",\"button\"],[8],[0,\"\\n      \"],[5,\"svg-image\",[],[[\"@name\",\"@class\"],[\"icon-trash\",\"icon\"]]],[0,\"\\n      \"],[5,\"ember-tooltip\",[],[[\"@text\"],[\"Delete\"]]],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/env-var.hbs"
    }
  });

  _exports.default = _default;
});