define("travis/components/ui-kit/switch", ["exports", "travis/utils/ui-kit/assertions"], function (_exports, _assertions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  // Size definitions
  var SIZES = {
    MD: 'md'
  };
  var DEFAULT_SIZE = SIZES.MD;

  var DOT_SIZES = _defineProperty({}, SIZES.MD, 4);

  var SPACE_SIZES = _defineProperty({}, SIZES.MD, 5); // Color definitions


  var COLORS = {
    BLUE: 'blue-300'
  };
  var DEFAULT_COLOR = COLORS.BLUE;
  var BG_COLORS = {
    inactive: 'grey-300'
  }; // Component definition

  var _default = Ember.Component.extend({
    tagName: '',
    // Public interface
    active: false,
    disabled: false,
    size: DEFAULT_SIZE,
    color: DEFAULT_COLOR,
    onClick: function onClick() {},
    // Private
    dotSize: Ember.computed('size', function () {
      return DOT_SIZES[this.size];
    }),
    spaceSize: Ember.computed('size', function () {
      return SPACE_SIZES[this.size];
    }),
    activeBgColor: Ember.computed.reads('color'),
    inactiveBgColor: Ember.computed(function () {
      return BG_COLORS['inactive'];
    }),
    // Lifecycle
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      (0, _assertions.checkDictionary)(this.color, COLORS, '@color', 'Switch');
      (0, _assertions.checkDictionary)(this.size, SIZES, '@size', 'Switch');
    },
    // Actions
    actions: {
      handleClick: function handleClick() {
        return this.onClick(!this.active);
      }
    }
  });

  _exports.default = _default;
});