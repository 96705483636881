define("travis/helpers/format-currency", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatCurrency = formatCurrency;
  _exports.default = void 0;

  function _toArray(arr) { return _arrayWithHoles(arr) || _iterableToArray(arr) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function formatCurrency(_ref, _ref2) {
    var _ref3 = _toArray(_ref),
        value = _ref3[0],
        rest = _ref3.slice(1);

    var floor = _ref2.floor;
    var parsedValue = Number(value);

    if (isNaN(parsedValue)) {
      return value;
    }

    var dollars = parsedValue / 100;
    return floor ? "$".concat(Math.floor(dollars)) : "$".concat(dollars.toFixed(2));
  }

  var _default = Ember.Helper.helper(formatCurrency);

  _exports.default = _default;
});