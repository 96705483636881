define("travis/templates/owner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "phvk9R2d",
    "block": "{\"symbols\":[],\"statements\":[[5,\"travis-layout\",[[12,\"class\",\"owner\"]],[[\"@layoutName\"],[\"layouts/center\"]],{\"statements\":[[0,\"\\n  \"],[7,\"header\",true],[10,\"class\",\"owner-header row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"owner-avatar\"],[8],[0,\"\\n      \"],[5,\"user-avatar\",[],[[\"@url\",\"@name\",\"@size\"],[[23,0,[\"owner\",\"avatarUrl\"]],[23,0,[\"owner\",\"name\"]],75]]],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"owner-info\"],[8],[0,\"\\n      \"],[7,\"h1\",true],[10,\"class\",\"owner-title\"],[8],[0,\"\\n        \"],[1,[23,0,[\"owner\",\"name\"]],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"p\",true],[10,\"class\",\"owner-handle\"],[8],[0,\"\\n        \"],[5,\"svg-image\",[],[[\"@name\",\"@class\"],[[28,\"vcs-icon\",[[23,0,[\"owner\",\"vcsType\"]]],null],\"icon--l\"]]],[0,\"\\n        \"],[7,\"a\",true],[11,\"href\",[23,0,[\"profileUrl\"]]],[11,\"title\",[29,[[28,\"concat\",[[23,0,[\"owner\",\"name\"]],\" on \",[28,\"vcs-name\",[[23,0,[\"owner\",\"vcsType\"]]],null]],null]]]],[8],[0,\"\\n          \"],[7,\"span\",true],[10,\"class\",\"label-align\"],[8],[0,\"\\n            \"],[1,[23,0,[\"owner\",\"login\"]],false],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"tabs--owner\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"tabbody\"],[8],[0,\"\\n      \"],[7,\"section\",true],[10,\"role\",\"tabpanel\"],[8],[0,\"\\n        \"],[1,[22,\"outlet\"],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/owner.hbs"
    }
  });

  _exports.default = _default;
});