define("travis/components/billing/information", ["exports", "travis/utils/countries"], function (_exports, _countries) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    countries: _countries.countries,
    actions: {
      updateEmails: function updateEmails(values) {
        this.newSubscription.billingInfo.set('billingEmail', values.join(','));
      }
    }
  });

  _exports.default = _default;
});