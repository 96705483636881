define("travis/utils/time-in-words", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = timeInWords;

  function timeInWords(duration) {
    var days, hours, minutes, result, seconds;
    days = Math.floor(duration / 86400);
    hours = Math.floor(duration % 86400 / 3600);
    minutes = Math.floor(duration % 3600 / 60);
    seconds = duration % 60;

    if (days > 0) {
      return 'more than 24 hrs';
    } else {
      result = [];

      if (hours === 1) {
        result.push("".concat(hours, " hr"));
      }

      if (hours > 1) {
        result.push("".concat(hours, " hrs"));
      }

      if (minutes > 0) {
        result.push("".concat(minutes, " min"));
      }

      if (seconds > 0) {
        result.push("".concat(seconds, " sec"));
      }

      if (result.length > 0) {
        return result.join(' ');
      } else {
        return '-';
      }
    }
  }
});