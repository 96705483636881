define("travis/components/build-tile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'li',
    classNameBindings: ['build.state'],
    attributeBindings: ['title'],
    title: Ember.computed('build.{number,state}', function () {
      var number = this.get('build.number');
      var state = this.get('build.state');

      if (number) {
        return "Build #".concat(number, " ").concat(state);
      } else {
        return '';
      }
    })
  });

  _exports.default = _default;
});