define("travis/components/owner-repo-tile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'li',
    classNames: ['owner-tile', 'rows', 'rows--owner', 'fade-out'],
    classNameBindings: ['repo.defaultBranch.lastBuild.state'],
    ownerName: Ember.computed('repo.slug', function () {
      var slug = this.get('repo.slug');
      return slug.split(/\//)[0];
    }),
    repoName: Ember.computed('repo.slug', function () {
      var slug = this.get('repo.slug');
      return slug.split(/\//)[1];
    }),
    isAnimating: Ember.computed('repo.defaultBranch.lastBuild.state', function () {
      var state = this.get('repo.defaultBranch.lastBuild.state');
      var animationStates = ['received', 'queued', 'started', 'booting'];
      return animationStates.includes(state);
    })
  });

  _exports.default = _default;
});