define("travis/routes/search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    tabStates: Ember.inject.service(),
    auth: Ember.inject.service(),
    repositories: Ember.inject.service(),
    redirect: function redirect() {
      if (!this.get('auth.signedIn')) {
        this.transitionTo('index');
      }
    },
    activate: function activate() {
      this.tabStates.set('sidebarTab', 'search');

      this._super.apply(this, arguments);
    },
    setupController: function setupController(controller, searchPhrase) {
      this._super.apply(this, arguments);

      this.set('repositories.searchQuery', searchPhrase);
    },
    model: function model(params) {
      return params.phrase.replace(/%2F/g, '/');
    },
    deactivate: function deactivate() {
      this._super.apply(this, arguments);

      this.set('repositories.searchQuery', undefined);
    }
  });

  _exports.default = _default;
});