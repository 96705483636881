define("travis/adapters/ssh-key", ["exports", "travis/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    namespace: 'settings',
    urlPrefix: function urlPrefix() {
      var prefix = this._super.apply(this, arguments);

      if (prefix.indexOf('http') === -1) {
        return "/".concat(prefix);
      } else {
        return prefix;
      }
    },
    pathForType: function pathForType(type) {
      return Ember.String.underscore(type);
    },
    createRecord: function createRecord(store, type, record) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, record, {
        includeId: true
      });
      var url = "".concat(this.urlPrefix(), "/ssh_key/").concat(record.id);
      return this.ajax(url, 'PATCH', {
        data: data
      });
    }
  });

  _exports.default = _default;
});