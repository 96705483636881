define("travis/templates/components/job-tabs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gKuFup78",
    "block": "{\"symbols\":[],\"statements\":[[7,\"nav\",true],[10,\"class\",\"travistab-nav--secondary\"],[8],[0,\"\\n  \"],[7,\"ul\",true],[8],[0,\"\\n    \"],[7,\"li\",true],[8],[0,\"\\n      \"],[5,\"link-to\",[[12,\"class\",[28,\"if\",[[23,0,[\"isLog\"]],\"active\"],null]]],[[\"@route\",\"@models\",\"@id\",\"@title\"],[\"job.index\",[28,\"array\",[[23,0,[\"repo\"]],[23,0,[\"job\"]]],null],\"tab_log\",\"Look at this job's log\"]],{\"statements\":[[0,\"\\n        Job log\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"li\",true],[8],[0,\"\\n      \"],[5,\"link-to\",[],[[\"@route\",\"@models\",\"@title\",\"@disabled\"],[\"job.config\",[28,\"array\",[[23,0,[\"repo\"]],[23,0,[\"job\"]]],null],\"Look at this job's config\",[23,0,[\"job\",\"build\",\"request\",\"noYaml\"]]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,0,[\"job\",\"build\",\"request\",\"noYaml\"]]],null,{\"statements\":[[0,\"          \"],[5,\"ember-tooltip\",[],[[\"@text\"],[\"Try a more recent job to see the config\"]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n        View config\\n\\n\"],[4,\"if\",[[23,0,[\"showConfigValidation\"]]],null,{\"statements\":[[0,\"          \"],[7,\"span\",true],[10,\"class\",\"tooltip-wrapper\"],[8],[0,\"\\n            \"],[7,\"span\",true],[11,\"class\",[29,[\"build-messages-badge badge-\",[23,0,[\"messagesMaxLevel\"]]]]],[8],[9],[0,\"\\n            \"],[5,\"ember-tooltip\",[],[[\"@text\"],[[23,0,[\"messagesBadgeTooltipText\"]]]]],[0,\"\\n          \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"travistab-body job-tabs\"],[8],[0,\"\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/job-tabs.hbs"
    }
  });

  _exports.default = _default;
});