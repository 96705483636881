define("travis/components/load-more", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    dynamicQuery: null,
    liveItems: null,
    items: Ember.computed.or('liveItems', 'dynamicQuery'),
    isItemsEmpty: Ember.computed.empty('items'),
    isNoneFound: Ember.computed.and('dynamicQuery.isNotLoading', 'isItemsEmpty'),
    actions: {
      showMore: function showMore() {
        return this.dynamicQuery.switchToNextPage();
      }
    }
  });

  _exports.default = _default;
});