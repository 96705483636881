define("travis/routes/account", ["exports", "travis/routes/basic"], function (_exports, _basic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basic.default.extend({
    raven: Ember.inject.service(),
    accounts: Ember.inject.service(),
    features: Ember.inject.service(),
    needsAuth: true,
    titleToken: function titleToken() {
      var account = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return "".concat(account.name || account.login || 'Account', " - Profile");
    },
    model: function model() {
      return this.accounts.user;
    },
    afterModel: function afterModel(model) {
      if (model && !model.error && !this.features.get('enterpriseVersion')) model.fetchBetaMigrationRequests();
    }
  });

  _exports.default = _default;
});