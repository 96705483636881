define("travis/routes/job", ["exports", "travis/routes/basic"], function (_exports, _basic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basic.default.extend({
    router: Ember.inject.service(),
    titleToken: function titleToken(model) {
      return "Job #".concat(model.get('number'));
    },
    serialize: function serialize(model
    /* , params*/
    ) {
      var id = model.get ? model.get('id') : model;
      return {
        job_id: id
      };
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      var buildController, repo;

      if (model && !model.get) {
        model = this.store.recordForId('job', model);
        this.store.find('job', model);
      }

      repo = this.controllerFor('repo');
      controller.set('job', model);
      repo.activate('job');
      buildController = this.controllerFor('build');
      model.get('repo');
      var buildPromise = model.get('build');

      if (buildPromise) {
        buildPromise.then(function (build) {
          build = _this.store.recordForId('build', build.get('id'));
          return buildController.set('build', build);
        });
      } // this is a hack to not set favicon changes from build
      // controller while we're viewing job, this should go away
      // after refactoring of controllers


      return buildController.set('sendFaviconStateChanges', false);
    },
    model: function model(params) {
      return this.store.findRecord('job', params.job_id);
    },
    afterModel: function afterModel(job) {
      var slug = this.modelFor('repo').get('slug');
      this.ensureJobOwnership(job, slug);
      return job.get('build.request').then(function (request) {
        return request && request.fetchMessages.perform();
      });
    },
    ensureJobOwnership: function ensureJobOwnership(job, urlSlug) {
      var jobSlug = job.get('repositorySlug') || job.get('repo.slug');
      var repoVcsSlug = job.get('repo.vcsSlug');

      if (jobSlug !== urlSlug && repoVcsSlug !== urlSlug) {
        throw new Error('invalidJobId');
      }
    },
    deactivate: function deactivate() {
      var buildController;
      buildController = this.controllerFor('build');
      buildController.set('sendFaviconStateChanges', true);
      this.controllerFor('build').set('build', null);
      this.controllerFor('job').set('job', null);
      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});