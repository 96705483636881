define("travis/templates/components/repository-visibility-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NfvQX66/",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"and\",[[28,\"not\",[[24,[\"hideShared\"]]],null],[23,0,[\"repository\",\"shared\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[8],[0,\"\\n    \"],[5,\"svg-image\",[],[[\"@name\",\"@alt\",\"@class\"],[\"icon-shared\",\"Shared repository\",\"icon shared\"]]],[0,\"\\n    \"],[5,\"ember-tooltip\",[],[[\"@text\"],[\"Shared repository\"]]],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"span\",true],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"repository\",\"private\"]]],null,{\"statements\":[[0,\"    \"],[5,\"svg-image\",[],[[\"@name\",\"@alt\",\"@class\"],[\"icon-lock\",\"Private repository\",\"icon private\"]]],[0,\"\\n    \"],[5,\"ember-tooltip\",[],[[\"@text\"],[\"Private repository\"]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[5,\"svg-image\",[],[[\"@name\",\"@alt\",\"@class\"],[\"icon-os\",\"Public repository\",\"icon public\"]]],[0,\"\\n    \"],[5,\"ember-tooltip\",[],[[\"@text\"],[\"Public repository\"]]],[0,\"\\n\"]],\"parameters\":[]}],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/repository-visibility-icon.hbs"
    }
  });

  _exports.default = _default;
});