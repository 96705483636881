define("travis/templates/components/request-config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pl+14Xj/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"inner-yaml-container\"],[8],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",\"header\"],[3,\"on\",[\"click\",[28,\"action\",[[23,0,[]],\"toggle\"],null]]],[8],[0,\"\\n    \"],[5,\"svg-image\",[],[[\"@name\",\"@class\",\"@width\",\"@height\"],[\"job-name-icon\",\"icon\",\"14\",\"14\"]]],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"vertical-align\"],[8],[0,\"\\n      Build Config\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"tools\"],[8],[0,\"\\n      \"],[5,\"svg-image\",[],[[\"@name\",\"@class\"],[\"icon-dropdown-arrow\",[29,[\"icon-toggle icon-\",[23,0,[\"toggleStatusClass\"]]]]]]],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"if\",[[23,0,[\"isExpanded\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"position-relative\"],[8],[0,\"\\n      \"],[5,\"code-block\",[[12,\"class\",\"codedisplay line-numbers\"]],[[\"@language\"],[\"yaml\"]],{\"statements\":[[1,[23,0,[\"formattedConfig\"]],false]],\"parameters\":[]}],[0,\"\\n      \"],[5,\"copy-button\",[],[[\"@clipboardText\",\"@title\",\"@success\"],[[23,0,[\"config\"]],[23,0,[\"buttonLabel\"]],[28,\"action\",[[23,0,[]],\"copied\",[23,0,[\"config\"]]],null]]],{\"statements\":[[0,\"\\n        \"],[5,\"svg-image\",[],[[\"@name\",\"@class\"],[\"icon-copy\",\"icon\"]]],[0,\"\\n        \"],[7,\"span\",true],[8],[0,\"\\n          \"],[1,[23,0,[\"buttonLabel\"]],false],[0,\"\\n        \"],[9],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/request-config.hbs"
    }
  });

  _exports.default = _default;
});