define("travis/templates/components/billing/price", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "i4kTR2YK",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"isNotGithubTrial\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"selected-plan__price\"],[8],[0,\"\\n    \"],[7,\"h6\",true],[10,\"class\",\"selected-plan__price--total\"],[8],[0,\"\\n      Total:\\n    \"],[9],[0,\"\\n\"],[4,\"if\",[[23,0,[\"subscription\",\"plan\"]]],null,{\"statements\":[[0,\"      \"],[7,\"p\",true],[10,\"class\",\"selected-plan__price--large\"],[8],[0,\"\\n        \"],[1,[28,\"format-currency\",[[23,0,[\"subscription\",\"plan\",\"price\"]]],[[\"floor\"],[\"true\"]]],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"selected-plan__period\"],[8],[0,\"\\n        \"],[1,[28,\"if\",[[23,0,[\"subscription\",\"plan\",\"annual\"]],\"/year\",\"/month\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[4,\"if\",[[23,0,[\"discountIsValid\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"discount\"],[8],[0,\"\\n      \"],[7,\"h6\",true],[8],[0,\"Discount: \"],[9],[0,\"\\n      \"],[7,\"p\",true],[8],[0,\" \\n\"],[4,\"if\",[[23,0,[\"percentOff\"]]],null,{\"statements\":[[0,\"          \"],[1,[23,0,[\"percentOff\"]],false],[0,\"%\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,0,[\"amountOff\"]]],null,{\"statements\":[[0,\"          $\"],[1,[23,0,[\"amountOff\"]],false],[0,\"\\n        \"]],\"parameters\":[]},null]],\"parameters\":[]}],[4,\"if\",[[28,\"eq\",[[23,0,[\"duration\"]],\"forever\"],null]],null,{\"statements\":[[0,\"          forever\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          off until \"],[1,[28,\"moment-format\",[[28,\"moment-add\",[[24,[\"subscription\",\"createdAt\"]],[23,0,[\"durationInMonths\"]]],[[\"precision\"],[\"months\"]]],\"MMMM YYYY\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/billing/price.hbs"
    }
  });

  _exports.default = _default;
});