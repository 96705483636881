define("travis/templates/components/billing/account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "w3ohWtuB",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"accounts\",\"fetchSubscriptions\",\"isRunning\"]]],null,{\"statements\":[[0,\"  \"],[5,\"loading-indicator\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"section\",true],[10,\"class\",\"billing\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"isEducation\"]]],null,{\"statements\":[[0,\"      \"],[5,\"billing/education\",[],[[\"@newSubscription\",\"@account\"],[[23,0,[\"newSubscription\"]],[23,0,[\"account\"]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,0,[\"isManual\"]]],null,{\"statements\":[[0,\"      \"],[5,\"billing-manual\",[],[[\"@subscription\",\"@account\",\"@newSubscription\"],[[23,0,[\"subscription\"]],[23,0,[\"account\"]],[23,0,[\"newSubscription\"]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,0,[\"isManaged\"]]],null,{\"statements\":[[0,\"      \"],[5,\"billing/subscription\",[],[[\"@newSubscription\",\"@account\"],[[23,0,[\"newSubscription\"]],[23,0,[\"account\"]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,0,[\"isTrial\"]]],null,{\"statements\":[[0,\"      \"],[5,\"billing/trial\",[],[[\"@account\",\"@newSubscription\"],[[23,0,[\"account\"]],[23,0,[\"newSubscription\"]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[4,\"if\",[[23,0,[\"invoices\"]]],null,{\"statements\":[[0,\"    \"],[5,\"billing/invoices\",[],[[\"@account\",\"@invoices\",\"@subscription\"],[[23,0,[\"account\"]],[23,0,[\"invoices\"]],[23,0,[\"subscription\"]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/billing/account.hbs"
    }
  });

  _exports.default = _default;
});