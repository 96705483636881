define("travis/models/plan", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    builds: (0, _model.attr)(),
    price: (0, _model.attr)(),
    annual: (0, _model.attr)('boolean'),
    currency: (0, _model.attr)()
  });

  _exports.default = _default;
});