define("travis/components/loading-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    auth: Ember.inject.service(),
    showHeader: Ember.computed.not('auth.signingIn')
  });

  _exports.default = _default;
});