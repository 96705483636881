define("travis/models/env-var", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    value: (0, _model.attr)('string'),
    'public': (0, _model.attr)('boolean'),
    branch: (0, _model.attr)('string'),
    repo: (0, _model.belongsTo)('repo', {
      async: true
    })
  });

  _exports.default = _default;
});