define("travis/controllers/application", ["exports", "travis/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var utmParametersResetDelay = _environment.default.timing.utmParametersResetDelay;

  var _default = Ember.Controller.extend({
    features: Ember.inject.service(),
    metrics: Ember.inject.service(),
    router: Ember.inject.service(),
    utm: Ember.inject.service(),
    trackPage: function trackPage(page) {
      var _this = this;

      page = page || this.router.currentURL || this.router.location.getURL();
      var delimiter = page.includes('?') ? '&' : '?';
      page = "".concat(page).concat(delimiter).concat(this.utm.existing);
      return new Ember.RSVP.Promise(function (resolve) {
        try {
          _this.metrics.trackPage({
            page: page,
            hitCallback: function hitCallback() {
              return resolve();
            }
          }); // If page is not reported to GA for some reason,
          // just resolve after a timeout to unblock teardown


          Ember.run.later(function () {
            return resolve();
          }, utmParametersResetDelay);
        } catch (err) {
          resolve(); // skip error and continue
        }
      });
    },
    handleRouteChange: function handleRouteChange() {
      var _this2 = this;

      this.trackPage().then(function () {
        return _this2.utm.removeFromUrl();
      });
    },
    init: function init() {
      var _this3 = this;

      this._super.apply(this, arguments);

      this.router.on('routeDidChange', function () {
        return _this3.handleRouteChange();
      });
      this.utm.capture();
    }
  });

  _exports.default = _default;
});