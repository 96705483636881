define("travis/routes/organization", ["exports", "travis/routes/basic"], function (_exports, _basic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basic.default.extend({
    accounts: Ember.inject.service(),
    features: Ember.inject.service(),
    auth: Ember.inject.service(),
    needsAuth: true,
    titleToken: function titleToken() {
      var org = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return "".concat(org.error ? 'Account' : org.name || org.login, " - Profile");
    },
    beforeModel: function beforeModel() {
      if (this.auth.signedIn) {
        var fetchOrganizations = this.accounts.fetchOrganizations;
        var lastSuccessful = fetchOrganizations.lastSuccessful;

        if (lastSuccessful && lastSuccessful._promise) {
          return lastSuccessful._promise;
        } else {
          return fetchOrganizations.perform();
        }
      } else {
        return this._super.apply(this, arguments);
      }
    },
    model: function model(_ref) {
      var login = _ref.login;
      var org = this.accounts.organizations.findBy('login', login);
      return org || {
        login: login,
        error: true
      };
    },
    afterModel: function afterModel(model) {
      if (model && !model.error && !this.features.get('enterpriseVersion')) model.fetchBetaMigrationRequests();
    }
  });

  _exports.default = _default;
});