define("travis/utils/live-paginated-collection", ["exports", "travis/utils/computed-limit"], function (_exports, _computedLimit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  // LivePaginatedCollection is an interface for a first page of paginated set of
  // results.
  var LivePaginatedCollection = Ember.ArrayProxy.extend({
    pagination: Ember.computed('paginationData', function () {
      var paginationData = this.paginationData;
      if (!paginationData) return;
      return {
        total: paginationData.count,
        perPage: paginationData.limit,
        offset: paginationData.offset,
        isFirst: paginationData.is_first,
        isLast: paginationData.is_last,
        prev: paginationData.prev,
        next: paginationData.next,
        first: paginationData.first,
        last: paginationData.last,
        currentPage: paginationData.offset / paginationData.limit + 1,
        numberOfPages: Math.ceil(paginationData.count / paginationData.limit)
      };
    }),
    arrangedContent: Ember.computed.reads('limited'),
    setPaginationData: function setPaginationData(queryResult) {
      this.set('paginationData', queryResult.get('meta.pagination'));
    }
  });
  LivePaginatedCollection.reopenClass({
    create: function create(properties) {
      var instance = this._super.apply(this, arguments);

      instance.setPaginationData(properties.content.get('queryResult'));
      this.defineSortByFunction(instance, properties.store, properties.modelName, properties.sort, properties.dependencies);
      return instance;
    },
    defineSortByFunction: function defineSortByFunction(instance, store, modelName) {
      var sort = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'id:desc';
      var dependencies = arguments.length > 4 ? arguments[4] : undefined;
      var sortByFunction, sortKey, order;

      if (typeof sort === 'function') {
        sortByFunction = sort;
      } else {
        var _sort$split = sort.split(':');

        var _sort$split2 = _slicedToArray(_sort$split, 2);

        sortKey = _sort$split2[0];
        order = _sort$split2[1];
        order = order || 'desc'; // TODO: we need to deal with paths, like for example
        // defaultBranch.lastBuild

        if (store.modelFor(modelName).typeForRelationship(sortKey, store)) {
          // it's a relationship, so sort by id by default
          sortKey = "".concat(sortKey, ".id");
        }

        sortByFunction = function sortByFunction(a, b) {
          var aValue = a.get(sortKey),
              bValue = b.get(sortKey);
          var result; // TODO: this should check types, not only if it's id or not

          if (sortKey.endsWith('id') || sortKey.endsWith('Id')) {
            result = parseInt(aValue) - parseInt(bValue);
          } else {
            if (aValue < bValue) {
              result = -1;
            } else if (aValue > bValue) {
              result = 1;
            } else {
              result = 0;
            }
          }

          if (order === 'desc') {
            result = -result;
          }

          return result;
        };
      }

      var sortDependencies = dependencies.slice(0); // clone

      if (sortKey && !sortDependencies.includes(sortKey)) {
        sortDependencies.push(sortKey);
      }

      sortDependencies = sortDependencies.map(function (dep) {
        return "content.@each.".concat(dep);
      });
      sortDependencies.push('content.[]');
      Ember.defineProperty(instance, 'sorted', Ember.computed.apply(void 0, _toConsumableArray(sortDependencies).concat([function () {
        return this.content.toArray().sort(sortByFunction);
      }])));
      Ember.defineProperty(instance, 'limited', (0, _computedLimit.default)('sorted', 'pagination.perPage'));
    }
  });

  LivePaginatedCollection.toString = function () {
    return 'LivePaginatedCollection';
  };

  var _default = LivePaginatedCollection;
  _exports.default = _default;
});