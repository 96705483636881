define("travis/templates/components/build-count", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hL0Jczig",
    "block": "{\"symbols\":[],\"statements\":[[5,\"insights-glance\",[],[[\"@isLoading\",\"@isEmpty\",\"@title\",\"@statistic\",\"@delta\",\"@deltaTitle\",\"@deltaText\",\"@labels\",\"@values\",\"@datasetTitle\",\"@centerline\"],[[23,0,[\"isLoading\"]],[23,0,[\"isEmpty\"]],\"Total Builds\",[23,0,[\"totalBuildText\"]],[23,0,[\"percentageChange\"]],[23,0,[\"percentChangeTitle\"]],[23,0,[\"percentageChangeText\"]],[23,0,[\"labels\"]],[23,0,[\"builds\"]],\"Builds\",[23,0,[\"avgBuilds\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/build-count.hbs"
    }
  });

  _exports.default = _default;
});