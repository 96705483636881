define("travis/components/loading-indicator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNameBindings: ['center:loading-container', 'single:loading-single', 'inline:inline-block', 'height:icon-height', 'white:white'],
    center: false,
    single: false
  });

  _exports.default = _default;
});