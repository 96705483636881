define("travis/initializers/configure-inflector", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // As suggested here: https://github.com/samselikoff/ember-cli-mirage/issues/265#issuecomment-142059544
  var initializer = {
    name: 'inflector',
    initialize: function initialize() {
      var inflector = _emberInflector.default.inflector;
      inflector.uncountable('permissions');
      inflector.irregular('cache', 'caches');
    }
  };
  var _default = initializer;
  _exports.default = _default;
});