define("travis/initializers/services", ["exports", "travis/utils/tailing"], function (_exports, _tailing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(app) {
    app.tailing = new _tailing.default('#tail', '#log');
  }

  var _default = {
    name: 'services',
    initialize: initialize
  };
  _exports.default = _default;
});