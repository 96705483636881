define("travis/controllers/settings", ["exports", "travis/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    externalLinks: Ember.inject.service(),
    features: Ember.inject.service(),
    store: Ember.inject.service(),
    envVars: Ember.computed('unsortedEnvVars', function () {
      var envVars = this.unsortedEnvVars;
      return envVars.sortBy('name');
    }),
    config: _environment.default,
    unsortedEnvVars: Ember.computed.filterBy('model.envVars', 'isNew', false),
    cronJobs: Ember.computed.reads('model.repository.cronJobs'),
    showAutoCancellationSwitches: Ember.computed('model.settings', function () {
      var settings = this.get('model.settings');
      return settings.hasOwnProperty('auto_cancel_pushes') || settings.hasOwnProperty('auto_cancel_pull_requests');
    }),
    showAllowConfigImportsSwitch: Ember.computed('model.settings', 'repo.private', function () {
      var settings = this.get('model.settings');
      var isPrivate = this.get('repo.private');
      return isPrivate && settings.hasOwnProperty('allow_config_imports');
    }),
    showBetaFeatures: Ember.computed.reads('showConfigValidationSwitches'),
    hasNoConfigValidation: Ember.computed.none('model.settings.config_validation'),
    showConfigValidationSwitches: Ember.computed.not('hasNoConfigValidation'),
    migratedRepositorySettingsLink: Ember.computed('repo.slug', function () {
      var slug = this.get('repo.slug');
      return this.externalLinks.migratedToComSettingsLink(slug);
    }),
    displaySettingsDisabledAfterMigrationModal: Ember.computed('features.{proVersion,enterpriseVersion}', 'repo.migrationStatus', function () {
      var pro = this.get('features.proVersion');
      var enterprise = this.get('features.enterpriseVersion');
      var migrationStatus = this.get('repo.migrationStatus');
      return !pro && !enterprise && ['migrating', 'migrated'].includes(migrationStatus);
    }),
    actions: {
      sshKeyAdded: function sshKeyAdded(sshKey) {
        return this.set('model.customSshKey', sshKey);
      },
      sshKeyDeleted: function sshKeyDeleted() {
        var id = this.get('repo.id');
        var currentSshKey = this.store.peekRecord('ssh_key', id);
        if (currentSshKey) this.store.unloadRecord(currentSshKey);
        return this.set('model.customSshKey', null);
      }
    }
  });

  _exports.default = _default;
});