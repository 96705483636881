define("travis/templates/components/header-broadcasts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ppriKTnF",
    "block": "{\"symbols\":[\"broadcast\"],\"statements\":[[4,\"unless\",[[23,0,[\"features\",\"enterpriseVersion\"]]],null,{\"statements\":[[4,\"if\",[[23,0,[\"auth\",\"signedIn\"]]],null,{\"statements\":[[0,\"    \"],[5,\"broadcast-tower\",[],[[\"@status\",\"@count\",\"@toggleBroadcasts\"],[[23,0,[\"broadcasts\",\"lastBroadcastStatus\"]],[23,0,[\"broadcasts\",\"length\"]],[28,\"action\",[[23,0,[]],\"toggleBroadcasts\"],null]]]],[0,\"\\n    \"],[7,\"ul\",true],[11,\"class\",[29,[\"broadcasts \",[28,\"if\",[[23,0,[\"showBroadcasts\"]],\"is-open\"],null]]]],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"broadcasts\",\"content\"]]],null,{\"statements\":[[0,\"        \"],[7,\"li\",true],[11,\"title\",[29,[\"Transmitted on \",[23,1,[\"created_at\"]]]]],[8],[0,\"\\n          \"],[7,\"p\",true],[8],[0,\"\\n            \"],[7,\"span\",true],[11,\"class\",[29,[\"broadcast-status \",[23,1,[\"category\"]]]]],[8],[9],[0,\"\\n            \"],[7,\"span\",true],[10,\"class\",\"message\"],[8],[0,\"\\n              \"],[1,[23,1,[\"message\"]],true],[0,\"\\n            \"],[9],[0,\"\\n            \"],[7,\"button\",true],[10,\"title\",\"hide this broadcast\"],[10,\"class\",\"broadcast-close no-button\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"markBroadcastAsSeen\",[23,1,[]]],null]],[10,\"type\",\"button\"],[8],[0,\"\\n              \"],[5,\"svg-image\",[],[[\"@name\",\"@class\"],[\"icon-failed\",\"icon\"]]],[0,\"\\n            \"],[9],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"        \"],[7,\"li\",true],[8],[0,\"\\n          \"],[7,\"p\",true],[8],[0,\"\\n            There are no broadcasts\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/header-broadcasts.hbs"
    }
  });

  _exports.default = _default;
});