define("travis/templates/components/repository-security-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rHGKrGYy",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"showLicenseBanner\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"enterprise-banner security\"],[8],[0,\"\\n    \"],[7,\"div\",true],[8],[0,\"\\n        \"],[7,\"p\",true],[8],[0,\"\\n            If you have \"],[5,\"external-link-to\",[[12,\"href\",\"https://docs.travis-ci.com/user/private-dependencies/\"]],[[],[]],{\"statements\":[[0,\"SSH keys\"]],\"parameters\":[]}],[0,\" defined for your repositories, please review their settings now.\\nThe new Share ssh keys with forks (\"],[1,[28,\"vcs-vocab\",[[23,0,[\"repo\",\"vcsType\"]],\"pr\"],[[\"plural\"],[true]]],false],[0,\") repository setting are present and set\\nto ON for \"],[5,\"link-to\",[],[[\"@route\"],[\"account.repositories\"]],{\"statements\":[[0,\"repositories\"]],\"parameters\":[]}],[0,\" already in Travis CI in order to not break existing build\\nsetups.\\n            \"],[7,\"button\",false],[12,\"class\",\"close-btn\"],[3,\"action\",[[23,0,[]],\"closeLicenseBanner\"]],[8],[0,\"\\n                \"],[5,\"svg-image\",[],[[\"@name\",\"@class\"],[\"flash-close\",\"close-icon\"]]],[0,\"\\n            \"],[9],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/repository-security-banner.hbs"
    }
  });

  _exports.default = _default;
});