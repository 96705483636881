define("travis/serializers/job", ["exports", "travis/serializers/job_v2_fallback"], function (_exports, _job_v2_fallback) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _job_v2_fallback.default.extend({
    attrs: {
      _finishedAt: {
        key: 'finished_at'
      },
      _startedAt: {
        key: 'started_at'
      },
      _config: {
        key: 'config'
      }
    }
  });

  _exports.default = _default;
});