define("travis/templates/components/repo-build-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2ybt44Wi",
    "block": "{\"symbols\":[\"loader\",\"build\",\"@repo\",\"@missingNotice\"],\"statements\":[[5,\"load-more\",[],[[\"@dynamicQuery\",\"@liveItems\"],[[23,0,[\"buildsLoader\"]],[23,0,[\"builds\"]]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,1,[\"isNoneFound\"]]],null,{\"statements\":[[0,\"    \"],[5,\"missing-notice\",[],[[\"@title\"],[[23,4,[]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"ul\",true],[10,\"class\",\"build-list\"],[8],[0,\"\\n\"],[4,\"each\",[[23,1,[\"items\"]]],null,{\"statements\":[[0,\"        \"],[5,\"builds-item\",[],[[\"@build\",\"@vcsType\"],[[23,2,[]],[23,3,[\"vcsType\"]]]]],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/repo-build-list.hbs"
    }
  });

  _exports.default = _default;
});