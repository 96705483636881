define("travis/templates/components/ui-kit/code", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/jvjN4lz",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[5,\"ui-kit/box\",[],[[\"@tag\",\"@color\",\"@display\",\"@padding\",\"@radius\"],[[23,0,[\"tag\"]],\"grey-lighter\",\"inline-block\",[28,\"hash\",null,[[\"x\"],[1]]],\"sm\"]],{\"statements\":[[0,\"\\n  \"],[5,\"ui-kit/text\",[],[[\"@family\",\"@size\"],[\"mono\",\"sm\"]],{\"statements\":[[14,1]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/ui-kit/code.hbs"
    }
  });

  _exports.default = _default;
});