define("travis/templates/components/subscription-status-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QMNE1DBC",
    "block": "{\"symbols\":[],\"statements\":[[5,\"svg-image\",[],[[\"@name\",\"@class\"],[\"icon-alert\",\"icon\"]]],[0,\"\\n\"],[7,\"span\",true],[8],[0,\"\\n  \"],[7,\"span\",true],[8],[0,\"\\n    \"],[1,[23,0,[\"message\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"if\",[[23,0,[\"showLink\"]]],null,{\"statements\":[[0,\"    \"],[7,\"a\",true],[11,\"href\",[23,0,[\"billingUrl\"]]],[10,\"title\",\"Go to Travis CI Billing\"],[10,\"class\",\"right\"],[8],[0,\"\\n      \"],[1,[23,0,[\"billingLinkText\"]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/subscription-status-banner.hbs"
    }
  });

  _exports.default = _default;
});