define("travis/templates/components/missing-notice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9eLMnIwB",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"missing-notice\"],[8],[0,\"\\n  \"],[7,\"figure\",true],[10,\"class\",\"page-graphic\"],[8],[0,\"\\n    \"],[5,\"svg-image\",[],[[\"@name\",\"@class\"],[[28,\"or\",[[23,0,[\"image\"]],\"icon-cones\"],null],\"snapshot-hide\"]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"if\",[[23,0,[\"title\"]]],null,{\"statements\":[[0,\"    \"],[7,\"h2\",true],[10,\"class\",\"page-title\"],[8],[0,\"\\n      \"],[1,[23,0,[\"title\"]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/missing-notice.hbs"
    }
  });

  _exports.default = _default;
});