define("travis/templates/layouts/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iVVeh4Es",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"header\",true],[10,\"id\",\"top\"],[10,\"class\",\"top\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"centered\"],[8],[0,\"\\n    \"],[5,\"top-bar\",[],[[],[]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"main\",true],[10,\"class\",\"main main--error\"],[10,\"role\",\"main\"],[8],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/layouts/error.hbs"
    }
  });

  _exports.default = _default;
});