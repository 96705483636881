define("travis/templates/components/active-repo-count", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3F9EMlqt",
    "block": "{\"symbols\":[],\"statements\":[[5,\"insights-glance\",[],[[\"@isLoading\",\"@isEmpty\",\"@title\",\"@statistic\",\"@labels\",\"@values\",\"@datasetTitle\",\"@centerline\"],[[23,0,[\"isAnythingLoading\"]],[23,0,[\"isEmpty\"]],\"Active Repositories\",[23,0,[\"activeTotal\"]],[23,0,[\"labels\"]],[23,0,[\"activeRepos\"]],\"Active Repositories\",[23,0,[\"avgReposRounded\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/active-repo-count.hbs"
    }
  });

  _exports.default = _default;
});