define("travis/components/repository-security-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    storage: Ember.inject.service(),
    bannerKey: 'travis.repository-security-banner',
    showLicenseBanner: Ember.computed(function () {
      return !this.storage.getItem(this.bannerKey);
    }),
    actions: {
      closeLicenseBanner: function closeLicenseBanner() {
        this.storage.setItem(this.bannerKey, 'true');
        this.set('showLicenseBanner', false);
      }
    }
  });

  _exports.default = _default;
});