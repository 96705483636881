define("travis/mixins/controller/account-repositories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    queryParams: ['page', 'apps-page', 'apps-org-page'],
    page: 1,
    'apps-page': 1,
    'apps-org-page': 1
  });

  _exports.default = _default;
});