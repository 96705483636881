define("travis/components/repo-show-tools", ["exports", "travis/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    auth: Ember.inject.service(),
    permissions: Ember.inject.service(),
    features: Ember.inject.service(),
    tagName: '',
    isOpen: false,
    currentUser: Ember.computed.alias('auth.currentUser'),
    close: function close() {
      this.set('isOpen', false);
    },
    toggle: function toggle() {
      this.set('isOpen', !this.isOpen);
    },
    displaySettingsLink: Ember.computed('permissions.all', 'repo', function () {
      var repo = this.repo;
      return this.permissions.hasPushPermission(repo);
    }),
    displayCachesLink: Ember.computed('permissions.all', 'repo', function () {
      var repo = this.repo;
      return this.permissions.hasPushPermission(repo) && _environment.default.endpoints.caches;
    }),
    displayStatusImages: Ember.computed('permissions.all', 'repo', function () {
      var repo = this.repo;
      return this.permissions.hasPermission(repo);
    }),
    displayTriggerBuildLink: Ember.computed('repo.migrationStatus', 'repo.permissions.create_request', 'features.{enterpriseVersion,proVersion}', function () {
      var migrationStatus = this.get('repo.migrationStatus');
      var canTriggerBuild = this.get('repo.permissions.create_request');
      var enterprise = this.get('features.enterpriseVersion');
      var pro = this.get('features.proVersion');

      if (enterprise || pro) {
        return canTriggerBuild;
      }

      return canTriggerBuild && migrationStatus !== 'migrated';
    }),
    actions: {
      triggerBuildModal: function triggerBuildModal() {
        this.onTriggerBuild();
      }
    }
  });

  _exports.default = _default;
});