define("travis/app", ["exports", "travis/resolver", "ember-load-initializers", "travis/config/environment"], function (_exports, _resolver, _emberLoadInitializers, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // This can be set per environment in config/environment.js
  var debuggingEnabled = _environment.default.featureFlags['debug-logging'];
  var App = Ember.Application.extend(Ember.Evented, {
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default,
    // Configure global logging based on debug feature flag
    LOG_TRANSITIONS: debuggingEnabled,
    LOG_TRANSITIONS_INTERNAL: debuggingEnabled,
    LOG_ACTIVE_GENERATION: debuggingEnabled,
    LOG_MODULE_RESOLVER: debuggingEnabled,
    LOG_VIEW_LOOKUPS: debuggingEnabled,
    ready: function ready() {
      this.on('user:signed_in', function (user) {
        return Travis.onUserUpdate(user);
      });
      this.on('user:refreshed', function (user) {
        return Travis.onUserUpdate(user);
      });
      this.on('user:synced', function (user) {
        return Travis.onUserUpdate(user);
      });
    },
    currentDate: function currentDate() {
      return new Date();
    },
    onUserUpdate: function onUserUpdate(user) {
      return this.subscribePusher(user);
    },
    subscribePusher: function subscribePusher(user) {
      if (!user || !user.channels) {
        return;
      }

      Travis.pusher.subscribeAll(user.channels);
    }
  });
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
  var _default = App;
  _exports.default = _default;
});