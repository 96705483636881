define("travis/templates/components/repo-not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0DEJwsDx",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"missing-notice\"],[8],[0,\"\\n  \"],[7,\"figure\",true],[10,\"class\",\"page-graphic\"],[8],[0,\"\\n    \"],[5,\"svg-image\",[],[[\"@name\",\"@class\"],[\"barricade\",\"barricade\"]]],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"h2\",true],[10,\"class\",\"page-title\"],[8],[0,\"\\n    We couldn't display the repository\"],[7,\"br\",true],[8],[9],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"h2--red\"],[8],[0,\"\\n      \"],[1,[23,0,[\"slug\"]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"if\",[[23,0,[\"features\",\"proVersion\"]]],null,{\"statements\":[[0,\"    \"],[7,\"p\",true],[10,\"class\",\"page-notice\"],[8],[0,\"\\n      This repository may not exist or you may need to\\n\"],[4,\"if\",[[23,0,[\"auth\",\"signedIn\"]]],null,{\"statements\":[[0,\"        use another account to have access to it \"],[7,\"br\",true],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[7,\"button\",false],[12,\"class\",[29,[\"auth-button--white pointer m-l-s \",[28,\"if\",[[23,0,[\"auth\",\"signedIn\"]],\"mt-5\"],null]]]],[3,\"on\",[\"click\",[28,\"action\",[[23,0,[]],\"signIn\"],null]]],[8],[0,\"\\n        Sign in \"],[1,[28,\"if\",[[23,0,[\"auth\",\"signedIn\"]],\"with another account\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/repo-not-found.hbs"
    }
  });

  _exports.default = _default;
});