define("travis/controllers/dashboard/builds", ["exports", "visibilityjs", "travis/config/environment"], function (_exports, _visibilityjs, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    updateTimesService: Ember.inject.service('updateTimes'),
    init: function init() {
      this._super.apply(this, arguments);

      if (!Ember.testing) {
        _visibilityjs.default.every(_environment.default.intervals.updateTimes, this.updateTimes.bind(this));
      }
    },
    updateTimes: function updateTimes() {
      var updateTimesService = this.updateTimesService;
      updateTimesService.push(this.model);
    }
  });

  _exports.default = _default;
});