define("travis/templates/components/job-log", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gB7rnmm3",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"error\"]]],null,{\"statements\":[[0,\"  \"],[7,\"p\",true],[10,\"class\",\"notice-banner--red\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"or\",[[28,\"eq\",[[23,0,[\"errorType\"]],\"log_expired\"],null],[28,\"eq\",[[23,0,[\"errorType\"]],\"log_access_denied\"],null]],null]],null,{\"statements\":[[0,\"    \"],[1,[23,0,[\"errorMessage\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    There was an error while trying to fetch the log.\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,0,[\"log\",\"fetchTask\",\"isRunning\"]]],null,{\"statements\":[[0,\"  \"],[5,\"loading-indicator\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[5,\"log-content\",[],[[\"@log\",\"@job\"],[[23,0,[\"log\"]],[23,0,[\"job\"]]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/job-log.hbs"
    }
  });

  _exports.default = _default;
});