define("travis/components/forms/form-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['travis-form__field-checkbox'],
    classNameBindings: ['disabled:travis-form__field-checkbox--disabled', 'checked:travis-form__field-checkbox--checked:travis-form__field-checkbox--unchecked'],
    disabled: false,
    checked: false,
    onChange: function onChange() {},
    onFocus: function onFocus() {},
    onBlur: function onBlur() {},
    onInit: function onInit() {},
    click: function click() {
      if (!this.disabled) this.send('toggle');
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.onInit(this.elementId);
    },
    actions: {
      toggle: function toggle() {
        if (!this.disabled) this.onChange(!this.checked);
      },
      focus: function focus() {
        if (!this.disabled) this.onFocus();
      },
      blur: function blur() {
        if (!this.disabled) this.onBlur();
      }
    }
  });

  _exports.default = _default;
});