define("travis/models/credit-card-info", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    api: Ember.inject.service(),
    lastDigits: (0, _model.attr)(),
    subscription: (0, _model.belongsTo)('subscription'),
    token: (0, _model.attr)('string'),
    updateToken: function updateToken(subscriptionId, _ref) {
      var id = _ref.id,
          card = _ref.card;
      this.setProperties({
        token: id,
        lastDigits: card.last4
      });
      return this.api.patch("/subscription/".concat(subscriptionId, "/creditcard"), {
        data: {
          token: id
        }
      });
    }
  });

  _exports.default = _default;
});