define("travis/components/ui-kit/link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    // Public interface
    variant: 'link-underlined',
    href: null,
    rel: 'noopener noreferrer',
    target: '_blank',
    route: null
  });

  _exports.default = _default;
});