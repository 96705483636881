define("travis/mixins/duration-calculations", ["exports", "travis/utils/duration-from"], function (_exports, _durationFrom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    duration: Ember.computed('_duration', 'finishedAt', 'startedAt', 'notStarted', function () {
      var duration = this._duration;
      var finishedAt = this.finishedAt;
      var startedAt = this.startedAt;
      var notStarted = this.notStarted;

      if (notStarted) {
        return null;
      } else if (duration) {
        return duration;
      } else {
        return (0, _durationFrom.default)(startedAt, finishedAt);
      }
    }),
    updateTimes: function updateTimes() {
      this.notifyPropertyChange('duration');
      return this.notifyPropertyChange('finishedAt');
    },
    elapsedTime: Ember.computed('startedAt', 'finishedAt', function () {
      var startedAt = this.startedAt;
      var finishedAt = this.finishedAt;
      return (0, _durationFrom.default)(startedAt, finishedAt);
    })
  });

  _exports.default = _default;
});