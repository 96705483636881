define("travis/utils/ui-kit/variant", ["exports", "travis/utils/ui-kit/responsive"], function (_exports, _responsive) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.variantProp = variantProp;

  function variantProp() {
    var variantDict = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var defaultVal = arguments.length > 1 ? arguments[1] : undefined;
    return Ember.computed('variant', {
      get: function get(key) {
        var variant = this.variant;
        var variantDetails = variantDict[variant] || {};
        var variantPropVal = variantDetails[key];
        var value = variantPropVal || defaultVal;
        return (0, _responsive.getResponsiveProp)(value);
      },
      set: function set(key, value) {
        return (0, _responsive.getResponsiveProp)(value);
      }
    });
  }
});