define("travis/models/installation", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    githubId: (0, _model.attr)(),
    owner: (0, _model.belongsTo)('owner', {
      polymorphic: true
    })
  });

  _exports.default = _default;
});