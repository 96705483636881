define("travis/controllers/account/repositories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['legacy-page', 'apps-page'],
    'legacy-page': 1,
    'apps-page': 1
  });

  _exports.default = _default;
});