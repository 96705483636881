define("travis/templates/repo/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XrMycud3",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"eq\",[[23,0,[\"model\",\"status\"]],403],null]],null,{\"statements\":[[0,\"  Your permissions are insufficient to access this content.\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  There was an error while loading data.\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/repo/error.hbs"
    }
  });

  _exports.default = _default;
});