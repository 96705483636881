define("travis/utils/wrap-with-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  // Wraps a given object with an Array if it's not an array
  // already, otherwise just returns the argument unchanged.
  // In case the argument is not defined or null just return
  // an empty array.
  function _default(object) {
    if (Ember.isArray(object)) {
      return object;
    } else if (object) {
      return [object];
    } else {
      return [];
    }
  }
});