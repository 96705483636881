define("travis/controllers/repo/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    auth: Ember.inject.service(),
    accounts: Ember.inject.service(),
    repo: null,
    repoOwner: Ember.computed('accounts.all.@each.login', 'repo.owner.login', 'auth.signedIn', function () {
      return this.auth.signedIn && this.accounts.all.findBy('login', this.repo.owner.login);
    }),
    permissions: Ember.computed.reads('repo.permissions'),
    isMigrationEnabled: Ember.computed.reads('repoOwner.allowMigration'),
    isMigrationNotEnabled: Ember.computed.not('isMigrationEnabled'),
    isMigrationBetaRequested: Ember.computed.reads('repoOwner.isMigrationBetaRequested'),
    isMigrationBetaNotRequested: Ember.computed.not('isMigrationBetaRequested'),
    hasPermissions: Ember.computed.reads('permissions.admin'),
    isInsufficientPermissions: Ember.computed.not('hasPermissions'),
    isMigrationAllowed: Ember.computed.reads('repo.isMigratable'),
    isMigrationNotAllowed: Ember.computed.not('isMigrationAllowed'),
    showSignInButton: Ember.computed.not('auth.signedIn'),
    showMigrateButton: Ember.computed.and('auth.signedIn', 'isMigrationEnabled'),
    showBetaButton: Ember.computed.and('auth.signedIn', 'isMigrationNotEnabled', 'isMigrationBetaNotRequested'),
    isButtonDisabled: Ember.computed.or('repo.isMigrationInProgress', 'isMigrationNotAllowed', 'isInsufficientPermissions'),
    headerText: Ember.computed('isMigrationEnabled', 'isMigrationBetaRequested', function () {
      var isMigrationEnabled = this.isMigrationEnabled,
          isMigrationBetaRequested = this.isMigrationBetaRequested;
      if (isMigrationEnabled) return 'You can now start building this repository, right here at travis-ci.com!';
      if (isMigrationBetaRequested) return 'You can start building this repository at travis-ci.com once your Beta request is accepted';else return 'You can now have all your public and private repositories together at travis-ci.com';
    }),
    helperText: Ember.computed('isMigrationBetaRequested', 'hasPermissions', 'isMigrationEnabled', 'auth.signedIn', function () {
      var isMigrationEnabled = this.isMigrationEnabled,
          isMigrationBetaRequested = this.isMigrationBetaRequested,
          hasPermissions = this.hasPermissions,
          auth = this.auth;
      if (isMigrationBetaRequested) return 'This repository cannot be migrated at this time. Please wait until your account is accepted into the beta. Thank you.';else if (isMigrationEnabled && !hasPermissions) return 'Only repository owners can migrate this repository from travis-ci.org';else if (!isMigrationEnabled && !hasPermissions && auth.signedIn) return 'Only repository owners can sign up for beta';
    }),
    actions: {
      migrate: function migrate() {
        this.repo.set('migrationStatus', 'queued');
        this.repo.startMigration();
      }
    }
  });

  _exports.default = _default;
});