define("travis/templates/components/billing/selected-plan", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ezdP1g2V",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"selected-plan\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"selected-plan__details\"],[8],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"selected-plan__details--plan\"],[8],[0,\"\\n      summary\\n    \"],[9],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"selected-plan__details--name\"],[8],[0,\"\\n      \"],[1,[23,0,[\"selectedPlan\",\"name\"]],false],[0,\" plan\\n    \"],[9],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"selected-plan__details--jobs\"],[8],[0,\"\\n      \"],[1,[28,\"pluralize\",[[23,0,[\"selectedPlan\",\"builds\"]],\"concurrent job\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"button\",true],[11,\"onClick\",[28,\"action\",[[23,0,[]],[23,0,[\"goToFirstStep\"]]],null]],[10,\"class\",\"mt-3 button--white button--hover\"],[8],[0,\"\\n      Change plan\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"selected-plan__price\"],[8],[0,\"\\n      \"],[7,\"p\",true],[10,\"class\",\"selected-plan__price--total\"],[8],[0,\"\\n        Total:\\n      \"],[9],[0,\"\\n      \"],[7,\"p\",true],[10,\"class\",\"selected-plan__price--large\"],[8],[0,\"\\n        $\"],[1,[23,0,[\"price\"]],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"selected-plan__period\"],[8],[0,\"\\n        \"],[1,[28,\"if\",[[23,0,[\"selectedPlan\",\"annual\"]],\"/year\",\"/month\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/billing/selected-plan.hbs"
    }
  });

  _exports.default = _default;
});