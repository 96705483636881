define("travis/components/billing-resubscribe-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isActiveGithubSubscription: Ember.computed.reads('githubSubscription.isSubscribed')
  });

  _exports.default = _default;
});