define("travis/components/paper-block", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.MAX_ELEVATION = _exports.MIN_ELEVATION = void 0;
  var MIN_ELEVATION = 0;
  _exports.MIN_ELEVATION = MIN_ELEVATION;
  var MAX_ELEVATION = 4;
  _exports.MAX_ELEVATION = MAX_ELEVATION;

  var _default = Ember.Component.extend({
    classNames: ['paper-block'],
    classNameBindings: ['elevationClass', 'padding::no-padding'],
    title: '',
    elevation: 1,
    padding: true,
    normalizedElevation: Ember.computed('elevation', function () {
      var elevation = this.elevation;
      elevation = elevation < MIN_ELEVATION ? MIN_ELEVATION : elevation;
      elevation = elevation > MAX_ELEVATION ? MAX_ELEVATION : elevation;
      return elevation;
    }),
    elevationClass: Ember.computed('normalizedElevation', function () {
      var normalizedElevation = this.normalizedElevation;
      return "elevation-x".concat(normalizedElevation);
    })
  });

  _exports.default = _default;
});