define("travis/templates/components/insights-tabs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NIelezKl",
    "block": "{\"symbols\":[\"tab\"],\"statements\":[[4,\"each\",[[23,0,[\"tabs\"]]],null,{\"statements\":[[0,\"  \"],[7,\"li\",true],[10,\"class\",\"insights-tab\"],[8],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@query\"],[[28,\"hash\",null,[[\"timeInterval\"],[[23,1,[\"slug\"]]]]]]],{\"statements\":[[0,\"\\n      \"],[1,[23,1,[\"title\"]],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/insights-tabs.hbs"
    }
  });

  _exports.default = _default;
});