define("travis/routes/provider", ["exports", "travis/utils/vcs"], function (_exports, _vcs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel(transition) {
      var _transition$to = transition.to,
          params = _transition$to.params,
          queryParams = _transition$to.queryParams;
      var provider = params.provider;

      if ((0, _vcs.vcsConfigByUrlPrefix)(provider)) {
        this.transitionTo('index'); // redirect to home page if accessing provider route directly
      } else {
        // Legacy owner page
        var owner = provider;
        this.transitionTo('owner', _vcs.defaultVcsConfig.urlPrefix, owner, {
          queryParams: queryParams
        });
      }
    }
  });

  _exports.default = _default;
});