define("travis/components/forms/form-select", ["exports", "ember-power-select/components/power-select", "travis/mixins/components/form-select"], function (_exports, _powerSelect, _formSelect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _powerSelect.default.extend(_formSelect.default, {});

  _exports.default = _default;
});