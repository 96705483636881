define("travis/components/travis-switch", ["exports", "travis/utils/abstract-method"], function (_exports, _abstractMethod) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'button',
    classNames: ['travis-switch', 'switch', 'inline-block'],
    classNameBindings: ['active:active', 'disabled:disabled'],
    attributeBindings: ['aria-checked', 'role', 'type'],
    type: 'button',
    role: 'switch',
    description: '',
    active: false,
    disabled: false,
    'aria-checked': Ember.computed('active', function () {
      return this.active ? 'true' : 'false';
    }),
    onToggle: (0, _abstractMethod.default)('onToggle'),
    click: function click() {
      if (!this.disabled) {
        this.onToggle(!this.active);
      }
    }
  });

  _exports.default = _default;
});