define("travis/templates/components/lastbuild-tile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Vec+Dokx",
    "block": "{\"symbols\":[],\"statements\":[[5,\"link-to\",[],[[\"@route\",\"@models\"],[\"build\",[28,\"array\",[[23,0,[\"repo\",\"provider\"]],[23,0,[\"repo\",\"ownerName\"]],[23,0,[\"repo\",\"vcsName\"]],[24,[\"build\",\"id\"]]],null]]],{\"statements\":[[0,\"\\n  \"],[5,\"status-icon\",[],[[\"@status\"],[[23,0,[\"build\",\"state\"]]]]],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"label-align\"],[8],[0,\"\\n    #\"],[1,[23,0,[\"build\",\"number\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/lastbuild-tile.hbs"
    }
  });

  _exports.default = _default;
});