define("travis/utils/ui-kit/prefix", ["exports", "travis/utils/ui-kit/responsive"], function (_exports, _responsive) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = generatePrefix;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function generatePrefix(key) {
    var propPrefix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';

    var _ref = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {},
        _ref$dictionary = _ref.dictionary,
        dictionary = _ref$dictionary === void 0 ? {} : _ref$dictionary,
        _ref$validator = _ref.validator,
        validator = _ref$validator === void 0 ? Ember.isPresent : _ref$validator,
        _ref$separator = _ref.separator,
        separator = _ref$separator === void 0 ? '-' : _ref$separator,
        _ref$defaultValue = _ref.defaultValue,
        defaultValue = _ref$defaultValue === void 0 ? null : _ref$defaultValue,
        _ref$negatable = _ref.negatable,
        negatable = _ref$negatable === void 0 ? false : _ref$negatable;

    return Ember.computed(key, function () {
      // Helps handle complex values, like padding.top
      var _key$split = key.split('.'),
          _key$split2 = _slicedToArray(_key$split, 2),
          primeKey = _key$split2[0],
          subKey = _key$split2[1];

      var propVal = this.get(primeKey);
      var screenVals = (0, _responsive.getResponsiveProp)(propVal);

      var classes = _responsive.screenKeys.map(function (screen) {
        var screenVal = screenVals[screen];
        var simpleVal = Ember.isPresent(screenVal) && Ember.isPresent(subKey) ? screenVal[subKey] : screenVal;
        var screenInfo = _responsive.screens[screen];
        var screenPrefix = screen === 'base' ? '' : "".concat(screenInfo.prefix, ":");
        var value = dictionary[simpleVal] || simpleVal;
        var isNegative = negatable && typeof value === 'number' && value < 0;
        var negator = isNegative ? '-' : '';
        var sep = propPrefix.length > 0 ? separator : ''; // Removes extra dash from negative vals, for negatable props like margin etc.

        var displayVal = isNegative ? Math.abs(value) : value;
        return validator(value) ? "".concat(screenPrefix).concat(negator).concat(propPrefix).concat(sep).concat(displayVal) : defaultValue;
      });

      return classes.compact().join(' ');
    });
  }
});