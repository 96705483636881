define("travis/templates/components/button-activate-all-github", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8CxApBpv",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"isVisible\"]]],null,{\"statements\":[[4,\"if\",[[23,0,[\"useUiKit\"]]],null,{\"statements\":[[0,\"    \"],[5,\"ui-kit/button\",[[3,\"on\",[\"click\",[28,\"action\",[[23,0,[]],\"activateAll\"],null]]]],[[\"@color\",\"@invert\"],[\"grey\",true]],{\"statements\":[[0,\"\\n      \"],[5,\"svg-image\",[],[[\"@name\",\"@class\"],[\"icon-repooctocat\",\"fill-current inline-block align-text-bottom w-5 h-5 ml-1 mr-2\"]]],[0,\"\\n      Activate all repositories using GitHub Apps\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"button\",false],[12,\"class\",\"button--blue\"],[3,\"on\",[\"click\",[28,\"action\",[[23,0,[]],\"activateAll\"],null]]],[8],[0,\"\\n      \"],[5,\"svg-image\",[],[[\"@name\",\"@class\"],[\"icon-repooctocat\",\"fill-current align-text-bottom w-5 h-5 ml-1 mr-2\"]]],[0,\"\\n      Activate all repositories using GitHub Apps\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/button-activate-all-github.hbs"
    }
  });

  _exports.default = _default;
});