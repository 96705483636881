define("travis/components/scroll-here", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    scroller: Ember.inject.service(),
    immediate: false,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var duration = this.immediate ? 1 : 1000;
      this.scroller.scrollToElement(this.element, {
        duration: duration
      });
    }
  });

  _exports.default = _default;
});