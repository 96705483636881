define("travis/controllers/flash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    flashes: Ember.inject.service(),
    loadFlashes: function loadFlashes() {
      var _this$flashes;

      return (_this$flashes = this.flashes).loadFlashes.apply(_this$flashes, arguments);
    }
  });

  _exports.default = _default;
});