define("travis/controllers/job", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    auth: Ember.inject.service(),
    repoController: Ember.inject.controller('repo'),
    repo: Ember.computed.alias('repoController.repo'),
    currentUser: Ember.computed.alias('auth.currentUser'),
    tab: Ember.computed.alias('repoController.tab'),
    jobStateDidChange: Ember.observer('job.state', function () {
      return this.send('faviconStateDidChange', this.get('job.state'));
    })
  });

  _exports.default = _default;
});