define("travis/routes/requests", ["exports", "travis/routes/basic"], function (_exports, _basic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basic.default.extend({
    setupController: function setupController() {
      this._super.apply(this, arguments);

      return this.controllerFor('repo').activate('requests');
    },
    model: function model() {
      return this.store.query('request', {
        repository_id: this.modelFor('repo').get('id')
      });
    }
  });

  _exports.default = _default;
});