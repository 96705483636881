define("travis/mixins/duration-attributes", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    _startedAt: (0, _model.attr)(),
    _finishedAt: (0, _model.attr)(),
    startedAt: Ember.computed('_startedAt', 'notStarted', function () {
      var startedAt = this._startedAt;
      var notStarted = this.notStarted;

      if (!notStarted) {
        return startedAt;
      }
    }),
    finishedAt: Ember.computed('_finishedAt', 'notStarted', function () {
      var finishedAt = this._finishedAt;
      var notStarted = this.notStarted;

      if (!notStarted) {
        return finishedAt;
      }
    })
  });

  _exports.default = _default;
});