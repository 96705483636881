define("travis/templates/components/oss-usage-digit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZKeJAJMz",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"flip-num \",[23,0,[\"digitClass\"]]]]],[8],[0,\"\\n  \"],[7,\"img\",true],[11,\"src\",[29,[\"../images/landing-page/oss-num-\",[23,0,[\"digit\"]],\".svg\"]]],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/oss-usage-digit.hbs"
    }
  });

  _exports.default = _default;
});