define("travis/templates/build/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3suy+lSO",
    "block": "{\"symbols\":[\"build\",\"stage\"],\"statements\":[[4,\"let\",[[23,0,[\"build\"]]],null,{\"statements\":[[4,\"if\",[[23,1,[\"isMatrix\"]]],null,{\"statements\":[[4,\"if\",[[23,1,[\"stages\"]]],null,{\"statements\":[[4,\"each\",[[23,1,[\"sortedStages\"]]],null,{\"statements\":[[0,\"        \"],[5,\"jobs-list\",[],[[\"@stage\",\"@build\",\"@repo\",\"@stages\"],[[23,2,[]],[23,1,[]],[23,1,[\"repo\"]],[23,0,[\"sortedBuildStages\"]]]]],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[5,\"jobs-list\",[],[[\"@jobs\",\"@build\",\"@repo\",\"@required\"],[[23,1,[\"requiredJobs\"]],[23,1,[]],[23,1,[\"repo\"]],true]]],[0,\"\\n      \"],[5,\"jobs-list\",[],[[\"@jobs\",\"@build\",\"@repo\"],[[23,1,[\"allowedFailureJobs\"]],[23,1,[]],[23,1,[\"repo\"]]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/build/index.hbs"
    }
  });

  _exports.default = _default;
});