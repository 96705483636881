define("travis/templates/components/show-more-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FV9gHvV2",
    "block": "{\"symbols\":[],\"statements\":[[5,\"svg-image\",[],[[\"@name\",\"@class\"],[\"icon-seemore\",\"icon white\"]]],[0,\"\\n\"],[1,[23,0,[\"label\"]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/show-more-button.hbs"
    }
  });

  _exports.default = _default;
});