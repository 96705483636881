define("travis/templates/components/flash-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vY5e+Pcb",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\",true],[10,\"class\",\"flash-message\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"flash\",\"icon\"]]],null,{\"statements\":[[0,\"    \"],[5,\"svg-image\",[],[[\"@name\"],[[23,0,[\"flash\",\"icon\"]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,0,[\"flash\",\"preamble\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"preamble\"],[8],[0,\"\\n      \"],[1,[23,0,[\"flash\",\"preamble\"]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"span\",true],[10,\"class\",\"message\"],[8],[0,\"\\n    \"],[1,[23,0,[\"flash\",\"message\"]],true],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[23,0,[\"flash\",\"closeButton\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\",true],[10,\"class\",\"no-button close\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"close\"],null]],[10,\"type\",\"button\"],[8],[0,\"\\n    \"],[5,\"svg-image\",[],[[\"@name\",\"@class\"],[\"flash-close\",\"icon\"]]],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/flash-item.hbs"
    }
  });

  _exports.default = _default;
});