define("travis/templates/dashboard/builds", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cire2vHd",
    "block": "{\"symbols\":[\"build\"],\"statements\":[[7,\"ul\",true],[10,\"class\",\"repo-list\"],[8],[0,\"\\n\"],[4,\"each\",[[28,\"sort-by\",[\"startedAt:desc\",[23,0,[\"model\"]]],null]],null,{\"statements\":[[0,\"    \"],[5,\"my-build\",[],[[\"@build\"],[[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/dashboard/builds.hbs"
    }
  });

  _exports.default = _default;
});