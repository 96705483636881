define("travis/helpers/combine-validators", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.combineValidators = combineValidators;
  _exports.default = void 0;

  function combineValidators(validators) {
    return validators.reduce(function (previousValidator, currentValidator) {
      return function (value) {
        var currentValidatorResult = currentValidator(value);
        return currentValidatorResult === true ? previousValidator(value) : currentValidatorResult;
      };
    }, function () {
      return true;
    });
  }

  var _default = Ember.Helper.helper(combineValidators);

  _exports.default = _default;
});