define("travis/templates/components/modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IFx5Y6c5",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[5,\"animated-if\",[],[[\"@predicate\",\"@use\",\"@duration\"],[[23,0,[\"isVisible\"]],[23,0,[\"transition\"]],[23,0,[\"animationDuration\"]]]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",\"travis-modal-overlay fixed inset-0 z-50 flex items-center justify-center overflow-hidden bg-grey-800-fog\"],[3,\"on\",[\"click\",[28,\"action\",[[23,0,[]],[23,0,[\"onClickOverlay\"]]],null]]],[8],[0,\"\\n    \"],[7,\"div\",false],[12,\"class\",\"travis-modal bg-white p-8 relative rounded-sm\"],[13,1],[3,\"on\",[\"click\",[23,0,[\"onClickModal\"]]]],[8],[14,2],[4,\"if\",[[23,0,[\"closeButton\"]]],null,{\"statements\":[[0,\"        \"],[7,\"button\",false],[12,\"title\",\"Close modal\"],[12,\"class\",\"absolute cursor-pointer top-0 right-0 p-0 mt-8 mr-8\"],[12,\"type\",\"button\"],[3,\"on\",[\"click\",[23,0,[\"onClose\"]]]],[8],[0,\"\\n          \"],[5,\"svg-image\",[],[[\"@name\",\"@class\"],[\"icon-failed\",\"w-5 h-5\"]]],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/modal.hbs"
    }
  });

  _exports.default = _default;
});