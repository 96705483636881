define("travis/templates/components/build-status-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A5LaoauH",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h3\",true],[10,\"class\",\"insights-odyssey__title\"],[8],[0,\"\\n  Build Statuses\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"insights-odyssey__chart\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"isLoading\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"insights-odyssey__loading-indicator\"],[8],[0,\"\\n      \"],[5,\"loading-indicator\",[],[[],[]]],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,0,[\"hasNoBuilds\"]]],null,{\"statements\":[[0,\"    No builds \"],[1,[23,0,[\"currentIntervalLabel\"]],false],[0,\".\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[5,\"c3-chart\",[[12,\"class\",\"chart-component\"]],[[\"@data\",\"@axis\",\"@grid\",\"@tooltip\"],[[23,0,[\"data\"]],[23,0,[\"axis\"]],[23,0,[\"grid\"]],[23,0,[\"tooltip\"]]]]],[0,\"\\n  \"]],\"parameters\":[]}]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/build-status-chart.hbs"
    }
  });

  _exports.default = _default;
});