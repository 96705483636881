define("travis/templates/components/loading-overlay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VLLCS6Xd",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"loading-overlay__backdrop\"],[8],[0,\"\\n  \"],[5,\"loading-indicator\",[[12,\"class\",\"loading-overlay__indicator\"]],[[],[]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/loading-overlay.hbs"
    }
  });

  _exports.default = _default;
});