define("travis/components/travis-form", ["exports", "travis/utils/form-validators"], function (_exports, validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    validators: validators,
    erroredFields: Ember.computed.filterBy('fields', 'isError', true),
    validFields: Ember.computed.filterBy('fields', 'isValid', true),
    hasErrors: Ember.computed.gt('erroredFields.length', 0),
    isValid: Ember.computed.not('hasErrors'),
    onSubmit: function onSubmit() {},
    registerField: function registerField(field) {
      this.fields.addObject(field);
    },
    unregisterField: function unregisterField(field) {
      this.fields.removeObject(field);
    },
    validate: function validate() {
      this.fields.forEach(function (field) {
        var value = field.value,
            multipleInputsValue = field.multipleInputsValue,
            isMultipleInputsField = field.isMultipleInputsField;

        if (isMultipleInputsField) {
          field.validateMultipleInputs(multipleInputsValue || [''], true);
        } else {
          field.validate(value, true);
        }
      });
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.fields = [];
    },
    actions: {
      submit: function submit() {
        this.validate();

        if (this.isValid) {
          this.onSubmit();
        }

        return false;
      }
    }
  });

  _exports.default = _default;
});