define("travis/utils/eventually", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(anObjectOrAPromise, callback) {
    if (anObjectOrAPromise.then) {
      anObjectOrAPromise.then(function (result) {
        return callback(result);
      });
    } else {
      callback(anObjectOrAPromise);
    }
  }
});