define("travis/utils/log-folder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = function () {
    function LogFolder(element) {
      var _this = this;

      this.element = element;

      if (this.element) {
        var handleClick = function handleClick(event) {
          var folder = _this.getFolderFromLine(event.target);

          _this.toggle(folder);

          event.preventDefault();
        };

        this.element.addEventListener('click', handleClick);
      }
    }

    LogFolder.prototype.fold = function (line) {
      var folder = this.getFolderFromLine(line);

      if (folder && folder.classList && folder.classList.contains('open')) {
        return this.toggle(folder);
      }
    };

    LogFolder.prototype.unfold = function (line) {
      var folder = this.getFolderFromLine(line);

      if (folder && folder.classList && !folder.classList.contains('open')) {
        return this.toggle(folder);
      }
    };

    LogFolder.prototype.toggle = function (folder) {
      return folder && folder.classList && folder.classList.toggle('open');
    };

    LogFolder.prototype.getFolderFromLine = function (line) {
      var firstFoldLine,
          currentElem = line,
          parentElem = line.parentNode;

      while (parentElem) {
        if (!parentElem.classList || parentElem.classList.contains('log-body-content')) {
          break;
        }

        if (parentElem.classList.contains('fold')) {
          var _parentElem = parentElem,
              children = _parentElem.children;

          if (children.length >= 2 && children[1] === currentElem) {
            firstFoldLine = parentElem;
          }

          break;
        }

        currentElem = currentElem.parentNode;
        parentElem = parentElem.parentNode;
      }

      return firstFoldLine;
    };

    return LogFolder;
  }();

  _exports.default = _default;
});