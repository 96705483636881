define("travis/models/lead", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.LEAD_UTM_FIELDS = void 0;
  var LEAD_UTM_FIELDS = {
    SOURCE: 'utm_source',
    CAMPAIGN: 'utm_campaign',
    MEDIUM: 'utm_medium',
    TERM: 'utm_term',
    CONTENT: 'utm_content'
  };
  _exports.LEAD_UTM_FIELDS = LEAD_UTM_FIELDS;

  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    email: (0, _model.attr)('string'),
    team_size: (0, _model.attr)('number'),
    phone: (0, _model.attr)('string'),
    message: (0, _model.attr)('string'),
    referral_source: (0, _model.attr)('string'),
    utm_fields: (0, _model.attr)()
  });

  _exports.default = _default;
});