define("travis/mixins/polling", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    polling: Ember.inject.service(),
    init: function init() {
      this.set('currentPollModels', {});
      return this._super.apply(this, arguments);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      return this.startPolling();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      return this.stopPolling();
    },
    pollModelDidChange: function pollModelDidChange(sender, key) {
      return this.pollModel(key);
    },
    pollModel: function pollModel(property) {
      var _this = this;

      var model = this.get(property),
          currentPollModels = this.currentPollModels;

      if (currentPollModels[property]) {
        this.polling.stopPolling(currentPollModels[property]);
      }

      currentPollModels[property] = model;

      var addToPolling = function addToPolling() {
        return _this.polling.startPolling(model);
      };

      if (model) {
        if (model.then) {
          return model.then(function (resolved) {
            return addToPolling(resolved);
          });
        } else {
          return addToPolling(model);
        }
      }
    },
    stopPollingModel: function stopPollingModel(property) {
      var model = this.get(property);

      if (model) {
        return this.polling.stopPolling(model);
      }
    },
    startPolling: function startPolling() {
      var _this2 = this;

      var pollModels;
      pollModels = this.pollModels;

      if (pollModels) {
        if (!Ember.isArray(pollModels)) {
          pollModels = [pollModels];
        }

        pollModels.forEach(function (property) {
          _this2.pollModel(property);

          _this2.addObserver(property, _this2, 'pollModelDidChange');
        });
      }

      if (this.pollHook) {
        return this.polling.startPollingHook(this);
      }
    },
    stopPolling: function stopPolling() {
      var _this3 = this;

      var pollModels = this.pollModels;

      if (pollModels) {
        if (!Ember.isArray(pollModels)) {
          pollModels = [pollModels];
        }

        pollModels.forEach(function (property) {
          _this3.stopPollingModel(property);

          _this3.removeObserver(property, _this3, 'pollModelDidChange');
        });
      }

      return this.polling.stopPollingHook(this);
    }
  });

  _exports.default = _default;
});