define("travis/components/oss-usage-numbers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    individualDigits: Ember.computed('numbers', function () {
      return this.numbers.toString().split('');
    })
  });

  _exports.default = _default;
});