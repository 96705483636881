define("travis/controllers/index", ["exports", "visibilityjs", "travis/config/environment"], function (_exports, _visibilityjs, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    auth: Ember.inject.service(),
    tabStates: Ember.inject.service(),
    updateTimesService: Ember.inject.service('updateTimes'),
    statusImages: Ember.inject.service(),
    repositories: Ember.inject.service(),
    features: Ember.inject.service(),
    config: _environment.default,
    init: function init() {
      this._super.apply(this, arguments);

      if (!Ember.testing) {
        return _visibilityjs.default.every(_environment.default.intervals.updateTimes, this.updateTimes.bind(this));
      }
    },
    updateTimes: function updateTimes() {
      this.updateTimesService.push(this.get('build.stages'));
      this.updateTimesService.push(this.get('build.jobs'));
    },
    landingPage: Ember.computed('features.proVersion', function () {
      var pro = this.get('features.proVersion');
      var version = pro ? 'pro' : 'default';
      return "landing-".concat(version, "-page");
    }),
    repo: Ember.computed.alias('repositories.accessible.firstObject'),
    tab: Ember.computed.alias('tabStates.mainTab'),
    build: Ember.computed.alias('repo.currentBuild'),
    job: Ember.computed.alias('build.jobs.firstObject')
  });

  _exports.default = _default;
});