define("travis/templates/pull-requests", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xMNOvtHf",
    "block": "{\"symbols\":[],\"statements\":[[5,\"repo-build-list\",[],[[\"@repo\",\"@eventTypes\",\"@missingNotice\"],[[23,0,[\"repo\"]],\"pull_request\",[29,[\"No \",[28,\"vcs-vocab\",[[23,0,[\"repo\",\"vcsType\"]],\"pullRequest\"],[[\"lower\"],[true]]],\" builds for this repository\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/pull-requests.hbs"
    }
  });

  _exports.default = _default;
});