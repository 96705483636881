define("travis/models/stage", ["exports", "@ember-data/model", "travis/mixins/duration-calculations", "travis/mixins/duration-attributes"], function (_exports, _model, _durationCalculations, _durationAttributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_durationCalculations.default, _durationAttributes.default, {
    number: (0, _model.attr)(),
    name: (0, _model.attr)('string'),
    state: (0, _model.attr)(),
    build: (0, _model.belongsTo)('build', {
      async: true
    }),
    notStarted: Ember.computed('state', function () {
      var state = this.state;
      var waitingStates = ['queued', 'created', 'received'];
      return waitingStates.includes(state);
    })
  });

  _exports.default = _default;
});