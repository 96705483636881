define("travis/adapters/owner", ["exports", "travis/adapters/v3"], function (_exports, _v) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _v.default.extend({
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      if (requestType === 'queryRecord' && query.login !== undefined) {
        var prefix = this.urlPrefix();
        var login = query.login,
            provider = query.provider;
        var providerPrefix = provider ? "".concat(provider, "/") : '';
        return "".concat(prefix, "/owner/").concat(providerPrefix).concat(login);
      } else {
        throw new Error("The owner adapter only supports a\n        queryRecord request type with a query of login");
      }
    }
  });

  _exports.default = _default;
});