define("travis/templates/components/sales-contact-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HUcPyoZm",
    "block": "{\"symbols\":[],\"statements\":[[5,\"ui-kit/box\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"iframe-wrapper\"],[8],[0,\"\\n    \"],[7,\"iframe\",false],[12,\"class\",\"pardot-iframe\"],[12,\"id\",\"pardot-iframe\"],[12,\"src\",[23,0,[\"pardotFormUrl\"]]],[12,\"frameborder\",\"0\"],[12,\"allowTransparency\",\"true\"],[12,\"type\",\"text/html\"],[3,\"did-insert\",[[23,0,[\"setHeight\"]]]],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/sales-contact-form.hbs"
    }
  });

  _exports.default = _default;
});