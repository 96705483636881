define("travis/adapters/env-var", ["exports", "travis/adapters/application", "ember-inflector"], function (_exports, _application, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    namespace: 'settings',
    pathForType: function pathForType(type) {
      return (0, _emberInflector.pluralize)(Ember.String.underscore(type));
    },
    buildURL: function buildURL(type, id, record) {
      var repoId, url;
      url = this._super.apply(this, arguments);

      if (record && record.belongsTo('repo') && (repoId = record.belongsTo('repo').id)) {
        var delimiter = url.indexOf('?') !== -1 ? '&' : '?';
        url = "".concat(url).concat(delimiter, "repository_id=").concat(repoId);
      }

      return url;
    },
    updateRecord: function updateRecord(store, type, record) {
      var data, serializer;
      data = {};
      serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, record);
      var id = record.id;
      return this.ajax(this.buildURL(type.modelName, id, record), 'PATCH', {
        data: data
      });
    }
  });

  _exports.default = _default;
});