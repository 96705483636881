define("travis/templates/components/repo-status-badge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TdwuyLQq",
    "block": "{\"symbols\":[\"@onClick\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"repo-badge inline-block vertical-align\"],[8],[0,\"\\n  \"],[7,\"a\",false],[12,\"title\",[29,[\"Latest push build on default branch: \",[23,0,[\"lastBuildState\"]]]]],[12,\"name\",\"status-images\"],[12,\"class\",\"pointer open-popup\"],[3,\"on\",[\"click\",[23,1,[]]]],[8],[0,\"\\n    \"],[7,\"img\",true],[11,\"src\",[23,0,[\"statusImageUrl\"]]],[11,\"alt\",[29,[\"build:\",[23,0,[\"lastBuildState\"]]]]],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/repo-status-badge.hbs"
    }
  });

  _exports.default = _default;
});