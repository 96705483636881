define("travis/components/billing/invoices", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    invoices: null,
    invoiceYears: Ember.computed('invoices.@each.createdAt', function () {
      return this.invoices.mapBy('year').uniq().sort(function (a, b) {
        return b - a;
      });
    }),
    year: Ember.computed.reads('invoiceYears.firstObject'),
    selectedInvoices: Ember.computed('invoices.@each.createdAt', 'year', function () {
      return this.invoices.filterBy('year', this.year);
    })
  });

  _exports.default = _default;
});