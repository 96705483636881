define("travis/templates/components/insights-overlay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tj266oJ0",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[5,\"overlay-backdrop\",[],[[\"@visible\"],[[23,0,[\"showOverlay\"]]]],{\"statements\":[[0,\"\\n\"],[0,\"  \"],[7,\"h2\",true],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"isMonth\"]]],null,{\"statements\":[[0,\"      Build to get monthly insights\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,0,[\"isWeek\"]]],null,{\"statements\":[[0,\"      It's been a quiet week for builds\\n    \"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[0,\"  \"],[7,\"p\",true],[8],[0,\"\\n    All the build status results from the last\\n    \"],[4,\"if\",[[23,0,[\"isMonth\"]]],null,{\"statements\":[[0,\"30\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,0,[\"isWeek\"]]],null,{\"statements\":[[0,\"7\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\n    days will appear here.\\n\"],[4,\"if\",[[23,0,[\"auth\",\"signedOut\"]]],null,{\"statements\":[[0,\"      Have you tried logging in?\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[0,\"  \"],[5,\"multi-signin-button\",[],[[],[]]],[0,\"\\n\"],[4,\"if\",[[23,0,[\"canSync\"]]],null,{\"statements\":[[0,\"    \"],[5,\"external-link-to\",[[12,\"class\",\"button button--blue\"]],[[\"@href\"],[[28,\"config-get\",[\"urls.gettingStarted\"],null]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,0,[\"isMonth\"]]],null,{\"statements\":[[0,\"        Let's get you going\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,0,[\"isWeek\"]]],null,{\"statements\":[[0,\"        Want help building?\\n      \"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/insights-overlay.hbs"
    }
  });

  _exports.default = _default;
});