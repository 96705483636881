define("travis/components/ui-kit/grid", ["exports", "travis/utils/ui-kit/prefix", "travis/utils/ui-kit/concat"], function (_exports, _prefix, _concat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    // Public interface
    tag: 'div',
    display: 'flex',
    dir: 'row',
    wrap: 'wrap',
    align: 'stretch',
    justify: 'between',
    base: 1,
    gap: 0,
    // Private
    isRow: Ember.computed.match('dir', /^row/),
    isCol: Ember.computed.match('dir', /^col/),
    displayClass: Ember.computed.reads('display'),
    dirClass: (0, _prefix.default)('dir', 'flex'),
    wrapClass: (0, _prefix.default)('wrap', 'flex'),
    alignClass: (0, _prefix.default)('align', 'items'),
    justifyClass: (0, _prefix.default)('justify', 'justify'),
    gapClass: Ember.computed('gap', 'isCol', function () {
      var gap = this.gap,
          isCol = this.isCol;
      var marginDir = isCol ? 'y' : 'x';
      return gap === 0 ? '' : "-m".concat(marginDir, "-").concat(gap);
    }),
    allClasses: (0, _concat.default)('displayClass', 'dirClass', 'wrapClass', 'alignClass', 'justifyClass', 'gapClass')
  });

  _exports.default = _default;
});