define("travis/templates/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JYPOILWZ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"message\"]]],null,{\"statements\":[[0,\"  \"],[1,[23,0,[\"message\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  There was an error, please try again.\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/error.hbs"
    }
  });

  _exports.default = _default;
});