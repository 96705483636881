define("travis/templates/components/top-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OJP6V+3A",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"topbar \",[28,\"if\",[[23,0,[\"isNavigationOpen\"]],\"expanded\"],null],\" \",[28,\"if\",[[23,0,[\"showBroadcasts\"]],\"expanded\"],null]]]],[8],[0,\"\\n  \"],[5,\"header-burger-menu\",[],[[\"@isOpen\",\"@onClick\"],[[23,0,[\"isNavigationOpen\"]],[28,\"action\",[[23,0,[]],\"toggleNavigation\"],null]]]],[0,\"\\n  \"],[7,\"h1\",true],[10,\"id\",\"logo\"],[10,\"class\",\"logo\"],[8],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\"],[\"index\"]],{\"statements\":[[0,\"\\n      Travis CI\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n  \"],[5,\"header-broadcasts\",[],[[\"@showBroadcasts\"],[[23,0,[\"showBroadcasts\"]]]]],[0,\"\\n  \"],[5,\"header-links\",[],[[\"@isOpen\"],[[23,0,[\"isNavigationOpen\"]]]]],[0,\"\\n  \"],[5,\"profile-menu\",[],[[],[]]],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[23,0,[\"features\",\"enterpriseVersion\"]]],null,{\"statements\":[[0,\"  \"],[5,\"enterprise-banner\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,0,[\"user\"]]],null,{\"statements\":[[0,\"  \"],[5,\"repository-security-banner\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,0,[\"showCta\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"cta\"],[8],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"row\"],[8],[0,\"\\n      Help make Open Source a better place and start building better software today!\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/top-bar.hbs"
    }
  });

  _exports.default = _default;
});