define("travis/templates/repo-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OrA6doD4",
    "block": "{\"symbols\":[],\"statements\":[[5,\"error-page-layout\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[5,\"repo-not-found\",[],[[\"@slug\"],[[23,0,[\"model\",\"slug\"]]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/repo-error.hbs"
    }
  });

  _exports.default = _default;
});