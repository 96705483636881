define("travis/instance-initializers/pusher", ["exports", "travis/config/environment", "travis/utils/pusher"], function (_exports, _environment, _pusher) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(applicationInstance) {
    var app = applicationInstance.application;
    app.pusher = new _pusher.default(_environment.default.pusher, applicationInstance.lookup('service:api'));

    if (!applicationInstance.lookup('pusher:main')) {
      app.register('pusher:main', app.pusher, {
        instantiate: false
      });
    }

    app.inject('route', 'pusher', 'pusher:main');
    app.inject('component', 'pusher', 'pusher:main');
    app.pusher.store = applicationInstance.lookup('service:store');
    app.pusher.pusherService = applicationInstance.lookup('service:pusher');
  }

  var _default = {
    name: 'pusher',
    after: 'ember-data',
    initialize: initialize
  };
  _exports.default = _default;
});