define("travis/utils/filtered-array-manager", ["exports", "travis/utils/string-hash"], function (_exports, _stringHash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PromiseArray = Ember.ArrayProxy.extend(Ember.PromiseProxyMixin); // An array proxy wrapping records that fit a filtered array.

  var FilteredArray = Ember.ArrayProxy.extend({
    init: function init(createArgs) {
      var filterFunction = createArgs.filterFunction,
          _all = createArgs._all,
          dependencies = createArgs.dependencies;
      Ember.defineProperty(this, 'content', Ember.computed("_all.@each.{".concat(dependencies.join(','), "}"), function () {
        return _all.filter(function (item) {
          return item && filterFunction(item);
        });
      }));

      this._super(createArgs);
    }
  }); // Manages filtered arrays for a given type. It keeps a reference to all of the
  // records of a given type (obtained using store.peekAll() function) and watches
  // for changes on all of the records to determine wheather a record should be
  // added to one of the filtered arrays.
  //
  // Filtered arrays are indexed by an id unique for a given set of parameters,
  // calculated using the calculateId function.
  //
  // In order to minimise the number of observers FilteredArrayManagerForType will
  // group arrays by dependencies. Let's consider the following code:
  //
  //     let manager = FilteredArrayManagerForType.create({ modelName: 'repo' });
  //
  //     manager.fetchArray({}, function() {}, ['id']) {
  //     manager.fetchArray({}, function() {}, ['id', 'slug']) {
  //
  // 2 arrays will be created for such a call for dependencies ['id'] and
  // ['id', 'slug'] respectively. That means that all of the records need to be
  // observed for dependencies 'id' and 'slug'. Instead of creating 2 observers
  // for the 'id' dependency we will create only one observer, which will process
  // both arrays.
  //
  // Whenever a record is added to a store for a given type,
  // FilteredArrayManagerForType will add observers for the record and it will see
  // if the record needs to be added to any of the filtered arrays. Whenever a
  // record is removed, the observers will be removed as well and it will be
  // removed from all of the filtered arrays.

  var FilteredArrayManagerForType = Ember.Object.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.arrays = {};
      this.allRecords = this.store.peekAll(this.modelName);
    },
    // Fetches a filtered array. If an array for a given set of dependencies
    // doesn't exist, it will be created. A query will be run for a newly created
    // array and each time the forceReload option is set to true
    //
    // queryParams    - params that will be passed to the store.query function when
    //                  fetching records on the initial call
    // filterFunction - a function that will be used to test if a record should be
    //                  added or removed from an array. It will be called with a
    //                  record under test as a sole argument
    // dependencies   - a set of dependencies that will be watched to re-evaluate
    //                  if a record should be a part of a filtered array
    // forceReload    - if set to true, store.query will be run on each call
    //
    // Examples:
    //
    //   manager.fetchArray(
    //     { starred: true },
    //     (repo) => repo.get('starred'),
    //     ['starred'],
    //     true
    //   )
    //
    // Returns a FilteredArray (an ArrayProxy)
    fetchArray: function fetchArray(queryParams, filterFunction, dependencies, forceReload) {
      var _this = this;

      var id = this.calculateId(queryParams, filterFunction, dependencies);
      var hasArray = !!this.arrays[id];

      var array = this._getFilterArray(id, queryParams, filterFunction, dependencies);

      if (hasArray && forceReload) {
        // if forceReload is true and array already exist, just run the query
        // to get new results
        var promise = new Ember.RSVP.Promise(function (resolve, reject) {
          _this.fetchQuery(queryParams).then(function (queryResult) {
            array.set('queryResult', queryResult);
            resolve(array);
          }, reject);
        });
        array._lastPromise = promise;
      }

      return array;
    },
    _getFilterArray: function _getFilterArray(id, queryParams, filterFunction, dependencies) {
      var array = this.arrays[id];

      if (!array) {
        array = this.createArray(id, queryParams, filterFunction, dependencies);
      }

      return array;
    },
    getFilterArray: function getFilterArray(queryParams, filterFunction, dependencies) {
      var id = this.calculateId(queryParams, filterFunction, dependencies);
      return this._getFilterArray(id, queryParams, filterFunction, dependencies);
    },
    // Creates an array for a given id and set of params.
    createArray: function createArray(id, queryParams, filterFunction, dependencies) {
      var _this2 = this;

      // TODO: test what ahppens when records already exist in a store,I think it
      // won't work
      var array = this.arrays[id] = FilteredArray.create({
        filterFunction: filterFunction,
        _all: this.allRecords,
        dependencies: dependencies
      });
      var promise = new Ember.RSVP.Promise(function (resolve, reject) {
        // TODO: think about error handling, at the moment it will just pass the
        // reject from store.query
        _this2.fetchQuery(queryParams).then(function (queryResult) {
          array.set('queryResult', queryResult);
          resolve(array);
        }, reject);
      });
      array._promise = promise;
      array._lastPromise = promise;
      return array;
    },
    // Runs a store.query() function for a type that the array is handling with
    // queryParams passed as an argument.
    fetchQuery: function fetchQuery(queryParams) {
      if (queryParams) {
        return this.store.query(this.modelName, queryParams);
      } else {
        return Ember.RSVP.resolve([]);
      }
    },
    calculateId: function calculateId(queryParams, filterFunction, dependencies) {
      var params = queryParams || {};
      var id = (0, _stringHash.default)([JSON.stringify(params), (dependencies || []).sort(), // not sure if this is a good idea, but I want to get the unique id for
      // each set of arguments to filter
      filterFunction.toString()].toString());
      return id;
    },
    destroy: function destroy() {
      var _this3 = this;

      this._super.apply(this, arguments);

      Object.keys(this.arrays).forEach(function (key) {
        _this3.arrays[key].destroy();
      });
    }
  });
  var FilteredArrayManager = Ember.Object.extend({
    init: function init() {
      this.filteredArrayManagersByType = {};
    },
    filter: function filter(modelName, queryParams, filterFunction, dependencies) {
      var filterArray = this.filteredArrayManagerForType(modelName).getFilterArray(queryParams, filterFunction, dependencies);

      if (queryParams) {
        var currentRecords = this.store.peekAll(modelName);

        if (filterFunction) {
          currentRecords = currentRecords.filter(function (record) {
            return filterFunction(record);
          });
        }

        var promise = Ember.RSVP.resolve(currentRecords).then(function () {
          return filterArray;
        });
        return PromiseArray.create({
          promise: promise
        });
      }

      return filterArray;
    },
    fetchArray: function fetchArray(modelName) {
      var _this$filteredArrayMa;

      for (var _len = arguments.length, rest = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        rest[_key - 1] = arguments[_key];
      }

      return (_this$filteredArrayMa = this.filteredArrayManagerForType(modelName)).fetchArray.apply(_this$filteredArrayMa, rest)._promise;
    },
    filteredArrayManagerForType: function filteredArrayManagerForType(modelName) {
      var manager = this.filteredArrayManagersByType[modelName];

      if (!manager) {
        manager = this.filteredArrayManagersByType[modelName] = FilteredArrayManagerForType.create({
          store: this.store,
          modelName: modelName
        });
      }

      return manager;
    },
    destroy: function destroy() {
      var _this4 = this;

      this._super.apply(this, arguments);

      Object.keys(this.filteredArrayManagersByType).forEach(function (key) {
        _this4.filteredArrayManagersByType[key].destroy();
      });
      this.filteredArrayManagersByType = {};
    }
  });
  var _default = FilteredArrayManager;
  _exports.default = _default;
});