define("travis/serializers/beta-migration-request", ["exports", "travis/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pushPayload: function pushPayload(store, payload) {
      var modelClass = store.modelFor('beta-migration-request');
      var json = this.normalizeArrayResponse(store, modelClass, payload);
      store.push(json);
    },
    normalize: function normalize(modelClass) {
      var payload = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      if (payload.organizations) {
        payload['organization_ids'] = payload.organizations;
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});