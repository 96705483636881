define("travis/components/my-build", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Component.extend({
    externalLinks: Ember.inject.service(),
    tagName: 'li',
    classNames: ['rows', 'my-build'],
    classNameBindings: ['state'],
    state: Ember.computed.alias('build.state'),
    branchUrl: Ember.computed('build.repo.{slug,vcsType}', 'build.branchName', function () {
      var _split = (this.get('build.repo.slug') || '').split('/'),
          _split2 = _slicedToArray(_split, 2),
          owner = _split2[0],
          repo = _split2[1];

      var vcsType = this.get('build.repo.vcsType');
      var vcsId = this.get('build.repo.vcsId');
      var branch = this.get('build.branchName');
      return this.externalLinks.branchUrl(vcsType, {
        owner: owner,
        repo: repo,
        branch: branch,
        vcsId: vcsId
      });
    }),
    commitUrl: Ember.computed('build.repo.{slug,vcsType}', 'build.commit.sha', function () {
      var _split3 = (this.get('build.repo.slug') || '').split('/'),
          _split4 = _slicedToArray(_split3, 2),
          owner = _split4[0],
          repo = _split4[1];

      var vcsType = this.get('build.repo.vcsType');
      var vcsId = this.get('build.repo.vcsId');
      var commit = this.get('build.commit.sha');
      return this.externalLinks.commitUrl(vcsType, {
        owner: owner,
        repo: repo,
        commit: commit,
        vcsId: vcsId
      });
    })
  });

  _exports.default = _default;
});