define("travis/models/vcs-entity", ["exports", "@ember-data/model", "travis/utils/vcs"], function (_exports, _model, _vcs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    vcsId: (0, _model.attr)('string'),
    vcsType: (0, _model.attr)('string'),
    vcsProvider: Ember.computed('vcsType', function () {
      return (0, _vcs.vcsConfig)(this.vcsType);
    }),
    provider: Ember.computed.reads('vcsProvider.urlPrefix')
  });

  _exports.default = _default;
});