define("travis/templates/components/owner-not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5EA44VHy",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"missing-notice\"],[8],[0,\"\\n  \"],[7,\"figure\",true],[10,\"class\",\"page-graphic\"],[8],[0,\"\\n    \"],[5,\"svg-image\",[],[[\"@name\",\"@class\"],[\"barricade\",\"barricade\"]]],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"h2\",true],[10,\"class\",\"page-title\"],[8],[0,\"\\n    We couldn't find the owner \"],[7,\"br\",true],[8],[9],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"h2--red\"],[8],[0,\"\\n      \"],[1,[23,0,[\"ownerLogin\"]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"if\",[[23,0,[\"features\",\"enterpriseVersion\"]]],null,{\"statements\":[[4,\"unless\",[[23,0,[\"auth\",\"signedIn\"]]],null,{\"statements\":[[0,\"      \"],[7,\"p\",true],[10,\"class\",\"page-notice\"],[8],[0,\"\\n        This owner may not exist or you may need to\\n        \"],[5,\"link-to\",[[12,\"class\",\"auth-button--white m-l-s\"]],[[\"@route\"],[\"signin\"]],{\"statements\":[[0,\"\\n          Sign in\\n        \"]],\"parameters\":[]}],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/owner-not-found.hbs"
    }
  });

  _exports.default = _default;
});