define("travis/components/ui-kit/button-signin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    auth: Ember.inject.service(),
    multiVcs: Ember.inject.service(),
    account: null,
    isSignup: false,
    provider: Ember.computed.or('account.provider', 'multiVcs.primaryProvider'),
    isLogoVisible: true,
    isLogoSeparatorVisible: true,
    isBetaBadgeVisible: Ember.computed.reads('isBetaProvider'),
    minWidth: 'md',
    isLoading: false,
    vcsType: Ember.computed('provider', function () {
      return "".concat(this.provider.capitalize(), "User");
    }),
    isPrimaryProvider: Ember.computed('provider', function () {
      return this.multiVcs.isProviderPrimary(this.provider);
    }),
    isProviderEnabled: Ember.computed('provider', 'isPrimaryProvider', function () {
      var provider = this.provider,
          isPrimaryProvider = this.isPrimaryProvider,
          multiVcs = this.multiVcs;
      return isPrimaryProvider || multiVcs.enabled && multiVcs.isProviderEnabled(provider);
    }),
    isBetaProvider: Ember.computed('provider', function () {
      return this.multiVcs.isProviderBeta(this.provider);
    }),
    minWidthClass: Ember.computed('minWidth', function () {
      var minWidth = this.minWidth;
      return minWidth ? "min-w-".concat(minWidth) : '';
    }),
    logoSeparatorWidth: Ember.computed('isLogoSeparatorVisible', function () {
      return this.isLogoSeparatorVisible ? 'xs' : 'none';
    }),
    signin: function signin() {
      if (this.account) {
        this.auth.switchAccount(this.account.id, this.auth.redirectUrl || '/');
      } else {
        this.set('isLoading', true);
        this.auth.signInWith(this.provider);
      }
    }
  });

  _exports.default = _default;
});