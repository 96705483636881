define("travis/templates/components/error-page-layout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j/4Piiwb",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"loading-page\"],[8],[0,\"\\n  \"],[5,\"top-bar\",[],[[],[]]],[0,\"\\n  \"],[5,\"flash-display\",[],[[],[]]],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"main\"],[8],[0,\"\\n    \"],[14,1],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/error-page-layout.hbs"
    }
  });

  _exports.default = _default;
});