define("travis/templates/components/feature-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "caZnQUsU",
    "block": "{\"symbols\":[],\"statements\":[[5,\"travis-switch\",[],[[\"@active\",\"@disabled\",\"@onToggle\"],[[23,0,[\"feature\",\"enabled\"]],[23,0,[\"disabled\"]],[28,\"perform\",[[23,0,[\"save\"]]],null]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/feature-toggle.hbs"
    }
  });

  _exports.default = _default;
});