define("travis/templates/components/repository-filter-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hjLBAHBG",
    "block": "{\"symbols\":[\"form\",\"field\",\"&default\"],\"statements\":[[5,\"travis-form\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"field\"]],[],[[\"@icon\",\"@value\",\"@allowClear\",\"@onChange\"],[\"icon-filter\",[23,0,[\"query\"]],true,[23,0,[\"onSearch\"]]]],{\"statements\":[[0,\"\\n    \"],[14,3,[[28,\"hash\",null,[[\"searchField\"],[[28,\"component\",[[23,2,[\"input\"]]],[[\"onKeyUp\"],[[23,0,[\"onSearch\"]]]]]]]]]],[0,\"\\n  \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/repository-filter-form.hbs"
    }
  });

  _exports.default = _default;
});