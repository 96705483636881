define("travis/components/show-more-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'button',
    classNames: ['showmore-button', 'button'],
    label: 'Show more'
  });

  _exports.default = _default;
});