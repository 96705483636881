define("travis/templates/components/insights-glance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yXuPwKt1",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h3\",true],[10,\"class\",\"insights-glance__title\"],[8],[0,\"\\n  \"],[1,[23,0,[\"title\"]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"insights-glance__center\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"insights-glance__stat\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"isLoading\"]]],null,{\"statements\":[[0,\"      \"],[5,\"loading-indicator\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[23,0,[\"statistic\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[4,\"if\",[[23,0,[\"delta\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"insights-glance-delta\"],[11,\"data-dir\",[28,\"if\",[[28,\"gte\",[[23,0,[\"delta\"]],0],null],\"+\",\"-\"],null]],[11,\"title\",[23,0,[\"deltaTitle\"]]],[8],[0,\"\\n      \"],[5,\"svg-image\",[],[[\"@name\",\"@class\"],[\"icon-dropdown-arrow\",\"icon\"]]],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"insights-glance-delta__stat\"],[8],[0,\"\\n        \"],[1,[23,0,[\"deltaText\"]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"insights-glance__chart\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"showPlaceholder\"]]],null,{\"statements\":[[0,\"    \"],[7,\"hr\",true],[10,\"class\",\"insights-glance__chart-placeholder\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[5,\"c3-chart\",[[12,\"class\",\"chart-component snapshot-hide\"]],[[\"@data\",\"@axis\",\"@legend\",\"@grid\",\"@size\",\"@point\",\"@tooltip\"],[[23,0,[\"data\"]],[23,0,[\"axis\"]],[23,0,[\"legend\"]],[23,0,[\"grid\"]],[23,0,[\"size\"]],[23,0,[\"point\"]],[23,0,[\"tooltip\"]]]]],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/insights-glance.hbs"
    }
  });

  _exports.default = _default;
});