define("travis/templates/components/build-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qF1JVx7D",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"yml-message\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"isLinkable\"]]],null,{\"statements\":[[0,\"    \"],[7,\"a\",true],[11,\"href\",[23,0,[\"lineLink\"]]],[10,\"class\",\"yml-message-link\"],[10,\"tabindex\",\"0\"],[8],[0,\"\\n      \"],[5,\"build-message-line\",[],[[\"@iconClass\",\"@level\",\"@code\",\"@message\",\"@tooltipText\"],[[23,0,[\"iconClass\"]],[23,0,[\"message\",\"level\"]],[23,0,[\"message\",\"code\"]],[23,0,[\"readableMessage\"]],[23,0,[\"tooltipText\"]]]]],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"tabindex\",\"0\"],[8],[0,\"\\n      \"],[5,\"build-message-line\",[],[[\"@iconClass\",\"@level\",\"@code\",\"@message\",\"@tooltipText\"],[[23,0,[\"iconClass\"]],[23,0,[\"message\",\"level\"]],[23,0,[\"message\",\"code\"]],[23,0,[\"readableMessage\"]],[23,0,[\"tooltipText\"]]]]],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/build-message.hbs"
    }
  });

  _exports.default = _default;
});