define("travis/mixins/route/owner/migrate", ["exports", "travis/utils/dynamic-query"], function (_exports, _dynamicQuery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PAGE_CHANGED = _dynamicQuery.EVENTS.PAGE_CHANGED;

  var _default = Ember.Mixin.create({
    features: Ember.inject.service(),
    page: 1,
    queryParams: {
      page: {
        refreshModel: true
      }
    },
    model: function model(_ref) {
      var page = _ref.page;
      this.setProperties({
        page: page
      });
    },
    afterModel: function afterModel(model) {
      var _this = this;

      if (model && !model.error) {
        var repos = model.githubAppsRepositoriesOnOrg;
        repos.switchToPage(this.page);
        repos.on(PAGE_CHANGED, function (page) {
          var queryParams = {
            page: page
          };

          _this.transitionTo({
            queryParams: queryParams
          });
        });

        if (repos.isAny('isMigrationSucceeded')) {
          repos.reload();
        }

        return repos.load();
      }
    },
    redirect: function redirect(model, transition) {
      if (!this.get('features.proVersion') || this.get('features.enterpriseVersion')) {
        transition.abort();
        model.isUser ? this.transitionTo('account.repositories') : this.transitionTo('organization.repositories', model);
      }
    }
  });

  _exports.default = _default;
});