define("travis/templates/components/queue-times", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sk/H2TPP",
    "block": "{\"symbols\":[],\"statements\":[[5,\"insights-glance\",[],[[\"@isLoading\",\"@isEmpty\",\"@title\",\"@statistic\",\"@delta\",\"@deltaTitle\",\"@deltaText\",\"@isReverseDeltaColors\",\"@labels\",\"@values\",\"@datasetTitle\",\"@centerline\"],[[23,0,[\"isLoading\"]],[23,0,[\"isEmpty\"]],\"Average Queue Time\",[23,0,[\"avgWaitText\"]],[23,0,[\"percentageChange\"]],[23,0,[\"percentChangeTitle\"]],[23,0,[\"percentageChangeText\"]],true,[23,0,[\"labels\"]],[23,0,[\"waitMins\"]],\"Minutes\",[23,0,[\"averageRounded\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/queue-times.hbs"
    }
  });

  _exports.default = _default;
});