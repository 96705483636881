define("travis/routes/job/config", ["exports", "travis/routes/basic"], function (_exports, _basic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basic.default.extend({
    titleToken: 'Config',
    model: function model() {
      var _this = this;

      return this.modelFor('job').get('build').then(function (build) {
        var requestId = build.get('build.request.id') || build.belongsTo('request').id();
        return _this.store.findRecord('request', requestId);
      });
    },
    afterModel: function afterModel(request) {
      return request.fetchMessages.perform();
    }
  });

  _exports.default = _default;
});