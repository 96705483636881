define("travis/mixins/tailwind-base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    headData: Ember.inject.service(),
    activate: function activate() {
      this.set('headData.useTailwindBase', true);
      return this._super.apply(this, arguments);
    },
    deactivate: function deactivate() {
      this.set('headData.useTailwindBase', false);
      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});