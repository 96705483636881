define("travis/templates/components/sales-contact-thanks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CtrXvpmB",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[5,\"ui-kit/box\",[[13,1]],[[\"@textAlign\"],[\"center\"]],{\"statements\":[[0,\"\\n  \"],[5,\"ui-kit/text\",[],[[\"@tag\",\"@variant\"],[\"h1\",\"h1\"]],{\"statements\":[[0,\"\\n    Thank you!\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"],[5,\"ui-kit/box\",[],[[\"@maxWidth\",\"@margin\"],[\"lg\",[28,\"hash\",null,[[\"y\",\"x\"],[4,\"auto\"]]]]],{\"statements\":[[0,\"\\n    \"],[5,\"svg-image\",[],[[\"@name\"],[\"hard-hat-success\"]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"],[5,\"ui-kit/text\",[],[[\"@margin\"],[[28,\"hash\",null,[[\"bottom\"],[4]]]]],{\"statements\":[[0,\"\\n    We'll get back to you as soon as possible.\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n\"],[4,\"if\",[[23,0,[\"backRoute\"]]],null,{\"statements\":[[0,\"    \"],[5,\"link-to\",[],[[\"@route\"],[[23,0,[\"backRoute\"]]]],{\"statements\":[[0,\"\\n      \"],[5,\"ui-kit/button\",[],[[],[]],{\"statements\":[[0,\"\\n        Back\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/sales-contact-thanks.hbs"
    }
  });

  _exports.default = _default;
});