define("travis/routes/first-sync", ["exports", "travis/config/environment", "travis/routes/simple-layout"], function (_exports, _environment, _simpleLayout) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _simpleLayout.default.extend({
    activate: function activate() {
      var controller = this.controllerFor('firstSync');
      controller.addObserver('isSyncing', this, 'isSyncingDidChange');
      this.isSyncingDidChange();
    },
    deactivate: function deactivate() {
      var controller = this.controllerFor('firstSync');
      return controller.removeObserver('controller.isSyncing', this, 'isSyncingDidChange');
    },
    isSyncingDidChange: function isSyncingDidChange() {
      var _this = this;

      var controller = this.controllerFor('firstSync');

      if (!controller.isSyncing) {
        Ember.run.later(function () {
          return _this.transitionTo('account');
        }, _environment.default.timing.syncingPageRedirectionTime);
      }
    }
  });

  _exports.default = _default;
});