define("travis/models/beta-migration-request", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    store: Ember.inject.service(),
    ownerId: (0, _model.attr)('number'),
    ownerName: (0, _model.attr)('string'),
    ownerType: (0, _model.attr)('string'),
    acceptedAt: (0, _model.attr)('date'),
    organizations: (0, _model.hasMany)('organization'),
    owner: Ember.computed('ownerName', 'ownerType', function () {
      var ownerName = this.ownerName,
          _this$ownerType = this.ownerType,
          ownerType = _this$ownerType === void 0 ? '' : _this$ownerType,
          store = this.store;
      return ownerName && ownerType ? store.peekRecord(ownerType.toLowerCase(), ownerName) : null;
    })
  });

  _exports.default = _default;
});