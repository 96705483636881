define("travis/components/remove-log-popup", ["exports", "ember-keyboard-shortcuts"], function (_exports, _emberKeyboardShortcuts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['remove-log-popup'],
    flashes: Ember.inject.service(),
    keyboardShortcuts: {
      'esc': 'toggleRemoveLogModal'
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      (0, _emberKeyboardShortcuts.bindKeyboardShortcuts)(this);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      (0, _emberKeyboardShortcuts.unbindKeyboardShortcuts)(this);
    },
    actions: {
      removeLog: function removeLog() {
        var _this = this;

        var job = this.job;
        this.onCloseModal();
        return job.removeLog().then(function () {
          _this.flashes.success('Log has been successfully removed.');
        }, function (xhr) {
          if (xhr.status === 409) {
            return _this.flashes.error('Log can’t be removed');
          } else if (xhr.status === 401) {
            return _this.flashes.error('You don’t have sufficient access to remove the log');
          } else {
            return _this.flashes.error('An error occurred when removing the log');
          }
        });
      },
      toggleRemoveLogModal: function toggleRemoveLogModal() {
        this.onCloseModal();
      }
    }
  });

  _exports.default = _default;
});