define("travis/templates/components/repos-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UPhZuMdg",
    "block": "{\"symbols\":[\"repo\"],\"statements\":[[4,\"if\",[[23,0,[\"repos\"]]],null,{\"statements\":[[0,\"  \"],[7,\"ul\",true],[10,\"class\",\"repos-list\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"repos\"]]],null,{\"statements\":[[0,\"      \"],[5,\"repos-list-item\",[],[[\"@repo\"],[[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"loading-container\"],[8],[0,\"\\n    \"],[1,[23,0,[\"noReposMessage\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/repos-list.hbs"
    }
  });

  _exports.default = _default;
});