define("travis/components/billing/subscription", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    account: null,
    newSubscriptionProcess: false,
    subscription: Ember.computed.reads('account.subscription'),
    hasExpiredStripeSubscription: Ember.computed.bool('account.expiredStripeSubscription')
  });

  _exports.default = _default;
});