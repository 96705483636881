define("travis/utils/fuzzy-match", ["exports", "fuzzysort"], function (_exports, _fuzzysort) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = fuzzyMatch;

  function fuzzyMatch(string, query) {
    var prefix = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '<b>';
    var suffix = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '</b>';
    return _fuzzysort.default.highlight(_fuzzysort.default.single(query, string), prefix, suffix) || string;
  }
});