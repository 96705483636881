define("travis/templates/components/broadcast-tower", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7IHkBmqO",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\",true],[11,\"class\",[29,[\"icon-broadcast \",[23,0,[\"status\"]],\" \",[28,\"if\",[[23,0,[\"isOpen\"]],\"is-open\"],null]]]],[10,\"title\",\"broadcasts\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],[28,\"perform\",[[24,[\"toggle\"]]],null]],null]],[8],[0,\"\\n  \"],[5,\"svg-image\",[],[[\"@name\"],[\"icon-broadcast\"]]],[0,\"\\n\"],[4,\"if\",[[23,0,[\"count\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"count-wrapper\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"count\"],[8],[0,\"\\n        \"],[1,[23,0,[\"count\"]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/broadcast-tower.hbs"
    }
  });

  _exports.default = _default;
});