define("travis/templates/components/email-switch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GOcGWduR",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"switch-inner\"],[8],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"on\"],[8],[0,\"\\n    \"],[5,\"svg-image\",[],[[\"@name\",\"@class\",\"@alt\"],[\"icon-email\",\"icon-on\",\"on\"]]],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"off\"],[8],[0,\"\\n    \"],[5,\"svg-image\",[],[[\"@name\",\"@class\",\"@alt\"],[\"icon-email\",\"icon-off\",\"off\"]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"span\",true],[10,\"class\",\"label\"],[8],[0,\"\\n  \"],[1,[23,0,[\"description\"]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/email-switch.hbs"
    }
  });

  _exports.default = _default;
});