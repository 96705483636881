define("travis/utils/paginated-collection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.ArrayProxy.extend({
    arrangedContent: Ember.computed.alias('content'),
    pagination: Ember.computed('content.meta.pagination', function () {
      var paginationData = this.get('content.meta.pagination');
      var object = {
        total: paginationData.count,
        perPage: paginationData.limit,
        offset: paginationData.offset,
        isFirst: paginationData.is_first,
        isLast: paginationData.is_last,
        prev: paginationData.prev,
        next: paginationData.next,
        first: paginationData.first,
        last: paginationData.last
      };
      Ember.defineProperty(object, 'currentPage', Ember.computed(function () {
        var offset = paginationData.offset,
            limit = paginationData.limit;
        return offset / limit + 1;
      }));
      Ember.defineProperty(object, 'numberOfPages', Ember.computed(function () {
        var count = paginationData.count,
            limit = paginationData.limit;
        return Math.ceil(count / limit);
      }));
      return object;
    })
  });

  _exports.default = _default;
});