define("travis/controllers/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    layoutName: Ember.computed({
      get: function get() {
        if (this._layoutName) {
          return "layouts/".concat(this._layoutName);
        }
      },
      set: function set(key, value) {
        return this._layoutName = value;
      }
    })
  });

  _exports.default = _default;
});