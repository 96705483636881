define("travis/adapters/v3", ["exports", "ember-inflector", "travis/config/environment", "ember-data/adapters/rest"], function (_exports, _emberInflector, _environment, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rest.default.extend({
    auth: Ember.inject.service(),
    host: _environment.default.apiEndpoint,
    sortQueryParams: false,
    coalesceFindRequests: false,
    headers: {
      'Travis-API-Version': '3',
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-Client-Release': _environment.default.release
    },
    ajaxOptions: function ajaxOptions(url) {
      var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'GET';
      var options = arguments.length > 2 ? arguments[2] : undefined;
      options = options || {};
      options.data = options.data || {};
      options.data = Ember.assign({}, options.data); // clone

      for (var key in options.data) {
        var value = options.data[key];

        if (Array.isArray(value)) {
          options.data[key] = value.join(',');
        }
      }

      var includes = this.includes;

      if (includes) {
        if (options.data.include) {
          options.data.include += ",".concat(includes);
        } else {
          options.data.include = includes;
        }
      }

      if (options.data.page_size) {
        options.data.limit = options.data.page_size;
        delete options.data.page_size;
      }

      var hash = this._super(url, type, options);

      hash.headers = hash.headers || {};
      var token = this.get('auth.token');

      if (token) {
        hash.headers['Authorization'] = "token ".concat(token);
      }

      return hash;
    },
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var url = [];
      var host = this.host;
      var prefix = this.urlPrefix();
      var pathPrefix = this.pathPrefix.apply(this, arguments);

      if (pathPrefix) {
        url.push(pathPrefix);
      }

      if (modelName) {
        var path = this.pathForType(modelName, id);

        if (path) {
          url.push(path);
        }
      }

      if (id) {
        url.push(encodeURIComponent(id));
      }

      if (prefix) {
        url.unshift(prefix);
      }

      url = url.join('/');

      if (!host && url && url.charAt(0) !== '/') {
        url = "/".concat(url);
      }

      return url;
    },
    pathPrefix: function pathPrefix() {},
    pathForType: function pathForType(modelName, id) {
      var underscored = Ember.String.underscore(modelName);
      return id ? underscored : (0, _emberInflector.pluralize)(underscored);
    },
    // Get the host alone, without a path
    getHost: function getHost() {
      var match = this.host.match(/(https?:\/\/)?([^\/]+)/);

      if (match) {
        return match[0];
      } else {
        return _environment.default.apiEndpoint;
      }
    }
  });

  _exports.default = _default;
});