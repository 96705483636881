define("travis/templates/components/ui-kit/switch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yIE3lrxW",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[7,\"button\",false],[12,\"disabled\",[23,0,[\"disabled\"]]],[12,\"class\",[29,[\"\\n    rounded-full p-px\\n    bg-\",[28,\"if\",[[23,0,[\"active\"]],[23,0,[\"activeBgColor\"]],[23,0,[\"inactiveBgColor\"]]],null],\"\\n    \",[28,\"if\",[[23,0,[\"active\"]],\"pl\",\"pr\"],null],\"-\",[23,0,[\"spaceSize\"]],\"\\n  \"]]],[13,1],[3,\"on\",[\"click\",[28,\"action\",[[23,0,[]],\"handleClick\"],null]]],[8],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[\"\\n      bg-white rounded-full p-1\\n      h-\",[23,0,[\"dotSize\"]],\"\\n      w-\",[23,0,[\"dotSize\"]],\"\\n    \"]]],[8],[0,\"\\n    \"],[14,2],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/ui-kit/switch.hbs"
    }
  });

  _exports.default = _default;
});