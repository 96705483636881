define("travis/routes/team", ["exports", "travis/routes/basic", "travis/config/environment"], function (_exports, _basic, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basic.default.extend({
    redirect: function redirect() {
      window.location.replace(_environment.default.urls.about);
    }
  });

  _exports.default = _default;
});