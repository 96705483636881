define("travis/templates/components/billing/education", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FMeN5e2r",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\",true],[10,\"class\",\"billing-subscription\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"billing-subscription-section\"],[8],[0,\"\\n    \"],[7,\"h3\",true],[10,\"class\",\"plan-overview__header\"],[8],[0,\"Overview\"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"plan\"],[8],[0,\"\\n      \"],[5,\"billing-summary-status\",[],[[\"@subscription\",\"@account\"],[[23,0,[\"subscription\"]],[23,0,[\"account\"]]]]],[0,\"\\n      \"],[7,\"p\",true],[10,\"class\",\"plan-overview__description\"],[8],[0,\"1 concurrent job\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"hr\",true],[8],[9],[0,\"\\n\"],[5,\"billing/process\",[],[[\"@account\",\"@newSubscription\"],[[23,0,[\"account\"]],[23,0,[\"newSubscription\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/billing/education.hbs"
    }
  });

  _exports.default = _default;
});