define("travis/utils/abstract-method", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = abstractMethod;

  function abstractMethod(name) {
    var nameSufix = name ? "for ".concat(name) : '';
    return function () {
      throw new Error("Must supply an implementation ".concat(nameSufix));
    };
  }
});