define("travis/components/ui-kit/badge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    color: 'grey-light',
    display: 'inline-block',
    pin: null,
    size: 'xs'
  });

  _exports.default = _default;
});