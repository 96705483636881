define("travis/templates/components/job-not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "I15KEEpE",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"missing-notice\"],[8],[0,\"\\n  \"],[7,\"figure\",true],[10,\"class\",\"page-graphic\"],[8],[0,\"\\n    \"],[5,\"svg-image\",[],[[\"@name\"],[\"barricade\"]]],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"h2\",true],[10,\"class\",\"page-title\"],[8],[0,\"\\n    Oops, we couldn't find that job!\\n  \"],[9],[0,\"\\n  \"],[7,\"p\",true],[10,\"class\",\"page-notice\"],[8],[0,\"\\n    The job may not exist or may belong to another repository.\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/job-not-found.hbs"
    }
  });

  _exports.default = _default;
});