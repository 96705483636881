define("travis/templates/account/repositories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YE4FW0I1",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"and\",[[23,0,[\"owner\",\"isUser\"]],[23,0,[\"owner\",\"isAssembla\"]]],null]],null,{\"statements\":[[0,\"  \"],[5,\"paper-block\",[],[[],[]],{\"statements\":[[0,\"\\n    In order to see your repositories please select a Space on the left sidebar.\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[5,\"owner/repositories\",[],[[\"@owner\"],[[23,0,[\"owner\"]]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/account/repositories.hbs"
    }
  });

  _exports.default = _default;
});