define("travis/serializers/subscription", ["exports", "travis/serializers/v3"], function (_exports, _v) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _v.default.extend({
    attrs: {
      permissions: {
        key: '@permissions'
      }
    },
    serialize: function serialize(snapshot, options) {
      var json = this._super.apply(this, arguments);

      if (options && options.update) {
        return Object.keys(json).reduce(function (updatedJson, attribute) {
          var splitAttribute = attribute.split('.');
          var leftAttribute = splitAttribute[0];
          var rightAttribute = splitAttribute[1];

          if (leftAttribute === 'billing_info') {
            updatedJson[rightAttribute] = json["billing_info.".concat(rightAttribute)];
          }

          return updatedJson;
        }, {});
      }

      return json;
    },
    serializeBelongsTo: function serializeBelongsTo(snapshot, json, relationship) {
      var key = relationship.key;
      var belongsTo = snapshot.belongsTo(key);
      var planId = snapshot.belongsTo('plan', {
        id: true
      });
      key = this.keyForRelationship ? this.keyForRelationship(key, 'belongsTo', 'serialize') : key;

      if (Ember.isNone(belongsTo)) {
        json[key] = null;
      } else if (key === 'plan') {
        json['plan'] = planId;
      } else {
        belongsTo.eachAttribute(function (name) {
          json["".concat(key, ".").concat(Ember.String.underscore(name))] = belongsTo.attr(name);
        });
      }
    },
    normalizeArrayResponse: function normalizeArrayResponse(store, primaryModelClass, payload) {
      var documentHash = this._super.apply(this, arguments);

      (payload['@permissions'] || []).forEach(function (permissionsObject) {
        documentHash.included.push({
          type: permissionsObject.owner['type'],
          id: permissionsObject.owner.id,
          attributes: {
            subscriptionPermissions: {
              create: permissionsObject.create
            }
          }
        });
      });
      return documentHash;
    }
  });

  _exports.default = _default;
});