define("travis/components/repo-status-badge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    statusImages: Ember.inject.service(),
    repo: null,
    onClick: function onClick() {},
    lastBuildState: Ember.computed.reads('repo.defaultBranch.lastBuild.state'),
    defaultBranch: Ember.computed.reads('repo.defaultBranch.name'),
    statusImageUrl: Ember.computed('repo.slug', 'repo.private', 'defaultBranch', 'lastBuildState', function () {
      var defaultBranch = this.defaultBranch,
          _this$lastBuildState = this.lastBuildState,
          lastBuildState = _this$lastBuildState === void 0 ? 'unknown' : _this$lastBuildState,
          repo = this.repo;
      var url = this.statusImages.imageUrl(repo, defaultBranch);
      var divider = url.includes('?') ? '&' : '?';
      return "".concat(url).concat(divider, "status=").concat(lastBuildState);
    })
  });

  _exports.default = _default;
});