define("travis/routes/account/settings", ["exports", "travis/routes/basic"], function (_exports, _basic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basic.default.extend({
    featureFlags: Ember.inject.service(),
    model: function model() {
      var featureFlags = this.featureFlags.fetchTask.perform({
        forceServerRequest: true
      });
      var account = this.modelFor('account');
      return Ember.RSVP.hash({
        featureFlags: featureFlags,
        account: account
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.fetchRepositories.perform();
    }
  });

  _exports.default = _default;
});