define("travis/templates/components/build-message-line", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wfz7K2fL",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"yml-message-line\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"tooltip-wrapper level-icon\"],[8],[0,\"\\n    \"],[5,\"svg-image\",[],[[\"@name\",\"@class\"],[[23,0,[\"level\"]],[23,0,[\"iconClass\"]]]]],[0,\"\\n    \"],[5,\"ember-tooltip\",[],[[\"@text\"],[[23,0,[\"tooltipText\"]]]]],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"message\"],[8],[0,\"\\n    \"],[1,[23,0,[\"message\"]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[5,\"external-link-to\",[[12,\"href\",[29,[[28,\"config-get\",[\"urls.buildConfigValidation\"],null],\"#\",[23,0,[\"code\"]]]]],[12,\"class\",\"message-help\"]],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"svg-image\",[],[[\"@name\",\"@class\"],[\"icon-help\",\"icon-help\"]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/build-message-line.hbs"
    }
  });

  _exports.default = _default;
});