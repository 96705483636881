define("travis/serializers/organization", ["exports", "travis/serializers/v3"], function (_exports, _v) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _v.default.extend({
    attrs: {
      permissions: {
        key: '@permissions'
      }
    }
  });

  _exports.default = _default;
});