define("travis/templates/insufficient-oauth-permissions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qsJa72yA",
    "block": "{\"symbols\":[],\"statements\":[[5,\"travis-layout\",[],[[\"@layoutName\"],[\"layouts/center\"]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"content-page\"],[8],[0,\"\\n    \"],[7,\"img\",true],[10,\"src\",\"/images/travis-crying.png\"],[10,\"alt\",\"Sad Travis\"],[10,\"class\",\"sad-travis\"],[10,\"width\",\"200\"],[8],[9],[0,\"\\n    \"],[7,\"h1\",true],[10,\"class\",\"content-title\"],[8],[0,\"\\n      Sorry, something went missing.\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"content-text\"],[8],[0,\"\\n      \"],[7,\"p\",true],[8],[0,\"\\n        It looks like we don't have sufficient privileges for your GitHub account.\\n        This can happen for different reasons, most commonly due to an older token\\n        stored on our end, or because some of the permissions have been removed\\n        from the token. Unfortunately we need those to provide you with the best service.\\n      \"],[9],[0,\"\\n      \"],[7,\"p\",true],[8],[0,\"\\n        Luckily, the issue can be easily corrected by going through the login flow\\n        once again. Make sure the permissions we ask for are all available.\\n      \"],[9],[0,\"\\n      \"],[7,\"p\",true],[8],[0,\"\\n        For more information about the OAuth scopes and what we need them for,\\n        \"],[5,\"external-link-to\",[],[[\"@href\"],[\"http://docs.travis-ci.com/user/github-oauth-scopes/\"]],{\"statements\":[[0,\"\\n          check our documentation on this topic\\n        \"]],\"parameters\":[]}],[0,\".\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/insufficient-oauth-permissions.hbs"
    }
  });

  _exports.default = _default;
});