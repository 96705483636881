define("travis/utils/job-config-language", ["exports", "travis/utils/keys-map"], function (_exports, _keysMap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = jobConfigLanguage;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function jobConfigLanguage(config) {
    var gemfile, key, languageName, output;
    output = [];
    var completedLanguageNames = [];

    if (config) {
      for (key in _keysMap.languageConfigKeys) {
        languageName = _keysMap.languageConfigKeys[key];
        var version = config[key];

        if (version) {
          // special case for Dart lang's Task key
          if (_typeof(version) === 'object' && version.test) {
            version = version.test;
          }

          if (languageName === 'Android') {
            output.push('Android');
          } else if (version instanceof Object) {
            output.push(languageName);
          } else {
            output.push("".concat(languageName, ": ").concat(version));
          }

          completedLanguageNames.push(languageName);
        }
      }

      gemfile = config.gemfile;

      if (gemfile && config.env) {
        output.push("Gemfile: ".concat(gemfile));
      }

      if (config.language) {
        languageName = _keysMap.languageConfigKeys[config.language];

        if (languageName && completedLanguageNames.indexOf(languageName) === -1) {
          output.push(_keysMap.languageConfigKeys[config.language]);
        }
      }
    }

    return output.join(' ');
  }
});