define("travis/templates/components/ui-kit/grid-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vIQZbgdo",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[5,\"ui-kit/box\",[[12,\"class\",[23,0,[\"allClasses\"]]],[13,1]],[[\"@tag\",\"@display\",\"@borderColor\",\"@borderWidth\",\"@margin\",\"@padding\"],[[23,0,[\"tag\"]],[23,0,[\"display\"]],[23,0,[\"borderColor\"]],[23,0,[\"borderWidth\"]],[23,0,[\"margin\"]],[23,0,[\"padding\"]]]],{\"statements\":[[14,2]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/ui-kit/grid-item.hbs"
    }
  });

  _exports.default = _default;
});