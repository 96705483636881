define("travis/components/insights-date-display", ["exports", "moment", "travis/services/insights"], function (_exports, _moment, _insights) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.INSIGHTS_DATE_RANGE_PLACEHOLDER = _exports.INSIGHTS_DATE_RANGE_FORMAT = void 0;
  var INSIGHTS_DATE_RANGE_FORMAT = 'MMMM DD, YYYY';
  _exports.INSIGHTS_DATE_RANGE_FORMAT = INSIGHTS_DATE_RANGE_FORMAT;
  var INSIGHTS_DATE_RANGE_PLACEHOLDER = '...';
  _exports.INSIGHTS_DATE_RANGE_PLACEHOLDER = INSIGHTS_DATE_RANGE_PLACEHOLDER;

  var _default = Ember.Component.extend({
    tagName: 'span',
    classNames: ['insights-dates', 'snapshot-hide'],
    insights: Ember.inject.service(),
    interval: _insights.DEFAULT_INSIGHTS_INTERVAL,
    dates: Ember.computed('interval', function () {
      return this.insights.getDatesFromInterval(this.interval);
    }),
    startDate: Ember.computed('dates.firstObject', function () {
      var _this$dates = this.dates,
          dates = _this$dates === void 0 ? [] : _this$dates;
      return dates.firstObject ? (0, _moment.default)(dates.firstObject).format(INSIGHTS_DATE_RANGE_FORMAT) : INSIGHTS_DATE_RANGE_PLACEHOLDER;
    }),
    endDate: Ember.computed('dates.lastObject', function () {
      var _this$dates2 = this.dates,
          dates = _this$dates2 === void 0 ? [] : _this$dates2;
      return dates.lastObject ? (0, _moment.default)(dates.lastObject).format(INSIGHTS_DATE_RANGE_FORMAT) : INSIGHTS_DATE_RANGE_PLACEHOLDER;
    })
  });

  _exports.default = _default;
});