define("travis/templates/components/build-layout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "04mUfbCK",
    "block": "{\"symbols\":[],\"statements\":[[5,\"build-wrapper\",[],[[\"@build\"],[[23,0,[\"build\"]]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,0,[\"loading\"]]],null,{\"statements\":[[0,\"    \"],[5,\"loading-indicator\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,0,[\"build\"]]],null,{\"statements\":[[0,\"    \"],[5,\"build-header\",[],[[\"@item\",\"@commit\",\"@repo\"],[[23,0,[\"build\"]],[23,0,[\"build\",\"commit\"]],[23,0,[\"repo\"]]]]],[0,\"\\n\"],[4,\"if\",[[23,0,[\"noJobsError\"]]],null,{\"statements\":[[0,\"      \"],[7,\"p\",true],[10,\"class\",\"notice-banner--red\"],[8],[0,\"\\n        Sorry, we're having troubles fetching jobs. Please try again later.\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,0,[\"build\",\"isMatrix\"]]],null,{\"statements\":[[0,\"      \"],[5,\"build-tabs\",[],[[\"@build\"],[[23,0,[\"build\"]]]]],[0,\"\\n\"],[4,\"if\",[[23,0,[\"jobsLoaded\"]]],null,{\"statements\":[],\"parameters\":[]},{\"statements\":[[0,\"        \"],[5,\"loading-indicator\",[],[[\"@center\"],[true]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[5,\"job-infrastructure-notification\",[],[[\"@job\"],[[23,0,[\"job\"]]]]],[0,\"\\n      \"],[5,\"job-tabs\",[],[[\"@job\",\"@repo\"],[[23,0,[\"job\"]],[23,0,[\"repo\"]]]]],[0,\"\\n      \"],[5,\"job-log\",[],[[\"@job\"],[[23,0,[\"job\"]]]]],[0,\"\\n    \"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[5,\"no-builds\",[],[[],[]]],[0,\"\\n  \"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/build-layout.hbs"
    }
  });

  _exports.default = _default;
});