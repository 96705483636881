define("travis/mixins/route/owner/repositories", ["exports", "travis/utils/dynamic-query"], function (_exports, _dynamicQuery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PAGE_CHANGED = _dynamicQuery.EVENTS.PAGE_CHANGED;

  var _default = Ember.Mixin.create({
    owner: null,
    appsPage: 1,
    legacyPage: 1,
    queryParams: {
      'apps-page': {
        refreshModel: true
      },
      'legacy-page': {
        refreshModel: true
      }
    },
    model: function model(params) {
      this.legacyPage = params['legacy-page'];
      this.appsPage = params['apps-page'];
    },
    afterModel: function afterModel() {
      var owner = this.owner;

      if (owner && !owner.error) {
        owner.legacyRepositories.switchToPage(this.legacyPage);
        owner.githubAppsRepositories.switchToPage(this.appsPage);
      }
    },
    redirect: function redirect() {
      var _this = this;

      var owner = this.owner;

      if (owner && !owner.error) {
        this.owner.legacyRepositories.on(PAGE_CHANGED, function (page) {
          var queryParams = {
            'legacy-page': page
          };

          _this.transitionTo({
            queryParams: queryParams
          });
        });
        this.owner.githubAppsRepositories.on(PAGE_CHANGED, function (page) {
          var queryParams = {
            'apps-page': page
          };

          _this.transitionTo({
            queryParams: queryParams
          });
        });
      }
    },
    setupController: function setupController(controller, model) {
      var owner = this.owner;

      if (owner && !owner.error) {
        var login = owner.login;
        controller.setProperties({
          owner: owner,
          login: login
        });
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});