define("travis/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "piJuvB7h",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[4,\"let\",[[23,0,[\"features\"]]],null,{\"statements\":[[0,\"  \"],[5,\"head-layout\",[],[[],[]]],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[\"feature-wrapper \",[28,\"if\",[[23,1,[\"dashboard\"]],\"dashboard\"],null],\" \",[28,\"if\",[[23,1,[\"pride\"]],\"travis-pride\"],null],\" \",[28,\"if\",[[23,1,[\"comicSans\"]],\"travis-comicsans\"],null]]]],[8],[0,\"\\n    \"],[1,[22,\"outlet\"],false],[0,\"\\n    \"],[5,\"page-footer\",[],[[],[]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"if\",[[23,1,[\"tracer\"]]],null,{\"statements\":[[0,\"    \"],[5,\"x-tracer\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[5,\"intercom-io\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/application.hbs"
    }
  });

  _exports.default = _default;
});