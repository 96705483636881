define("travis/templates/components/no-account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3bTlXzsm",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"missing-notice\"],[8],[0,\"\\n  \"],[7,\"figure\",true],[10,\"class\",\"page-graphic\"],[8],[0,\"\\n    \"],[5,\"svg-image\",[],[[\"@name\"],[\"barricade\"]]],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"h2\",true],[10,\"class\",\"page-title no-account\"],[8],[0,\"\\n    We couldn't find the \"],[1,[28,\"vcs-vocab\",[[23,0,[\"vcsType\"]],\"organization\"],[[\"lower\"],[true]]],false],[7,\"br\",true],[8],[9],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"h2--red\"],[8],[0,\"\\n      \"],[1,[23,0,[\"name\"]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"p\",true],[10,\"class\",\"helptext\"],[8],[0,\"\\n    The \"],[1,[28,\"vcs-vocab\",[[23,0,[\"vcsType\"]],\"organization\"],[[\"lower\"],[true]]],false],[0,\" may not exist or you may not have access to it.\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/no-account.hbs"
    }
  });

  _exports.default = _default;
});