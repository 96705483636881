define("travis/components/subscription-status-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'p',
    classNames: ['subscription-status'],
    classNameBindings: ['bannerColor'],
    bannerColor: Ember.computed('color', function () {
      return "notice-banner--".concat(this.color);
    })
  });

  _exports.default = _default;
});