define("travis/services/storage/utm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var storage = window.localStorage;

  var _default = Ember.Service.extend({
    campaign: storageComputed('travis.utm.campaign'),
    content: storageComputed('travis.utm.content'),
    medium: storageComputed('travis.utm.medium'),
    source: storageComputed('travis.utm.source'),
    term: storageComputed('travis.utm.term')
  });

  _exports.default = _default;

  function storageComputed(key) {
    return Ember.computed({
      get: function get() {
        return storage.getItem(key) || '';
      },
      set: function set(_, value) {
        if (value) {
          storage.setItem(key, value);
        } else {
          storage.removeItem(key);
        }

        return value;
      }
    });
  }
});