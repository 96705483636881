define("travis/services/polling", ["exports", "travis/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    pollingInterval: 30000,
    ajaxPolling: true,
    init: function init() {
      var _this = this;

      var interval;

      this._super.apply(this, arguments);

      this.set('watchedModels', []);
      this.set('sources', []);
      interval = setInterval(function () {
        if (_environment.default.ajaxPolling) {
          _this.poll();
        }
      }, this.pollingInterval);
      this.set('interval', interval);
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      var interval = this.interval;

      if (interval) {
        return clearInterval(interval);
      }
    },
    startPollingHook: function startPollingHook(source) {
      var sources;
      sources = this.sources;

      if (!sources.includes(source)) {
        return sources.pushObject(source);
      }
    },
    stopPollingHook: function stopPollingHook(source) {
      var sources;
      sources = this.sources;
      return sources.removeObject(source);
    },
    startPolling: function startPolling(model) {
      var watchedModels;
      watchedModels = this.watchedModels;

      if (!watchedModels.includes(model)) {
        return watchedModels.pushObject(model);
      }
    },
    stopPolling: function stopPolling(model) {
      var watchedModels;
      watchedModels = this.watchedModels;
      return watchedModels.removeObject(model);
    },
    poll: function poll() {
      var _this2 = this;

      this.watchedModels.forEach(function (model) {
        return model.reload();
      });
      return this.sources.forEach(function (source) {
        if (Ember.get(source, 'isDestroyed')) {
          return _this2.sources.removeObject(source);
        } else {
          return source.pollHook();
        }
      });
    }
  });

  _exports.default = _default;
});