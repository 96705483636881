define("travis/adapters/job", ["exports", "travis/adapters/v3"], function (_exports, _v) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _v.default.extend({
    includes: 'build.request,build.commit,build.created_by',
    coalesceFindRequests: true,
    groupRecordsForFindMany: function groupRecordsForFindMany(store, snapshots) {
      var jobsByBuildId = {};
      var jobsWithoutBuildId = [];
      snapshots.forEach(function (snapshot) {
        var build = snapshot.belongsTo('build');

        if (build) {
          if (!jobsByBuildId[build.id]) {
            jobsByBuildId[build.id] = [];
          }

          jobsByBuildId[build.id].push(snapshot);
        } else {
          jobsWithoutBuildId.push([snapshot]);
        }
      });
      return Object.values(jobsByBuildId).concat(jobsWithoutBuildId);
    },
    findMany: function findMany(store, modelClass, ids, snapshots) {
      var build = snapshots[0].belongsTo('build');

      if (build) {
        return this.ajax("".concat(this.buildURL(), "/build/").concat(build.id, "/jobs"));
      } else {
        return this.ajax("".concat(this.buildURL(), "/job/").concat(snapshots[0].id));
      }
    }
  });

  _exports.default = _default;
});