define("travis/models/trial", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    buildsRemaining: (0, _model.attr)(),
    owner: (0, _model.belongsTo)('owner', {
      polymorphic: true
    }),
    permissions: (0, _model.attr)(),
    status: (0, _model.attr)(),
    type: (0, _model.attr)(),
    isNew: Ember.computed.equal('status', 'new'),
    isStarted: Ember.computed.equal('status', 'started'),
    isEnded: Ember.computed.equal('status', 'ended'),
    hasActiveTrial: Ember.computed.or('isNew', 'isStarted')
  });

  _exports.default = _default;
});