define("travis/routes/settings", ["exports", "travis/routes/basic", "travis/config/environment"], function (_exports, _basic, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basic.default.extend({
    api: Ember.inject.service(),
    auth: Ember.inject.service(),
    permissions: Ember.inject.service(),
    raven: Ember.inject.service(),
    flashes: Ember.inject.service(),
    needsAuth: true,
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('repo', this.modelFor('repo'));
      this.controllerFor('repo').activate('settings');
      return controller.set('concurrentBuildsLimit', !!model.settings.maximum_number_of_builds);
    },
    fetchEnvVars: function fetchEnvVars() {
      var repo = this.modelFor('repo');
      return repo.get('envVars.promise');
    },
    fetchCustomSshKey: function fetchCustomSshKey() {
      if (_environment.default.endpoints.sshKey) {
        var repo = this.modelFor('repo');
        return this.store.find('ssh_key', repo.get('id')).then(function (result) {
          if (!result.get('isNew')) {
            return result;
          }
        }, function (xhr) {
          if (xhr.status === 404) {
            return false;
          }
        });
      }
    },
    fetchSshKey: function fetchSshKey() {
      var _this = this;

      if (_environment.default.endpoints.sshKey) {
        var repo = this.modelFor('repo');
        var url = "/repos/".concat(repo.get('id'), "/key");
        return this.api.get(url, {
          travisApiVersion: null
        }).then(function (data) {
          var fingerprint = Ember.Object.create({
            fingerprint: data.fingerprint
          });
          return fingerprint;
        }).catch(function (e) {
          // 404s happen regularly and are unremarkable
          if (e.status !== 404) {
            _this.raven.logException(e);
          }
        });
      }
    },
    fetchRepositoryActiveFlag: function fetchRepositoryActiveFlag() {
      var repoId = this.modelFor('repo').get('id');
      return this.api.get("/repo/".concat(repoId)).then(function (response) {
        return response.active;
      });
    },
    beforeModel: function beforeModel() {
      var repo = this.modelFor('repo');
      var hasPushPermission = this.permissions.hasPushPermission(repo);

      if (!hasPushPermission) {
        this.transitionTo('repo.index');
        this.flashes.error('Your permissions are insufficient to access this repository\'s settings');
      }
    },
    model: function model() {
      var repo = this.modelFor('repo');
      return Ember.RSVP.hash({
        settings: repo.fetchSettings.perform(),
        repository: repo,
        envVars: this.fetchEnvVars(),
        sshKey: this.fetchSshKey(),
        customSshKey: this.fetchCustomSshKey(),
        hasPushAccess: this.permissions.hasPushPermission(repo),
        repositoryActive: this.fetchRepositoryActiveFlag()
      });
    }
  });

  _exports.default = _default;
});