define("travis/templates/job", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kpJ00f1b",
    "block": "{\"symbols\":[],\"statements\":[[5,\"job-wrapper\",[],[[\"@repo\",\"@job\"],[[23,0,[\"repo\"]],[23,0,[\"job\"]]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,0,[\"job\",\"isLoaded\"]]],null,{\"statements\":[[0,\"    \"],[5,\"build-header\",[],[[\"@item\",\"@commit\",\"@repo\"],[[23,0,[\"job\"]],[23,0,[\"job\",\"commit\"]],[23,0,[\"repo\"]]]]],[0,\"\\n    \"],[5,\"job-infrastructure-notification\",[],[[\"@job\"],[[23,0,[\"job\"]]]]],[0,\"\\n    \"],[5,\"job-tabs\",[],[[\"@job\",\"@repo\"],[[23,0,[\"job\"]],[23,0,[\"repo\"]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"id\",\"job\"],[8],[0,\"\\n      \"],[5,\"loading-indicator\",[],[[],[]]],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/job.hbs"
    }
  });

  _exports.default = _default;
});