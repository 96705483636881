define("travis/components/insights-glance", ["exports", "d3"], function (_exports, _d) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var _default = Ember.Component.extend({
    classNames: ['insights-glance'],
    classNameBindings: ['isLoading:insights-glance--loading', 'isReverseDeltaColors:insights-glance--reverse-delta-colors'],
    isLoading: true,
    isEmpty: false,
    isReverseDeltaColors: false,
    title: '',
    statistic: '',
    delta: 0,
    deltaTitle: '',
    deltaText: '',
    labels: Ember.computed(function () {
      return [];
    }),
    values: Ember.computed(function () {
      return [];
    }),
    datasetTitle: 'Data',
    centerline: null,
    showPlaceholder: Ember.computed.or('isLoading', 'isEmpty'),
    // Chart component data
    data: Ember.computed('values.[]', 'labels.[]', 'datasetTitle', function () {
      return {
        type: 'spline',
        x: 'x',
        columns: [['x'].concat(_toConsumableArray(this.labels)), [this.datasetTitle].concat(_toConsumableArray(this.values))],
        colors: _defineProperty({}, this.datasetTitle, '#666')
      };
    }),
    // Chart component options
    legend: Ember.computed(function () {
      return {
        show: false
      };
    }),
    size: Ember.computed(function () {
      return {
        height: 50
      };
    }),
    point: Ember.computed(function () {
      return {
        r: 0,
        focus: {
          expand: {
            r: 4
          }
        }
      };
    }),
    axis: Ember.computed(function () {
      return {
        x: {
          type: 'timeseries',
          tick: {
            format: '%A, %b %e'
          },
          show: false
        },
        y: {
          show: false
        }
      };
    }),
    tooltip: Ember.computed(function () {
      return {
        position: function position(data, width, height, element) {
          var top = -50;
          var left = (element.getAttribute('width') - width) / 2;
          return {
            top: top,
            left: left
          };
        },
        format: {
          value: (0, _d.format)(',')
        }
      };
    }),
    grid: Ember.computed('centerline', function () {
      var grid = {
        lines: {
          front: false
        }
      };

      if (this.centerline) {
        grid.y = {
          lines: [{
            value: this.centerline,
            class: 'insights-glance__centerline'
          }]
        };
      }

      return grid;
    })
  });

  _exports.default = _default;
});