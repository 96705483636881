define("travis/templates/components/beta-feature", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "90afFkfr",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[8],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"name\"],[8],[0,\"\\n    \"],[1,[23,0,[\"feature\",\"displayName\"]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"p\",true],[10,\"class\",\"description\"],[8],[0,\"\\n    \"],[1,[23,0,[\"feature\",\"description\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[5,\"feature-toggle\",[],[[\"@feature\"],[[23,0,[\"feature\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/beta-feature.hbs"
    }
  });

  _exports.default = _default;
});