define("travis/utils/lines-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var SELECTORS = {
    LOG_LINE: '.log-line'
  };

  var _default = function () {
    LinesSelector.prototype.Location = {
      getHash: function getHash() {
        return window.location.hash;
      },
      setHash: function setHash(hash) {
        var path;
        path = "".concat(window.location.pathname).concat(hash);
        return window.history.pushState({
          path: path
        }, null, path);
      }
    };
    LinesSelector.prototype.element = null;
    LinesSelector.prototype.scroll = null;
    LinesSelector.prototype.location = null;
    LinesSelector.prototype.view = null;
    LinesSelector.prototype.last_selected_line = null;

    function LinesSelector(element1, scroll, folder, location, onLogLineClick) {
      var _this = this;

      this.element = element1;
      this.scroll = scroll;
      this.folder = folder;
      this.location = location || this.Location;
      Ember.run.scheduleOnce('afterRender', this, function () {
        var ref;
        this.last_selected_line = (ref = this.getSelectedLines()) != null ? ref.first : void 0;
        return this.highlightLines();
      });
      this.element.addEventListener('click', function (event) {
        if (event.target.tagName === 'A') {
          var callback = function callback() {
            var parentEl = event.target.parentNode;

            _this.loadLineNumbers(parentEl, event.shiftKey);
          };

          if (onLogLineClick) {
            onLogLineClick().then(callback);
          } else {
            callback();
          }

          event.preventDefault();
        }
      });
    }

    LinesSelector.prototype.willDestroy = function () {
      return this.destroyed = true;
    };

    LinesSelector.prototype.loadLineNumbers = function (element, multiple) {
      this.setHashValueWithLine(element, multiple);
      return this.highlightLines();
    };

    LinesSelector.prototype.highlightLines = function (tries) {
      tries = tries || 0;
      this.removeAllHighlights();
      var lines = this.getSelectedLines();

      if (lines) {
        var logLines = this.getLogLinesArray();
        var elements = logLines.slice(lines.first - 1, lines.last);

        if (elements.length) {
          elements.forEach(function (el) {
            return el.classList.add('highlight');
          });
          var focusElement = elements[0];
          focusElement.setAttribute('tabindex', '0');
          focusElement.focus();
          focusElement.removeAttribute('tabindex');
        } else if (tries < 4) {
          Ember.run.later(this, function () {
            if (!this.destroyed) {
              return this.highlightLines(tries + 1);
            }
          }, 500);
          return;
        }
      }

      this.scroll.tryScroll();
      return this.unfoldLines();
    };

    LinesSelector.prototype.unfoldLines = function () {
      var index, l, line, results;
      var lines = this.getSelectedLines();

      if (lines) {
        results = [];
        var logLines = this.getLogLinesArray();

        for (index in lines) {
          l = lines[index];
          line = logLines.slice(l - 1, l);
          results.push(this.folder.unfold(line));
        }

        return results;
      }
    };

    LinesSelector.prototype.setHashValueWithLine = function (line, multiple) {
      var hash, lineNumber, lines;
      lineNumber = this.getLineNumberFromElement(line);

      if (multiple && this.last_selected_line != null) {
        lines = [lineNumber, this.last_selected_line].sort(function (a, b) {
          return a - b;
        });
        hash = "#L".concat(lines[0], "-L").concat(lines[1]);
      } else {
        hash = "#L".concat(lineNumber);
      }

      this.last_selected_line = lineNumber;
      return this.location.setHash(hash);
    };

    LinesSelector.prototype.getLineNumberFromElement = function (element) {
      if (this && this.element) {
        var logLines = this.getLogLinesArray();
        var index = logLines.indexOf(element);
        return index + 1;
      }
    };

    LinesSelector.prototype.getLogLinesArray = function () {
      if (this && this.element) {
        var allLines = this.element.querySelectorAll(SELECTORS.LOG_LINE);
        return _toConsumableArray(allLines);
      }

      return [];
    };

    LinesSelector.prototype.removeAllHighlights = function () {
      if (this && this.element) {
        return this.element.querySelectorAll('.log-line.highlight').forEach(function (hiElf) {
          return hiElf.classList.remove('highlight');
        });
      }
    };

    LinesSelector.prototype.getSelectedLines = function () {
      var match = this.location.getHash().match(/#L(\d+)(-L(\d+))?$/);

      if (match) {
        var first = match[1];
        var last = match[3] || match[1];
        return {
          first: first,
          last: last
        };
      }
    };

    return LinesSelector;
  }();

  _exports.default = _default;
});