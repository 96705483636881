define("travis/adapters/application", ["exports", "travis/config/environment", "@ember-data/adapter/rest"], function (_exports, _environment, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rest.default.extend({
    auth: Ember.inject.service(),
    features: Ember.inject.service(),
    host: _environment.default.apiEndpoint,
    coalesceFindRequests: true,
    // Before Ember Data 2.0 the default behaviour of running `findAll` was to get
    // new records only when there're no records in the store. This will change
    // to a different strategy in 2.0: when you run `findAll` it will not get any
    // new data initially, but it will try loading new data in the background.
    //
    // I'm disabling the new behaviour for now.
    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
      return false;
    },
    ajaxOptions: function ajaxOptions() {
      var hash = this._super.apply(this, arguments);

      hash.headers = hash.headers || {};
      hash.headers['accept'] = 'application/json; version=2';
      hash.headers['X-Client-Release'] = _environment.default.release;
      var token = this.get('auth.token');

      if (token) {
        if (!hash.headers['Authorization']) {
          hash.headers['Authorization'] = "token ".concat(token);
        }
      }

      return hash;
    },
    findMany: function findMany(store, type, ids) {
      return this.ajax(this.buildURL(type.modelName), 'GET', {
        data: {
          ids: ids
        }
      });
    },
    handleResponse: function handleResponse(status, headers, payload) {
      if (status > 299) {
        if (this.get('features.debugLogging')) {
          // eslint-disable-next-line
          console.log("[ERROR] API responded with an error (" + status + "): " + JSON.stringify(payload));
        }
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});