define("travis/components/running-jobs", ["exports", "travis/config/environment", "visibilityjs"], function (_exports, _environment, _visibilityjs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    updateTimesService: Ember.inject.service('updateTimes'),
    runningJobs: Ember.computed.reads('jobs'),
    init: function init() {
      this._super.apply(this, arguments);

      if (!Ember.testing) {
        return _visibilityjs.default.every(_environment.default.intervals.updateTimes, this.updateTimes.bind(this));
      }
    },
    updateTimes: function updateTimes() {
      this.updateTimesService.push(this.jobs);
    }
  });

  _exports.default = _default;
});