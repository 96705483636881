define("travis/serializers/branch", ["exports", "travis/serializers/v2_fallback"], function (_exports, _v2_fallback) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _v2_fallback.default.extend({
    extractAttributes: function extractAttributes(klass, payload) {
      payload.id = payload['@href'];
      return this._super.apply(this, arguments);
    },
    extractId: function extractId(modelClass, resourceHash) {
      return resourceHash.id || resourceHash['@href'];
    }
  });

  _exports.default = _default;
});