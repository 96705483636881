define("travis/routes/github-apps-installation", ["exports", "travis/routes/simple-layout"], function (_exports, _simpleLayout) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _simpleLayout.default.extend({
    auth: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      if (!this.auth.signedIn) {
        this.set('auth.afterSignInTransition', transition);
        return Ember.RSVP.reject('needs-auth');
      }
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.startPolling();
    }
  });

  _exports.default = _default;
});