define("travis/components/getting-started-step", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    number: null,
    provider: null,
    providerImg: false,
    title: '',
    smDir: Ember.computed('number', function () {
      var number = this.number;
      return number && number % 2 === 1 ? 'row' : 'row-reverse';
    }),
    imageName: Ember.computed('number', 'provider', 'providerImg', function () {
      var number = this.number,
          provider = this.provider,
          providerImg = this.providerImg;
      var affix = providerImg ? "-".concat(provider) : '';
      return "getting-started-step-".concat(number).concat(affix);
    })
  });

  _exports.default = _default;
});