define("travis/serializers/application", ["exports", "travis/serializers/v2_fallback"], function (_exports, _v2_fallback) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _v2_fallback.default.extend({});

  _exports.default = _default;
});