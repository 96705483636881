define("travis/components/job-wrapper", ["exports", "travis/utils/color-for-state"], function (_exports, _colorForState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    pollModels: 'job.build',
    color: Ember.computed('job.state', function () {
      var jobState = this.get('job.state');
      return (0, _colorForState.default)(jobState);
    })
  });

  _exports.default = _default;
});