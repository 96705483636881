define("travis/utils/job-config-arch", ["exports", "travis/utils/keys-map"], function (_exports, _keysMap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = jobConfigArch;

  function jobConfigArch(config) {
    var _ref = config || {},
        arch = _ref.arch,
        os = _ref.os; // Previously it was possible to choose ppc64le arch only by setting `os: linux-ppc64le` in the config.
    // We have introduced a separate `arch` config key, but many customers are still using the old one.
    // We need this hack until we deprecate `os: linux-ppc64le`.


    if (os === 'linux-ppc64le') {
      return 'ppc64le';
    }

    return _keysMap.archConfigKeys[arch || 'amd64'] || arch;
  }
});