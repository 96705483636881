define("travis/routes/getting-started", ["exports", "travis/routes/basic", "travis/mixins/tailwind-base"], function (_exports, _basic, _tailwindBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basic.default.extend(_tailwindBase.default, {});

  _exports.default = _default;
});