define("travis/controllers/repo", ["exports", "travis/utils/eventually", "visibilityjs", "travis/config/environment"], function (_exports, _eventually, _visibilityjs, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    auth: Ember.inject.service(),
    repositories: Ember.inject.service(),
    tabStates: Ember.inject.service(),
    features: Ember.inject.service(),
    updateTimesService: Ember.inject.service('updateTimes'),
    queryParams: ['migrationStatus'],
    migrationStatus: null,
    jobController: Ember.inject.controller('job'),
    buildController: Ember.inject.controller('build'),
    buildsController: Ember.inject.controller('builds'),
    repos: Ember.computed.alias('repositories.accessible'),
    currentUser: Ember.computed.alias('auth.currentUser'),
    build: Ember.computed.alias('buildController.build'),
    builds: Ember.computed.alias('buildsController.model'),
    job: Ember.computed.alias('jobController.job'),
    showGitHubApps: Ember.computed.alias('features.github-apps'),
    showGitHubAppsCTA: Ember.computed('showGitHubApps', 'repo.private', 'currentUser', function () {
      var showGitHubApps = this.showGitHubApps;
      var isPrivate = this.get('repo.private');
      var currentUser = this.currentUser;
      return showGitHubApps && !isPrivate && !currentUser;
    }),
    isCentered: Ember.computed('auth.signedIn', 'features.dashboard', function () {
      var isSignedIn = this.get('auth.signedIn');
      var isDashboard = this.get('features.dashboard');
      return !isSignedIn || isDashboard;
    }),
    config: _environment.default,
    classNames: ['repo'],
    reset: function reset() {
      this.set('repo', null);
    },
    isEmpty: Ember.computed('repos.isLoaded', 'repos.[]', function () {
      var loaded = this.get('repos.isLoaded');
      var repos = this.repos;
      return loaded && Ember.isEmpty(repos);
    }),
    init: function init() {
      this._super.apply(this, arguments);

      if (!Ember.testing) {
        _visibilityjs.default.every(_environment.default.intervals.updateTimes, this.updateTimes.bind(this));
      }
    },
    updateTimes: function updateTimes() {
      var updateTimesService = this.updateTimesService;
      updateTimesService.push(this.build);
      updateTimesService.push(this.builds);
      updateTimesService.push(this.get('build.jobs'));
    },
    deactivate: function deactivate() {
      return this.stopObservingLastBuild();
    },
    activate: function activate(action) {
      this.stopObservingLastBuild();
      var observesLastBuild = ['index', 'current'];

      if (observesLastBuild.includes(action)) {
        this.observeLastBuild();
        this.set('tabStates.mainTab', 'current');
      } else {
        this.set('tabStates.mainTab', action);
      }
    },
    currentBuildDidChange: function currentBuildDidChange() {
      return Ember.run.scheduleOnce('actions', this, this._currentBuildDidChange);
    },
    _currentBuildDidChange: function _currentBuildDidChange() {
      var _this = this;

      var currentBuild = this.get('repo.currentBuild');

      if (currentBuild && currentBuild.get('id')) {
        (0, _eventually.default)(currentBuild, function (build) {
          _this.set('build', build);

          if (build.get('jobs.length') === 1) {
            _this.set('job', build.get('jobs.firstObject'));
          }
        });
      }
    },
    stopObservingLastBuild: function stopObservingLastBuild() {
      return this.removeObserver('repo.currentBuild', this, 'currentBuildDidChange');
    },
    observeLastBuild: function observeLastBuild() {
      this.currentBuildDidChange();
      return this.addObserver('repo.currentBuild', this, 'currentBuildDidChange');
    }
  });

  _exports.default = _default;
});