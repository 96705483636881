define("travis/components/ui-kit/box", ["exports", "travis/utils/ui-kit/assertions", "travis/utils/ui-kit/prefix", "travis/utils/ui-kit/concat", "travis/components/ui-kit/text"], function (_exports, _assertions, _prefix, _concat, _text) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.FLEX_SIZE_VALS = _exports.FLEX_SIZES = _exports.DISPLAYS = _exports.COLORS = void 0;

  var _BG_COLORS, _BORDER_COLORS, _BORDER_WIDTHS, _FLEX_SIZE_VALS;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var COLORS = {
    WHITE: 'white',
    BLUE_LIGHT: 'blue-light',
    BLUE: 'blue',
    GREEN: 'green',
    GREY_LIGHTEST: 'grey-lightest',
    GREY_LIGHTER: 'grey-lighter',
    GREY_LIGHT: 'grey-light',
    GREY: 'grey',
    GREY_DARK: 'grey-dark',
    YELLOW_LIGHTER: 'yellow-lighter',
    YELLOW_LIGHT: 'yellow-light',
    RED: 'red-300',
    RED_LIGHT: 'red-90'
  };
  _exports.COLORS = COLORS;
  var BG_COLORS = (_BG_COLORS = {}, _defineProperty(_BG_COLORS, COLORS.WHITE, 'white'), _defineProperty(_BG_COLORS, COLORS.BLUE_LIGHT, 'blue-300'), _defineProperty(_BG_COLORS, COLORS.GREY_LIGHTEST, 'grey-100'), _defineProperty(_BG_COLORS, COLORS.GREY_LIGHTER, 'grey-150'), _defineProperty(_BG_COLORS, COLORS.GREY_LIGHT, 'grey-300'), _defineProperty(_BG_COLORS, COLORS.GREY_DARK, 'grey-800'), _defineProperty(_BG_COLORS, COLORS.BLUE, 'blue-400'), _defineProperty(_BG_COLORS, COLORS.YELLOW_LIGHT, 'yellow-200'), _defineProperty(_BG_COLORS, COLORS.YELLOW_LIGHTER, 'yellow-100'), _BG_COLORS);
  var BORDER_COLORS = (_BORDER_COLORS = {}, _defineProperty(_BORDER_COLORS, COLORS.WHITE, 'white'), _defineProperty(_BORDER_COLORS, COLORS.BLUE, 'blue-400'), _defineProperty(_BORDER_COLORS, COLORS.GREEN, 'green-300'), _defineProperty(_BORDER_COLORS, COLORS.GREY_LIGHT, 'grey-150'), _defineProperty(_BORDER_COLORS, COLORS.GREY, 'grey-700'), _defineProperty(_BORDER_COLORS, COLORS.GREY_DARK, 'grey-800'), _defineProperty(_BORDER_COLORS, COLORS.RED, 'red-300'), _defineProperty(_BORDER_COLORS, COLORS.RED_LIGHT, 'red-90'), _BORDER_COLORS);
  var WIDTHS = {
    NONE: 'none',
    XS: 'xs',
    SM: 'sm',
    MD: 'md'
  };
  var BORDER_WIDTHS = (_BORDER_WIDTHS = {}, _defineProperty(_BORDER_WIDTHS, WIDTHS.NONE, 'none'), _defineProperty(_BORDER_WIDTHS, WIDTHS.XS, 'px'), _defineProperty(_BORDER_WIDTHS, WIDTHS.SM, 'sm'), _defineProperty(_BORDER_WIDTHS, WIDTHS.MD, 'md'), _BORDER_WIDTHS);
  var DISPLAYS = {
    BLOCK: 'block',
    INLINE: 'inline',
    INLINE_BLOCK: 'inline-block',
    FLEX: 'flex'
  };
  _exports.DISPLAYS = DISPLAYS;
  var DEFAULT_DISPLAY = DISPLAYS.BLOCK;
  var LAYERS = {
    AUTO: 'auto',
    ZERO: '0',
    TEN: '10',
    TWENTY: '20',
    THIRTY: '30',
    FORTY: '40',
    FIFTY: '50'
  };
  var SHADOWS = {
    SM: 'sm',
    MD: 'md',
    NONE: 'none'
  };
  var RADII = {
    NONE: 'none',
    SM: 'sm',
    MD: 'md',
    LG: 'lg',
    FULL: 'full'
  };
  var OVERFLOWS = {
    AUTO: 'auto',
    HIDDEN: 'hidden'
  }; // Position

  var POSITION_TYPES = {
    STATIC: 'static',
    FIXED: 'fixed',
    ABSOLUTE: 'absolute',
    RELATIVE: 'relative',
    STICKY: 'sticky'
  };
  var POSITION_VALUES = {
    ZERO: 0,
    AUTO: 'auto'
  };
  var POSITION_INSETS = {
    ZERO: '0',
    X_ZERO: 'x-0',
    Y_ZERO: 'y-0',
    AUTO: 'auto',
    X_AUTO: 'x-auto',
    Y_AUTO: 'y-auto'
  };
  var PIN_LOCATIONS = {
    TOP_RIGHT: 'top-right',
    TOP_LEFT: 'top-left'
  }; // Height & Width

  var MAX_WIDTHS = {
    XS2: '2xs',
    XS: 'xs',
    SM: 'sm',
    MD: 'md',
    LG: 'lg',
    XL: 'xl',
    XL2: '2xl',
    XL3: '3xl',
    XL5: '5xl',
    XL6: '6xl',
    FULL: 'full'
  }; // Flexbox

  var FLEX_ALIGNMENTS = {
    STRETCH: 'stretch',
    START: 'start',
    CENTER: 'center',
    END: 'end',
    BASELINE: 'baseline'
  };
  var FLEX_JUSTIFICATIONS = {
    START: 'start',
    CENTER: 'center',
    END: 'end',
    BETWEEN: 'between',
    AROUND: 'around'
  };
  var FLEX_DIRECTIONS = {
    ROW: 'row',
    COL: 'col',
    ROW_REVERSE: 'row-reverse',
    COL_REVERSE: 'col-reverse'
  };
  var FLEX_WRAPS = {
    wrap: 'wrap',
    NO_WRAP: 'no-wrap'
  };
  var FLEX_SIZES = {
    GROW_SINGLE: 'grow-single',
    SHRINK_SINGLE: 'shrink-single',
    RESIZE_SINGLE: 'resize-single',
    NONE: 'none',
    SHRINK_ZERO: 'shrink-none'
  };
  _exports.FLEX_SIZES = FLEX_SIZES;
  var FLEX_SIZE_VALS = (_FLEX_SIZE_VALS = {}, _defineProperty(_FLEX_SIZE_VALS, FLEX_SIZES.GROW_SINGLE, 'grow-single'), _defineProperty(_FLEX_SIZE_VALS, FLEX_SIZES.SHRINK_SINGLE, 'shrink-single'), _defineProperty(_FLEX_SIZE_VALS, FLEX_SIZES.RESIZE_SINGLE, 'resize-single'), _defineProperty(_FLEX_SIZE_VALS, FLEX_SIZES.NONE, 'none'), _defineProperty(_FLEX_SIZE_VALS, FLEX_SIZES.SHRINK_ZERO, 'shrink-0'), _FLEX_SIZE_VALS); // Component definition

  _exports.FLEX_SIZE_VALS = FLEX_SIZE_VALS;

  var _default = Ember.Component.extend({
    tagName: '',
    // Public interface //
    tag: 'div',
    color: null,
    display: DEFAULT_DISPLAY,
    layer: null,
    overflow: null,
    pin: null,
    radius: null,
    shadow: null,
    textAlign: null,
    borderColor: null,
    borderWidth: null,
    width: null,
    maxWidth: null,
    height: null,
    margin: null,
    padding: null,
    position: null,
    flexAlign: null,
    flexJustify: null,
    flexDir: null,
    flexWrap: null,
    // Private //
    colorClass: (0, _prefix.default)('color', 'bg', {
      dictionary: BG_COLORS
    }),
    displayClass: (0, _prefix.default)('display', ''),
    layerClass: (0, _prefix.default)('layer', 'z'),
    overflowAllClass: (0, _prefix.default)('overflow.all', 'overflow'),
    overflowXClass: (0, _prefix.default)('overflow.x', 'overflow'),
    overflowYClass: (0, _prefix.default)('overflow.y', 'overflow'),
    pinClass: (0, _prefix.default)('pin', 'pin'),
    radiusClass: (0, _prefix.default)('radius', 'rounded'),
    shadowClass: (0, _prefix.default)('shadow', 'shadow'),
    textAlignClass: (0, _prefix.default)('textAlign', 'text'),
    widthClass: (0, _prefix.default)('width', 'w'),
    maxWidthClass: (0, _prefix.default)('maxWidth', 'max-w'),
    heightClass: (0, _prefix.default)('height', 'h'),
    // Position
    positionType: (0, _prefix.default)('position.type', ''),
    positionTop: (0, _prefix.default)('position.top', 'top'),
    positionRight: (0, _prefix.default)('position.right', 'right'),
    positionBottom: (0, _prefix.default)('position.bottom', 'bottom'),
    positionLeft: (0, _prefix.default)('position.left', 'left'),
    positionInset: (0, _prefix.default)('position.inset', 'inset'),
    // Border
    borderAllWidthClass: (0, _prefix.default)('borderWidth.all', 'border', {
      dictionary: BORDER_WIDTHS
    }),
    borderTopWidthClass: (0, _prefix.default)('borderWidth.top', 'border-t', {
      dictionary: BORDER_WIDTHS
    }),
    borderRightWidthClass: (0, _prefix.default)('borderWidth.right', 'border-r', {
      dictionary: BORDER_WIDTHS
    }),
    borderBottomWidthClass: (0, _prefix.default)('borderWidth.bottom', 'border-b', {
      dictionary: BORDER_WIDTHS
    }),
    borderLeftWidthClass: (0, _prefix.default)('borderWidth.left', 'border-l', {
      dictionary: BORDER_WIDTHS
    }),
    borderColorClass: (0, _prefix.default)('borderColor', 'border', {
      dictionary: BORDER_COLORS
    }),
    borderWidthClasses: (0, _concat.default)('borderAllWidthClass', 'borderTopWidthClass', 'borderRightWidthClass', 'borderBottomWidthClass', 'borderLeftWidthClass'),
    // Margin
    marginTop: (0, _prefix.default)('margin.top', 'mt', {
      negatable: true
    }),
    marginRight: (0, _prefix.default)('margin.right', 'mr', {
      negatable: true
    }),
    marginBottom: (0, _prefix.default)('margin.bottom', 'mb', {
      negatable: true
    }),
    marginLeft: (0, _prefix.default)('margin.left', 'ml', {
      negatable: true
    }),
    marginX: (0, _prefix.default)('margin.x', 'mx', {
      negatable: true
    }),
    marginY: (0, _prefix.default)('margin.y', 'my', {
      negatable: true
    }),
    marginAll: (0, _prefix.default)('margin.all', 'm', {
      negatable: true
    }),
    marginClasses: (0, _concat.default)('marginTop', 'marginRight', 'marginBottom', 'marginLeft', 'marginX', 'marginY', 'marginAll'),
    // Padding
    paddingTop: (0, _prefix.default)('padding.top', 'pt'),
    paddingRight: (0, _prefix.default)('padding.right', 'pr'),
    paddingBottom: (0, _prefix.default)('padding.bottom', 'pb'),
    paddingLeft: (0, _prefix.default)('padding.left', 'pl'),
    paddingX: (0, _prefix.default)('padding.x', 'px'),
    paddingY: (0, _prefix.default)('padding.y', 'py'),
    paddingAll: (0, _prefix.default)('padding.all', 'p'),
    paddingClasses: (0, _concat.default)('paddingTop', 'paddingRight', 'paddingBottom', 'paddingLeft', 'paddingX', 'paddingY', 'paddingAll'),
    // Flex
    flexAlignClass: (0, _prefix.default)('flexAlign', 'items'),
    flexJustifyClass: (0, _prefix.default)('flexJustify', 'justify'),
    flexDirClass: (0, _prefix.default)('flexDir', 'flex'),
    flexWrapClass: (0, _prefix.default)('flexWrap', 'flex'),
    flexClass: (0, _prefix.default)('flex', 'flex', {
      dictionary: FLEX_SIZE_VALS
    }),
    // Collected classes
    allClasses: (0, _concat.default)('colorClass', 'displayClass', 'layerClass', 'overflowAllClass', 'overflowXClass', 'overflowYClass', 'pinClass', 'radiusClass', 'shadowClass', 'textAlignClass', 'widthClass', 'maxWidthClass', 'heightClass', 'positionType', 'positionTop', 'positionRight', 'positionBottom', 'positionLeft', 'positionInset', 'borderColorClass', 'borderWidthClasses', 'marginClasses', 'paddingClasses', 'flexAlignClass', 'flexJustifyClass', 'flexDirClass', 'flexWrapClass', 'flexClass'),
    // Lifecycle
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      (0, _assertions.checkDictionary)(this.color, COLORS, '@color', 'Box');
      (0, _assertions.checkDictionary)(this.display, DISPLAYS, '@display', 'Box');
      (0, _assertions.checkDictionary)(this.layer, LAYERS, '@layer', 'Box');
      (0, _assertions.checkDictionary)(this.pin, PIN_LOCATIONS, '@pin', 'Box');
      (0, _assertions.checkDictionary)(this.radius, RADII, '@radius', 'Box');
      (0, _assertions.checkDictionary)(this.shadow, SHADOWS, '@shadow', 'Box');
      (0, _assertions.checkDictionary)(this.textAlign, _text.ALIGNMENTS, '@textAlign', 'Box');
      (0, _assertions.checkDictionary)(this.maxWidth, MAX_WIDTHS, '@maxWidth', 'Box');

      var _ref = this.position || {},
          top = _ref.top,
          right = _ref.right,
          bottom = _ref.bottom,
          left = _ref.left,
          inset = _ref.inset,
          type = _ref.type;

      (0, _assertions.checkDictionary)(type, POSITION_TYPES, '@position.type', 'Box');
      (0, _assertions.checkDictionary)(top, POSITION_VALUES, '@position.top', 'Box');
      (0, _assertions.checkDictionary)(right, POSITION_VALUES, '@position.right', 'Box');
      (0, _assertions.checkDictionary)(bottom, POSITION_VALUES, '@position.bottom', 'Box');
      (0, _assertions.checkDictionary)(left, POSITION_VALUES, '@position.left', 'Box');
      (0, _assertions.checkDictionary)(inset, POSITION_INSETS, '@position.inset', 'Box');

      var _ref2 = this.overflow || {},
          all = _ref2.all,
          x = _ref2.x,
          y = _ref2.y;

      (0, _assertions.checkDictionary)(all, OVERFLOWS, '@overflow.all', 'Box');
      (0, _assertions.checkDictionary)(x, OVERFLOWS, '@overflow.x', 'Box');
      (0, _assertions.checkDictionary)(y, OVERFLOWS, '@overflow.y', 'Box');
      (0, _assertions.checkDictionary)(this.borderColor, COLORS, '@borderColor');
      var bw = this.borderWidth || {};
      (0, _assertions.checkDictionary)(bw.top, WIDTHS, '@borderWidth.top');
      (0, _assertions.checkDictionary)(bw.right, WIDTHS, '@borderWidth.right');
      (0, _assertions.checkDictionary)(bw.bottom, WIDTHS, '@borderWidth.bottom');
      (0, _assertions.checkDictionary)(bw.left, WIDTHS, '@borderWidth.left');
      (0, _assertions.checkDictionary)(bw.all, WIDTHS, '@borderWidth.all');
      (0, _assertions.checkDictionary)(this.flexAlign, FLEX_ALIGNMENTS, '@flexAlign', 'Box');
      (0, _assertions.checkDictionary)(this.flexJustify, FLEX_JUSTIFICATIONS, '@flexJustify', 'Box');
      (0, _assertions.checkDictionary)(this.flexDir, FLEX_DIRECTIONS, '@flexDir', 'Box');
      (0, _assertions.checkDictionary)(this.flexWrap, FLEX_WRAPS, '@flexWrap', 'Box');
      (0, _assertions.checkDictionary)(this.flex, FLEX_SIZES, '@flex', 'Box');
    }
  });

  _exports.default = _default;
});