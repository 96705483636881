define("travis/templates/components/manage-subscription-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "URm04b1W",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"account\",\"hasSubscriptionPermissions\"]]],null,{\"statements\":[[0,\"  \"],[7,\"a\",true],[11,\"href\",[23,0,[\"subscription\",\"billingUrl\"]]],[11,\"class\",[29,[[28,\"if\",[[23,0,[\"subscription\",\"isExpired\"]],\"button--green\",\"button--blue\"],null],\" manage-subscription\"]]],[8],[0,\"\\n    \"],[5,\"svg-image\",[],[[\"@name\",\"@width\",\"@class\"],[\"github\",\"15px\",\"icon github-icon\"]]],[0,\" \\n\"],[4,\"if\",[[23,0,[\"subscription\",\"isExpired\"]]],null,{\"statements\":[[0,\"      Resubscribe or change plan\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      Edit subscription\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"button--green manage-subscription disabled\"],[8],[0,\"\\n    Edit subscription\\n    \"],[5,\"ember-tooltip\",[],[[\"@text\"],[\"You do not have permission to edit this subscription\"]]],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/manage-subscription-button.hbs"
    }
  });

  _exports.default = _default;
});