define("travis/services/animation", ["exports", "ember-animated/transitions/fade"], function (_exports, _fade) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.DURATIONS = _exports.DURATION_NAMES = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var isTest = Ember.testing;
  var DURATION_NAMES = {
    QUICK: 'quick'
  };
  _exports.DURATION_NAMES = DURATION_NAMES;

  var DURATIONS = _defineProperty({}, DURATION_NAMES.QUICK, 200);

  _exports.DURATIONS = DURATIONS;

  var _default = Ember.Service.extend({
    off: isTest,
    durations: Ember.computed('off', function () {
      var off = this.off;
      var durations = Object.values(DURATION_NAMES).reduce(function (durationMap, name) {
        durationMap[name] = off ? 0 : DURATIONS[name];
        return durationMap;
      }, {});
      return durations;
    }),
    transitions: Ember.computed(function () {
      return {
        fade: _fade.default
      };
    })
  });

  _exports.default = _default;
});