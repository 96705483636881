define("travis/utils/ui-kit/responsive", ["exports", "travis/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getResponsiveProp = getResponsiveProp;
  _exports.screenKeys = _exports.screens = void 0;
  var screens = _environment.default.screens;
  _exports.screens = screens;
  var screenKeys = Object.keys(screens);
  _exports.screenKeys = screenKeys;

  function getResponsiveProp(value) {
    // Cannot destructure from null/undefined
    var saferVal = value || {};
    var base = saferVal.base;
    var sm = saferVal.sm,
        md = saferVal.md,
        lg = saferVal.lg,
        xl = saferVal.xl;
    var keys = Object.keys(saferVal);
    var hasScreenKey = keys.some(function (key) {
      return screenKeys.includes(key);
    });

    if (!hasScreenKey) {
      base = value;
    }

    return {
      base: base,
      sm: sm,
      md: md,
      lg: lg,
      xl: xl
    };
  }
});