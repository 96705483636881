define("travis/components/build-wrapper", ["exports", "travis/utils/color-for-state"], function (_exports, _colorForState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['color'],
    pollModels: 'build',
    color: Ember.computed('build.state', function () {
      var buildState = this.get('build.state');
      return (0, _colorForState.default)(buildState);
    })
  });

  _exports.default = _default;
});