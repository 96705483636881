define("travis/mixins/scroll-reset", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    beforeModel: function beforeModel() {
      window.scrollTo(0, 0);
      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});