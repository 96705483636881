define("travis/components/repository-layout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    externalLinks: Ember.inject.service(),
    features: Ember.inject.service(),
    isShowingTriggerBuildModal: false,
    isShowingStatusBadgeModal: false,
    repoUrl: Ember.computed('repo.{ownerName,vcsName,vcsType}', function () {
      var owner = this.get('repo.ownerName');
      var repo = this.get('repo.vcsName');
      var vcsType = this.get('repo.vcsType');
      var vcsId = this.get('repo.vcsId');
      return this.externalLinks.repoUrl(vcsType, {
        owner: owner,
        repo: repo,
        vcsId: vcsId
      });
    }),
    orgBuildHistoryLink: Ember.computed('repo.slug', function () {
      var slug = this.get('repo.slug');
      return this.externalLinks.orgBuildHistoryLink(slug);
    }),
    comBuildHistoryLink: Ember.computed('repo.slug', function () {
      var slug = this.get('repo.slug');
      return this.externalLinks.comBuildHistoryLink(slug);
    }),
    actions: {
      toggleStatusBadgeModal: function toggleStatusBadgeModal() {
        this.toggleProperty('isShowingStatusBadgeModal');
      },
      toggleTriggerBuildModal: function toggleTriggerBuildModal() {
        this.toggleProperty('isShowingTriggerBuildModal');
      }
    }
  });

  _exports.default = _default;
});