define("travis/components/forms/form-select-multiple", ["exports", "ember-power-select/components/power-select-multiple", "travis/mixins/components/form-select"], function (_exports, _powerSelectMultiple, _formSelect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _powerSelectMultiple.default.extend(_formSelect.default, {});

  _exports.default = _default;
});