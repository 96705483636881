define("travis/services/update-times", ["exports", "travis/config/environment", "travis/utils/eventually", "visibilityjs"], function (_exports, _environment, _eventually, _visibilityjs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    allowFinishedBuilds: false,
    init: function init() {
      var visibilityId = _visibilityjs.default.every(_environment.default.intervals.updateTimes, Ember.run.bind(this, 'updateTimes'));

      var intervalId = setInterval(this.resetAllowFinishedBuilds.bind(this), 60000);
      var records = [];
      this.setProperties({
        visibilityId: visibilityId,
        intervalId: intervalId,
        records: records
      });
      return this._super.apply(this, arguments);
    },
    willDestroy: function willDestroy() {
      _visibilityjs.default.stop(this.visibilityId);

      clearInterval(this.intervalId);

      this._super.apply(this, arguments);
    },
    resetAllowFinishedBuilds: function resetAllowFinishedBuilds() {
      this.set('allowFinishedBuilds', true);
    },
    updateTimes: function updateTimes() {
      var _this = this;

      var records = this.records;
      records.filter(function (record) {
        return _this.allowFinishedBuilds || !record.get('isFinished');
      }).forEach(function (record) {
        (0, _eventually.default)(record, function (resolvedRecord) {
          if (resolvedRecord) {
            resolvedRecord.updateTimes();
          }
        });
      });
      this.set('records', []);

      if (this.allowFinishedBuilds) {
        this.set('allowFinishedBuilds', false);
      }
    },
    pushObject: function pushObject(record) {
      var records = this.records;

      if (!records.includes(record)) {
        records.pushObject(record);
      }
    },
    push: function push(model) {
      var _this2 = this;

      if (!model) {
        return;
      }

      if (model.forEach) {
        model.forEach(function (element) {
          _this2.pushObject(element);
        });
      } else {
        this.pushObject(model);
      }
    }
  });

  _exports.default = _default;
});