define("travis/components/created-by", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    nameOrLogin: Ember.computed('user.name', 'user.login', function () {
      var name = this.get('user.name');
      var login = this.get('user.login');
      return name || login;
    }),
    showUser: Ember.computed('nameOrLogin', 'eventType', function () {
      var nameOrLogin = this.nameOrLogin;
      var eventType = this.eventType;
      return nameOrLogin && eventType !== 'cron';
    }),
    userProvider: Ember.computed.or('user.provider', 'provider')
  });

  _exports.default = _default;
});