define("travis/components/branch-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Component.extend({
    api: Ember.inject.service(),
    auth: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    permissions: Ember.inject.service(),
    externalLinks: Ember.inject.service(),
    tagName: 'li',
    classNameBindings: ['branch.last_build.state'],
    classNames: ['branch-row', 'row-li'],
    isLoading: false,
    isTriggering: false,
    hasTriggered: false,
    commitUrl: Ember.computed('branch.repository.slug', 'branch.last_build.commit.sha', 'vcsType', function () {
      var _this$get$split = this.get('branch.repository.slug').split('/'),
          _this$get$split2 = _slicedToArray(_this$get$split, 2),
          owner = _this$get$split2[0],
          repo = _this$get$split2[1];

      var vcsType = this.get('vcsType');
      var commit = this.get('branch.last_build.commit.sha');
      return this.externalLinks.commitUrl(vcsType, {
        owner: owner,
        repo: repo,
        commit: commit
      });
    }),
    vcsType: Ember.computed('branch.repository.id', function () {
      var repository = this.store.peekRecord('repo', this.get('branch.repository.id'));
      return repository.vcsType;
    }),
    provider: Ember.computed('vcsType', function () {
      return this.get('vcsType') && this.get('vcsType').toLowerCase().replace('repository', '');
    }),
    rawCreatedBy: Ember.computed.alias('branch.last_build.created_by'),
    createdBy: Ember.computed('rawCreatedBy.name', 'rawCreatedBy.login', 'rawCreatedBy.avatar_url', function () {
      var name = this.get('rawCreatedBy.name');
      var login = this.get('rawCreatedBy.login');
      var avatarUrl = this.get('rawCreatedBy.avatar_url');
      return {
        name: name,
        login: login,
        avatarUrl: avatarUrl
      };
    }),
    rawCommit: Ember.computed.alias('branch.last_build.commit'),
    commit: Ember.computed('rawCommit.author.name', 'rawCommit.author.avatar_url', 'rawCommit.committer.name', 'rawCommit.committer.avatar_url', function () {
      var authorName = this.get('rawCommit.author.name');
      var authorAvatarUrl = this.get('rawCommit.author.avatar_url');
      var committerName = this.get('rawCommit.committer.name');
      var committerAvatarUrl = this.get('rawCommit.committer.avatar_url');
      var authorIsCommitter = authorName === committerName && authorAvatarUrl === committerAvatarUrl;
      return {
        authorIsCommitter: authorIsCommitter,
        authorName: authorName,
        authorAvatarUrl: authorAvatarUrl,
        committerName: committerName,
        committerAvatarUrl: committerAvatarUrl
      };
    }),
    getLast5Builds: Ember.computed(function () {
      var branchName, lastBuilds, repoId;
      lastBuilds = Ember.ArrayProxy.create({
        content: [{}, {}, {}, {}, {}],
        isLoading: true,
        count: 0
      });

      if (!this.get('branch.last_build')) {
        lastBuilds.set('isLoading', false);
      } else {
        repoId = this.get('branch.repository.id');
        branchName = encodeURIComponent(this.get('branch.name'));
        var path = "/repo/".concat(repoId, "/builds");
        var params = "?branch.name=".concat(branchName, "&limit=5&build.event_type=push,api,cron");
        var url = "".concat(path).concat(params);
        this.api.get(url).then(function (response) {
          var array, i, trueLength;
          array = response.builds.map(function (build) {
            return Ember.Object.create(build);
          }); // We need exactly 5 elements in array

          if (array.length < 5) {
            trueLength = array.length;

            for (i = trueLength; i < 5; i++) {
              array.push({});
            }
          }

          Ember.run(function () {
            lastBuilds.set('count', response['@pagination'].count);
            lastBuilds.set('content', array);
            lastBuilds.set('isLoading', false);
          });
        });
      }

      return lastBuilds;
    }),
    actions: {
      viewAllBuilds: function viewAllBuilds() {
        return this.router.transitionTo('builds');
      }
    }
  });

  _exports.default = _default;
});