define("travis/templates/components/forms/form-slider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c9SPd9lR",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"input\",true],[11,\"min\",[23,0,[\"min\"]]],[11,\"max\",[23,0,[\"max\"]]],[11,\"step\",[23,0,[\"step\"]]],[11,\"value\",[23,0,[\"value\"]]],[11,\"list\",[23,0,[\"list\"]]],[10,\"type\",\"range\"],[8],[9],[0,\"\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/forms/form-slider.hbs"
    }
  });

  _exports.default = _default;
});