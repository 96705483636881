define("travis/adapters/coupon", ["exports", "travis/adapters/v3"], function (_exports, _v) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _v.default.extend({
    pathForType: function pathForType() {
      return 'coupons';
    }
  });

  _exports.default = _default;
});