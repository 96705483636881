define("travis/services/broadcasts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    api: Ember.inject.service(),
    auth: Ember.inject.service(),
    raven: Ember.inject.service(),
    storage: Ember.inject.service(),
    broadcasts: Ember.computed('auth.signedIn', function () {
      var _this = this;

      var signedIn = this.get('auth.signedIn');
      var broadcasts, seenBroadcasts;

      if (signedIn) {
        broadcasts = Ember.ArrayProxy.create({
          content: [],
          lastBroadcastStatus: '',
          isLoading: true
        });
        seenBroadcasts = this.storage.getItem('travis.seen_broadcasts');

        if (seenBroadcasts) {
          seenBroadcasts = JSON.parse(seenBroadcasts);
        } else {
          seenBroadcasts = [];
        }

        this.api.get('/broadcasts').then(function (response) {
          var receivedBroadcasts = response.broadcasts.reduce(function (processed, broadcast) {
            if (!broadcast.expired && seenBroadcasts.indexOf(broadcast.id.toString()) === -1) {
              processed.unshift(Ember.Object.create(broadcast));
            }

            return processed;
          }, []);
          Ember.run(function () {
            broadcasts.set('lastBroadcastStatus', _this.getStatus(receivedBroadcasts));
            broadcasts.set('content', receivedBroadcasts);
            broadcasts.set('isLoading', false);
          });
        }).catch(function (response) {
          // 403 Forbidden responses are probably due to token expiry / automatic sign out
          if (response.status !== 403) {
            _this.raven.logException("Broadcast error: (".concat(response.status, ") ").concat(response.statusText));
          }
        });
        return broadcasts;
      }
    }),
    markAsSeen: function markAsSeen(broadcast) {
      var id, seenBroadcasts;
      id = broadcast.get('id').toString();
      seenBroadcasts = this.storage.getItem('travis.seen_broadcasts');

      if (seenBroadcasts) {
        seenBroadcasts = JSON.parse(seenBroadcasts);
      } else {
        seenBroadcasts = [];
      }

      seenBroadcasts.push(id);
      this.storage.setItem('travis.seen_broadcasts', JSON.stringify(seenBroadcasts));
      this.get('broadcasts.content').removeObject(broadcast);
      var status = this.getStatus(this.get('broadcasts.content'));
      this.set('broadcasts.lastBroadcastStatus', status);
    },
    getStatus: function getStatus(broadcastArray) {
      if (!broadcastArray) {
        return '';
      }

      if (broadcastArray.length) {
        if (broadcastArray.findBy('category', 'warning')) {
          return 'warning';
        } else if (broadcastArray.findBy('category', 'announcement')) {
          return 'announcement';
        } else {
          return '';
        }
      }
    }
  });

  _exports.default = _default;
});