define("travis/templates/components/no-builds", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bRi9pxom",
    "block": "{\"symbols\":[],\"statements\":[[5,\"missing-notice\",[],[[\"@title\"],[\"No builds for this repository\"]],{\"statements\":[[0,\"\\n  \"],[7,\"p\",true],[10,\"class\",\"page-notice\"],[8],[0,\"\\n    Want to start testing this project on Travis CI?\\n  \"],[9],[0,\"\\n  \"],[5,\"external-link-to\",[[12,\"class\",\"button\"],[12,\"title\",\"Travis documentation on getting set up\"]],[[\"@href\"],[\"http://docs.travis-ci.com/user/getting-started/\"]],{\"statements\":[[0,\"\\n    Read the Docs on Getting Started\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/no-builds.hbs"
    }
  });

  _exports.default = _default;
});