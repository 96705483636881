define("travis/components/dashboard-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    permissionsService: Ember.inject.service('permissions'),
    api: Ember.inject.service(),
    flashes: Ember.inject.service(),
    tagName: '',
    isLoading: false,
    isTriggering: false,
    dropupIsOpen: false,
    currentBuild: Ember.computed.alias('repo.currentBuild'),
    displayMenuTofu: Ember.computed.alias('repo.permissions.create_request'),
    openDropup: function openDropup() {
      this.set('dropupIsOpen', true);
    },
    closeDropup: function closeDropup() {
      this.set('dropupIsOpen', false);
    },
    triggerBuild: function triggerBuild() {
      var self = this;
      var data = {};
      data.request = "{ 'branch': '".concat(this.get('repo.defaultBranch.name'), "' }");
      this.api.post("/repo/".concat(this.get('repo.id'), "/requests"), {
        data: data
      }).then(function () {
        self.set('isTriggering', false);
        self.get('flashes').success("You\u2019ve successfully triggered a build for ".concat(self.get('repo.slug'), ".\n                   Hold tight, it might take a moment to show up."));
      });
      this.set('dropupIsOpen', false);
      this.set('isTriggering', true);
    },
    actions: {
      openDropup: function openDropup() {
        this.openDropup();
      },
      triggerBuild: function triggerBuild() {
        this.triggerBuild();
      },
      starRepo: function starRepo() {
        if (this.get('repo.starred')) {
          this.unstar.perform(this.repo);
        } else {
          this.star.perform(this.repo);
        }
      }
    }
  });

  _exports.default = _default;
});