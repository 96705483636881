define("travis/utils/color-for-state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = colorForState;
  var colors = {
    'default': 'yellow',
    passed: 'green',
    failed: 'red',
    errored: 'red',
    canceled: 'gray'
  };

  function colorForState(state) {
    return colors[state] || colors['default'];
  }
});