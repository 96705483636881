define("travis/components/ui-kit/grid-item", ["exports", "travis/utils/ui-kit/assertions", "travis/utils/ui-kit/concat", "travis/utils/ui-kit/prefix", "travis/components/ui-kit/box"], function (_exports, _assertions, _concat, _prefix, _box) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function screenClass(key, screen) {
    return Ember.computed('sizePrefix', key, function () {
      var size = this.get(key);
      var screenPrefix = screen === 'base' ? '' : "".concat(screen, ":");

      if (typeof size === 'string') {
        var value = _box.FLEX_SIZE_VALS[size] || "".concat(this.sizePrefix, "-").concat(size);
        return "".concat(screenPrefix).concat(value);
      }

      if (typeof size === 'number') {
        return "".concat(screenPrefix).concat(this.sizePrefix, "-1/").concat(size).replace('1/1', 'full');
      }

      return null;
    });
  }

  var ORDERS = {
    FIRST: 'first',
    LAST: 'last',
    NONE: 'none',
    ONE: 1,
    TWO: 2,
    THREE: 3,
    FOUR: 4,
    FIVE: 5,
    SIX: 6
  };

  var _default = Ember.Component.extend({
    tagName: '',
    // Public interface
    componentClass: 'grid-item',
    tag: 'div',
    grid: null,
    base: null,
    sm: null,
    md: null,
    lg: null,
    xl: null,
    gap: null,
    order: null,
    display: null,
    flex: null,
    borderColor: null,
    borderWidth: null,
    margin: null,
    padding: null,
    // Private
    sizePrefix: Ember.computed('grid.isCol', function () {
      return this.grid.isCol ? 'h' : 'w';
    }),
    currentBase: Ember.computed.or('base', 'grid.base'),
    currentSm: Ember.computed.or('sm', 'grid.sm'),
    currentMd: Ember.computed.or('md', 'grid.md'),
    currentLg: Ember.computed.or('lg', 'grid.lg'),
    currentXl: Ember.computed.or('xl', 'grid.xl'),
    currentGap: Ember.computed.or('gap', 'grid.gap'),
    baseClass: screenClass('currentBase', 'base'),
    smClass: screenClass('currentSm', 'sm'),
    mdClass: screenClass('currentMd', 'md'),
    lgClass: screenClass('currentLg', 'lg'),
    xlClass: screenClass('currentXl', 'xl'),
    gapClass: Ember.computed('currentGap', 'grid.isCol', function () {
      var currentGap = this.currentGap;
      var paddingDir = this.grid.isCol ? 'y' : 'x';
      return currentGap === 0 ? '' : "p".concat(paddingDir, "-").concat(currentGap);
    }),
    flexClass: (0, _prefix.default)('flex', 'flex', {
      dictionary: _box.FLEX_SIZE_VALS
    }),
    orderClass: (0, _prefix.default)('order', 'order'),
    allClasses: (0, _concat.default)('componentClass', 'baseClass', 'smClass', 'mdClass', 'lgClass', 'xlClass', 'gapClass', 'flexClass', 'orderClass'),
    // Lifecycle
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      (0, _assertions.requireProp)(this.grid, '@grid', 'GridItem');
      (0, _assertions.checkDictionary)(this.flex, _box.FLEX_SIZES, '@flex', 'GridItem');
      (0, _assertions.checkDictionary)(this.order, ORDERS, '@order', 'GridItem');
    }
  });

  _exports.default = _default;
});