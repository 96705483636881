define("travis/models/build", ["exports", "@ember-data/model", "moment", "travis/utils/keys-map", "travis/mixins/duration-calculations"], function (_exports, _model, _moment, _keysMap, _durationCalculations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_durationCalculations.default, {
    api: Ember.inject.service(),
    branchName: Ember.computed.alias('branch.name'),
    permissions: (0, _model.attr)(),
    state: (0, _model.attr)(),
    number: (0, _model.attr)('number'),
    message: (0, _model.attr)('string'),
    _duration: (0, _model.attr)('number'),
    startedAt: (0, _model.attr)('string'),
    finishedAt: (0, _model.attr)('string'),
    pullRequestNumber: (0, _model.attr)('number'),
    pullRequestTitle: (0, _model.attr)('string'),
    tag: (0, _model.attr)(),
    eventType: (0, _model.attr)('string'),
    _config: (0, _model.attr)(),
    updatedAt: (0, _model.attr)('date'),
    repo: (0, _model.belongsTo)('repo'),
    branch: (0, _model.belongsTo)('branch', {
      async: false,
      inverse: 'builds'
    }),
    repoCurrentBuild: (0, _model.belongsTo)('repo', {
      async: true,
      inverse: 'currentBuild'
    }),
    commit: (0, _model.belongsTo)('commit', {
      async: false
    }),
    request: (0, _model.belongsTo)('request', {
      async: true
    }),
    jobs: (0, _model.hasMany)('job', {
      async: true
    }),
    stages: (0, _model.hasMany)('stage', {
      async: true
    }),
    stagesSort: ['number'],
    sortedStages: Ember.computed.sort('stages', 'stagesSort'),
    createdBy: (0, _model.belongsTo)('user'),
    stagesAreLoaded: Ember.computed.alias('stages.isSettled'),
    priority: (0, _model.attr)('boolean'),
    config: Ember.computed('_config', 'currentState.stateName', function () {
      var config = this._config;
      var stateName = this.get('currentState.stateName');

      if (config) {
        return Object.keys(config).reduce(function (compact, key) {
          var value = config[key];
          if (Ember.isPresent(value)) compact[key] = value;
          return compact;
        });
      } else if (stateName !== 'root.loading') {
        if (this.isFetchingConfig) {
          return;
        }

        this.set('isFetchingConfig', true);
        return this.reload();
      }
    }),
    isPullRequest: Ember.computed('eventType', function () {
      var eventType = this.eventType;
      return eventType === 'pull_request';
    }),
    isMatrix: Ember.computed('jobs.[]', function () {
      var jobs = this.jobs;
      return jobs.get('length') > 1;
    }),
    isTag: Ember.computed('tag', function () {
      var tag = this.tag;
      return tag && tag.name;
    }),
    isFinished: Ember.computed('state', function () {
      var state = this.state;
      var finishedStates = ['passed', 'failed', 'errored', 'canceled'];
      return finishedStates.includes(state);
    }),
    notStarted: Ember.computed('state', function () {
      var state = this.state;
      var waitingStates = ['queued', 'created', 'received'];
      return waitingStates.includes(state);
    }),
    requiredJobs: Ember.computed('jobs.@each.allowFailure', function () {
      var jobs = this.jobs;
      return jobs.filter(function (job) {
        return !job.get('allowFailure');
      });
    }),
    allowedFailureJobs: Ember.computed('jobs.@each.allowFailure', function () {
      var jobs = this.jobs;
      return jobs.filter(function (job) {
        return job.get('allowFailure');
      });
    }),
    rawConfigKeys: Ember.computed('jobs.@each.config', function () {
      var jobs = this.jobs;
      var keys = [];
      jobs.forEach(function (job) {
        var configKeys = job.config || [];
        return configKeys.forEach(function (key) {
          if (!keys.includes(key) && _keysMap.default.hasOwnProperty(key)) {
            return keys.pushObject(key);
          }
        });
      });
      return keys;
    }),
    configKeys: Ember.computed('rawConfigKeys.[]', function () {
      var keys = this.rawConfigKeys;
      var headers = ['Job', 'Duration', 'Finished'];
      return headers.concat(keys).map(function (key) {
        if (_keysMap.default.hasOwnProperty(key)) {
          return _keysMap.default[key];
        } else {
          return key;
        }
      });
    }),
    canCancel: Ember.computed('jobs.@each.canCancel', function () {
      var jobs = this.jobs;
      return !Ember.isEmpty(jobs.filterBy('canCancel'));
    }),
    canRestart: Ember.computed.alias('isFinished'),
    cancel: function cancel() {
      var url = "/build/".concat(this.id, "/cancel");
      return this.api.post(url);
    },
    restart: function restart() {
      var url = "/build/".concat(this.id, "/restart");
      return this.api.post(url);
    },
    restartedBy: Ember.computed('jobs.@each.restartedBy', function () {
      var jobs = this.jobs;

      if (jobs.get('length') == 1) {
        return jobs.get('firstObject').get('restartedBy');
      } else {
        return null;
      }
    }),
    canDebug: Ember.computed('jobs.[]', 'repo.private', function () {
      var jobs = this.jobs;
      var repoPrivate = this.get('repo.private');
      return jobs.get('length') === 1 && repoPrivate;
    }),
    debug: function debug() {
      return Ember.RSVP.all(this.jobs.map(function (job) {
        return job.debug();
      }));
    },
    formattedFinishedAt: Ember.computed('finishedAt', function () {
      var finishedAt = this.finishedAt;

      if (finishedAt) {
        var m = (0, _moment.default)(finishedAt);
        return m.isValid() ? m.format('lll') : 'not finished yet';
      }
    }),
    increasePriority: function increasePriority(shouldCancelRunningJobs) {
      var isCancelRunningJob = false;

      if (shouldCancelRunningJobs) {
        isCancelRunningJob = true;
      }

      var url = "/build/".concat(this.id, "/priority?cancel_all=").concat(isCancelRunningJob);
      return this.api.post(url);
    }
  });

  _exports.default = _default;
});