define("travis/routes/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    auth: Ember.inject.service(),
    tabStates: Ember.inject.service(),
    repositories: Ember.inject.service(),
    features: Ember.inject.service(),
    redirect: function redirect() {
      if (this.get('auth.signedIn')) {
        if (this.get('features.dashboard')) {
          this.transitionTo('dashboard');
        }
      } else if (this.get('features.enterpriseVersion')) {
        this.transitionTo('signin');
      }
    },
    renderTemplate: function renderTemplate() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      this._super(args);

      this.render('build/index', {
        into: 'index',
        controller: 'build/index'
      });
    },
    activate: function activate() {
      for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        args[_key2] = arguments[_key2];
      }

      this._super(args);

      if (this.get('auth.signedIn')) {
        this.tabStates.set('sidebarTab', 'owned');
        this.set('tabStates.mainTab', 'current');
      }
    },
    deactivate: function deactivate() {
      this.controllerFor('build').set('build', null);
      this.controllerFor('job').set('job', null);
      this.stopObservingRepoStatus();
      return this._super.apply(this, arguments);
    },
    stopObservingRepoStatus: function stopObservingRepoStatus() {
      var controller = this.controllerFor('repo');
      controller.removeObserver('repo.active', this, 'renderTemplate');
      controller.removeObserver('repo.currentBuildId', this, 'renderTemplate');
    },
    actions: {
      redirectToGettingStarted: function redirectToGettingStarted() {
        return this.transitionTo('getting_started');
      }
    }
  });

  _exports.default = _default;
});