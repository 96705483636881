define("travis/templates/components/billing-manual", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KAXoNY4V",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\",true],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"subscription\",\"manualSubscriptionExpired\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"billing-subscription\"],[8],[0,\"\\n      \"],[5,\"billing/summary\",[],[[\"@subscription\",\"@account\",\"@planMessage\"],[[23,0,[\"subscription\"]],[23,0,[\"account\"]],\"Expired\"]]],[0,\"\\n    \"],[9],[0,\"\\n    \"],[5,\"billing/process\",[],[[\"@account\",\"@newSubscription\"],[[23,0,[\"account\"]],[23,0,[\"newSubscription\"]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"section\",true],[10,\"class\",\"billing-subscription\"],[8],[0,\"\\n      \"],[5,\"billing/summary\",[],[[\"@subscription\",\"@account\"],[[23,0,[\"subscription\"]],[23,0,[\"account\"]]]]],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"mt-5\"],[8],[0,\"\\n        \"],[7,\"h3\",true],[8],[0,\"\\n          Billing details\\n        \"],[9],[0,\"\\n        \"],[7,\"p\",true],[10,\"class\",\"flex px-4 py-5 shadow items-center notice-banner--white\"],[8],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"mr-4\"],[8],[0,\"\\n            \"],[5,\"svg-image\",[],[[\"@name\",\"@class\"],[\"icon-help\",\"icon-help\"]]],[0,\"\\n          \"],[9],[0,\"\\n          \"],[7,\"span\",true],[10,\"class\",\"py-1 leading-loose\"],[8],[0,\"\\n            This manual subscription is paid to Travis CI by bank transfer. If you have any questions or would like to update your plan,\\n            \"],[5,\"link-to\",[],[[\"@route\",\"@query\"],[\"help\",[28,\"hash\",null,[[\"anchor\"],[\"form\"]]]]],{\"statements\":[[0,\"\\n              contact our support team.\\n            \"]],\"parameters\":[]}],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/billing-manual.hbs"
    }
  });

  _exports.default = _default;
});