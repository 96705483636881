define("travis/templates/components/ui-kit/note", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hKrx654g",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[5,\"ui-kit/box\",[],[[\"@tag\",\"@color\",\"@margin\",\"@padding\"],[[23,0,[\"tag\"]],[23,0,[\"bgColor\"]],[23,0,[\"margin\"]],[23,0,[\"padding\"]]]],{\"statements\":[[0,\"\\n  \"],[5,\"ui-kit/text\",[],[[\"@color\",\"@size\"],[[23,0,[\"textColor\"]],\"sm\"]],{\"statements\":[[14,1]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/ui-kit/note.hbs"
    }
  });

  _exports.default = _default;
});