define("travis/adapters/build", ["exports", "travis/adapters/v3"], function (_exports, _v) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var includes = 'build.commit,build.branch,build.request,build.created_by'; // TODO this is a workaround for an infinite loop in Mirage serialising 😞

  if (!Ember.testing) {
    includes += ',build.repository';
  }

  var _default = _v.default.extend({
    includes: includes,
    pathPrefix: function pathPrefix(modelName, id, snapshot, type, query) {
      if (type === 'query' && query.repository_id) {
        return "repo/".concat(query.repository_id);
      }
    },
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      if (requestType == 'queryRecord' && query.id) {
        var _id = query.id;
        delete query.id;
        return this._super(modelName, _id, snapshot, 'findRecord', query);
      } else {
        // This tells the API to skip page count for pagination, speeding up queries.
        if (query && !query.force_count) {
          query.skip_count = true;
        }

        return this._super.apply(this, arguments);
      }
    }
  });

  _exports.default = _default;
});