define("travis/services/tab-states", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SIDEBAR_TAB_STATES = void 0;
  var SIDEBAR_TAB_STATES = {
    OWNED: 'owned',
    RUNNING: 'running',
    SEARCH: 'search'
  };
  _exports.SIDEBAR_TAB_STATES = SIDEBAR_TAB_STATES;

  var _default = Ember.Service.extend({
    sidebarTab: SIDEBAR_TAB_STATES.OWNED,
    mainTab: 'current',
    isSidebarOwned: Ember.computed.equal('sidebarTab', SIDEBAR_TAB_STATES.OWNED),
    isSidebarRunning: Ember.computed.equal('sidebarTab', SIDEBAR_TAB_STATES.RUNNING),
    isSidebarSearch: Ember.computed.equal('sidebarTab', SIDEBAR_TAB_STATES.SEARCH),
    switchSidebar: function switchSidebar(state) {
      this.set('sidebarTab', state);
    },
    switchSidebarToOwned: function switchSidebarToOwned() {
      this.switchSidebar(SIDEBAR_TAB_STATES.OWNED);
    },
    switchSidebarToRunning: function switchSidebarToRunning() {
      this.switchSidebar(SIDEBAR_TAB_STATES.RUNNING);
    },
    switchSidebarToSearch: function switchSidebarToSearch() {
      this.switchSidebar(SIDEBAR_TAB_STATES.SEARCH);
    }
  });

  _exports.default = _default;
});