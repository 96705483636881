define("travis/routes/owner/repositories", ["exports", "travis/routes/basic", "travis/config/environment", "travis/controllers/owner/repositories"], function (_exports, _basic, _environment, _repositories) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basic.default.extend({
    features: Ember.inject.service(),
    insights: Ember.inject.service(),
    needsAuth: false,
    queryParams: {
      page: {
        refreshModel: true
      },
      tab: {
        refreshModel: true
      }
    },
    page: null,
    tab: null,
    owner: null,
    model: function model(_ref) {
      var page = _ref.page,
          tab = _ref.tab;
      var owner = this.modelFor('owner');
      this.setProperties({
        tab: tab,
        page: page,
        owner: owner
      });
      return {
        owner: owner
      };
    },
    loadRepositories: function loadRepositories() {
      var limit = _environment.default.pagination.profileReposPerPage;
      var page = this.page || 1;
      var offset = (page - 1) * limit;

      var _this$paramsFor = this.paramsFor('owner'),
          owner = _this$paramsFor.owner,
          provider = _this$paramsFor.provider;

      var type = 'byOwner';
      var sort_by = 'default_branch.last_build:desc'; // eslint-disable-line

      var queryParams = {
        offset: offset,
        limit: limit,
        sort_by: sort_by,
        provider: provider,
        custom: {
          owner: owner,
          type: type
        }
      };

      if (this.features.get('github-apps')) {
        queryParams['repository.active'] = true;
      }

      return this.store.paginated('repo', queryParams, {
        live: false
      });
    },
    loadInsights: function loadInsights() {
      return this.insights.getChartData.perform(this.owner, 'week', 'builds', 'sum', ['count_started'], {
        private: true
      });
    },
    loadData: function loadData() {
      var controller = this.controller;
      controller.setProperties({
        builds: null,
        repos: null
      });

      if (this.tab === _repositories.OWNER_TABS.INSIGHTS) {
        controller.set('builds', this.loadInsights());
      } else {
        this.loadRepositories().then(function (data) {
          return controller.set('repos', data);
        });
      }
    },
    setupController: function setupController() {
      this._super.apply(this, arguments);

      this.loadData();
    }
  });

  _exports.default = _default;
});