define("travis/components/job-infrastructure-notification", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var NOVEMBER_2017_RETIREMENT = '2017-11-28T12:00:00-08:00';
  var LATEST_TRUSTY_RELEASE = '2017-12-12T17:25:00-00:00';

  var _default = Ember.Component.extend({
    auth: Ember.inject.service(),
    features: Ember.inject.service(),
    queue: Ember.computed.reads('job.queue'),
    isJobFinished: Ember.computed.reads('job.isFinished'),
    jobStartedAt: Ember.computed.reads('job.startedAt'),
    jobConfig: Ember.computed.reads('job.config'),
    dist: Ember.computed.reads('jobConfig.dist'),
    language: Ember.computed.reads('jobConfig.language'),
    isPreciseDist: Ember.computed.equal('dist', 'precise'),
    isTrustyDist: Ember.computed.equal('dist', 'trusty'),
    isAndroidLanguage: Ember.computed.equal('language', 'android'),
    isNotAndroidLanguage: Ember.computed.not('isAndroidLanguage'),
    isPreciseEOL: Ember.computed.and('isGceBuild', 'isPreciseDist', 'isNotAndroidLanguage'),
    isWindows: Ember.computed.equal('jobConfig.os', 'windows'),
    conjugatedRun: Ember.computed('isJobFinished', function () {
      return this.isJobFinished ? 'ran' : 'is running';
    }),
    isGceBuild: Ember.computed.equal('queue', 'build.gce'),
    isLegacyInfrastructure: Ember.computed.equal('queue', 'builds.linux'),
    isTrustySudoFalse: Ember.computed.equal('queue', 'builds.ec2'),
    isMacStadium6: Ember.computed.equal('queue', 'builds.macstadium6'),
    isTrustySudoRequired: Ember.computed('jobStartedAt', 'isGceBuild', 'isTrustyDist', function () {
      if (this.isGceBuild && this.isTrustyDist) {
        var jobRanAfterReleaseDate = Date.parse(this.jobStartedAt) > Date.parse(LATEST_TRUSTY_RELEASE);

        if (jobRanAfterReleaseDate) {
          return true;
        }
      }

      return false;
    }),
    macOSImage: Ember.computed.reads('jobConfig.osx_image'),
    deprecatedXcodeImages: ['xcode8.1', 'xcode8.2', 'xcode6.4'],
    imageToRetirementDate: {
      'xcode8.1': NOVEMBER_2017_RETIREMENT,
      'xcode8.2': NOVEMBER_2017_RETIREMENT
    },
    imageToNewImage: {
      'xcode8.1': 'xcode8.3',
      'xcode8.2': 'xcode8.3'
    },
    newImageStrings: {
      'xcode8.3': 'Xcode 8.3',
      'xcode7.3': 'Xcode 7.3.1',
      'xcode6.4': 'Xcode 6.4'
    },
    isDeprecatedOrRetiredMacImage: Ember.computed('isMacStadium6', 'macOSImage', function () {
      var isMacStadium6 = this.isMacStadium6;
      var macOSImage = this.macOSImage;
      return isMacStadium6 && this.deprecatedXcodeImages.includes(macOSImage);
    }),
    deprecatedOrRetiredMacImageMessage: Ember.computed('jobStartedAt', 'macOSImage', 'isJobFinished', 'conjugatedRun', 'isDeprecatedOrRetiredMacImage', function () {
      var image = this.macOSImage;
      var conjugatedRun = this.conjugatedRun;

      if (image === 'xcode6.4') {
        return "Running builds with Xcode 6.4 in Travis CI is deprecated and will be\n  removed in January 2019. If Xcode 6.4 is critical to your builds, please contact our support team\n  at <a href=\"mailto:support@travis-ci.com\">support@travis-ci.com</a> to discuss options.";
      }

      var retirementDate = Date.parse(this.imageToRetirementDate[image]);
      var newImage = this.imageToNewImage[image];
      var newImageString = this.newImageStrings[newImage];
      var newImageAnchor = newImageString.replace(' ', '-');
      var newImageURLString = "<a href='https://docs.travis-ci.com/user/reference/osx/#".concat(newImageAnchor, "'>").concat(newImageString, "</a>");
      var imageRetirementAnnouncementURL = 'https://blog.travis-ci.com/2017-11-21-xcode8-3-default-image-announce';
      var jobRanBeforeRetirementDate = Date.parse(this.jobStartedAt) < retirementDate;
      var retirementDateIsInTheFuture = retirementDate > new Date();
      var formattedRetirementDate = (0, _moment.default)(retirementDate).format('MMMM D, YYYY');
      var retirementLink = "<a href='".concat(imageRetirementAnnouncementURL, "'>\n        ").concat(retirementDateIsInTheFuture ? 'will be retired' : 'was retired', "\n        on ").concat(formattedRetirementDate, "</a>");
      var retirementSentence, routingSentence;

      if (retirementDateIsInTheFuture) {
        retirementSentence = "This job ".concat(conjugatedRun, " on an OS X image that ").concat(retirementLink, ".");
      } else {
        retirementSentence = "\n          This job ".concat(this.isJobFinished ? 'was configured to run on' : 'is configured to run on', "\n          an OS X image that ").concat(retirementLink, ".");
      }

      if (retirementDateIsInTheFuture) {
        routingSentence = "After that, it will route to our ".concat(newImageURLString, " image.");
      } else if (jobRanBeforeRetirementDate) {
        routingSentence = "New jobs will route to our ".concat(newImageURLString, " image.");
      } else {
        routingSentence = "It was routed to our ".concat(newImageURLString, " image.");
      }

      return "".concat(retirementSentence, " ").concat(routingSentence);
    })
  });

  _exports.default = _default;
});