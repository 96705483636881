define("travis/components/profile-accounts-wrapper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['profile-orglist', 'columns', 'medium-4'],
    tagName: 'aside'
  });

  _exports.default = _default;
});