define("travis/templates/components/top-forum-post-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3Bs6+NCL",
    "block": "{\"symbols\":[\"topic\"],\"statements\":[[5,\"loading-overlay\",[],[[\"@visible\"],[[23,0,[\"isLoading\"]]]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[23,0,[\"topicsToShow\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\",true],[8],[0,\"\\n      \"],[5,\"external-link-to\",[],[[\"@href\"],[[23,1,[\"url\"]]]],{\"statements\":[[0,\"\\n        \"],[1,[23,1,[\"title\"]],false],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/top-forum-post-list.hbs"
    }
  });

  _exports.default = _default;
});