define("travis/components/sync-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    auth: Ember.inject.service(),
    user: Ember.computed.reads('auth.currentUser'),
    classNames: ['sync-button'],
    actions: {
      sync: function sync() {
        return this.user.sync(this.isOrganization);
      }
    }
  });

  _exports.default = _default;
});