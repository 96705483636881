define("travis/components/owner-sync-button", ["exports", "travis/components/sync-button"], function (_exports, _syncButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _syncButton.default.extend();

  _exports.default = _default;
});