define("travis/adapters/cron", ["exports", "travis/adapters/v3"], function (_exports, _v) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _v.default.extend({
    includes: 'cron.branch',
    createRecord: function createRecord(store, type, record) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, record, {});
      var url = "".concat(this.getHost()).concat(data.branch, "/cron");
      return this.ajax(url, 'POST', {
        data: {
          dont_run_if_recent_build_exists: data.dont_run_if_recent_build_exists,
          interval: data.interval
        }
      });
    },
    query: function query(store, type, _query) {
      var repoId = _query['repository_id'];
      delete _query['repository_id'];
      var url = "".concat(this.urlPrefix(), "/repo/").concat(repoId, "/crons");
      return this.ajax(url, 'GET', _query);
    }
  });

  _exports.default = _default;
});