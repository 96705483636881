define("travis/templates/components/insights-privacy-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VgwqzTil",
    "block": "{\"symbols\":[\"form\",\"field\",\"option\"],\"statements\":[[7,\"span\",true],[10,\"class\",\"insights-privacy-selector__title\"],[8],[0,\"\\n  View:\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[23,0,[\"isPrivateViewable\"]]],null,{\"statements\":[[0,\"  \"],[5,\"travis-form\",[],[[],[]],{\"statements\":[[0,\"\\n    \"],[6,[23,1,[\"field\"]],[],[[\"@value\",\"@onChange\",\"@showFrame\"],[[23,0,[\"currentState\"]],[28,\"action\",[[23,0,[]],\"selectInsightScope\"],null],[23,0,[\"showFrame\"]]]],{\"statements\":[[0,\"\\n      \"],[6,[23,2,[\"select\"]],[],[[\"@options\"],[[23,0,[\"availableOptions\"]]]],{\"statements\":[[0,\"\\n        \"],[1,[23,3,[]],false],[0,\"\\n      \"]],\"parameters\":[3]}],[0,\"\\n    \"]],\"parameters\":[2]}],[0,\"\\n  \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"insights-privacy-selector__selected\"],[8],[0,\"\\n    \"],[1,[23,0,[\"currentState\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/insights-privacy-selector.hbs"
    }
  });

  _exports.default = _default;
});