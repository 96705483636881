define("travis/templates/components/ui-kit/box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4QIBynph",
    "block": "{\"symbols\":[\"Tag\",\"&attrs\",\"&default\"],\"statements\":[[4,\"let\",[[28,\"component\",[[28,\"-element\",[[23,0,[\"tag\"]]],null]],[[\"tagName\"],[[23,0,[\"tag\"]]]]]],null,{\"statements\":[[6,[23,1,[]],[[12,\"class\",[23,0,[\"allClasses\"]]],[13,2]],[[],[]],{\"statements\":[[14,3]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/ui-kit/box.hbs"
    }
  });

  _exports.default = _default;
});