define("travis/components/repos-list-tabs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    auth: Ember.inject.service(),
    features: Ember.inject.service(),
    tabStates: Ember.inject.service(),
    tagName: 'nav',
    classNames: ['travistab-nav', 'travistab-nav--underline', 'travistab-nav--sidebar'],
    tab: Ember.computed.alias('tabStates.sidebarTab'),
    currentUser: Ember.computed.alias('auth.currentUser'),
    classRunning: Ember.computed('tab', function () {
      var tab = this.tab;
      return tab === 'running' ? 'active' : '';
    }),
    classOwned: Ember.computed('tab', 'currentUser', function () {
      var tab = this.tab;
      var currentUser = this.currentUser;
      var classes = [];

      if (tab === 'owned') {
        classes.push('active');
      }

      if (currentUser) {
        classes.push('display-inline');
      }

      return classes.join(' ');
    }),
    classNew: Ember.computed('currentUser', function () {
      var currentUser = this.currentUser;

      if (currentUser) {
        return 'display-inline';
      }
    })
  });

  _exports.default = _default;
});