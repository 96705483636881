define("travis/controllers/dashboard/repositories", ["exports", "travis/utils/dashboard-repositories-sort"], function (_exports, _dashboardRepositoriesSort) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    page: 1,
    flashes: Ember.inject.service(),
    api: Ember.inject.service(),
    dashboardController: Ember.inject.controller('dashboard'),
    starredRepos: Ember.computed('model.starredRepos.[]', 'model.starredRepos.@each.currentBuildState', 'model.starredRepos.@each.currentBuildFinishedAt', function () {
      var repositories = this.get('model.starredRepos');
      return repositories.toArray().sort(_dashboardRepositoriesSort.default);
    }),
    filteredRepos: Ember.computed('model.repos.[]', 'account', 'model.accounts', 'model.repos.@each.currentBuildState', 'model.repos.@each.currentBuildFinishedAt', function () {
      var repositories = this.get('model.repos');
      var accountParam = this.account;
      var accounts = this.get('model.accounts');
      var account = accounts.filter(function (x) {
        if (accountParam) {
          if (x.id === accountParam) {
            return x;
          }
        } else {
          return null;
        }
      });
      var type = null;

      if (account && account[0]) {
        type = account[0].get('type');
      }

      var repos = repositories.filter(function (item) {
        if (!Ember.isBlank(account)) {
          if (Ember.isEqual(type, 'user')) {
            if (Ember.isEqual(item.get('owner.@type'), 'user')) {
              return item;
            }
          } else {
            if (Ember.isEqual(item.get('owner.login'), accountParam)) {
              return item;
            }
          }
        } else {
          return item;
        }
      }).sort(_dashboardRepositoriesSort.default);
      return repos;
    }),
    selectedOrg: Ember.computed('model.accounts', 'account', function () {
      var accounts = this.get('model.accounts');
      var account = this.account;
      var filteredAccount = accounts.filter(function (item) {
        if (item.get('login') === account) {
          return item;
        }
      });
      return filteredAccount[0];
    }),
    actions: {
      selectOrg: function selectOrg(org) {
        var login = Ember.isBlank(org) ? undefined : org.get('login');
        return this.set('account', login);
      }
    }
  });

  _exports.default = _default;
});