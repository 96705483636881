define("travis/templates/components/user-avatar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BmKceGIA",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\",true],[11,\"class\",[29,[[28,\"if\",[[23,0,[\"small\"]],\"avatar--small\",\"avatar\"],null]]]],[8],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"pseudo-avatar\"],[10,\"aria-hidden\",\"true\"],[11,\"data-initials\",[23,0,[\"userInitials\"]]],[8],[9],[0,\"\\n\"],[4,\"if\",[[23,0,[\"url\"]]],null,{\"statements\":[[0,\"    \"],[7,\"img\",true],[10,\"class\",\"real-avatar\"],[11,\"src\",[29,[[23,0,[\"avatarUrl\"]]]]],[11,\"srcset\",[29,[[23,0,[\"avatarUrl\"]],\" 1x, \",[23,0,[\"highResAvatarUrl\"]],\" 2x\"]]],[11,\"alt\",[29,[[23,0,[\"name\"]],\" avatar\"]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"],[4,\"if\",[[23,0,[\"showSubscriptionCheckmark\"]]],null,{\"statements\":[[0,\"  \"],[5,\"svg-image\",[],[[\"@name\",\"@class\"],[\"avatar-checkmark\",\"checkmark\"]]],[0,\"\\n  \"],[5,\"ember-tooltip\",[],[[\"@text\"],[[23,0,[\"subscriptionTooltipText\"]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,0,[\"providerIcon\"]]],null,{\"statements\":[[0,\"  \"],[5,\"svg-image\",[],[[\"@name\",\"@width\",\"@height\",\"@class\"],[[23,0,[\"providerIcon\"]],15,15,\"avatar__provider-icon\"]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/user-avatar.hbs"
    }
  });

  _exports.default = _default;
});