define("travis/templates/components/load-more", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lCSrm2bG",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[8],[0,\"\\n  \"],[14,1,[[23,0,[]]]],[0,\"\\n  \"],[7,\"div\",true],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"dynamicQuery\",\"isLoading\"]]],null,{\"statements\":[[0,\"      \"],[5,\"loading-indicator\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,0,[\"dynamicQuery\",\"hasNextPage\"]]],null,{\"statements\":[[0,\"      \"],[5,\"show-more-button\",[],[[\"@click\"],[[28,\"action\",[[23,0,[]],\"showMore\"],null]]]],[0,\"\\n    \"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/load-more.hbs"
    }
  });

  _exports.default = _default;
});