define("travis/utils/format-commit", ["exports", "travis/utils/format-sha"], function (_exports, _formatSha) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = formatCommit;

  function formatCommit(sha, branch) {
    var commitString = (0, _formatSha.default)(sha);

    if (branch) {
      commitString = "".concat(commitString, " (").concat(branch, ")");
    }

    return commitString;
  }
});