define("travis/helpers/config-get", ["exports", "travis/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.configGet = configGet;
  _exports.default = void 0;

  function configGet()
  /* , hash*/
  {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var path = params[0] || '';
    return Ember.get(_environment.default, path);
  }

  var _default = Ember.Helper.helper(configGet);

  _exports.default = _default;
});