define("travis/templates/components/header-burger-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "q8PudukZ",
    "block": "{\"symbols\":[\"@onClick\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"navigation-toggle\"],[8],[0,\"\\n  \"],[7,\"button\",true],[10,\"class\",\"tofuburger no-button\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],[23,1,[]]],null]],[10,\"type\",\"button\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"isOpen\"]]],null,{\"statements\":[[0,\"      \"],[5,\"svg-image\",[],[[\"@name\"],[\"icon-close\"]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[5,\"svg-image\",[],[[\"@name\"],[\"icon-tofuburger\"]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/header-burger-menu.hbs"
    }
  });

  _exports.default = _default;
});