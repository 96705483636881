define("travis/instance-initializers/raven-release", ["exports", "travis/config/environment", "raven-js"], function (_exports, _environment, _ravenJs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    var sha; // this is Heroku-specific, will not work in other environments

    if (_environment.default.environment === 'production') {
      sha = _environment.default.release.slice(0, 7);
    } else {
      sha = appInstance.application.version.slice(6, -1);
    }

    var env = window.location.href;
    var domain = env.includes('.org') ? 'org' : 'com';
    var release = "".concat(domain, "-").concat(sha);

    _ravenJs.default.setRelease(release);
  }

  var _default = {
    name: 'raven-release',
    after: 'sentry-setup',
    initialize: initialize
  };
  _exports.default = _default;
});