define("travis/components/profile-nav", ["exports", "travis/config/environment", "travis/services/external-links"], function (_exports, _environment, _externalLinks) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var billingEndpoint = _environment.default.billingEndpoint;

  var _default = Ember.Component.extend({
    tagName: '',
    accounts: Ember.inject.service(),
    features: Ember.inject.service(),
    activeModel: null,
    model: Ember.computed.reads('activeModel'),
    user: Ember.computed.reads('accounts.user'),
    organizations: Ember.computed.reads('accounts.organizations'),
    vcsType: Ember.computed.reads('user.vcsType'),
    vcsId: Ember.computed.reads('user.vcsId'),
    isProVersion: Ember.computed.reads('features.proVersion'),
    isNotProVersion: Ember.computed.not('isProVersion'),
    isEnterpriseVersion: Ember.computed.reads('features.enterpriseVersion'),
    isNotEnterpriseVersion: Ember.computed.not('isEnterpriseVersion'),
    isMatchGithub: Ember.computed.match('vcsType', /Github\S+$/),
    accountsForBeta: Ember.computed.filterBy('accounts.all', 'isMigrationBetaRequested', false),
    hasAccountsForBeta: Ember.computed.notEmpty('accountsForBeta'),
    accountName: Ember.computed.or('model.name', 'model.login'),
    billingUrl: Ember.computed.or('model.subscription.billingUrl', 'model.billingUrl'),
    accessSettingsUrl: Ember.computed('user.vcsType', 'user.vcsId', function () {
      return _externalLinks.vcsLinks.accessSettingsUrl(this.user.vcsType, {
        owner: this.user.login
      });
    }),
    reposToMigrate: Ember.computed.reads('model.githubAppsRepositoriesOnOrg'),
    showMigrateTab: Ember.computed.and('features.proVersion', 'isNotEnterpriseVersion', 'isMatchGithub'),
    showSubscriptionStatusBanner: Ember.computed.and('showSubscriptionTab', 'model.subscriptionError'),
    showMigrationBetaBanner: Ember.computed.and('isNotProVersion', 'isNotEnterpriseVersion', 'hasAccountsForBeta'),
    isOrganization: Ember.computed.reads('model.isOrganization'),
    hasAdminPermissions: Ember.computed.reads('model.permissions.admin'),
    isOrganizationAdmin: Ember.computed.and('isOrganization', 'hasAdminPermissions'),
    showOrganizationSettings: Ember.computed.and('isOrganizationAdmin', 'isProVersion'),
    showSubscriptionTab: Ember.computed('features.enterpriseVersion', 'model.isAssembla', 'model.isUser', function () {
      var isAssemblaUser = this.model.isUser && this.model.isAssembla;
      var isEnterprise = this.features.get('enterpriseVersion');
      return !isEnterprise && !isAssemblaUser && !!billingEndpoint;
    })
  });

  _exports.default = _default;
});