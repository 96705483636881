define("travis/components/repo-wrapper", ["exports", "travis/mixins/polling"], function (_exports, _polling) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_polling.default, {
    pollModels: 'repo',
    classNameBindings: ['isLoading:loading']
  });

  _exports.default = _default;
});