define("travis/components/forms/form-textarea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'textarea',
    classNames: ['travis-form__field-textarea'],
    attributeBindings: ['disabled', 'placeholder', 'rows', 'readonly'],
    disabled: false,
    readonly: false,
    placeholder: '',
    rows: 2,
    value: '',
    onChange: function onChange() {},
    onFocus: function onFocus() {},
    onBlur: function onBlur() {},
    onInit: function onInit() {},
    focusIn: function focusIn() {
      this.onFocus();
    },
    focusOut: function focusOut() {
      this.onBlur(this.value);
    },
    change: function change(_ref) {
      var target = _ref.target;
      this.set('value', target.value);
      this.onChange(this.value);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.onInit(this.elementId);
    }
  });

  _exports.default = _default;
});