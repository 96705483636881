define("travis/templates/components/pagination-navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8JhD/AGB",
    "block": "{\"symbols\":[\"page\"],\"statements\":[[4,\"if\",[[23,0,[\"showPagination\"]]],null,{\"statements\":[[0,\"  \"],[7,\"ul\",true],[10,\"role\",\"navigation\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"prevPage\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\",true],[8],[0,\"\\n        \"],[7,\"a\",true],[11,\"href\",[23,0,[\"prevPage\",\"url\"]]],[10,\"rel\",\"prev\"],[10,\"class\",\"pagination-button\"],[10,\"title\",\"Go to previous page\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"switchToPage\",[23,0,[\"prevPage\"]]],null]],[8],[0,\"\\n          prev\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"each\",[[24,[\"pages\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\",true],[8],[0,\"\\n\"],[4,\"if\",[[23,1,[\"num\"]]],null,{\"statements\":[[0,\"          \"],[7,\"a\",true],[11,\"href\",[23,1,[\"url\"]]],[11,\"class\",[29,[\"pagination-link \",[28,\"if\",[[23,1,[\"isCurrent\"]],\"active\"],null]]]],[11,\"title\",[29,[\"Go to page number \",[23,1,[\"num\"]]]]],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"switchToPage\",[23,1,[]]],null]],[8],[0,\"\\n            \"],[1,[23,1,[\"num\"]],false],[0,\"\\n          \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          ...\\n\"]],\"parameters\":[]}],[0,\"      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[4,\"if\",[[23,0,[\"nextPage\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\",true],[8],[0,\"\\n        \"],[7,\"a\",true],[11,\"href\",[23,0,[\"nextPage\",\"url\"]]],[10,\"rel\",\"next\"],[10,\"class\",\"pagination-button\"],[10,\"title\",\"Go to next page\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"switchToPage\",[23,0,[\"nextPage\"]]],null]],[8],[0,\"\\n          next\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/pagination-navigation.hbs"
    }
  });

  _exports.default = _default;
});