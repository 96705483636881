define("travis/controllers/owner/repositories", ["exports", "travis/services/insights"], function (_exports, _insights) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.OWNER_TABS = void 0;
  var OWNER_TABS = {
    REPOSITORIES: 'repositories',
    INSIGHTS: 'insights'
  };
  _exports.OWNER_TABS = OWNER_TABS;

  var _default = Ember.Controller.extend({
    queryParams: ['page', 'tab', 'timeInterval'],
    features: Ember.inject.service(),
    isLoading: false,
    page: 1,
    tab: OWNER_TABS.REPOSITORIES,
    requestPrivateInsights: true,
    timeInterval: _insights.DEFAULT_INSIGHTS_INTERVAL,
    defaultTimeInterval: _insights.DEFAULT_INSIGHTS_INTERVAL,
    isInsights: Ember.computed.equal('tab', OWNER_TABS.INSIGHTS),
    isPrivateInsightsViewable: Ember.computed.and('features.proVersion', 'builds.value.private'),
    includePrivateInsights: Ember.computed.and('isPrivateInsightsViewable', 'requestPrivateInsights'),
    repos: null,
    reposLoading: Ember.computed.equal('repos', null),
    builds: null,
    buildsReady: Ember.computed.bool('builds.isFinished'),
    actions: {
      setRequestPrivateInsights: function setRequestPrivateInsights(val) {
        this.set('requestPrivateInsights', val);
      }
    }
  });

  _exports.default = _default;
});