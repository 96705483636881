define("travis/routes/dashboard", ["exports", "travis/routes/basic"], function (_exports, _basic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basic.default.extend({
    needsAuth: true,
    features: Ember.inject.service(),
    accounts: Ember.inject.service(),
    model: function model(params) {
      return Ember.RSVP.hash({
        starredRepos: this.store.filter('repo', {
          active: true,
          sort_by: 'current_build:desc',
          starred: true
        }, function (repo) {
          return repo.starred && repo.active;
        }, ['starred', 'active'], true)
      });
    }
  });

  _exports.default = _default;
});