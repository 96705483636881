define("travis/components/x-tracer", ["exports", "travis/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    panelIsOpen: false,
    config: _environment.default,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (window.localStorage.TravisTracerIsOpen) {
        this.panelIsOpen = true;
      }

      TravisTracer.onRequest = function (req) {
        _this.requests.pushObject(req);

        _this.ensurePanelScrolledToBottom();
      };

      this.requests = [];
    },
    actions: {
      toggleOpen: function toggleOpen() {
        this.toggleProperty('panelIsOpen');
        this.rememberPanelOpenState();
        this.ensurePanelScrolledToBottom();
      }
    },
    ensurePanelScrolledToBottom: function ensurePanelScrolledToBottom() {
      Ember.run.next(function () {
        var panel = document.getElementById('tracer-panel');
        panel.scrollTop = panel.scrollHeight + 20;
      });
    },
    rememberPanelOpenState: function rememberPanelOpenState() {
      if (this.panelIsOpen) {
        window.localStorage.TravisTracerIsOpen = 'true';
      } else {
        delete window.localStorage.TravisTracerIsOpen;
      }
    }
  });

  _exports.default = _default;
});