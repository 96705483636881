define("travis/models/branch", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    defaultBranch: (0, _model.attr)('boolean'),
    lastBuild: (0, _model.belongsTo)('build'),
    exists_on_github: (0, _model.attr)('boolean'),
    builds: (0, _model.hasMany)('builds', {
      inverse: 'branch'
    }),
    repo: (0, _model.belongsTo)('repo', {
      inverse: '_branches'
    }),
    repoId: Ember.computed('id', function () {
      var id = this.id;
      var match = id.match(/\/repo\/(\d+)\//);

      if (match) {
        return match[1];
      }
    })
  });

  _exports.default = _default;
});