define("travis/adapters/organization", ["exports", "travis/adapters/v3"], function (_exports, _v) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _v.default.extend({
    includes: 'owner.installation',
    pathForType: function pathForType() {
      return 'orgs';
    }
  });

  _exports.default = _default;
});