define("travis/components/insights-privacy-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.INSIGHTS_PRIVACY_OPTIONS = void 0;
  var INSIGHTS_PRIVACY_OPTIONS = {
    PUBLIC: 'public builds',
    PRIVATE: 'public and private builds'
  };
  _exports.INSIGHTS_PRIVACY_OPTIONS = INSIGHTS_PRIVACY_OPTIONS;

  var _default = Ember.Component.extend({
    tagName: 'span',
    classNames: ['insights-privacy-selector'],
    classNameBindings: ['isPrivateViewable:insights-privacy-selector--selectable'],
    'data-test-insights-privacy-selector': '',
    isPrivateViewable: false,
    includePrivate: false,
    showFrame: false,
    availableOptions: Ember.computed('isPrivateViewable', function () {
      return this.isPrivateViewable ? Object.values(INSIGHTS_PRIVACY_OPTIONS) : [];
    }),
    currentState: Ember.computed('isPrivateViewable', 'includePrivate', function () {
      return this.isPrivateViewable && this.includePrivate ? INSIGHTS_PRIVACY_OPTIONS.PRIVATE : INSIGHTS_PRIVACY_OPTIONS.PUBLIC;
    }),
    setRequestPrivateInsights: function setRequestPrivateInsights() {},
    actions: {
      selectInsightScope: function selectInsightScope(option) {
        this.setRequestPrivateInsights(option === INSIGHTS_PRIVACY_OPTIONS.PRIVATE);
      }
    }
  });

  _exports.default = _default;
});