define("travis/controllers/getting-started", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    features: Ember.inject.service(),
    multiVcs: Ember.inject.service(),
    provider: Ember.computed.reads('multiVcs.currentProvider'),
    defaultProvider: Ember.computed.reads('multiVcs.primaryProvider'),
    selectedProvider: Ember.computed.or('provider', 'defaultProvider'),
    showGithub: Ember.computed.equal('selectedProvider', 'github'),
    showBitbucket: Ember.computed.equal('selectedProvider', 'bitbucket'),
    showGitlab: Ember.computed.equal('selectedProvider', 'gitlab'),
    showAssembla: Ember.computed.equal('selectedProvider', 'assembla')
  });

  _exports.default = _default;
});