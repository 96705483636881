define("travis/controllers/organization/billing", ["exports", "travis/mixins/controller/billing"], function (_exports, _billing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_billing.default, {});

  _exports.default = _default;
});