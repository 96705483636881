define("travis/templates/components/account-token", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "w3Vxkz4X",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"label-and-token\"],[8],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"label\"],[8],[0,\"\\n    Token\\n  \"],[9],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"token-field\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"showCopySuccess\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\",true],[10,\"class\",\"token-copied-text\"],[8],[0,\"\\n        Token copied!\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,0,[\"tokenIsVisible\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\",true],[10,\"class\",\"auth-token\"],[8],[0,\"\\n        \"],[1,[23,0,[\"token\"]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"span\",true],[10,\"class\",\"obfuscated-chars\"],[8],[0,\"\\n        \"],[1,[28,\"obfuscated-chars\",[20],null],false],[0,\"\\n      \"],[9],[0,\"\\n    \"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"token-actions\"],[8],[0,\"\\n  \"],[5,\"copy-button\",[[12,\"class\",\"copy-button\"]],[[\"@clipboardText\",\"@title\",\"@success\"],[[23,0,[\"token\"]],\"Copy to clipboard\",[28,\"action\",[[23,0,[]],\"copyTokenSuccessful\"],null]]],{\"statements\":[[0,\"\\n    \"],[5,\"svg-image\",[],[[\"@name\",\"@class\"],[\"icon-copy\",\"icon\"]]],[0,\"\\n    \"],[7,\"span\",true],[8],[0,\"Copy token\"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"],[7,\"button\",true],[10,\"class\",\"show-token\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"tokenVisibility\"],null]],[8],[0,\"\\n    \"],[5,\"svg-image\",[],[[\"@name\",\"@class\"],[\"icon-seemore\",\"icon\"]]],[0,\"\\n    \"],[7,\"span\",true],[8],[0,\"\\n      \"],[1,[28,\"if\",[[23,0,[\"tokenIsVisible\"]],\"Hide token\",\"View token\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/account-token.hbs"
    }
  });

  _exports.default = _default;
});