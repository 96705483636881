define("travis/components/header-broadcasts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    auth: Ember.inject.service(),
    features: Ember.inject.service(),
    broadcastsService: Ember.inject.service('broadcasts'),
    broadcasts: Ember.computed.alias('broadcastsService.broadcasts'),
    actions: {
      toggleBroadcasts: function toggleBroadcasts() {
        this.toggleProperty('showBroadcasts');
        return false;
      },
      markBroadcastAsSeen: function markBroadcastAsSeen(broadcast) {
        this.broadcastsService.markAsSeen(broadcast);
        return false;
      }
    }
  });

  _exports.default = _default;
});