define("travis/templates/integration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RYW0lLaT",
    "block": "{\"symbols\":[\"layout\"],\"statements\":[[5,\"scroll-here\",[],[[\"@immediate\"],[true]]],[0,\"\\n\"],[5,\"layouts/striped\",[],[[\"@useTailwind\",\"@isTopBarWhite\",\"@isFlush\"],[true,true,true]],{\"statements\":[[0,\"\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/integration.hbs"
    }
  });

  _exports.default = _default;
});