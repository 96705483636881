define("travis/templates/not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7GHcW4Iw",
    "block": "{\"symbols\":[],\"statements\":[[5,\"travis-layout\",[],[[\"@layoutName\"],[\"layouts/center\"]],{\"statements\":[[0,\"\\n  The requested page was not found.\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/not-found.hbs"
    }
  });

  _exports.default = _default;
});