define("travis/utils/fetch-all", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var fetchAll = function fetchAll(store, type, query) {
    return store.query(type, query).then(function (collection) {
      var nextPage = collection.get('meta.pagination.next');

      if (nextPage) {
        var limit = nextPage.limit,
            offset = nextPage.offset;
        return fetchAll(store, type, Ember.assign(query, {
          limit: limit,
          offset: offset
        }));
      }
    });
  };

  var _default = fetchAll;
  _exports.default = _default;
});