define("travis/routes/caches", ["exports", "travis/routes/basic"], function (_exports, _basic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basic.default.extend({
    api: Ember.inject.service(),
    needsAuth: true,
    setupController: function setupController()
    /* controller*/
    {
      this._super.apply(this, arguments);

      return this.controllerFor('repo').activate('caches');
    },
    model: function model() {
      var repo = this.modelFor('repo');
      var url = "/repo/".concat(repo.get('id'), "/caches");
      return this.api.get(url).then(function (data) {
        return consolidateCaches(repo, data);
      });
    }
  });

  _exports.default = _default;

  function consolidateCaches(repo, data) {
    var consolidatedCaches = {};
    var pushes = [],
        pullRequests = [];
    data['caches'].forEach(function (cacheData) {
      var branch = cacheData.branch;
      var consolidatedCache = consolidatedCaches[branch];

      if (consolidatedCache) {
        consolidatedCache.size += cacheData.size;

        if (consolidatedCache.last_modified < cacheData.last_modified) {
          consolidatedCache.last_modified = cacheData.last_modified;
        }
      } else {
        consolidatedCaches[branch] = cacheData;

        if (/PR./.test(branch)) {
          cacheData.type = 'pull_request';
          pullRequests.push(cacheData);
        } else {
          cacheData.type = 'push';
          pushes.push(cacheData);
        }
      }
    });
    return {
      repo: repo,
      pushes: pushes,
      pullRequests: pullRequests
    };
  }
});