define("travis/controllers/pull-requests", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    repoController: Ember.inject.controller('repo'),
    repo: Ember.computed.reads('model'),
    tab: Ember.computed.reads('repoController.tab')
  });

  _exports.default = _default;
});