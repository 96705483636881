define("travis/controllers/requests", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    repoController: Ember.inject.controller('repo'),
    queryParams: ['requestId'],
    slug: Ember.computed.reads('repoController.repo.slug'),
    lintUrl: Ember.computed('slug', function () {
      return "https://lint.travis-ci.org/".concat(this.slug);
    })
  });

  _exports.default = _default;
});