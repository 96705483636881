define("travis/templates/error404", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PvYa/e/r",
    "block": "{\"symbols\":[],\"statements\":[[5,\"travis-layout\",[[12,\"class\",\"error error404\"]],[[\"@layoutName\"],[\"layouts/error\"]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"error-bg error-full-size\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"hill-left error-full-size\"],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"hill-right error-full-size\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"error-excavator error-full-size\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"error-travis error-full-size\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"error-text\"],[8],[0,\"\\n    \"],[7,\"h1\",true],[8],[0,\"\\n      404: Something's Missing\\n    \"],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"\\n      We're sorry! It seems like this page cannot be found.\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/error404.hbs"
    }
  });

  _exports.default = _default;
});