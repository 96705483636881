define("travis/templates/components/build-tile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UeLI7qUq",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"build\",\"number\"]]],null,{\"statements\":[[0,\"  \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"build\",[23,0,[\"build\",\"id\"]]]],{\"statements\":[[0,\"\\n    \"],[5,\"status-icon\",[],[[\"@status\"],[[23,0,[\"build\",\"state\"]]]]],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"build-tile-number\"],[8],[0,\"\\n      #\"],[1,[23,0,[\"build\",\"number\"]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/build-tile.hbs"
    }
  });

  _exports.default = _default;
});