define("travis/components/forms/form-switch", ["exports", "travis/utils/abstract-method"], function (_exports, _abstractMethod) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'button',
    classNames: ['switch', 'inline-block', 'travis-form__field--switch'],
    classNameBindings: ['checked:active', 'disabled:disabled'],
    attributeBindings: ['ariaChecked:aria-checked', 'role', 'type'],
    type: 'button',
    role: 'switch',
    checked: false,
    disabled: false,
    onInit: function onInit() {},
    onChange: function onChange() {},
    onToggle: (0, _abstractMethod.default)('onToggle'),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.onInit(this.elementId);
    },
    'aria-checked': Ember.computed('checked', function () {
      return this.checked ? 'true' : 'false';
    }),
    click: function click() {
      if (!this.disabled) {
        this.onChange(!this.checked);
      }
    }
  });

  _exports.default = _default;
});