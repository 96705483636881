define("travis/templates/components/build-minutes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Kh70e5au",
    "block": "{\"symbols\":[],\"statements\":[[5,\"insights-glance\",[],[[\"@isLoading\",\"@isEmpty\",\"@title\",\"@statistic\",\"@labels\",\"@values\",\"@datasetTitle\",\"@centerline\"],[[23,0,[\"isLoading\"]],[23,0,[\"isEmpty\"]],\"Total Job Minutes\",[23,0,[\"totalBuildText\"]],[23,0,[\"labels\"]],[23,0,[\"buildMins\"]],\"Minutes\",[23,0,[\"avgBuildMins\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/build-minutes.hbs"
    }
  });

  _exports.default = _default;
});