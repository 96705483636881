define("travis/components/layouts/striped-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'section',
    classNames: ['layout-striped-section'],
    classNameBindings: ['hasBackground:layout-striped-section--with-bg'],
    hasBackground: false
  });

  _exports.default = _default;
});