define("travis/services/status-images", ["exports", "travis/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.IMAGE_FORMATS = void 0;
  var IMAGE_FORMATS = {
    'Image URL': 'imageUrl',
    'Markdown': 'markdownImageString',
    'Textile': 'textileImageString',
    'Rdoc': 'rdocImageString',
    'AsciiDoc': 'asciidocImageString',
    'RST': 'rstImageString',
    'Pod': 'podImageString',
    'CCTray': 'ccXml'
  };
  _exports.IMAGE_FORMATS = IMAGE_FORMATS;

  var _default = Ember.Service.extend({
    auth: Ember.inject.service(),
    features: Ember.inject.service(),
    imageUrl: function imageUrl(repo, branch) {
      var prefix = "".concat(location.protocol, "//").concat(location.host); // the ruby app (waiter) does an indirect, internal redirect to api on build status images
      // but that does not work if you only run `ember serve`
      // so in development we use the api endpoint directly

      if (_environment.default.environment === 'development') {
        prefix = _environment.default.apiEndpoint;
      }

      var slug = repo.get('slug'); // In Enterprise you can toggle public mode, where even "public" repositories are hidden
      // in which cases we need to generate a token for all images

      if (!_environment.default.publicMode || repo.get('private')) {
        var token = this.auth.assetToken;
        return "".concat(prefix, "/").concat(slug, ".svg?token=").concat(token).concat(branch ? "&branch=".concat(branch) : '');
      } else {
        return "".concat(prefix, "/").concat(slug, ".svg").concat(branch ? "?branch=".concat(encodeURIComponent(branch)) : '');
      }
    },
    repositoryUrl: function repositoryUrl(repo) {
      return "https://".concat(location.host, "/").concat(repo.get('slug'));
    },
    markdownImageString: function markdownImageString(repo, branch) {
      var url = this.repositoryUrl(repo);
      var imageUrl = this.imageUrl(repo, branch);
      return "[![Build Status](".concat(imageUrl, ")](").concat(url, ")");
    },
    textileImageString: function textileImageString(repo, branch) {
      var url = this.repositoryUrl(repo);
      var imageUrl = this.imageUrl(repo, branch);
      return "!".concat(imageUrl, "!:").concat(url);
    },
    rdocImageString: function rdocImageString(repo, branch) {
      var url = this.repositoryUrl(repo);
      var imageUrl = this.imageUrl(repo, branch);
      return "{<img src=\"".concat(imageUrl, "\" alt=\"Build Status\" />}[").concat(url, "]");
    },
    asciidocImageString: function asciidocImageString(repo, branch) {
      var url = this.repositoryUrl(repo);
      var imageUrl = this.imageUrl(repo, branch);
      return "image:".concat(imageUrl, "[\"Build Status\", link=\"").concat(url, "\"]");
    },
    rstImageString: function rstImageString(repo, branch) {
      var url = this.repositoryUrl(repo);
      var imageUrl = this.imageUrl(repo, branch);
      return ".. image:: ".concat(imageUrl, "\n    :target: ").concat(url);
    },
    podImageString: function podImageString(repo, branch) {
      var url = this.repositoryUrl(repo);
      var imageUrl = this.imageUrl(repo, branch);
      return "=for html <a href=\"".concat(url, "\"><img src=\"").concat(imageUrl, "\"></a>");
    },
    ccXml: function ccXml(repo, branch) {
      var url = "#".concat(_environment.default.apiEndpoint, "/repos/").concat(repo.get('slug'), "/cc.xml");

      if (branch) {
        url = "".concat(url, "?branch=").concat(branch);
      }

      if (repo.get('private')) {
        var token = this.auth.assetToken;
        var delimiter = url.indexOf('?') === -1 ? '?' : '&';
        url = "".concat(url).concat(delimiter, "token=").concat(token);
      }

      return url;
    }
  });

  _exports.default = _default;
});