define("travis/components/billing/price", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isGithubTrial: Ember.computed.and('subscription.isGithub', 'trial.hasActiveTrial'),
    isNotGithubTrial: Ember.computed.not('isGithubTrial'),
    discount: Ember.computed.reads('subscription.discount'),
    durationInMonths: Ember.computed.reads('discount.durationInMonths'),
    duration: Ember.computed.reads('discount.duration'),
    discountIsValid: Ember.computed.reads('discount.valid'),
    percentOff: Ember.computed.reads('discount.percentOff'),
    amountOffInCents: Ember.computed.reads('discount.amountOff'),
    amountOff: Ember.computed('amountOffInCents', function () {
      return this.amountOffInCents && Math.floor(this.amountOffInCents / 100);
    })
  });

  _exports.default = _default;
});