define("travis/components/build-message", ["exports", "travis/utils/format-config"], function (_exports, _formatConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var escape = Ember.Handlebars.Utils.escapeExpression;

  var _default2 = Ember.Component.extend({
    tagName: '',
    readableMessage: Ember.computed('message.code', 'message.key', 'message.args', function () {
      var _this$message = this.message,
          code = _this$message.code,
          key = _this$message.key,
          args = _this$message.args;

      if (this[code]) {
        return Ember.String.htmlSafe(this[code](key, args));
      } else {
        return Ember.String.htmlSafe("unrecognised message code ".concat(format(code)));
      }
    }),
    alias_key: function alias_key(key, args) {
      return "".concat(format(key), ": key ").concat(format(args.alias), " is an alias for ").concat(format(args.key), ", using ").concat(format(args.key));
    },
    alias_value: function alias_value(key, args) {
      return "".concat(format(key), ": value ").concat(format(args.alias), " is an alias for ").concat(format(args.value), ", using ").concat(format(args.value));
    },
    cast: function cast(key, args) {
      return "".concat(format(key), ": casting value ").concat(format(args.given_value), " (").concat(format(args.given_type), ") to ").concat(format(args.value), " (").concat(format(args.type), ")");
    },
    condition: function condition(key, args) {
      return "".concat(format(key), ": condition ").concat(format(args.condition), " does not match, skipping notification");
    },
    default: function _default(key, args) {
      return "".concat(format(key), ": missing ").concat(format(args.key), ", using the default ").concat(format(args.default));
    },
    deprecated: function deprecated(key, args) {
      return "".concat(format(key), " is deprecated: ").concat(format(args.info));
    },
    deprecated_key: function deprecated_key(key, args) {
      return "".concat(format(key), ": deprecated key ").concat(format(args.key), " (").concat(escape(args.info), ")");
    },
    deprecated_value: function deprecated_value(key, args) {
      return "".concat(format(key), ": deprecated value ").concat(format(args.value), " (").concat(escape(args.info), ")");
    },
    downcase: function downcase(key, args) {
      return "".concat(format(key), ": downcasing ").concat(format(args.value));
    },
    duplicate: function duplicate(key, args) {
      return "".concat(format(key), ": duplicate values: ").concat(format(args.values));
    },
    duplicate_key: function duplicate_key(key, args) {
      return "".concat(format(key), ": duplicate key: ").concat(format(args.key));
    },
    edge: function edge(key, args) {
      return "".concat(format(key), " is experimental and might change or be removed");
    },
    flagged: function flagged(key, args) {
      return "".concat(format(key), " your repository must be feature flagged for ").concat(format(args.key), " to be used");
    },
    required: function required(key, args) {
      return "".concat(format(key), ": missing required key ").concat(format(args.key));
    },
    secure: function secure(key, args) {
      return "".concat(format(key), ": using a plain string on a key that expects an encrypted string");
    },
    empty: function empty(key, args) {
      return "".concat(format(key), ": empty section");
    },
    find_key: function find_key(key, args) {
      return "".concat(format(key), ": key ").concat(format(args.original), " is not known, but ").concat(format(args.key), " is, using ").concat(format(args.key));
    },
    find_value: function find_value(key, args) {
      return "".concat(format(key), ": value ").concat(format(args.original), " is not known, but ").concat(format(args.value), " is, using ").concat(format(args.value));
    },
    clean_key: function clean_key(key, args) {
      return "".concat(format(key), ": key ").concat(format(args.original), " contains special characters, using ").concat(format(args.key));
    },
    clean_value: function clean_value(key, args) {
      return "".concat(format(key), ": value ").concat(format(args.original), " is not known, but ").concat(format(args.value), " is, using ").concat(format(args.value));
    },
    overwrite: function overwrite(key, args) {
      return "".concat(format(key), ": both ").concat(format(args.key), " and ").concat(format(args.other), " given, ").concat(format(args.key), " overwrites ").concat(format(args.other));
    },
    strip_key: function strip_key(key, args) {
      return "".concat(format(key), ": key ").concat(format(args.original), " contains whitespace, using ").concat(format(args.key));
    },
    underscore_key: function underscore_key(key, args) {
      return "".concat(format(key), ": key ").concat(format(args.original), " is not underscored, using ").concat(format(args.key));
    },
    unexpected_seq: function unexpected_seq(key, args) {
      return "".concat(format(key), ": ").concat(format(args.key), " unexpected sequence, using the first value (").concat(format(args.value), ")");
    },
    unknown_key: function unknown_key(key, args) {
      return "".concat(format(key), ": unknown key ").concat(format(args.key), " (").concat(format(args.value), ")");
    },
    unknown_value: function unknown_value(key, args) {
      return "".concat(format(key), ": unknown value ").concat(format(args.value));
    },
    unknown_default: function unknown_default(key, args) {
      return "".concat(format(key), ": unknown value ").concat(format(args.value), ", using the default ").concat(format(args.default));
    },
    unknown_var: function unknown_var(key, args) {
      return "".concat(format(key), ": unknown template variable ").concat(format(args.var));
    },
    unsupported: function unsupported(key, args) {
      return "".concat(format(key), ": the key ").concat(format(args.key), " (").concat(format(args.value), ") is not supported on the ").concat(format(args.on_key), " ").concat(format(args.on_value));
    },
    invalid_type: function invalid_type(key, args) {
      return "".concat(format(key), ": unexpected ").concat(format(args.actual), ", expected ").concat(format(args.expected), " (").concat(format(args.value), ")");
    },
    invalid_format: function invalid_format(key, args) {
      return "".concat(format(key), ": invalid format ").concat(format(args.value));
    },
    invalid_condition: function invalid_condition(key, args) {
      return "".concat(format(key), ": invalid condition ").concat(format(args.condition));
    },
    invalid_env_var: function invalid_env_var(key, args) {
      return "".concat(format(key), ": invalid env var ").concat(format(args.var));
    },
    skip_allow_failure: function skip_allow_failure(key, args) {
      return "".concat(format(key), ": skipping jobs allow failure rule #").concat(format(args.number), " because its condition does not match: ").concat(format(args.condition));
    },
    skip_exclude: function skip_exclude(key, args) {
      return "".concat(format(key), ": skipping jobs exclude rule #").concat(format(args.number), " because its condition does not match: ").concat(format(args.condition));
    },
    skip_import: function skip_import(key, args) {
      return "".concat(format(key), ": skipping import ").concat(format(args.source), " because its condition does not match: ").concat(format(args.condition));
    },
    skip_job: function skip_job(key, args) {
      return "".concat(format(key), ": skipping job #").concat(format(args.number), " because its condition does not match: ").concat(format(args.condition));
    },
    skip_notification: function skip_notification(key, args) {
      return "".concat(format(key), ": skipping notification ").concat(format(args.type), " because its condition does not match: ").concat(format(args.condition));
    },
    skip_stage: function skip_stage(key, args) {
      return "".concat(format(key), ": skipping stage #").concat(format(args.number), " because its condition does not match: ").concat(format(args.condition));
    },
    iconClass: Ember.computed('message.level', function () {
      var level = this.get('message.level');
      return "icon icon-level icon-".concat(level);
    }),
    tooltipText: Ember.computed('message.level', function () {
      var level = this.get('message.level');
      return {
        info: 'information',
        warn: 'warning',
        error: 'error',
        alert: 'alert'
      }[level];
    }),
    hasMessageSrc: Ember.computed.notEmpty('message.src'),
    hasMessageLine: Ember.computed.notEmpty('message.line'),
    isLinkable: Ember.computed.and('hasMessageSrc', 'hasMessageLine'),
    lineLink: Ember.computed('message.src', 'message.line', function () {
      var _this$message2 = this.message,
          src = _this$message2.src,
          line = _this$message2.line;
      return "#".concat((0, _formatConfig.codeblockName)(src), ".").concat(line + 1);
    })
  });

  _exports.default = _default2;

  function format(obj, length) {
    length = length || 30;
    return "<code>".concat(escape(truncate(dump(obj, length), 30)), "</code>");
  }

  function dump(obj, length) {
    switch (Ember.typeOf(obj)) {
      case 'array':
        return "[".concat(obj.map(function (obj) {
          return dump(obj, 10);
        }).join(', '), "]");

      case 'object':
        return "{ ".concat(Object.entries(obj).map(function (entry) {
          return "".concat(entry[0], ": ").concat(dump(entry[1], 10));
        }).join(', '), " }");

      case 'string':
        return "".concat(truncate(obj, length));

      default:
        return obj;
    }
  }

  function truncate(str, length) {
    if (str.length > length) {
      str = "".concat(str.substring(0, length), " ...");
    }

    return str;
  }
  /* eslint-enable max-len */

});