define("travis/routes/organization/settings", ["exports", "travis/routes/basic"], function (_exports, _basic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basic.default.extend({
    features: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (!this.get('features.proVersion')) {
        this.transitionTo('organization.repositories', this.modelFor('organization'));
      }
    },
    model: function model() {
      var organization = this.modelFor('organization');

      if (organization.permissions.admin !== true) {
        this.transitionTo('organization.repositories', organization);
      }

      var preferences = this.store.query('preference', {
        organization_id: organization.id
      });
      return Ember.RSVP.hash({
        organization: organization,
        preferences: preferences
      });
    }
  });

  _exports.default = _default;
});