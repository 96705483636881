define("travis/serializers/v3", ["exports", "ember-data/serializers/json", "travis/utils/wrap-with-array", "travis/utils/traverse-payload"], function (_exports, _json, _wrapWithArray, _traversePayload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  // Currently the way we normalize payload is as follows:
  //
  // * we extract attributes using a default implementation of `extractAttributes`
  //   from JSON serializer that we inherit from. It just grabs all of the defined
  //   attributes from the payload.
  //   * we also remove any @ attributes before getting them to the attributes
  //   section
  // * then we extract relationships:
  //   * we go through each relationship and get the hash or an array of hashes in
  //     case of a hasMany relationship
  //   * JSON API accepts relationships in a following way:
  //
  //       {
  //         relationships: {
  //           build: {
  //             data: { id: 1, type: 'build' }
  //           },
  //           jobs: {
  //             data: [
  //               { id: 1, type: 'job' },
  //               { id: 2, type: 'job' }
  //             ]
  //           }
  //         }
  //       }
  //   * during a first pass we save relationships as a full record, so instead of
  //     passing just id and type, we also pass attributes, relationships etc. We
  //     also save hasMany relationships a little bit differently, so after
  //     running through `extractRelationships` a record could look like this:
  //
  //       {
  //         relationships: {
  //           build: {
  //             data: {
  //               id: 1,
  //               type: 'build',
  //               attributes: {
  //                 number: '1',
  //                 state: 'passed'
  //               },
  //               relationships: {}
  //             },
  //             included: [],
  //             meta: {
  //               representation: 'standard'
  //             }
  //           },
  //           jobs: [
  //             {
  //               data: {
  //                 id: 1,
  //                 type: 'job',
  //                 attributes: {
  //                   number: '1.1',
  //                   state: 'passed'
  //                 },
  //                 relationships: {},
  //               },
  //               included: [],
  //               meta: {
  //                 representation: 'standard'
  //               }
  //             }
  //           ]
  //         }
  //       }
  //
  //   * note a few things here:
  //     * records inside relationships may also have their own related records,
  //       so we end up with a tree of nested records
  //     * records also have `included` array, which during normalization will be
  //       copied to the top most record
  //     * records also have a meta section that keeps any information that we may
  //       need later (in this case we keep representation to know which records
  //       we should load into the store and which should just be left as
  //       relationship info)
  //     * hasMany relationship structure differs (it's an array of JSON API
  //       records instead of just `data: []`)
  //   * after relationships are extracted this way it will be easier to further
  //     process them in `normalize`
  //   * in `normalize` we loop through each relationship and put any related
  //     records with a `standard` representation to the `included` array. This
  //     ensures that they're loaded into the store
  //     * we ommit other representations, because we don't have any mechanism to
  //       load missing data
  //     * while looping through relationships we also fix relationships to look
  //       like JSON API's relationships: we leave only id and type and for
  //       hasMany relationships we also change the structure
  var _default = _json.default.extend({
    extractRelationships: function extractRelationships(modelClass, resourceHash) {
      var _this = this;

      var relationships = {};
      modelClass.eachRelationship(function (key, relationshipMeta) {
        var relationship = null;

        var relationshipKey = _this.keyForRelationship(key, relationshipMeta.kind, 'deserialize');

        var relationshipHash = resourceHash[relationshipKey];

        if (relationshipHash) {
          var data = null;

          if (relationshipMeta.kind === 'belongsTo') {
            if (relationshipMeta.options.polymorphic) {
              // extracting a polymorphic belongsTo may need more information
              // than the type and the hash (which might only be an id) for the
              // relationship, hence we pass the key, resource and
              // relationshipMeta too
              var options = {
                key: key,
                resourceHash: resourceHash,
                relationshipMeta: relationshipMeta
              };
              data = _this.extractPolymorphicRelationship(relationshipMeta.type, relationshipHash, options);
            } else {
              data = _this.extractRelationship(relationshipMeta.type, relationshipHash);
            }
          } else if (relationshipMeta.kind === 'hasMany') {
            if (!Ember.isNone(relationshipHash)) {
              data = new Array(relationshipHash.length);

              for (var i = 0, l = relationshipHash.length; i < l; i++) {
                var item = relationshipHash[i];
                data[i] = _this.extractRelationship(relationshipMeta.type, item);
              }
            }
          }

          relationship = data;
        }

        if (relationship) {
          relationships[key] = relationship;
        }
      });
      return relationships;
    },
    extractRelationship: function extractRelationship(type, hash) {
      if (hash && !hash.id && hash['@href']) {
        hash.id = hash['@href'];
      }

      var relationshipHash = this._super.apply(this, arguments);

      if (relationshipHash && relationshipHash['@type']) {
        relationshipHash.type = this.getType(relationshipHash['@type']);
      } else if (relationshipHash && !relationshipHash.type) {
        relationshipHash.type = type;
      }

      var modelClass = this.store.modelFor(relationshipHash.type);
      var serializer = this.store.serializerFor(relationshipHash.type);
      return serializer.normalize(modelClass, relationshipHash);
    },
    keyForRelationship: function keyForRelationship(key
    /* , typeClass, method*/
    ) {
      if (key === 'repo') {
        return 'repository';
      } else if (key && key.underscore) {
        return key.underscore();
      } else {
        return key;
      }
    },
    extractAttributes: function extractAttributes()
    /* modelClass,resourceHash*/
    {
      var attributes = this._super.apply(this, arguments);

      for (var key in attributes) {
        if (key.startsWith('@')) {
          delete attributes.key;
        }
      }

      return attributes;
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload
    /* , id, requestType*/
    ) {
      this._fixReferences(payload);

      return this._super.apply(this, arguments);
    },
    normalizeArrayResponse: function normalizeArrayResponse(store, primaryModelClass, payload
    /* , id, requestType*/
    ) {
      var _this2 = this;

      var documentHash = {
        data: null,
        included: []
      };
      var meta = this.extractMeta(store, primaryModelClass, payload) || {},
          pagination = payload['@pagination'];

      if (pagination) {
        meta.pagination = pagination;
      }

      var metaType = Ember.typeOf(meta);
      var metaIsObject = metaType == 'object';
      var errorMessage = "The 'meta' returned from 'extractMeta' has to be an object, not ".concat(metaType, ".");
      (false && !(metaIsObject) && Ember.assert(errorMessage, metaIsObject));
      documentHash.meta = meta;
      var items;
      var type = payload['@type'];

      if (type) {
        items = payload[type];
      } else {
        var plural = "".concat(primaryModelClass.modelName.underscore(), "s");
        items = payload[plural];
      }

      documentHash.data = items.map(function (item) {
        var _this2$normalize = _this2.normalize(primaryModelClass, item),
            data = _this2$normalize.data,
            included = _this2$normalize.included;

        if (included) {
          var _documentHash$include;

          (_documentHash$include = documentHash.included).push.apply(_documentHash$include, _toConsumableArray(included));
        }

        return data;
      });
      return documentHash;
    },
    normalize: function normalize(modelClass, resourceHash) {
      var _this$_super = this._super.apply(this, arguments),
          data = _this$_super.data,
          meta = _this$_super.meta,
          included = _this$_super.included;

      if (!resourceHash['@type']) {
        return {
          data: data,
          included: included,
          meta: meta
        };
      }

      meta = meta || {};
      included = included || [];

      if (!meta['representation']) {
        meta.representation = resourceHash['@representation'];
      } // if we have relationship data, attempt to include those as sideloaded
      // records by adding them to the included array.
      // We must have both relationships *and* included specified for this to
      // work.


      if (data.relationships) {
        Object.keys(data.relationships).forEach(function (key) {
          var relationship = data.relationships[key];
          var relationshipHashes = (0, _wrapWithArray.default)(relationship);
          relationshipHashes.forEach(function (relationshipHash) {
            var meta = relationshipHash.meta || {};
            var relationshipIncluded = relationshipHash.included || [];

            if (meta.representation === 'standard') {
              included.push(relationshipHash.data);
            }

            relationshipIncluded.forEach(function (item) {
              return included.push(item);
            });
          });

          if (Ember.isArray(relationship)) {
            data.relationships[key] = {
              data: relationship.map(function (_ref) {
                var data = _ref.data;
                var id = data.id,
                    type = data.type;
                return {
                  id: id,
                  type: type
                };
              })
            };
          } else {
            data.relationships[key] = {
              data: {
                id: relationship.data.id,
                type: relationship.data.type
              }
            };
          }
        });
      }

      return {
        data: data,
        included: included,
        meta: meta
      };
    },
    keyForAttribute: function keyForAttribute(key) {
      return Ember.String.underscore(key);
    },
    getType: function getType(type) {
      return type === 'repository' ? 'repo' : type;
    },
    _fixReferences: function _fixReferences(payload) {
      var byHref = {},
          records;

      if (payload['@type']) {
        // API V3 doesn't return all of the objects in a full representation
        // If an object is present in one place in the response, all of the
        // other occurences will be just references of a kind - they will just
        // include @href property.
        //
        // I don't want to identify records by href in ember-data, so here I'll
        // set an id and a @type field on all of the references.
        //
        // First we need to group all of the items in the response by href:
        (0, _traversePayload.default)(payload, function (item) {
          var href = item['@href'];

          if (href) {
            var _records = byHref[href];

            if (_records) {
              _records.push(item);
            } else {
              byHref[href] = [item];
            }
          }
        }); // Then we can choose a record with an id for each href and put the id
        // in all of the other occurences.

        for (var href in byHref) {
          records = byHref[href];
          var recordWithAnId = records.find(function (record) {
            return record.id;
          });

          if (recordWithAnId) {
            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
              for (var _iterator = records[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                var record = _step.value;
                record.id = recordWithAnId.id; // record['@type'] = recordWithAnId['@type'];
              }
            } catch (err) {
              _didIteratorError = true;
              _iteratorError = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion && _iterator.return != null) {
                  _iterator.return();
                }
              } finally {
                if (_didIteratorError) {
                  throw _iteratorError;
                }
              }
            }
          }
        }
      }

      return payload;
    }
  });

  _exports.default = _default;
});