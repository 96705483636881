define("travis/routes/legacy-repo-url", ["exports", "travis/utils/vcs"], function (_exports, _vcs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    templateName: 'error404',
    beforeModel: function beforeModel(transition) {
      var _transition$to = transition.to,
          params = _transition$to.params,
          queryParams = _transition$to.queryParams;
      var owner = params.owner,
          repo = params.repo,
          method = params.method,
          id = params.id,
          view = params.view;
      var provider,
          routeName = 'provider',
          routeModels = [];
      var vcsConfig = (0, _vcs.vcsConfigByUrlPrefix)(owner);
      var isLegacyUrl = Ember.isEmpty(vcsConfig);

      if (isLegacyUrl) {
        provider = _vcs.defaultVcsConfig.urlPrefix;
      } else {
        // params include provider, so swap them accordingly
        var _ref = [owner, repo, method, id, view];
        provider = _ref[0];
        owner = _ref[1];
        repo = _ref[2];
        method = _ref[3];
        id = _ref[4];
      }

      routeModels.push(provider);

      if (owner) {
        routeName = 'owner';
        routeModels.push(owner);
      }

      if (repo) {
        routeName = 'repo';
        routeModels.push(repo);
      }

      if (method) {
        routeName = method;
      }

      if (id) {
        routeName = method.singularize();
        routeModels.push(id);
      }

      if (view) {
        routeName = "".concat(routeName, ".").concat(view);
      }

      if (this._router.hasRoute(routeName)) {
        transition.abort();
        this.transitionTo.apply(this, [routeName].concat(routeModels, [{
          queryParams: queryParams
        }]));
      }
    }
  });

  _exports.default = _default;
});