define("travis/components/multi-signin-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    animation: Ember.inject.service(),
    auth: Ember.inject.service(),
    multiVcs: Ember.inject.service(),
    router: Ember.inject.service(),
    isLinkToSignin: false,
    isOpen: false,
    isNotLinkToSignin: Ember.computed.not('isLinkToSignin'),
    isNotSignedIn: Ember.computed.not('auth.signedIn'),
    isOpenable: Ember.computed.and('isNotLinkToSignin', 'multiVcs.enabled'),
    showVcsOptions: Ember.computed.and('isOpenable', 'isOpen'),
    clickMainButton: function clickMainButton() {
      if (this.isLinkToSignin) {
        this.router.transitionTo('signin');
      } else if (this.multiVcs.disabled) {
        this.auth.signIn();
      }
    },
    close: function close() {
      this.set('isOpen', false);
    },
    open: function open() {
      this.set('isOpen', this.isOpenable);
    },
    signInWith: function signInWith(provider) {
      this.auth.signInWith(provider);
    }
  });

  _exports.default = _default;
});