define("travis/transitions", ["exports", "travis/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  var duration;

  if (_environment.default.environment === 'test') {
    duration = 0;
  } else {
    duration = 200;
  }

  function _default() {
    this.transition(this.hasClass('liquid-dialog-container'), this.use('explode', {
      pick: '.ember-modal-overlay',
      use: ['fade', {
        duration: duration
      }]
    }, {
      pick: '.ember-modal-dialog',
      use: ['fade', {
        duration: duration
      }]
    }));
  }
});