define("travis/components/user-avatar", ["exports", "url"], function (_exports, _url) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    classNames: ['avatar-wrapper'],
    userInitials: Ember.computed('name', function () {
      var name = this.name;

      if (name) {
        var arr = name.split(' ');
        var initials = '';

        if (arr.length >= 2) {
          initials = arr[0].split('')[0] + arr[1].split('')[0];
        } else {
          initials = arr[0].split('')[0];
        }

        return initials;
      }
    }),
    avatarUrl: Ember.computed('url', 'size', function () {
      var url;
      var size = this.size;

      if (!size) {
        size = 32;
      }

      try {
        url = new _url.default(this.url);
      } catch (e) {
        return "".concat(this.url, "?v=3&s=").concat(size); // relative image address
      }

      url.searchParams.set('v', '3');
      url.searchParams.set('s', size);
      return url.href;
    }),
    highResAvatarUrl: Ember.computed('url', 'size', function () {
      var url;
      var size = this.size;

      if (!size) {
        size = 32;
      }

      size = size * 2; // high-dpi

      try {
        url = new _url.default(this.url);
      } catch (e) {
        return "".concat(this.url, "?v=3&s=").concat(size); // relative image address
      }

      url.searchParams.set('v', '3');
      url.searchParams.set('s', size);
      return url.href;
    }),
    showSubscriptionCheckmark: Ember.computed('showSubscriptionStatus', 'account.subscription.isSubscribed', 'account.education', function () {
      var showStatus = this.showSubscriptionStatus;
      var isSubscribed = this.get('account.subscription.isSubscribed');
      var education = this.get('account.education');
      var manualSubscriptionExpired = this.get('account.subscription.manualSubscriptionExpired');
      return showStatus && !manualSubscriptionExpired && (isSubscribed || education);
    }),
    subscriptionTooltipText: Ember.computed('account.education', function () {
      var education = this.get('account.education');
      return "This account has an ".concat(education ? 'education' : 'active', " subscription");
    })
  });

  _exports.default = _default;
});