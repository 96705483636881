define("travis/templates/components/ui-kit/button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0DutEFRo",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[7,\"button\",false],[12,\"role\",[23,0,[\"role\"]]],[12,\"disabled\",[23,0,[\"disabled\"]]],[12,\"class\",[29,[\"\\n    rounded-md uppercase px-3 py-2 font-bold\\n    bg-\",[23,0,[\"bgColor\"]],\"\\n    hover:bg-\",[23,0,[\"hoverBgColor\"]],\"\\n    text-\",[23,0,[\"labelColor\"]],\"\\n    text-xs\\n    tracking-lg\\n\\n    \",[28,\"if\",[[23,0,[\"invert\"]],\"border-px border-solid\"],null],\"\\n    border-\",[23,0,[\"borderColor\"]],\"\\n  \"]]],[13,1],[3,\"on\",[\"click\",[28,\"action\",[[23,0,[]],\"handleClick\"],null]]],[8],[14,2],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/ui-kit/button.hbs"
    }
  });

  _exports.default = _default;
});