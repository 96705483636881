define("travis/routes/error", ["exports", "travis/routes/basic"], function (_exports, _basic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basic.default.extend({
    resetController: function resetController(controller, isExiting
    /* , transition*/
    ) {
      if (isExiting) {
        controller.set('message', null);
        controller.set('layoutName', null);
      }
    }
  });

  _exports.default = _default;
});