define("travis/templates/components/repos-list-tabs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NAc/o9dD",
    "block": "{\"symbols\":[],\"statements\":[[7,\"ul\",true],[8],[0,\"\\n  \"],[7,\"li\",true],[10,\"id\",\"tab_owned\"],[11,\"class\",[23,0,[\"classOwned\"]]],[8],[0,\"\\n    \"],[7,\"a\",true],[11,\"onclick\",[28,\"action\",[[23,0,[]],[23,0,[\"showMyRepositories\"]]],null]],[10,\"class\",\"pointer\"],[8],[0,\"\\n      My Repositories\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"if\",[[23,0,[\"features\",\"showRunningJobsInSidebar\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\",true],[10,\"id\",\"tab_running\"],[11,\"class\",[23,0,[\"classRunning\"]]],[8],[0,\"\\n      \"],[7,\"a\",true],[11,\"onclick\",[28,\"action\",[[23,0,[]],[23,0,[\"showRunningJobs\"]]],null]],[10,\"class\",\"pointer\"],[8],[0,\"\\n        Running (\"],[1,[23,0,[\"startedJobsCount\"]],false],[0,\"/\"],[1,[23,0,[\"allJobsCount\"]],false],[0,\")\\n        \"],[5,\"ember-tooltip\",[],[[\"@text\"],[\"Running jobs (started jobs / all jobs)\"]]],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"li\",true],[10,\"id\",\"tab_new\"],[11,\"class\",[23,0,[\"classNew\"]]],[8],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\",\"@trackEvent\",\"@title\"],[\"account\",\"add-repository-from-list\",\"Add New Repository\"]],{\"statements\":[[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"icon icon--plus\"],[8],[9],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/repos-list-tabs.hbs"
    }
  });

  _exports.default = _default;
});