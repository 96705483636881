define("travis/controllers/branches", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    repoController: Ember.inject.controller('repo'),
    tab: Ember.computed.alias('repoController.tab'),
    repo: Ember.computed.alias('repoController.repo'),
    defaultBranch: Ember.computed('model', function () {
      var model = this.model;
      return model.filterBy('default_branch')[0];
    }),
    branchesExist: Ember.computed.notEmpty('model'),
    nonDefaultBranches: Ember.computed.filter('model', function (branch) {
      return !branch.default_branch;
    }),
    activeBranches: Ember.computed('nonDefaultBranches', function () {
      var nonDefaultBranches = this.nonDefaultBranches;
      var activeBranches = nonDefaultBranches.filterBy('exists_on_github');
      return this._sortBranchesByFinished(activeBranches);
    }),
    inactiveBranches: Ember.computed('nonDefaultBranches', function () {
      var nonDefaultBranches = this.nonDefaultBranches;
      var inactiveBranches = nonDefaultBranches.filterBy('exists_on_github', false);
      return this._sortBranchesByFinished(inactiveBranches);
    }),
    _sortBranchesByFinished: function _sortBranchesByFinished(branches) {
      var unfinished = branches.filter(function (branch) {
        var finishedAt = Ember.get(branch, 'last_build.finished_at');
        return Ember.isNone(finishedAt);
      });
      var sortedFinished = branches.filterBy('last_build.finished_at').sortBy('last_build.finished_at').reverse();
      return unfinished.concat(sortedFinished);
    }
  });

  _exports.default = _default;
});