define("travis/components/ui-kit/code", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    // Public interface
    tag: 'code'
  });

  _exports.default = _default;
});