define("travis/components/billing/selected-plan", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    selectedPlanPrice: Ember.computed.reads('selectedPlan.price'),
    price: Ember.computed('totalPrice', 'selectedPlanPrice', function () {
      return Ember.typeOf(this.totalPrice) === 'number' && this.totalPrice >= 0 ? this.totalPrice : Math.floor(this.selectedPlanPrice / 100);
    })
  });

  _exports.default = _default;
});