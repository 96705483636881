define("travis/components/repo-not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    auth: Ember.inject.service(),
    router: Ember.inject.service(),
    features: Ember.inject.service(),
    actions: {
      signIn: function signIn() {
        var redirectUrl = window.location.href;
        this.router.transitionTo('signin', {
          queryParams: {
            redirectUrl: redirectUrl
          }
        });
      }
    }
  });

  _exports.default = _default;
});