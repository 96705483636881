define("travis/components/pagination-navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend({
    tagName: 'nav',
    classNames: ['pagination-navigation'],
    router: Ember.inject.service(),
    queryParam: 'page',
    outer: 1,
    inner: 2,
    route: Ember.computed.reads('router.currentRouteName'),
    pagination: Ember.computed.reads('collection.pagination'),
    currentPage: Ember.computed.reads('pagination.currentPage'),
    isFirst: Ember.computed.reads('pagination.isFirst'),
    isLast: Ember.computed.reads('pagination.isLast'),
    offset: Ember.computed.reads('pagination.offset'),
    numberOfPages: Ember.computed.reads('pagination.numberOfPages'),
    perPage: Ember.computed.reads('pagination.perPage'),
    currentRouteName: Ember.computed.reads('router.currentRouteName'),
    prevPage: Ember.computed('currentPage', 'isFirst', 'currentRouteName', function () {
      return this.isFirst ? null : this.buildPage(this.currentPage - 1);
    }),
    nextPage: Ember.computed('currentPage', 'isLast', 'currentRouteName', function () {
      return this.isLast ? null : this.buildPage(this.currentPage + 1);
    }),
    pages: Ember.computed('numberOfPages', 'perPage', 'currentPage', 'offset', 'inner', 'outer', 'currentRouteName', function () {
      var outer = this.outer,
          inner = this.inner,
          numberOfPages = this.numberOfPages,
          currentPage = this.currentPage,
          offset = this.offset;
      var thresholdDisplayAll = (outer + 1) * 2 + (inner + 1);
      var pageArray = []; // display all pages if there is only a few

      if (numberOfPages <= thresholdDisplayAll) {
        for (var i = 0; i < numberOfPages; i++) {
          pageArray.push(this.buildPage(i + 1));
        } // else stack together pagination

      } else {
        var innerHalf = Math.ceil(inner / 2);
        var lowerInnerBoundary = currentPage - innerHalf;

        if (lowerInnerBoundary < 0) {
          lowerInnerBoundary = 0;
        }

        var upperInnerBoundary = currentPage + innerHalf;
        var lowerOuterBoundary = 1 + outer;
        var upperOuterBoundary = numberOfPages - outer;
        pageArray.push(this.buildPage(1)); // outerwindow first page

        if (currentPage !== 1) {
          for (var _i = 1; _i <= outer; _i++) {
            if (_i !== currentPage) {
              pageArray.push(this.buildPage(_i + 1));
            }
          }
        } // ... divider unit


        if (lowerInnerBoundary - pageArray.length > outer) {
          pageArray.push({});
        } // innerwindow < current page


        for (var _i2 = lowerInnerBoundary; _i2 < currentPage; _i2++) {
          if (_i2 > lowerOuterBoundary) {
            pageArray.push(this.buildPage(_i2));
          }
        }

        if (currentPage > lowerOuterBoundary && currentPage < upperOuterBoundary) {
          // current page
          pageArray.push(this.buildPage(currentPage, offset));
        } // innerwindow > current page


        for (var _i3 = currentPage + 1; _i3 <= upperInnerBoundary; _i3++) {
          if (_i3 < upperOuterBoundary) {
            pageArray.push(this.buildPage(_i3));
          }
        } // ... devider unit


        if (upperOuterBoundary - upperInnerBoundary > 1) {
          pageArray.push({});
        } // outerwindow last page


        for (var _i4 = upperOuterBoundary; _i4 < numberOfPages; _i4++) {
          if (!(_i4 < currentPage)) {
            pageArray.push(this.buildPage(_i4));
          }
        }

        pageArray.push(this.buildPage(numberOfPages, this.get('pagination.last.offset')));
      }

      return pageArray;
    }),
    showPagination: Ember.computed.gt('pages.length', 1),
    buildPage: function buildPage(num, offset) {
      var route = this.route,
          queryParam = this.queryParam,
          _this$perPage = this.perPage,
          perPage = _this$perPage === void 0 ? 0 : _this$perPage;

      var queryParams = _defineProperty({}, queryParam, num);

      var isCurrent = num === this.currentPage;

      if (Ember.isEmpty(offset)) {
        offset = perPage * (num - 1);
      }

      var url = this.router.urlFor(route, {
        queryParams: queryParams
      });
      return {
        num: num,
        offset: offset,
        url: url,
        isCurrent: isCurrent,
        queryParams: queryParams
      };
    },
    actions: {
      switchToPage: function switchToPage(_ref) {
        var queryParams = _ref.queryParams;
        var router = this.router,
            route = this.route;
        router.transitionTo(route, {
          queryParams: queryParams
        });
        return false; // prevent default <a> click handler
      }
    }
  });

  _exports.default = _default;
});