define("travis/mixins/components/with-config-validation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    auth: Ember.inject.service(),
    messages: null,
    repo: null,
    hasMessages: Ember.computed.gt('messages.length', 0),
    isConfigValidationEnabled: Ember.computed.and('auth.signedIn', 'repo.settings.config_validation'),
    showConfigValidation: Ember.computed.reads('isConfigValidationEnabled'),
    messagesMaxLevel: Ember.computed('messages.@each.level', function () {
      if (this.hasMessages) {
        return this.messages.sortBy('level').lastObject.level;
      }
    }),
    messagesBadgeTooltipText: Ember.computed('messagesMaxLevel', function () {
      return "This build's config has ".concat(this.messagesMaxLevel, " level validation messages");
    })
  });

  _exports.default = _default;
});