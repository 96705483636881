define("travis/templates/build/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4yowXLHN",
    "block": "{\"symbols\":[],\"statements\":[[5,\"annotated-yaml\",[],[[\"@request\"],[[23,0,[\"model\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/build/config.hbs"
    }
  });

  _exports.default = _default;
});