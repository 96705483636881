define("travis/utils/object-collect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = objectCollect;

  function objectCollect() {
    for (var _len = arguments.length, keys = new Array(_len), _key = 0; _key < _len; _key++) {
      keys[_key] = arguments[_key];
    }

    return Ember.computed.apply(void 0, keys.concat([function () {
      var _this = this;

      var result = keys.reduce(function (collection, key) {
        var item = _this.get(key);

        if (Ember.isPresent(item)) {
          collection[key] = item;
        }

        return collection;
      }, {});
      return result;
    }]));
  }
});