define("travis/templates/components/ui-kit/image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cQbTz3MV",
    "block": "{\"symbols\":[\"@svg\",\"@alt\"],\"statements\":[[4,\"if\",[[23,0,[\"svg\"]]],null,{\"statements\":[[0,\"  \"],[5,\"ui-kit/box\",[],[[\"@display\",\"@margin\",\"@height\",\"@width\"],[[23,0,[\"display\"]],[23,0,[\"margin\"]],[23,0,[\"height\"]],[23,0,[\"width\"]]]],{\"statements\":[[0,\"\\n    \"],[5,\"svg-image\",[],[[\"@name\",\"@alt\",\"@width\"],[[23,1,[]],[23,2,[]],\"100%\"]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[5,\"ui-kit/box\",[[12,\"alt\",[23,0,[\"alt\"]]],[12,\"src\",[23,0,[\"path\"]]]],[[\"@tag\",\"@display\",\"@margin\",\"@height\",\"@width\"],[\"img\",[23,0,[\"display\"]],[23,0,[\"margin\"]],[23,0,[\"height\"]],[23,0,[\"width\"]]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/ui-kit/image.hbs"
    }
  });

  _exports.default = _default;
});