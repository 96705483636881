define("travis/templates/organization/migrate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0/u/dfy0",
    "block": "{\"symbols\":[],\"statements\":[[5,\"owner/migrate\",[],[[\"@owner\"],[[23,0,[\"model\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/organization/migrate.hbs"
    }
  });

  _exports.default = _default;
});