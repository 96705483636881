define("travis/components/jobs-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'section',
    classNames: ['jobs'],
    classNameBindings: ['stage:stage'],
    jobTableId: Ember.computed('required', function () {
      var required = this.required;

      if (required) {
        return 'jobs';
      }

      return 'allowed_failure_jobs';
    }),
    buildJobs: Ember.computed.alias('build.jobs'),
    jobStages: Ember.computed.mapBy('buildJobs', 'stage'),
    filteredJobs: Ember.computed('jobs.[]', 'build.jobs.[]', 'stage', 'jobStages.@each.id', function () {
      var jobs = this.jobs;
      var buildJobs = this.buildJobs;
      var stage = this.stage;

      if (stage) {
        // Without this, the stage ids are undefined, despite the dependent key. 🤔
        var jobStageIds = this.jobStages.mapBy('id'); // eslint-disable-line

        return buildJobs.filterBy('stage.id', stage.get('id'));
      }

      return jobs;
    }),
    stageState: Ember.computed.alias('stage.state'),
    stageStateIcon: Ember.computed('stageState', function () {
      var stageState = this.stageState;
      var icon = {
        'passed': 'passed',
        'failed': 'failed',
        'errored': 'errored',
        'canceled': 'canceled'
      }[stageState];

      if (icon) {
        return "stage-".concat(icon);
      } else {
        return undefined;
      }
    }),
    stageStateTitle: Ember.computed('stageState', function () {
      return "Stage ".concat(this.stageState);
    }),
    stageAllowFailuresText: Ember.computed('stage', 'stageIsLast', 'filteredJobs.@each.{state,allowFailure}', function () {
      var stage = this.stage;
      var stageIsLast = this.stageIsLast;
      var filteredJobs = this.filteredJobs;

      if (!stage) {
        return false;
      }

      var jobsAllowedToFail = filteredJobs.filterBy('allowFailure');
      var relevantJobs = jobsAllowedToFail.filterBy('isFinished').rejectBy('state', 'passed');
      var failedJobsNotAllowedToFail = this.filteredJobs.rejectBy('allowFailure').filterBy('isFinished').rejectBy('state', 'passed');

      if (relevantJobs.length > 0) {
        var jobList;

        if (relevantJobs.length == 1) {
          jobList = "job ".concat(relevantJobs.mapBy('number')[0]);
        } else if (relevantJobs.length == 2) {
          jobList = "jobs ".concat(relevantJobs.mapBy('number').join(' and '));
        } else if (relevantJobs.length > 5) {
          jobList = 'multiple jobs';
        } else {
          var firstJobs = relevantJobs.slice(0, relevantJobs.length - 1);
          var lastJob = relevantJobs[relevantJobs.length - 1];
          jobList = "jobs ".concat(firstJobs.mapBy('number').join(', '), ", ") + "and ".concat(Ember.get(lastJob, 'number'));
        }

        var continuationText = '';

        if (!stageIsLast && failedJobsNotAllowedToFail.length === 0) {
          continuationText = ' so we continued this build to the next stage';
        }

        return 'Your build matrix was set to allow the failure of ' + "".concat(jobList).concat(continuationText, ".");
      }

      return false;
    }),
    stageIsLast: Ember.computed('stages', 'stage', function () {
      var stages = this.stages;
      var stage = this.stage;
      return stage && stages && stages.indexOf(stage) == stages.length - 1;
    })
  });

  _exports.default = _default;
});