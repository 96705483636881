define("travis/templates/account-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bbUxyP8b",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"missing-notice\"],[8],[0,\"\\n  \"],[7,\"figure\",true],[10,\"class\",\"page-graphic\"],[8],[0,\"\\n    \"],[5,\"svg-image\",[],[[\"@name\"],[\"barricade\"]]],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"h2\",true],[10,\"class\",\"page-title\"],[8],[0,\"\\n    No repositories found for organization\"],[7,\"br\",true],[8],[9],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"h2--red\"],[8],[0,\"\\n      \"],[1,[23,0,[\"account\"]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"p\",true],[10,\"class\",\"page-notice\"],[8],[0,\"\\n    The organization may not exist or you may not have\\n    sufficient permissions to view its repositories.\\n  \"],[9],[0,\"\\n  \"],[7,\"p\",true],[10,\"class\",\"page-notice\"],[8],[0,\"\\n    If you believe you've received this message in error, please\\n    \"],[5,\"link-to\",[],[[\"@route\",\"@query\"],[\"help\",[28,\"hash\",null,[[\"anchor\"],[\"form\"]]]]],{\"statements\":[[0,\"\\n      contact support.\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/account-error.hbs"
    }
  });

  _exports.default = _default;
});