define("travis/utils/format-sha", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = formatSha;

  function formatSha(sha) {
    return (sha || '').substr(0, 7);
  }
});