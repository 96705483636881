define("travis/components/flash-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    flashes: Ember.inject.service(),
    classNames: ['flash'],
    tagName: 'ul',
    messages: Ember.computed.alias('flashes.messages'),
    actions: {
      closeMessage: function closeMessage(msg) {
        return this.flashes.close(msg);
      }
    }
  });

  _exports.default = _default;
});