define("travis/components/forms/form-field", ["exports", "travis/utils/form-validators", "travis/helpers/combine-validators"], function (_exports, _formValidators, _combineValidators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.FIELD_STATE = void 0;
  var FIELD_STATE = {
    DEFAULT: 'default',
    VALID: 'valid',
    ERROR: 'error'
  };
  _exports.FIELD_STATE = FIELD_STATE;

  var _default = Ember.Component.extend({
    classNames: ['travis-form__field'],
    classNameBindings: ['isValid:travis-form__field--valid', 'isError:travis-form__field--error', 'isFocused:travis-form__field--focused', 'showIcon:travis-form__field--with-icon', 'showFrame::travis-form__field--without-frame'],
    fieldElementId: null,
    form: null,
    state: FIELD_STATE.DEFAULT,
    label: '',
    value: '',
    placeholder: '',
    helperText: '',
    disabled: false,
    requiredMark: '*',
    showRequiredMark: false,
    allowClear: false,
    icon: '',
    disableFrame: false,
    multiple: false,
    enableValidationStatusIcons: true,
    enableValidationStatusMessage: true,
    validateOnField: true,
    isMultipleInputsField: false,
    multipleInputsValue: null,
    validator: null,
    required: Ember.computed.equal('validator.kind', _formValidators.presense),
    autoValidate: true,
    errorMessage: '',
    isFocused: false,
    isDefault: Ember.computed.equal('state', FIELD_STATE.DEFAULT),
    isValid: Ember.computed.equal('state', FIELD_STATE.VALID),
    isError: Ember.computed.equal('state', FIELD_STATE.ERROR),
    requiresValidation: Ember.computed.or('required', 'validator'),
    showClear: Ember.computed.and('allowClear', 'value'),
    showIcon: Ember.computed.notEmpty('icon'),
    showFrame: Ember.computed.not('disableFrame'),
    showValidationStatusIcons: Ember.computed.and('enableValidationStatusIcons', 'requiresValidation'),
    showValidationStatusMessage: Ember.computed.and('enableValidationStatusMessage', 'requiresValidation'),
    selectComponent: Ember.computed('multiple', function () {
      return this.multiple ? 'forms/form-select-multiple' : 'forms/form-select';
    }),
    validate: function validate(value) {
      var isFormValidation = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      if (!this.validateOnField && !isFormValidation) return true;
      var validator = this.validator;

      if (!validator) {
        if (this.required) validator = (0, _formValidators.presense)();else return;
      }

      if (this.required && validator.kind !== _formValidators.presense) {
        validator = (0, _combineValidators.combineValidators)([validator, (0, _formValidators.presense)()]);
      }

      var validationResult = validator(value || this.value);

      if (validationResult === true) {
        this.setValid();
      } else {
        this.setError(validationResult);
      }
    },
    setValid: function setValid() {
      this.set('state', FIELD_STATE.VALID);
    },
    setError: function setError(errorMessage) {
      var state = FIELD_STATE.ERROR;
      this.setProperties({
        state: state,
        errorMessage: errorMessage
      });
    },
    clearError: function clearError() {
      if (this.state === FIELD_STATE.ERROR) {
        var state = FIELD_STATE.DEFAULT;
        this.setProperties({
          state: state,
          errorMessage: ''
        });
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.form.registerField(this);

      if (this.value && this.autoValidate) {
        this.validate();
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.form.unregisterField(this);
    },
    onBlur: function onBlur() {},
    onFocus: function onFocus() {},
    onChange: function onChange() {},
    onKeyUp: function onKeyUp() {},
    validateMultipleInputs: function validateMultipleInputs() {
      var _this = this;

      var values = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [''];
      var isFormValidation = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      this.set('multipleInputsValue', values);
      values.some(function (value) {
        _this.validate(value, isFormValidation);

        return _this.state === FIELD_STATE.ERROR;
      });
    },
    actions: {
      handleFocus: function handleFocus() {
        this.set('isFocused', true);
        this.clearError();
        this.onFocus();
      },
      handleBlur: function handleBlur(value) {
        this.set('isFocused', false);
        this.validate(value);
        this.onBlur(value);
      },
      validateMultipleInputs: function validateMultipleInputs() {
        var values = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [''];
        var isFormValidation = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        this.validateMultipleInputs(values, isFormValidation);
      },
      handleSelectBlur: function handleSelectBlur(publicAPI) {
        this.send('handleBlur', publicAPI.selected);
      },
      handleChange: function handleChange(value) {
        this.validate(value);
        this.onChange(value);
      },
      handleKeyUp: function handleKeyUp(value) {
        this.onKeyUp(value);
      },
      handleClear: function handleClear() {
        if (this.allowClear) this.send('handleChange', '');
      },
      setFieldElementId: function setFieldElementId(fieldElementId) {
        this.setProperties({
          fieldElementId: fieldElementId
        });
      }
    }
  });

  _exports.default = _default;
});