define("travis/adapters/preference", ["exports", "travis/adapters/v3"], function (_exports, _v) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _v.default.extend({
    pathPrefix: function pathPrefix(modelName, id, snapshot, type, query) {
      var prefix = 'v3'; // Handle queries for organization settings

      if (type === 'query' && query.organization_id) {
        prefix += "/org/".concat(query.organization_id); // Remove unnecessary query param from API call

        delete query.organization_id;
      } // Handle updates for organization settings


      if (type === 'updateRecord' && typeof snapshot.adapterOptions !== 'undefined' && snapshot.adapterOptions.hasOwnProperty('organization_id')) {
        prefix += "/org/".concat(snapshot.adapterOptions.organization_id);
      }

      return prefix;
    },
    ajax: function ajax(url, method) {
      if (method === 'PUT') method = 'PATCH';

      for (var _len = arguments.length, rest = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        rest[_key - 2] = arguments[_key];
      }

      return this._super.apply(this, [url, method].concat(rest));
    }
  });

  _exports.default = _default;
});