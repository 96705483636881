define("travis/controllers/help", ["exports", "travis/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ANCHOR = void 0;
  var ANCHOR = {
    TOP: 'top',
    DOCS: 'docs',
    COMMUNITY: 'community',
    FORM: 'form'
  };
  _exports.ANCHOR = ANCHOR;
  var UTM_SOURCE = 'help-page';
  var UTM_MEDIUM = 'travisweb';
  var UTM_PARAMS = "?utm_source=".concat(UTM_SOURCE, "&utm_medium=").concat(UTM_MEDIUM);
  var _config$urls = _environment.default.urls,
      docs = _config$urls.docs,
      community = _config$urls.community,
      docker = _config$urls.docker,
      node = _config$urls.node,
      multiOS = _config$urls.multiOS,
      noRun = _config$urls.noRun,
      tutorial = _config$urls.tutorial;

  var _default = Ember.Controller.extend({
    auth: Ember.inject.service(),
    features: Ember.inject.service(),
    queryParams: ['anchor', 'page'],
    anchor: ANCHOR.TOP,
    page: '',
    isSignedIn: Ember.computed.reads('auth.signedIn'),
    toTop: Ember.computed.equal('anchor', ANCHOR.TOP),
    toDocs: Ember.computed.equal('anchor', ANCHOR.DOCS),
    toCommunity: Ember.computed.equal('anchor', ANCHOR.COMMUNITY),
    toForm: Ember.computed.equal('anchor', ANCHOR.FORM),
    utmParams: Ember.computed(function () {
      return UTM_PARAMS;
    }),
    docsUrl: Ember.computed(function () {
      return "".concat(docs).concat(UTM_PARAMS);
    }),
    dockerUrl: Ember.computed(function () {
      return "".concat(docker).concat(UTM_PARAMS);
    }),
    nodeUrl: Ember.computed(function () {
      return "".concat(node).concat(UTM_PARAMS);
    }),
    multiOsUrl: Ember.computed(function () {
      return "".concat(multiOS).concat(UTM_PARAMS);
    }),
    noRunUrl: Ember.computed(function () {
      return "".concat(noRun).concat(UTM_PARAMS);
    }),
    tutorialUrl: Ember.computed(function () {
      return "".concat(tutorial).concat(UTM_PARAMS);
    }),
    communityUrl: Ember.computed(function () {
      return "".concat(community, "/top").concat(UTM_PARAMS);
    }),
    actions: {
      setAnchor: function setAnchor(anchor) {
        if (Object.values(ANCHOR).includes(anchor)) {
          this.set('anchor', anchor);
        }
      }
    }
  });

  _exports.default = _default;
});