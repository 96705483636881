define("travis/services/api", ["exports", "travis/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    auth: Ember.inject.service(),
    get: function get(url) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return this.request(url, 'GET', options);
    },
    post: function post(url) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return this.request(url, 'POST', options);
    },
    patch: function patch(url) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return this.request(url, 'PATCH', options);
    },
    put: function put(url) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return this.request(url, 'PUT', options);
    },
    delete: function _delete(url) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return this.request(url, 'DELETE', options);
    },
    request: function request(url) {
      var method = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'GET';
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      options.host = _environment.default.apiEndpoint || '';
      options.headers = this.setupHeaders(options);
      return this.ajax.request(url, method, options);
    },
    setupHeaders: function setupHeaders() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var _options$headers = options.headers,
          headers = _options$headers === void 0 ? {} : _options$headers;
      var token = this.auth.token; // Release

      if (_environment.default.release) {
        headers['X-Client-Release'] = _environment.default.release;
      } // Authorization


      if (token) {
        headers['Authorization'] = "token ".concat(token);
      } // Travis-API-Version


      if (options.travisApiVersion !== null) {
        headers['Travis-API-Version'] = options.travisApiVersion || '3';
      }

      return headers;
    }
  });

  _exports.default = _default;
});