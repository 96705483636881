define("travis/controllers/travisci-vs-jenkins/index", ["exports", "travis/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    caseStudyUrl: _environment.default.urls.caseStudy,
    scrollToContact: false,
    toggleContactScroll: function toggleContactScroll() {
      var _this = this;

      this.set('scrollToContact', true);
      Ember.run.later(function () {
        return _this.set('scrollToContact', false);
      }, 500);
    }
  });

  _exports.default = _default;
});