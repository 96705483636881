define("travis/routes/repo/index", ["exports", "travis/routes/basic"], function (_exports, _basic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basic.default.extend({
    features: Ember.inject.service(),
    tabStates: Ember.inject.service(),
    afterModel: function afterModel(repo) {
      try {
        return repo.get('currentBuild.request').then(function (request) {
          return request && request.fetchMessages.perform();
        });
      } catch (error) {}
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      this.controllerFor('repo').activate('current');
      controller.set('repo', model);
    },
    deactivate: function deactivate() {
      this.controllerFor('build').set('build', null);
      this.controllerFor('job').set('job', null);
      this.controllerFor('repo').set('migrationStatus', null);
      this.stopObservingRepoStatus();
      return this._super.apply(this, arguments);
    },
    activate: function activate() {
      this.observeRepoStatus();
      this.set('tabStates.mainTab', 'current');
      return this._super.apply(this, arguments);
    },
    observeRepoStatus: function observeRepoStatus() {
      var controller = this.controllerFor('repo');
      controller.addObserver('repo.active', this, 'renderTemplate');
      controller.addObserver('repo.currentBuildId', this, 'renderTemplate');
    },
    stopObservingRepoStatus: function stopObservingRepoStatus() {
      var controller = this.controllerFor('repo');
      controller.removeObserver('repo.active', this, 'renderTemplate');
      controller.removeObserver('repo.currentBuildId', this, 'renderTemplate');
    },
    renderTemplate: function renderTemplate() {
      var controller = this.controllerFor('repo');

      if (this.get('features.github-apps') && controller.get('repo.active_on_org') && controller.migrationStatus !== 'success') {
        this.render('repo/active-on-org');
      } else if (!controller.get('repo.active')) {
        this.render('repo/not-active');
      } else if (!controller.get('repo.currentBuildId')) {
        this.render('repo/no-build');
      } else {
        this.render('build');
        this.render('build/index', {
          into: 'build',
          controller: 'build'
        });
      }
    }
  });

  _exports.default = _default;
});