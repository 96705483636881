define("travis/controllers/builds", ["exports", "travis/mixins/builds/load-more"], function (_exports, _loadMore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var mixins = [_loadMore.default];

  var _default = Ember.Controller.extend.apply(Ember.Controller, mixins.concat([{
    tabStates: Ember.inject.service(),
    features: Ember.inject.service(),
    externalLinks: Ember.inject.service(),
    buildsSorting: ['number:desc'],
    builds: Ember.computed.sort('model', 'buildsSorting'),
    repoController: Ember.inject.controller('repo'),
    repo: Ember.computed.alias('repoController.repo'),
    tab: Ember.computed.alias('repoController.tab'),
    showBuildHistoryLink: Ember.computed('repo.{isMigrated,isHistoryMigrated}', 'builds.length', 'features.proVersion', function () {
      var _this$repo = this.repo,
          isMigrated = _this$repo.isMigrated,
          isHistoryMigrated = _this$repo.isHistoryMigrated;
      var proVersion = this.get('features.proVersion');
      var hasBuilds = this.builds.length > 0;
      return isMigrated && !isHistoryMigrated && hasBuilds && proVersion;
    }),
    buildHistoryLink: Ember.computed('repo.slug', function () {
      return this.externalLinks.orgBuildHistoryLink(this.repo.slug);
    }),
    displayShowMoreButton: Ember.computed('tab', 'builds.lastObject.number', function () {
      var tab = this.tab;
      var lastBuildNumber = this.get('builds.lastObject.number');
      return tab !== 'branches' && parseInt(lastBuildNumber) > 1;
    })
  }]));

  _exports.default = _default;
});