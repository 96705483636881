define("travis/templates/components/billing/process", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nUZlhK0s",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"account\",\"hasSubscriptionPermissions\"]]],null,{\"statements\":[[4,\"if\",[[23,0,[\"isStepOne\"]]],null,{\"statements\":[[0,\"    \"],[5,\"billing/select-plan\",[],[[\"@title\",\"@newSubscription\",\"@account\",\"@submit\"],[\"Subscribe to a plan\",[23,0,[\"newSubscription\"]],[23,0,[\"account\"]],[28,\"action\",[[23,0,[]],\"next\"],null]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,0,[\"isStepTwo\"]]],null,{\"statements\":[[0,\"    \"],[5,\"billing/information\",[],[[\"@newSubscription\",\"@goToFirstStep\",\"@next\",\"@back\"],[[23,0,[\"newSubscription\"]],[28,\"action\",[[23,0,[]],\"goToFirstStep\"],null],[28,\"action\",[[23,0,[]],\"next\"],null],[28,\"action\",[[23,0,[]],\"back\"],null]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,0,[\"isStepThree\"]]],null,{\"statements\":[[0,\"    \"],[5,\"billing/payment\",[],[[\"@newSubscription\",\"@account\",\"@goToFirstStep\",\"@back\"],[[23,0,[\"newSubscription\"]],[23,0,[\"account\"]],[28,\"action\",[[23,0,[]],\"goToFirstStep\"],null],[28,\"action\",[[23,0,[]],\"back\"],null]]]],[0,\"\\n  \"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"p\",true],[8],[0,\"\\n    You don't have permission to create a subscription\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/billing/process.hbs"
    }
  });

  _exports.default = _default;
});