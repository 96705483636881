define("travis/templates/layouts/support", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2PG18KaM",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"id\",\"about\"],[10,\"class\",\"box\"],[8],[0,\"\\n  \"],[7,\"h4\",true],[8],[0,\"\\n    How can we help?\\n  \"],[9],[0,\"\\n  \"],[7,\"ul\",true],[8],[0,\"\\n    \"],[7,\"li\",true],[8],[0,\"\\n      \"],[5,\"external-link-to\",[[12,\"href\",\"http://chat.travis-ci.com\"]],[[],[]],{\"statements\":[[0,\"\\n        Live Chat\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"li\",true],[8],[0,\"\\n      \"],[7,\"a\",true],[10,\"href\",\"mailto:support@travis-ci.com\"],[8],[0,\"\\n        E-Mail us\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"li\",true],[8],[0,\"\\n      \"],[5,\"external-link-to\",[[12,\"href\",\"http://docs.travis-ci.com/user/travis-pro\"]],[[],[]],{\"statements\":[[0,\"\\n        Documentation\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/layouts/support.hbs"
    }
  });

  _exports.default = _default;
});