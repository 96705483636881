define("travis/components/forms/multiple-inputs-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var _default = Ember.Component.extend({
    tagName: '',
    delimeter: ',',
    initialValue: '',
    value: Ember.computed.reads('initialValue'),
    fields: Ember.computed('value', {
      get: function get() {
        return (this.value || '').split(this.delimeter).map(function (value) {
          return {
            value: value
          };
        });
      },
      set: function set(_, value) {
        return value;
      }
    }),
    lastFieldIndex: Ember.computed('fields.length', function () {
      return this.fields.length - 1;
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var values = this.fields.map(function (input) {
        return input.value;
      });

      if (values.length > 0 && values[0]) {
        this.validateMultipleInputs(values);
      }
    },
    validateMultipleInputs: function validateMultipleInputs() {},
    updateValues: function updateValues() {},
    handleValidation: function handleValidation(values) {
      this.validateMultipleInputs(values);
      this.updateValues(values);
    },
    actions: {
      handleBlur: function handleBlur() {
        var values = this.fields.map(function (input) {
          return input.value;
        });
        this.handleValidation(values);
        var value = values.join(this.delimeter);
        this.set('value', value);
      },
      handleChange: function handleChange(index, _ref) {
        var target = _ref.target;
        var value = target.value;

        var fields = _toConsumableArray(this.fields);

        fields[index] = {
          value: value
        };
        var values = fields.map(function (input) {
          return input.value;
        });
        this.handleValidation(values);
        this.set('fields', fields);
      },
      removeInput: function removeInput(inputIndex, e) {
        e.preventDefault();
        var filteredFields = this.fields.filter(function (_, index) {
          return index !== inputIndex;
        });
        var values = filteredFields.map(function (input) {
          return input.value;
        });
        this.handleValidation(values);
        this.set('fields', filteredFields);
      },
      addInput: function addInput(e) {
        e.preventDefault();
        this.set('fields', [].concat(_toConsumableArray(this.fields), [{
          value: ''
        }]));
      }
    }
  });

  _exports.default = _default;
});