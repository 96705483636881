define("travis/templates/components/annotated-yaml", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sEHiuk9P",
    "block": "{\"symbols\":[\"rawConfig\",\"@request\"],\"statements\":[[5,\"build-messages-list\",[],[[\"@request\",\"@viewMessage\"],[[23,2,[]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"viewingMessage\"]]],null]],null]]]],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"raw-configs\"],[8],[0,\"\\n\"],[4,\"each\",[[23,2,[\"uniqRawConfigs\"]]],null,{\"statements\":[[0,\"    \"],[5,\"raw-config\",[],[[\"@rawConfig\",\"@build\",\"@slug\"],[[23,1,[]],[23,2,[\"build\"]],[23,2,[\"repo\",\"slug\"]]]]],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"starred-empty\"],[8],[0,\"\\n      The .travis.yml file used for this job is not available\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[5,\"request-config\",[],[[\"@config\"],[[23,2,[\"config\"]]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/annotated-yaml.hbs"
    }
  });

  _exports.default = _default;
});