define("travis/components/sales-contact-form", ["exports", "travis/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var pardotFormUrl = _environment.default.urls.pardotHost + _environment.default.urls.pardotForm;

  var _default = Ember.Component.extend({
    tagName: '',
    utm: Ember.inject.service(),
    pardotFormUrl: Ember.computed('utm.existing', function () {
      var _this$utm = this.utm,
          existing = _this$utm.existing,
          hasData = _this$utm.hasData;
      return hasData ? "".concat(pardotFormUrl, "?").concat(existing) : pardotFormUrl;
    }),
    setHeight: function setHeight(element) {
      window.addEventListener('message', function (event) {
        if (event.origin === _environment.default.urls.pardotHost && event.data) {
          element.style.height = "".concat(event.data.scrollHeight + 10, "px");
        }
      });
    }
  });

  _exports.default = _default;
});