define("travis/components/repos-list-item", ["exports", "travis/mixins/polling", "travis/utils/color-for-state"], function (_exports, _polling, _colorForState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_polling.default, {
    tagName: 'li',
    pollModels: 'repo',
    classNames: ['repo'],
    color: Ember.computed('repo.currentBuild.state', function () {
      return (0, _colorForState.default)(this.get('repo.currentBuild.state'));
    })
  });

  _exports.default = _default;
});