define("travis/components/header-links", ["exports", "travis/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    config: _environment.default,
    auth: Ember.inject.service(),
    router: Ember.inject.service(),
    features: Ember.inject.service(),
    externalLinks: Ember.inject.service(),
    multiVcs: Ember.inject.service(),
    deploymentVersion: Ember.computed(function () {
      if (window && window.location) {
        var hostname = window.location.hostname;

        if (hostname.indexOf('ember-beta') === 0 || hostname.indexOf('ember-canary') === 0) {
          return "Ember ".concat(Ember.VERSION);
        } else if (hostname.indexOf('test-deployments') > 0) {
          var branchName = hostname.split('.')[0];
          var branchURL = this.externalLinks.travisWebBranch(branchName);
          var branchLink = "<a href='".concat(branchURL, "'><code>").concat(branchName, "</code></a>");
          return Ember.String.htmlSafe("Test deployment ".concat(branchLink));
        } else {
          return false;
        }
      } else {
        return false;
      }
    }),
    actions: {
      goToHelp: function goToHelp() {
        if (this.router.currentRouteName !== 'help') {
          var page = encodeURI(window.location.href);
          this.router.transitionTo('help', {
            queryParams: {
              page: page
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});