define("travis/templates/components/loading-screen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2GDFt4P4",
    "block": "{\"symbols\":[],\"statements\":[[7,\"img\",true],[11,\"src\",[29,[\"/images/logos/\",[23,0,[\"randomLogo\",\"logo\"]],\".svg\"]]],[10,\"alt\",\"Travis CI Logo\"],[10,\"width\",\"200\"],[10,\"height\",\"200\"],[8],[9],[0,\"\\n\"],[5,\"loading-indicator\",[],[[\"@center\"],[true]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/loading-screen.hbs"
    }
  });

  _exports.default = _default;
});