define("travis/components/builds-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'li',
    classNameBindings: ['build.state'],
    classNames: ['row-li', 'pr-row'],
    isCronJob: Ember.computed('build.eventType', function () {
      var event = this.get('build.eventType');
      return event === 'cron';
    })
  });

  _exports.default = _default;
});