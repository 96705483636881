define("travis/controllers/build/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    repositories: Ember.inject.service(),
    latestCurrentBuild: Ember.computed.alias('repositories.accessible.firstObject.currentBuild'),
    build: Ember.computed('model', 'latestCurrentBuild', function () {
      var model = this.model;
      var latestCurrentBuild = this.latestCurrentBuild;

      if (model) {
        return model;
      } else {
        return latestCurrentBuild;
      }
    })
  });

  _exports.default = _default;
});