define("travis/components/loading-screen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['loading-screen'],
    classNameBindings: ['center:loading-screen--centered', 'margin:loading-screen--with-margins'],
    randomLogo: Ember.inject.service(),
    center: false,
    margin: false
  });

  _exports.default = _default;
});