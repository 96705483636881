define("travis/templates/travisci-vs-jenkins/thank-you", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rkGKX5nE",
    "block": "{\"symbols\":[],\"statements\":[[5,\"travis-layout\",[[12,\"class\",\"tailwind-overrides\"]],[[\"@layoutName\"],[\"layouts/center\"]],{\"statements\":[[0,\"\\n  \"],[5,\"sales-contact-thanks\",[],[[\"@backRoute\"],[\"travisci-vs-jenkins\"]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/travisci-vs-jenkins/thank-you.hbs"
    }
  });

  _exports.default = _default;
});