define("travis/instance-initializers/enterprise-environment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    var config = appInstance.resolveRegistration('config:environment');
    var featureFlags = config.featureFlags;

    if (featureFlags['enterprise-version']) {
      featureFlags['repository-filtering'] = true;
      featureFlags['debug-logging'] = false;
      featureFlags['landing-page-cta'] = false;
      featureFlags['show-running-jobs-in-sidebar'] = true;
      featureFlags['debug-builds'] = false;
      featureFlags['broadcasts'] = false;
      featureFlags['beta-features'] = false;
    }
  }

  var _default = {
    name: 'enterprise-environment',
    after: 'pro-environment',
    initialize: initialize
  };
  _exports.default = _default;
});