define("travis/routes/organization/migrate", ["exports", "travis/mixins/route/owner/migrate"], function (_exports, _migrate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_migrate.default, {
    model: function model() {
      this._super.apply(this, arguments);

      return this.modelFor('organization');
    }
  });

  _exports.default = _default;
});