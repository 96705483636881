define("travis/templates/components/build-messages-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dlCe7Lao",
    "block": "{\"symbols\":[\"message\"],\"statements\":[[4,\"if\",[[23,0,[\"showConfigValidation\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"yml-messages\"],[8],[0,\"\\n    \"],[7,\"div\",false],[12,\"class\",\"header\"],[3,\"action\",[[23,0,[]],\"toggle\"]],[8],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"tooltip-wrapper level-icon\"],[8],[0,\"\\n        \"],[5,\"svg-image\",[],[[\"@name\",\"@class\"],[[29,[\"msg-\",[23,0,[\"maxLevel\"]]]],[23,0,[\"iconClass\"]]]]],[0,\"\\n        \"],[5,\"ember-tooltip\",[],[[\"@text\"],[[23,0,[\"summary\"]]]]],[0,\"\\n      \"],[9],[0,\"\\n      Build config validation\\n\"],[4,\"unless\",[[23,0,[\"isExpanded\"]]],null,{\"statements\":[[0,\"        — \"],[1,[23,0,[\"summary\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[7,\"div\",true],[10,\"class\",\"tools\"],[8],[0,\"\\n        \"],[5,\"external-link-to\",[[12,\"href\",[29,[[28,\"config-get\",[\"urls.buildConfigValidation\"],null]]]]],[[],[]],{\"statements\":[[0,\"\\n          \"],[5,\"svg-image\",[],[[\"@name\",\"@class\"],[\"icon-help\",\"icon-help\"]]],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n        \"],[5,\"svg-image\",[],[[\"@name\",\"@class\"],[\"icon-dropdown-arrow\",[29,[\"icon-toggle icon-\",[23,0,[\"toggleStatusClass\"]]]]]]],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[4,\"if\",[[23,0,[\"isExpanded\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"list\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"request\",\"messages\"]]],null,{\"statements\":[[0,\"          \"],[5,\"build-message\",[],[[\"@message\"],[[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/build-messages-list.hbs"
    }
  });

  _exports.default = _default;
});