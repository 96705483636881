define("travis/components/travis-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['travis-status'],
    classNameBindings: ['indicator', 'colorizeText:colorize-text'],
    appLoading: Ember.inject.service(),
    colorizeText: false,
    indicator: Ember.computed.reads('appLoading.indicator'),
    description: Ember.computed.reads('appLoading.description'),
    showDescription: Ember.computed.notEmpty('description'),
    notShowDescription: Ember.computed.not('showDescription'),
    // there is description but it's hidden from outside
    showTooltip: Ember.computed.and('notShowDescription', 'description'),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.appLoading.fetchTravisStatus.perform();
    }
  });

  _exports.default = _default;
});