define("travis/components/ui-kit/image", ["exports", "travis/utils/ui-kit/assertions"], function (_exports, _assertions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    path: null,
    svg: null,
    alt: null,
    display: 'inline-block',
    margin: null,
    height: null,
    width: null,
    // Lifecycle
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      (false && !(this.path || this.svg) && Ember.assert('The @path or @svg property must be present on this Image component.', this.path || this.svg));
      (0, _assertions.requireProp)(this.alt, '@alt', 'Image');
    }
  });

  _exports.default = _default;
});