define("travis/components/page-footer", ["exports", "travis/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    features: Ember.inject.service(),
    config: _environment.default,
    tagName: 'footer',
    classNames: ['footer']
  });

  _exports.default = _default;
});