define("travis/templates/components/loading-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "X2M7ya2h",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"loading-page\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"showHeader\"]]],null,{\"statements\":[[0,\"    \"],[7,\"header\",true],[10,\"class\",\"top\"],[8],[0,\"\\n      \"],[5,\"top-bar\",[],[[],[]]],[0,\"\\n    \"],[9],[0,\"\\n\"],[4,\"if\",[[23,0,[\"showCta\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"cta\"],[8],[0,\"\\n        \"],[7,\"p\",true],[10,\"class\",\"row\"],[8],[0,\"\\n          Help make Open Source a better place and start building better software today!\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"  \"],[7,\"div\",true],[10,\"class\",\"main\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"content-page\"],[8],[0,\"\\n      \"],[5,\"loading-screen\",[],[[],[]]],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/loading-page.hbs"
    }
  });

  _exports.default = _default;
});