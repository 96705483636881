define("travis/controllers/account/billing/index", ["exports", "travis/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    config: _environment.default,
    accountController: Ember.inject.controller('account'),
    account: Ember.computed.alias('accountController.model'),
    invoices: Ember.computed('model.subscriptions.id', function () {
      var subscriptionId = this.model.subscriptions.id;

      if (subscriptionId) {
        return this.store.query('invoice', {
          subscription_id: subscriptionId
        });
      } else {
        return [];
      }
    }),
    isEducation: Ember.computed('model', 'account', function () {
      if (!this.model.subscriptions) {
        return !this.model.subscriptions && this.account.education;
      }
    }),
    isTrial: Ember.computed('model', 'account', function () {
      if (!this.model.subscriptions) {
        return !this.model.subscriptions && !this.account.education;
      }
    })
  });

  _exports.default = _default;
});