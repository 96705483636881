define("travis/components/new-subscription-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isNew: Ember.computed('subscription', function () {
      return !this.subscription;
    })
  });

  _exports.default = _default;
});