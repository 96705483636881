define("travis/components/repos-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tabStates: Ember.inject.service(),
    noReposMessage: Ember.computed('viewingOwned', function () {
      if (this.viewingOwned === 'owned') {
        return 'You don\'t have any repos set up on Travis CI';
      } else {
        return 'No repositories found';
      }
    })
  });

  _exports.default = _default;
});