define("travis/utils/favicon-data-uris", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  var __inlineImageDataUri__ = function __inlineImageDataUri__(img) {
    return "/images/".concat(img);
  }; // in case image inliner doesn't run


  var uris = {
    default: __inlineImageDataUri__('favicon.png'),
    red: __inlineImageDataUri__('favicon-red.png'),
    gray: __inlineImageDataUri__('favicon-gray.png'),
    green: __inlineImageDataUri__('favicon-green.png'),
    yellow: __inlineImageDataUri__('favicon-yellow.png')
  };

  function _default(type) {
    return uris[type] || uris.default;
  }
});