define("travis/models/ssh-key", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    value: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    fingerprint: (0, _model.attr)(),
    isCustom: true
  });

  _exports.default = _default;
});