define("travis/controllers/build", ["exports", "travis/mixins/polling", "visibilityjs", "travis/config/environment"], function (_exports, _polling, _visibilityjs, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_polling.default, {
    auth: Ember.inject.service(),
    updateTimesService: Ember.inject.service('updateTimes'),
    repoController: Ember.inject.controller('repo'),
    config: _environment.default,
    repo: Ember.computed.alias('repoController.repo'),
    currentUser: Ember.computed.alias('auth.currentUser'),
    tab: Ember.computed.alias('repoController.tab'),
    sendFaviconStateChanges: true,
    updateTimes: function updateTimes() {
      this.updateTimesService.push(this.get('build.stages'));
    },
    init: function init() {
      this._super.apply(this, arguments);

      if (!Ember.testing) {
        return _visibilityjs.default.every(_environment.default.intervals.updateTimes, this.updateTimes.bind(this));
      }
    },
    buildStateDidChange: Ember.observer('build.state', function () {
      if (this.sendFaviconStateChanges) {
        this.send('faviconStateDidChange', this.get('build.state'));
      }
    })
  });

  _exports.default = _default;
});