define("travis/adapters/request", ["exports", "travis/adapters/v3"], function (_exports, _v) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _v.default.extend({
    includes: 'request.builds,request.commit,request.config,request.raw_configs',
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var prefix = this.urlPrefix();

      if (requestType === 'query' && query.repository_id) {
        return "".concat(prefix, "/repo/").concat(query.repository_id, "/requests");
      } else if (requestType === 'findRecord') {
        var build = snapshot.belongsTo('build');

        if (build) {
          var repositoryId = build.belongsTo('repo').id;
          return "".concat(prefix, "/repo/").concat(repositoryId, "/request/").concat(id);
        } else {
          throw Error('Could not load request with an unknown repository');
        }
      } else {
        throw Error('The request adapter only supports findRecord and query with a repository_id.');
      }
    },
    // This overrides the parent implementation to ignore the query parameters
    query: function query(store, type, _query) {
      var url = this.buildURL(type.modelName, null, null, 'query', _query); // skip_count is from travis-ci/travis-api#778, a temporary measure because of slow pagination

      return this.ajax(url, 'GET', {
        data: {
          include: this.includes,
          skip_count: true
        }
      });
    }
  });

  _exports.default = _default;
});