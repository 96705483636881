define("travis/templates/organization/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SpKDy1HK",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\",true],[10,\"class\",\"organization-settings\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"showOrganizationSettings\"]]],null,{\"statements\":[[4,\"if\",[[23,0,[\"scrollToInsights\"]]],null,{\"statements\":[[0,\"      \"],[5,\"scroll-here\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"section\",true],[10,\"class\",\"insights-visibility-settings\"],[8],[0,\"\\n      \"],[7,\"h3\",true],[8],[0,\"\\n        Insights Visibility Settings\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"insights-settings\"],[8],[0,\"\\n        \"],[7,\"p\",true],[10,\"class\",\"description\"],[8],[0,\"\\n          Make more informed decisions about your development workflow using your build Insights. \"],[7,\"br\",true],[8],[9],[0,\"\\n          \"],[5,\"link-to\",[],[[\"@route\",\"@models\",\"@query\"],[\"owner.repositories\",[28,\"array\",[[23,0,[\"organization\",\"provider\"]],[23,0,[\"organization\",\"login\"]]],null],[28,\"hash\",null,[[\"tab\"],[\"insights\"]]]]],{\"statements\":[[0,\"\\n            View \"],[1,[23,0,[\"organization\",\"fullName\"]],false],[0,\"'s Insights\\n          \"]],\"parameters\":[]}],[0,\"\\n        \"],[9],[0,\"\\n        \"],[5,\"visibility-setting-list\",[],[[\"@options\",\"@initialKey\",\"@onConfirm\"],[[23,0,[\"insightsVisibilityOptions\"]],[23,0,[\"privateInsightsVisibility\"]],[28,\"action\",[[23,0,[]],\"setInsightsVis\"],null]]]],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/organization/settings.hbs"
    }
  });

  _exports.default = _default;
});