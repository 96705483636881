define("travis/utils/vcs", ["exports", "travis/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.availableProviders = _exports.vcsColor = _exports.vcsVocab = _exports.vcsIcon = _exports.vcsName = _exports.vcsUrl = _exports.vcsConfigByUrlPrefixOrType = _exports.vcsConfigByUrlPrefix = _exports.vcsConfig = _exports.defaultVcsConfig = void 0;
  var providers = _environment.default.providers,
      isEnterprise = _environment.default.enterprise,
      sourceEndpoint = _environment.default.sourceEndpoint;
  var defaultVcsConfig = Object.values(providers).find(function (config) {
    return config.isDefault;
  });
  _exports.defaultVcsConfig = defaultVcsConfig;

  var vcsConfig = function vcsConfig(vcsType) {
    return Object.values(providers).find(function (provider) {
      return provider.vcsTypes.includes(vcsType);
    }) || defaultVcsConfig;
  };

  _exports.vcsConfig = vcsConfig;

  var vcsConfigByUrlPrefix = function vcsConfigByUrlPrefix(urlPrefix) {
    return Object.values(providers).findBy('urlPrefix', urlPrefix);
  };

  _exports.vcsConfigByUrlPrefix = vcsConfigByUrlPrefix;

  var vcsConfigByUrlPrefixOrType = function vcsConfigByUrlPrefixOrType(prefixOrType) {
    var config = vcsConfigByUrlPrefix(prefixOrType);

    if (!config) {
      config = vcsConfig(prefixOrType);
    }

    return config;
  };

  _exports.vcsConfigByUrlPrefixOrType = vcsConfigByUrlPrefixOrType;

  var replaceParams = function replaceParams(template, params) {
    return Object.keys(params).reduce(function (url, key) {
      return url.replace(":".concat(key), params[key]);
    }, template);
  };

  var templateParams = function templateParams(template) {
    var params = template.match(/:[a-zA-Z]+/g);
    return params ? params.map(function (param) {
      return param.slice(1);
    }) : [];
  };

  var paramsValid = function paramsValid(template, params) {
    return arrayContainsArray(Object.keys(params), templateParams(template));
  };

  var arrayContainsArray = function arrayContainsArray(superset, subset) {
    return subset.every(function (value) {
      return superset.indexOf(value) >= 0;
    });
  };

  var vcsUrl = function vcsUrl(resource, vcsType) {
    var params = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    var vcs = vcsConfig(vcsType);
    var endpoint = isEnterprise && sourceEndpoint || vcs.endpoint;
    var url = endpoint + vcs.paths[resource];
    params.vcsId = params.vcsId || params.repo && params.repo.vcsId;
    (false && !(paramsValid(url, params)) && Ember.assert("Missing url params. URL: ".concat(url, ", PARAMS: ").concat(JSON.stringify(params)), paramsValid(url, params)));
    return replaceParams(url, params);
  };

  _exports.vcsUrl = vcsUrl;

  var vcsName = function vcsName(vcsType) {
    return vcsConfigByUrlPrefixOrType(vcsType).name;
  };

  _exports.vcsName = vcsName;

  var vcsIcon = function vcsIcon(vcsType) {
    return vcsConfig(vcsType).icon;
  };

  _exports.vcsIcon = vcsIcon;

  var vcsVocab = function vcsVocab(vcsType, vocabKey) {
    var vocab = vcsConfig(vcsType).vocabulary[vocabKey];

    if (!vocab) {
      throw new Error("Invalid vocabulary key: ".concat(vocabKey));
    }

    return vocab;
  };

  _exports.vcsVocab = vcsVocab;

  var vcsColor = function vcsColor(vcsType, colorKey) {
    var config = vcsConfigByUrlPrefixOrType(vcsType);
    var color = config.colors[colorKey];

    if (!color) {
      throw new Error("Invalid color key: ".concat(colorKey));
    }

    return color;
  };

  _exports.vcsColor = vcsColor;
  var availableProviders = Object.keys(providers);
  _exports.availableProviders = availableProviders;
});