define("travis/helpers/pretty-date", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prettyDate = prettyDate;
  _exports.default = void 0;

  function prettyDate(params) {
    var date = new Date(params[0]);
    return new Ember.String.htmlSafe((0, _moment.default)(date).format('MMMM D, YYYY H:mm:ss') || '-');
  }

  var _default = Ember.Helper.helper(prettyDate);

  _exports.default = _default;
});