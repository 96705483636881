define("travis/serializers/env-var", ["exports", "travis/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {
      repo: {
        key: 'repository_id'
      }
    },
    serialize: function serialize(snapshot, options) {
      return {
        env_var: this._super(snapshot, options)
      };
    },
    normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      payload = payload.env_var;
      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });

  _exports.default = _default;
});