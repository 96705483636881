define("travis/components/account-token", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['account-token'],
    tokenIsVisible: false,
    showCopySuccess: false,
    actions: {
      tokenVisibility: function tokenVisibility() {
        if (this.showCopySuccess) {
          this.toggleProperty('showCopySuccess');
        }

        this.toggleProperty('tokenIsVisible');
      },
      copyTokenSuccessful: function copyTokenSuccessful() {
        if (!this.showCopySuccess) {
          this.toggleProperty('showCopySuccess');
        }
      }
    }
  });

  _exports.default = _default;
});