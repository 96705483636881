define("travis/templates/components/ui-kit/badge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "faKvQxzZ",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[5,\"ui-kit/box\",[[13,1]],[[\"@color\",\"@display\",\"@padding\",\"@pin\",\"@radius\"],[[23,0,[\"color\"]],[23,0,[\"display\"]],[28,\"hash\",null,[[\"x\"],[2]]],[23,0,[\"pin\"]],\"sm\"]],{\"statements\":[[0,\"\\n  \"],[5,\"ui-kit/text\",[],[[\"@color\",\"@size\",\"@weight\"],[\"white\",[23,0,[\"size\"]],\"bold\"]],{\"statements\":[[14,2]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/ui-kit/badge.hbs"
    }
  });

  _exports.default = _default;
});